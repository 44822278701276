import moment from 'moment';
import React from 'react';
import VMasker from 'vanilla-masker';
import 'moment/locale/pt-br';
import { logoUrl } from '../../../theme';

type ExportProps = {
  credential: {
    number: number;
    userName: string;
    userDocument: string;
    observation: string;
  };
};

const date = moment().locale('pt-br').format('LLLL');

export const Export: React.FC<ExportProps> = ({ credential }) => {
  return (
    <div style={{ width: '100%', fontWeight: 'bold' }}>
      <div style={{ padding: 40 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img style={{ width: 150, height: 'auto' }} src={logoUrl} />
        </div>
        <h3 style={{ textAlign: 'center' }}>
          PROTOCOLO DE RECEBIMENTO DE PERMISSÃO DE ESTACIONAMENTO, COM ISENÇÃO
          DE PAGAMENTO NO SISTEMA ROTATIVO, PELO PERÍODO DE 12 MESES.
        </h3>
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%', border: '0.5px solid #000000' }}></div>
          <p style={{ textAlign: 'right' }}>
            {date.substring(0, date.indexOf('à') - 1)}
          </p>
        </div>
        <div style={{ width: '100%' }}>
          <p>Credencial: {credential.number}</p>
          <p>
            Usuário(a): {credential.userName} (
            {VMasker.toPattern(credential.userDocument, '999.999.999-99')})
          </p>
          <p>Delimitação: {credential.observation}</p>
          <p>Validade: 12 meses a contar da confecção</p>
        </div>
        <div style={{ width: '100%' }}>
          <p>
            Acuso o recebimento da Permissão de Estacionamento materializado
            através do Adesivo de número {credential.number}, que, deverá ser
            colado no pára-brisa do Veículo Permissionário.
          </p>
          <p>
            Qualquer adulteração no Adesivo fornecido importará no cancelamento
            ao direito de isenção de pagamento no sistema rotativo.
          </p>
          <p>
            A credencial é válida única e exclusivamente no trecho constante da
            delimitação, respeitada a normatização viária, existente no referido
            trecho.
          </p>
          <p>
            A credencial possui validade de 12 (doze) meses a contar da
            confecção, desta forma,{' '}
            <u>
              compareça à sede da concessionária 15 (quinze) dias úteis antes do
              vencimento da mesma
            </u>{' '}
            de posse de toda a documentação exigida pela lei 2018/2002.
          </p>
          <p>
            Ressaltamos que{' '}
            <u>
              enquanto a credencial estiver válida não haverá cobrança do
              sistema rotativo no trecho autorizado.
            </u>
          </p>
        </div>
        <div style={{ width: '100%', marginTop: 150 }}>
          <p style={{ textAlign: 'center' }}>Niterói, ____/____/____</p>
        </div>
        <div
          style={{
            width: '100%',
            marginTop: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '50%', border: '0.5px solid #000000' }}></div>
          <p>Assinatura do Usuário</p>
        </div>
      </div>
    </div>
  );
};
