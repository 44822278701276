// All user related database operations can be defined here.
import { api } from '..';
import {
  fetchNotificationsCancelUrl,
  fetchNotificationsFiltersUrl,
  fetchNotificationsInfoUrl,
  fetchNotificationsListUrl,
  fetchNotificationsTotalsUrl,
  fetchNotificationDetailsExportHTMLUrl,
  fetchNotificationsExportExcelUrl,
  fetchNotificationsExportHTMLUrl,
  fetchNotificationsExportFinesHTMLUrl,
  fetchNotificationsEditVehicleTypeUrl,
  fetchNotificationsExportReceiptHTMLUrl,
} from './constants';
import {
  NotificationDetailsExportResponseType,
  NotificationsExportResponseType,
  NotificationsFiltersResponseType,
  NotificationsInfoResponseType,
  NotificationsListResponseType,
  NotificationsTotalsResponseType,
  NotificationsFinesExportResponseType,
  NotificationEditVehicleTypeResponseType,
  NotificationsReceiptExportResponseType,
  NotificationsInfoForEditRecordsResponseType,
} from './types';

export const fetchNotificationsFiltersService = async () => {
  return await api
    .get<NotificationsFiltersResponseType>(fetchNotificationsFiltersUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationsListService = async (params: {
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  notificationId: string;
  situationId: string;
  routeAreaId: string;
  routeId: string;
  userName: string;
  serviceOrderOrigin: string;
  print: string;
  page: string;
}) => {
  return await api
    .get<NotificationsListResponseType>(fetchNotificationsListUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationsTotalsService = async (params: {
  startDateTime: string | undefined;
  finalDateTime: string | undefined;
  plate: string | undefined;
  notificationId: string | undefined;
  situationId: string | undefined;
  routeAreaId: string | undefined;
  routeId: string | undefined;
  userName: string | undefined;
  serviceOrderOrigin: string | undefined;
  print: string | undefined;
}) => {
  return await api
    .get<NotificationsTotalsResponseType>(fetchNotificationsTotalsUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationsInfoService = async (params: {
  notificationId: string;
}) => {
  return await api
    .get<NotificationsInfoResponseType>(
      `${fetchNotificationsInfoUrl}/${params.notificationId}`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationsCancelService = async (params: {
  notificationId: string;
  login: string;
  password: string;
}) => {
  const { notificationId, login, password } = params;
  return await api
    .put<{ msg: string }>(
      `${fetchNotificationsCancelUrl}/${notificationId}/cancel`,
      JSON.stringify({
        login: login,
        password: password,
      }),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationDetailsExportHTMLService = async (params: {
  notificationId: string;
}) => {
  return await api
    .get<NotificationDetailsExportResponseType>(
      `${fetchNotificationDetailsExportHTMLUrl}/${params.notificationId}/html`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationsExportExcelService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  routeAreaId: string;
  routeId: string;
  print: string;
  notificationId: string;
  situationId: string;
}) => {
  return await api
    .get<NotificationsExportResponseType>(fetchNotificationsExportExcelUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationsExportHTMLService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  routeAreaId: string;
  routeId: string;
  print: string;
  notificationId: string;
  situationId: string;
}) => {
  return await api
    .get<NotificationsExportResponseType>(fetchNotificationsExportHTMLUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationsExportFinesHTMLService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  routeAreaId: string;
  routeId: string;
  print: string;
  notificationId: string;
  situationId: string;
}) => {
  return await api
    .get<NotificationsFinesExportResponseType>(
      fetchNotificationsExportFinesHTMLUrl,
      {
        params: params,
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationEditVehicleTypeService = async (params: {
  notificationId: string;
  type: string;
  login: string;
  password: string;
}) => {
  const { notificationId, type, login, password } = params;
  return await api
    .put<NotificationEditVehicleTypeResponseType>(
      `${fetchNotificationsEditVehicleTypeUrl}/${notificationId}/vehicle/type`,
      JSON.stringify({
        type: type,
        login: login,
        password: password,
      }),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationsExportReceiptHTMLService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  plate: string;
  serviceOrderOrigin: string;
  routeAreaId: string;
  routeId: string;
  print: string;
  notificationId: string;
  situationId: string;
}) => {
  return await api
    .get<NotificationsReceiptExportResponseType>(
      fetchNotificationsExportReceiptHTMLUrl,
      {
        params: params,
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationsInfoForEditRecordsService = async (params: {
  notificationId: string;
}) => {
  return await api
    .get<NotificationsInfoForEditRecordsResponseType>(
      `/notifications/${params.notificationId}/records`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchNotificationEditRecordsService = async (params: {
  notificationId: string;
  registerTime: string;
  records: number[];
  login: string;
  password: string;
  observation: string;
}) => {
  const { notificationId, ...otherParams } = params;
  return await api
    .put<NotificationsInfoForEditRecordsResponseType>(
      `/notifications/${notificationId}/irregularity/records`,
      JSON.stringify(otherParams),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
