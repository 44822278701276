import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import { SalesFiltersValuesStateType } from './types';

const initialState: SalesFiltersValuesStateType = {
  days: '0',
  startDateTime: moment(
    moment().format('yyyy-MM-DD') + ' ' + moment().format('00:00'),
  )
    .format('yyyy-MM-DD HH:mm:ss')
    .toString(),
  finalDateTime: moment(
    moment().format('yyyy-MM-DD') + ' ' + moment().format('23:59'),
  )
    .format('yyyy-MM-DD HH:mm:ss')
    .toString(),
  plate: '',
  userName: '',
  serviceOrderUserId: '0',
  driverId: '0',
  serviceOrderOrigin: '0',
  paymentMethod: '0',
  routeAreaId: '0',
  routeId: '0',
  reprint: '0',
  hasNotPlate: '0',
  hasIrregularity: '0',
  hasDiscount: '0',
  profileId: '0',
  posMachine: '0',
};

export const salesFiltersValuesSlice = createSlice({
  name: 'salesFiltersValues',
  initialState,
  reducers: {
    setSalesFiltersValues(
      state,
      action: PayloadAction<SalesFiltersValuesStateType>,
    ) {
      state.days = action.payload.days;
      state.startDateTime = action.payload.startDateTime;
      state.finalDateTime = action.payload.finalDateTime;
      state.plate = action.payload.plate;
      state.userName = action.payload.userName;
      state.serviceOrderUserId = action.payload.serviceOrderUserId;
      state.driverId = action.payload.driverId;
      state.serviceOrderOrigin = action.payload.serviceOrderOrigin;
      state.paymentMethod = action.payload.paymentMethod;
      state.routeAreaId = action.payload.routeAreaId;
      state.routeId = action.payload.routeId;
      state.reprint = action.payload.reprint;
      state.hasNotPlate = action.payload.hasNotPlate;
      state.hasIrregularity = action.payload.hasIrregularity;
      state.hasDiscount = action.payload.hasDiscount;
      state.profileId = action.payload.profileId;
      state.posMachine = action.payload.posMachine;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSalesFiltersValues } = salesFiltersValuesSlice.actions;

// // now available:
// filtersValuesSlice.actions.setSalesFiltersValues();
// // also available:
// filtersValuesSlice.caseReducers.increment(0, { type: 'increment', payload: 5 });
