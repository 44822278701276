// All user related database operations can be defined here.
import { api } from '..';
import {
  fetchAreasListUrl,
  fetchAreasCreateUrl,
  fetchAreasCancelUrl,
  fetchAreasEditUrl,
  fetchAreasInfoUrl,
} from './constants';
import {
  AreasCancelResponseType,
  AreasCreateResponseType,
  AreasEditResponseType,
  AreasInfoResponseType,
  AreasListResponseType,
} from './types';

export const fetchAreasListService = async () => {
  return await api
    .get<AreasListResponseType>(fetchAreasListUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchAreasCreateService = async (params: {
  name: string;
  description: string | null;
  color: string;
}) => {
  return await api
    .post<AreasCreateResponseType>(fetchAreasCreateUrl, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchAreasInfoService = async (params: { areaId: string }) => {
  return await api
    .get<AreasInfoResponseType>(`${fetchAreasInfoUrl}/${params.areaId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchAreasCancelService = async (params: { areaId: string }) => {
  return await api
    .delete<AreasCancelResponseType>(`${fetchAreasCancelUrl}/${params.areaId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchAreasEditService = async (params: {
  areaId: string;
  name: string;
  description: string | undefined;
  color: string;
}) => {
  const { areaId, name, description, color } = params;
  return await api
    .put<AreasEditResponseType>(
      `${fetchAreasEditUrl}/${areaId}`,
      JSON.stringify({
        name: name,
        description: description,
        color: color,
      }),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};
