/* eslint-disable no-invalid-this */
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required('O nome é obrigatório'),
  areaId: Yup.string().required('Selecione uma área'),
  configurationId: Yup.string().required('Selecione uma configuração'),
  coordinates: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        address: Yup.string(),
        latitude: Yup.number(),
        longitude: Yup.number(),
        // errors: { [key: string]: string | undefined };
      }),
    )
    .min(2, 'Insira pelo menos um trecho'),
  vacancies: Yup.array()
    .of(
      Yup.object().shape({
        quantity: Yup.number().required('Insira uma quantidade'),
        type: Yup.string().required('Escolha um tipo'),
        // errors: { [key: string]: string | undefined };
      }),
    )
    .min(1, 'Insira pelo menos um tipo de vaga'),
});
