import DeleteIcon from '@mui/icons-material/Delete';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import EditRoadTwoToneIcon from '@mui/icons-material/EditRoadTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Button as MuiButton } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { find, forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, TableText, Switch } from '../../../components';
import { USER_ROUTES, USER_UPDATE } from '../../../routes';
import {
  fetchUsersList,
  useDispatch,
  useSelector,
  fetchUserInfo,
  userSlice,
  fetchUserBlock,
  usersListSlice,
} from '../../../stores';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { UsersTableToolbar } from './UsersTableToolbar';

export const UsersTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { usersList, usersListLoading } = useSelector(
    (state) => state.usersListReducer,
  );
  const { filters } = useSelector((state) => state.usersFiltersReducer);
  const filtersValues = useSelector((state) => state.usersFiltersValuesReducer);
  const { usersPage } = useSelector((state) => state.usersListReducer);
  const responsiveMode = useResponsiveMode();
  const [rows, setRows] = useState<any[]>([]);
  const [loadingSwitches, setLoadingSwitches] = useState<boolean[]>([]);

  const { userName, login, areaId, profileId } = filtersValues;

  useEffect(() => {
    if (usersList && usersList !== undefined) {
      setRows(usersList.page);
    } else setRows([]);
  }, [usersList]);

  const handleRoutes = (userId: GridRowId) => {
    dispatch(fetchUserInfo({ userId: userId.toString() })).then(() => {
      navigate(USER_ROUTES);
    });
  };

  const handleEdit = (userId: GridRowId) => {
    dispatch(fetchUserInfo({ userId: userId.toString() })).then(() => {
      navigate(USER_UPDATE);
    });
  };

  const handleCancel = (userId: GridRowId) => {
    dispatch(fetchUserInfo({ userId: userId.toString() }));
    dispatch(userSlice.actions.toggleUserCancelIsOpen());
  };

  const handleAreasText = (areas: string[]): string => {
    if (filters) {
      let areasText = '';
      forEach(areas, (areaId) => {
        areasText += `${find(filters.areas, ['id', areaId])?.value}, `;
      });
      return areasText.substring(0, areasText.length - 2);
    } else {
      return `Indisponível`;
    }
  };

  const handleProfileText = (profileId: number): string => {
    if (filters) {
      const profileText = find(filters.profiles, {
        id: profileId.toString(),
      })?.value;
      return profileText || '';
    } else {
      return `Indisponível`;
    }
  };

  const handleCheckAppBlock = (id: string, checked: boolean): void => {
    const handledLoadingSwitches = loadingSwitches;
    handledLoadingSwitches[parseInt(id)] = true;
    setLoadingSwitches(handledLoadingSwitches);

    dispatch(fetchUserBlock({ userId: id, block: checked ? '1' : '0' })).then(
      (response) => {
        const handledLoadingSwitches = loadingSwitches;
        handledLoadingSwitches[parseInt(id)] = false;
        setLoadingSwitches(handledLoadingSwitches);

        dispatch(
          usersListSlice.actions.setUserBlock({
            userId: response.meta.arg.userId,
          }),
        );
      },
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => <TableText label={params.row.name} size={30} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'login',
      headerName: 'Login',
      editable: false,
      sortable: false,
      width: 60,
      align: 'center',
      renderCell: (params) => <TableText label={params.row.login} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'areas',
      headerName: 'Áreas',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => (
        <TableText label={handleAreasText(params.row.areas)} size={50} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'profile',
      headerName: 'Perfil',
      // type: 'date',
      editable: false,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <TableText label={handleProfileText(params.row.profileId)} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'appBlock',
      headerName: 'Bloqueio',
      // type: 'date',
      editable: false,
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <Switch
          color='secondary'
          checked={params.row.appBlock === 0 ? false : true}
          onChange={(event, checked) =>
            handleCheckAppBlock(params.id.toString(), checked)
          }
          loading={loadingSwitches[params.row.id]}
        />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: responsiveMode === 'large-screen' ? 300 : 150,
      renderCell: (params) => {
        return responsiveMode === 'large-screen' ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant='contained'
              label='Editar'
              startIcon={<EditIcon />}
              size='small'
              onClick={() => handleEdit(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
            <Button
              variant='contained'
              label='Trechos'
              startIcon={<EditRoadIcon />}
              size='small'
              onClick={() => handleRoutes(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
            <Button
              variant='contained'
              color='secondary'
              label='Excluir'
              startIcon={<DeleteIcon />}
              size='small'
              onClick={() => handleCancel(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <MuiButton
              variant='contained'
              size='small'
              onClick={() => handleEdit(params.id)}
              sx={{
                minWidth: '30px',
                marginLeft: '5px',
                marginRight: '5px',
              }}
            >
              <EditTwoToneIcon />
            </MuiButton>
            <MuiButton
              variant='contained'
              size='small'
              onClick={() => handleRoutes(params.id)}
              sx={{
                minWidth: '30px',
                marginRight: '5px',
              }}
            >
              <EditRoadTwoToneIcon />
            </MuiButton>
            <MuiButton
              variant='contained'
              color='secondary'
              size='small'
              onClick={() => handleCancel(params.id)}
              sx={{
                minWidth: '30px',
              }}
            >
              <DeleteTwoToneIcon />
            </MuiButton>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => <TableText label={params.row.name} size={30} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'login',
      headerName: 'Login',
      editable: false,
      sortable: false,
      width: 60,
      align: 'center',
      renderCell: (params) => <TableText label={params.row.login} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'areas',
      headerName: 'Áreas',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => (
        <TableText label={handleAreasText(params.row.areas)} size={50} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'profile',
      headerName: 'Perfil',
      // type: 'date',
      editable: false,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <TableText label={handleProfileText(params.row.profileId)} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'appBlock',
      headerName: 'Bloqueio',
      // type: 'date',
      editable: false,
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <Switch
          color='secondary'
          checked={params.row.appBlock === 0 ? false : true}
          onChange={(event, checked) =>
            handleCheckAppBlock(params.id.toString(), checked)
          }
          loading={loadingSwitches[params.row.id]}
        />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: responsiveMode === 'large-screen' ? 300 : 150,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <MuiButton
              variant='contained'
              size='small'
              onClick={() => handleEdit(params.id)}
              sx={{
                minWidth: '30px',
                marginLeft: '5px',
                marginRight: '5px',
              }}
            >
              <EditTwoToneIcon />
            </MuiButton>
            <MuiButton
              variant='contained'
              size='small'
              onClick={() => handleRoutes(params.id)}
              sx={{
                minWidth: '30px',
                marginRight: '5px',
              }}
            >
              <EditRoadTwoToneIcon />
            </MuiButton>
            <MuiButton
              variant='contained'
              color='secondary'
              size='small'
              onClick={() => handleCancel(params.id)}
              sx={{
                minWidth: '30px',
              }}
            >
              <DeleteTwoToneIcon />
            </MuiButton>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  return (
    <Table
      page={usersPage}
      changePage={(value: number) => usersListSlice.actions.setUsersPage(value)}
      rows={rows}
      rowCount={usersList ? usersList.size : 0}
      fetchRows={() => {
        dispatch(
          fetchUsersList({
            userName: userName,
            login: login,
            areaId: areaId,
            profileId: profileId,
          }),
        );
      }}
      columns={
        responsiveMode === 'phone' || responsiveMode === 'tablet'
          ? columnsResponsive
          : columns
      }
      toolbar={<UsersTableToolbar />}
      loading={usersListLoading}
    />
  );
};
