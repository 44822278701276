import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { filter, map, replace, includes, toLower, forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ProgressCircle, Table, TableText } from '../../../components';
import {
  fetchOperationalDashboardNotifications,
  fetchOperationalDashboardSales,
  operationalDashboardSlice,
  useDispatch,
  useSelector,
} from '../../../stores';
import { palette } from '../../../theme';
import useResponsiveMode from '../../../utils/useResponsiveMode';

export const UsersListTable: React.FC = () => {
  const dispatch = useDispatch();
  const {
    operationalDashboardAreas,
    operationalDashboardAreasLoading,
    operationalDashboardUsersList,
    operationalDashboardUsersFullList,
    operationalDashboardUsersLoading,
    operationalDashboardUsersPage,
    operationalDashboardReloadResources,
  } = useSelector((state) => state.operationalDashboardReducer);
  const responsiveMode = useResponsiveMode();
  const [operatingAreasIds, setOperatingAreasIds] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [loadingRows, setLoadingRows] = useState(false);
  const [search, setSearch] = useState<string>('');
  const PAGESIZE = 10;

  useEffect(() => {
    setLoadingRows(true);
    setOperatingAreasIds(map(operationalDashboardAreas, 'id'));
    setLoadingRows(false);
  }, [operationalDashboardAreas]);

  useEffect(() => {
    handlePageData();
  }, [operationalDashboardUsersPage]);

  useEffect(() => {
    if (search === '') {
      setRows(operationalDashboardUsersFullList);
    } else {
      const filteredList = filter(operationalDashboardUsersFullList, (item) => {
        return includes(toLower(item.name), toLower(search));
      });

      setRows(filteredList);

      const delayDebounceFn = setTimeout(() => {
        handlePageData();
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [operationalDashboardUsersFullList, search]);

  useEffect(() => {
    if (
      operationalDashboardReloadResources &&
      operationalDashboardUsersFullList.length > 0
    ) {
      dispatch(
        operationalDashboardSlice.actions.setOperationalDashboardReloadResources(
          false,
        ),
      );
      handlePageData();
    }
  }, [rows]);

  const handlePageData = async () => {
    setLoadingRows(true);
    const startIdx = (operationalDashboardUsersPage - 1) * PAGESIZE;
    for (let i = 0; i < PAGESIZE; i++) {
      if (rows.length > 0) {
        if (
          rows[startIdx + i].salesToday === undefined ||
          rows[startIdx + i].salesLastWeek === undefined
        ) {
          await dispatch(
            fetchOperationalDashboardSales({
              areas: operatingAreasIds,
              userId: rows[startIdx + i].id,
            }),
          );
        }
        if (
          rows[startIdx + i].notificationsToday === undefined ||
          rows[startIdx + i].notificationsLastWeek === undefined
        ) {
          await dispatch(
            fetchOperationalDashboardNotifications({
              areas: operatingAreasIds,
              userId: rows[startIdx + i].id,
            }),
          );
        }
      }
    }
    setLoadingRows(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => <TableText label={params.row.name} size={30} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'sales',
      headerName: 'Vendas',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) =>
        params.row.salesToday !== undefined ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {params.row.salesToday}
            {params.row.salesDiffValue !== '0' ? (
              parseInt(params.row.salesDiffValue) < 0 ? (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <ArrowUpwardIcon
                    color='success'
                    sx={{
                      fontSize: 15,
                      // textAlign: 'center',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: palette.success.main,
                      paddingBottom: '12px',
                    }}
                  >
                    {replace(params.row.salesDiffValue, '-', '+')}
                  </Typography>
                </div>
              ) : (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: palette.error.main,
                      paddingTop: '15px',
                    }}
                  >
                    {'-' + params.row.salesDiffValue}
                  </Typography>
                  <ArrowDownwardIcon color='error' sx={{ fontSize: 15 }} />
                </div>
              )
            ) : null}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              paddingLeft: '15px',
            }}
          >
            <ProgressCircle color='primary' size={15} />
          </div>
        ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'notifications',
      headerName: 'Notificações',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) =>
        params.row.notificationsToday !== undefined ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              paddingLeft: '30px',
            }}
          >
            {params.row.notificationsToday}
            {params.row.notificationsDiffValue !== '0' ? (
              parseInt(params.row.notificationsDiffValue) <= 0 ? (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <ArrowUpwardIcon
                    color='success'
                    sx={{
                      fontSize: 15,
                      // textAlign: 'center',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: palette.success.main,
                      paddingBottom: '12px',
                    }}
                  >
                    {replace(params.row.notificationsDiffValue, '-', '+')}
                  </Typography>
                </div>
              ) : (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: palette.error.main,
                      paddingTop: '15px',
                    }}
                  >
                    {'-' + params.row.notificationsDiffValue}
                  </Typography>
                  <ArrowDownwardIcon color='error' sx={{ fontSize: 15 }} />
                </div>
              )
            ) : null}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              paddingLeft: '30px',
            }}
          >
            <ProgressCircle color='primary' size={15} />
          </div>
        ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 2,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'sales',
      headerName: 'Vendas',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) =>
        params.row.salesToday !== undefined ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              paddingLeft: '15px',
            }}
          >
            {params.row.salesToday}
            {params.row.salesDiffValue !== '0' ? (
              parseInt(params.row.salesDiffValue) <= 0 ? (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <ArrowUpwardIcon
                    color='success'
                    sx={{
                      fontSize: 15,
                      // textAlign: 'center',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: palette.success.main,
                      paddingBottom: '12px',
                    }}
                  >
                    {replace(params.row.salesDiffValue, '-', '+')}
                  </Typography>
                </div>
              ) : (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: palette.error.main,
                      paddingTop: '15px',
                    }}
                  >
                    {'-' + params.row.salesDiffValue}
                  </Typography>
                  <ArrowDownwardIcon color='error' sx={{ fontSize: 15 }} />
                </div>
              )
            ) : null}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              paddingLeft: '15px',
            }}
          >
            <ProgressCircle color='primary' size={15} />
          </div>
        ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'notifications',
      headerName: 'Notificações',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) =>
        params.row.notificationsToday !== undefined ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              paddingLeft: '30px',
            }}
          >
            {params.row.notificationsToday}
            {params.row.notificationsDiffValue !== '0' ? (
              parseInt(params.row.notificationsDiffValue) <= 0 ? (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <ArrowUpwardIcon
                    color='success'
                    sx={{
                      fontSize: 15,
                      // textAlign: 'center',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: palette.success.main,
                      paddingBottom: '12px',
                    }}
                  >
                    {replace(params.row.notificationsDiffValue, '-', '+')}
                  </Typography>
                </div>
              ) : (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: palette.error.main,
                      paddingTop: '15px',
                    }}
                  >
                    {'-' + params.row.notificationsDiffValue}
                  </Typography>
                  <ArrowDownwardIcon color='error' sx={{ fontSize: 15 }} />
                </div>
              )
            ) : null}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              paddingLeft: '30px',
            }}
          >
            <ProgressCircle color='primary' size={15} />
          </div>
        ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  return (
    <Table
      paginationMode='client'
      page={operationalDashboardUsersPage}
      pageSize={PAGESIZE}
      changePage={(value: number) => {
        dispatch(
          operationalDashboardSlice.actions.setOperationalDashboardUsersPage(
            value,
          ),
        );
      }}
      rows={rows}
      rowCount={
        operationalDashboardUsersList ? operationalDashboardUsersList.length : 0
      }
      columns={
        responsiveMode === 'phone' || responsiveMode === 'tablet'
          ? columnsResponsive
          : columns
      }
      detachedToolbar={
        <TextField
          id='search-input'
          placeholder='Filtrar...'
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant='standard'
        />
      }
      loading={
        operationalDashboardUsersLoading ||
        operationalDashboardAreasLoading ||
        loadingRows
      }
    />
  );
};
