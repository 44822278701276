/* eslint-disable pii/no-phone-number */
import DashboardIcon from '@mui/icons-material/Dashboard';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Grid, Skeleton, Typography } from '@mui/material';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  PageWrapper,
  FiltersGrid,
  DropdownMultiInput,
  Button,
  Input,
} from '../../components';
import {
  operationalDashboardSlice,
  fetchOperationalDashboardUsers,
  fetchOperationalDashboardAreas,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { useInterval } from '../../utils/useInterval';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { UsersListTable } from './table';

export const OperationalDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const {
    operationalDashboardAreas,
    operationalDashboardFilteredAreas,
    operationalDashboardLoading,
    operationalDashboardRequestLoading,
    operationalDashboardAreasLoading,
  } = useSelector((state) => state.operationalDashboardReducer);
  const responsiveMode = useResponsiveMode();
  const [selectedAreas, setSelectedAreas] = useState<
    { id: string; value: string }[]
  >(operationalDashboardAreas);
  const TIME_OF_INTERVAL = 180000;

  useEffect(() => {
    dispatch(fetchOperationalDashboardAreas());
  }, []);

  useEffect(() => {
    dispatch(
      fetchOperationalDashboardUsers({
        areas: map(operationalDashboardAreas, 'id'),
      }),
    );
  }, [operationalDashboardAreas]);

  const handleUpdate = () => {
    dispatch(
      fetchOperationalDashboardUsers({
        areas: map(operationalDashboardFilteredAreas, 'id'),
      }),
    );
  };

  useInterval(handleUpdate, TIME_OF_INTERVAL);

  const handleSubmit = () => {
    const operatingAreasIds = map(selectedAreas, 'id');
    dispatch(
      operationalDashboardSlice.actions.setOperationalDashboardFilteredAreas(
        selectedAreas,
      ),
    );
    dispatch(fetchOperationalDashboardUsers({ areas: operatingAreasIds }));
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h6'}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <DashboardIcon fontSize='large' sx={{ marginRight: 2 }} />
            Painel de controle operacional
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <FiltersGrid
          loading={operationalDashboardLoading}
          loadingRows={3}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            marginBottom: '20px',
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            margin={1}
            sx={{
              display: 'flex',
              flexDirection:
                responsiveMode === 'phone' || responsiveMode === 'tablet'
                  ? 'column'
                  : 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {operationalDashboardAreasLoading ? (
              <Skeleton
                animation='wave'
                width='100%'
                sx={{ padding: 0, margin: 0, minHeight: '147px' }}
              >
                <DropdownMultiInput
                  options={operationalDashboardAreas}
                  defaultValue={operationalDashboardAreas}
                  label='Áreas'
                  value={selectedAreas}
                  onChange={(value) => {
                    setSelectedAreas(value);
                  }}
                />
              </Skeleton>
            ) : (
              <DropdownMultiInput
                options={operationalDashboardAreas}
                defaultValue={operationalDashboardAreas}
                label='Áreas'
                value={selectedAreas}
                onChange={(value) => {
                  setSelectedAreas(value);
                }}
              />
            )}
            <Button
              variant='contained'
              startIcon={<FilterAltIcon />}
              label='Filtrar'
              sx={{ minWidth: '150px' }}
              loading={operationalDashboardRequestLoading}
              onClick={handleSubmit}
            />
          </Grid>
        </FiltersGrid>
        <UsersListTable />
      </Grid>
    </PageWrapper>
  );
};
