import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CredentialFiltersValueStateType = {
  number: string;
  plate: string;
  name: string;
  document: string;
  street: string;
  district: string;
  routesId: string;
  validitiesId: string;
  status: 'Todos' | 'N' | 'S';
};

const initialState: CredentialFiltersValueStateType = {
  number: '',
  plate: '',
  name: '',
  document: '',
  street: '',
  district: '',
  routesId: '0',
  validitiesId: '0',
  status: 'S',
};

export const credentialsFiltersValuesSlice = createSlice({
  name: 'credentialsFiltersValues',
  initialState,
  reducers: {
    setCredentialsFiltersValues: (
      state,
      action: PayloadAction<CredentialFiltersValueStateType>,
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetCredentialsFiltersValues: () => {
      return initialState;
    },
  },
});

export const { setCredentialsFiltersValues, resetCredentialsFiltersValues } =
  credentialsFiltersValuesSlice.actions;
