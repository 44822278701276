import { Skeleton } from '@mui/material';
import React from 'react';
import { randomIntFromInterval } from '../../utils/Helpers';
import useResponsiveMode from '../../utils/useResponsiveMode';
import {
  StyledFiltersGrid,
  StyledSkeletonGrid,
  StyledSkeletonGridRow,
} from './styles';
import { FiltersGridProps } from './types';

export const FiltersGrid = (props: FiltersGridProps) => {
  const { children, loading, loadingRows, ...otherProps } = props;
  const responsiveMode = useResponsiveMode();

  const CustomLoading = () => {
    return (
      <StyledSkeletonGrid>
        {Array.from(
          { length: loadingRows !== undefined ? loadingRows : 0 },
          () => (
            <StyledSkeletonGridRow key={Math.random().toString()}>
              {Array.from({ length: 7 }, () => (
                <Skeleton
                  key={Math.random().toString()}
                  animation='wave'
                  height={75}
                  width={randomIntFromInterval(100, 200)}
                  sx={{
                    marginLeft: 1,
                    marginRight: 1,
                    marginTop: 0.8,
                    marginBottom: 0.8,
                  }}
                />
              ))}
            </StyledSkeletonGridRow>
          ),
        )}
      </StyledSkeletonGrid>
    );
  };

  return (
    <StyledFiltersGrid
      container
      boxShadow={responsiveMode === 'phone' ? 0 : 1}
      sx={{
        paddingLeft: responsiveMode === 'phone' ? 0 : 1,
        paddingRight: responsiveMode === 'phone' ? 0 : 1,
        minHeight: responsiveMode === 'phone' ? 0 : 100,
      }}
      {...otherProps}
    >
      {loading ? <CustomLoading /> : children}
    </StyledFiltersGrid>
  );
};
