import SaveIcon from '@mui/icons-material/Save';
import { Box } from '@mui/material';
import { GridToolbarQuickFilter, GridToolbar } from '@mui/x-data-grid';
import React from 'react';
import { Button } from '../../../components';
import { areasSlice, useDispatch } from '../../../stores';
import { StyledTableHeader } from '../styles';

export const SavedAreasTableToolbar = () => {
  const dispatch = useDispatch();

  return (
    <StyledTableHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <Box
          sx={{
            p: 0.5,
            pb: 0,
          }}
        >
          <GridToolbarQuickFilter placeholder='Filtrar...' />
        </Box>
        <Button
          variant='contained'
          label='Cadastrar nova área'
          startIcon={<SaveIcon />}
          onClick={() => dispatch(areasSlice.actions.toggleAreasCreateIsOpen())}
        />
      </div>
    </StyledTableHeader>
  );
};
