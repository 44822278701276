import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { Button, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  routeSlice,
  fetchRouteCancel,
  fetchRoutesList,
} from '../../../stores';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const CancelRouteModal: React.FC = () => {
  const dispatch = useDispatch();
  const { routeInfo, routeInfoLoading, routeCancelIsOpen, routeCancelLoading } =
    useSelector((state) => state.routeReducer);
  const filtersValues = useSelector(
    (state) => state.routesFiltersValuesReducer,
  );

  const handleClose = () => {
    dispatch(routeSlice.actions.toggleRouteCancelIsOpen());
  };

  const handleSubmit = () => {
    if (routeInfo) {
      dispatch(fetchRouteCancel({ routeId: routeInfo.id.toString() })).then(
        (result) => {
          handleClose();
          if (result.meta.requestStatus === 'fulfilled') {
            dispatch(fetchRoutesList(filtersValues));
          }
        },
      );
    }
  };

  const handleReturn = () => {
    dispatch(routeSlice.actions.toggleRouteCancelIsOpen());
  };

  return (
    <Modal
      open={routeCancelIsOpen}
      onClose={handleClose}
      title='Deseja excluir essa área?'
    >
      <>
        {routeInfoLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <Skeleton animation='wave' height={29} width='100%' />
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Nome:
                </Typography>
                <Typography>{routeInfo?.name}</Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: '10px',
          }}
        >
          <Button
            variant='contained'
            label='Excluir'
            startIcon={<DeleteIcon />}
            type='submit'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={routeCancelLoading}
            onClick={handleSubmit}
          />
          <Button
            color='secondary'
            variant='contained'
            type='reset'
            startIcon={<ArrowBackIcon />}
            label='Voltar'
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleReturn}
          />
        </Box>
      </>
    </Modal>
  );
};
