import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { SalesExportCommissionsTxtResponseType } from '../../../../services/Sales/types';
import { salesListSlice } from '../../../../stores';

export const exportSalesCommissionsToTXT = async (
  apiData: SalesExportCommissionsTxtResponseType,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  workerOne: (apiData: SalesExportCommissionsTxtResponseType) => Promise<
    {
      type: string;
      value: number;
      profileId: number;
    }[]
  >,
  workerTwo: (
    apiData: SalesExportCommissionsTxtResponseType,
    handledCommissions: {
      type: string;
      value: number;
      profileId: number;
    }[],
  ) => Promise<{ userId: number; login: string; total: number }[]>,
  workerThree: (
    handledApiData: { userId: number; login: string; total: number }[],
  ) => Promise<string>,
): Promise<void> => {
  dispatch(salesListSlice.actions.setSalesExportPercentage(20));

  let handledApiData = '';
  if (apiData.commissions && apiData.sales) {
    handledApiData = await workerOne(apiData).then(async (workerOneReturn) => {
      dispatch(salesListSlice.actions.setSalesExportPercentage(40));
      return workerTwo(apiData, workerOneReturn).then(
        async (workerTwoReturn) => {
          dispatch(salesListSlice.actions.setSalesExportPercentage(80));
          return workerThree(workerTwoReturn);
        },
      );
    });
  }
  dispatch(salesListSlice.actions.setSalesExportPercentage(100));

  const fileType = 'text/plain;charset=utf-8';
  const fileExtension = '.txt';
  const blob = new Blob([handledApiData], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `relatório-comissões-${moment().format('yyyy-MM-DD_hh-mm')}` +
      fileExtension,
  );
};

export const handleApiDataSalesCommissionsToTXTPartOne = (
  apiData: SalesExportCommissionsTxtResponseType,
) => {
  // Create handledCommissions array with all types of commissions with values per 1 min
  const handledCommissions: {
    type: string;
    value: number;
    profileId: number;
  }[] = [];

  // eslint-disable-next-line lodash/prefer-lodash-method
  apiData.commissions.map((item) => {
    // eslint-disable-next-line lodash/prefer-lodash-method
    const splitTime = item.time.split(':');
    if (item.type !== 'Moto') {
      const timeInMinutes =
        parseInt(splitTime[0]) * 60 +
        parseInt(splitTime[1]) +
        parseInt(splitTime[2]) / 60;

      handledCommissions.push({
        type: item.type,
        value: item.price / timeInMinutes,
        profileId: item.profileId,
      });
    } else {
      handledCommissions.push({
        type: item.type,
        value: item.price,
        profileId: item.profileId,
      });
    }
  });

  return handledCommissions;
};

export const handleApiDataSalesCommissionsToTXTPartTwo = (
  apiData: SalesExportCommissionsTxtResponseType,
  handledCommissions: {
    type: string;
    value: number;
    profileId: number;
  }[],
) => {
  // Calculate sum of each type of sales
  const handledApiData: { userId: number; login: string; total: number }[] = [];
  // eslint-disable-next-line lodash/prefer-lodash-method
  apiData.sales.map((item) => {
    // eslint-disable-next-line lodash/prefer-lodash-method
    const idx = handledApiData.findIndex(
      (auxItem) => auxItem.userId === item.userId,
    );
    const commissionObj =
      item.profileId !== 8
        ? // eslint-disable-next-line lodash/prefer-lodash-method
          handledCommissions.find((auxItem) => {
            return auxItem.type === item.type && auxItem.profileId === 0;
          })
        : // eslint-disable-next-line lodash/prefer-lodash-method
          handledCommissions.find((auxItem) => {
            return auxItem.type === item.type && auxItem.profileId === 8;
          });

    // eslint-disable-next-line lodash/prefer-lodash-method
    const eventOriginalValue = apiData.commissions.find((item) => {
      return item.type === 'Evento';
    })?.price;

    if (idx !== -1) {
      if (item.type === 'Moto') {
        handledApiData[idx].total += commissionObj
          ? item.count * commissionObj.value
          : 0;
      } else if (item.type === 'Evento') {
        handledApiData[idx].total += eventOriginalValue
          ? (item.sumPrice / 4.0) * eventOriginalValue
          : 0;
      } else {
        handledApiData[idx].total += commissionObj
          ? parseInt(item.sumMinutes) * commissionObj?.value
          : 0;
      }
    } else {
      if (item.type === 'Moto') {
        handledApiData.push({
          userId: item.userId,
          login: item.login,
          total: commissionObj ? item.count * commissionObj.value : 0,
        });
      } else if (item.type === 'Evento') {
        handledApiData.push({
          userId: item.userId,
          login: item.login,
          total: eventOriginalValue
            ? (item.sumPrice / 4.0) * eventOriginalValue
            : 0,
        });
      } else {
        handledApiData.push({
          userId: item.userId,
          login: item.login,
          total: commissionObj
            ? commissionObj.value
              ? parseInt(item.sumMinutes) * commissionObj.value
              : parseInt(item.sumMinutes)
            : 0,
        });
      }
    }
  });

  return handledApiData;
};

export const handleApiDataSalesCommissionsToTXTPartThree = (
  handledApiData: { userId: number; login: string; total: number }[],
) => {
  // Set the string for the file
  let content = '';
  // eslint-disable-next-line lodash/prefer-lodash-method
  handledApiData.map((item) => {
    content =
      content +
      // eslint-disable-next-line lodash/prefer-lodash-method
      `${item.login.padStart(6, '0')}      ${item.total
        .toFixed(2)
        .toString()
        .replace('.', ',')}\n`;
  });

  return content;
};
