import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  ButtonProps as MuiButtonProps,
  Grow,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@mui/material';
import { map } from 'lodash';
import React from 'react';
// import { Button } from '../Button';
import { StyledButtonGroup, StyledButton } from './styles';

interface SplitButtonProps extends MuiButtonProps {
  label: string;
  loading?: boolean;
  options: {
    label: string;
    icon?: any;
    onClick: (values: any) => void;
  }[];
}

export const SplitButton = (props: SplitButtonProps) => {
  const { label, loading, options, startIcon, sx, ...rest } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <StyledButtonGroup
        variant='contained'
        ref={anchorRef}
        aria-label='split button'
        sx={sx}
      >
        <StyledButton
          variant='contained'
          startIcon={startIcon}
          loading={loading}
          disabled
          sx={{
            width: '80%',
          }}
        >
          {label}
        </StyledButton>
        <StyledButton
          variant='contained'
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          startIcon={<ArrowDropDownIcon />}
          sx={{
            width: '20%',
          }}
        />
      </StyledButtonGroup>
      <Popper
        style={{
          zIndex: 1000,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {map(options, (option, index) => (
                    <MenuItem key={option.label} onClick={option.onClick}>
                      {option.label} {option.icon}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
