import { styled, Typography } from '@mui/material';
import { palette } from '../../../theme';

export const StyledGoogleMapDiv = styled('div')(() => ({
  height: '70vh',
  width: '100%',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  overflow: 'hidden',
}));

export const StyledMarkerDiv = styled(Typography)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const StyledMapMarker = styled(Typography)(() => ({
  height: '34px',
  backgroundColor: palette.secondary.main,
  whiteSpace: 'nowrap',
  padding: 5,
  borderRadius: '0 5px 5px 0',
  boxShadow: '2px 2px 5px grey',
  position: 'absolute',
  top: '-17px',
  left: '17px',
  color: 'white',
  cursor: 'pointer',

  '&::before': {
    position: 'absolute',
    content: '""',
    borderTop: '17px solid transparent',
    borderLeft: '17px solid transparent',
    borderBottom: '17px solid transparent',
    borderRight: `17px solid ${palette.secondary.main}`,
    top: '0',
    left: '-17px',
    transform: 'translate(-50%, 0)',
    display: 'block',
  },
}));
