import { useWorker } from '@koale/useworker';
import DownloadIcon from '@mui/icons-material/Download';
import { PayloadAction } from '@reduxjs/toolkit';
import { map } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Button } from '../../../components';
import {
  useDispatch,
  useSelector,
  fetchSessionsExportExcel,
  fetchSessionsExportHTML,
  sessionsListSlice,
} from '../../../stores';
import { StyledTableHeader } from '../styles';
import {
  exportSessionsToCSV,
  exportSessionsToHTML,
  handleApiDataSessionsToCSV,
  handleApiDataSessionsToHTML,
} from './exports';

export const HistoryTableToolbar = () => {
  const dispatch = useDispatch();
  const filtersValues = useSelector(
    (state) => state.sessionsFiltersValuesReducer,
  );
  const { sessionsExportExcelLoading, sessionsExportHTMLLoading } = useSelector(
    (state) => state.sessionsListReducer,
  );
  const [exportSessionsToCSVWorker] = useWorker(handleApiDataSessionsToCSV);
  const [exportSessionsToHTMLWorker] = useWorker(handleApiDataSessionsToHTML);

  const handleExportExcel = () => {
    dispatch(fetchSessionsExportExcel(filtersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          exportSessionsToCSV(
            res.payload.data,
            dispatch,
            exportSessionsToCSVWorker,
          ).then(() => {
            dispatch(sessionsListSlice.actions.closeExportModal());
          });
        } else dispatch(sessionsListSlice.actions.closeExportModal());
      },
    );
  };

  const handleExportHTML = () => {
    dispatch(fetchSessionsExportHTML(filtersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          const handledData = map(res.payload.data, (item) => {
            const startDateTime = moment(item.startDateTime)
              .format('DD/MM/YYYY HH:mm:ss')
              .toString();
            const finalDateTime = moment(item.finalDateTime)
              .format('DD/MM/YYYY HH:mm:ss')
              .toString();
            return {
              ...item,
              startDateTime: startDateTime,
              finalDateTime:
                finalDateTime === '01/01/0001 12:00:00' ||
                finalDateTime === '01/01/0001 00:00:00'
                  ? '--'
                  : finalDateTime,
            };
          });

          exportSessionsToHTML(
            handledData,
            dispatch,
            exportSessionsToHTMLWorker,
          ).then(() => {
            dispatch(sessionsListSlice.actions.closeExportModal());
          });
        } else dispatch(sessionsListSlice.actions.closeExportModal());
      },
    );
  };

  return (
    <StyledTableHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <Button
          variant='contained'
          label='Exportar em html'
          startIcon={<DownloadIcon />}
          onClick={() => handleExportHTML()}
          loading={sessionsExportHTMLLoading}
        />
        <Button
          variant='contained'
          label='Exportar em excel'
          startIcon={<DownloadIcon />}
          onClick={() => handleExportExcel()}
          loading={sessionsExportExcelLoading}
        />
      </div>
    </StyledTableHeader>
  );
};
