import { Checkbox as MuiCheckbox, Typography } from '@mui/material';
import React from 'react';
import { palette } from '../../theme';
import { StyledCheckboxWrapper } from './styles';
import { CheckboxProps } from './types';

export const Checkbox = (props: CheckboxProps) => {
  const { label, ...otherProps } = props;

  return (
    <StyledCheckboxWrapper>
      <MuiCheckbox {...otherProps} />
      <Typography color={otherProps.disabled ? palette.grey[400] : 'text'}>
        {label}
      </Typography>
    </StyledCheckboxWrapper>
  );
};
