/* eslint-disable pii/no-phone-number */
import CancelIcon from '@mui/icons-material/Cancel';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Grid, IconButton, Typography } from '@mui/material';
import { omit, findIndex, find } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Dropdown,
  DatePicker,
  TimePicker,
  PageWrapper,
  FiltersGrid,
  Accordion,
  DropdownInput,
  Switch,
} from '../../components';
import {
  useDispatch,
  useSelector,
  fetchNotificationsFilters,
  fetchNotificationsList,
  fetchNotificationsTotals,
  notificationsFiltersValuesSlice,
  notificationsListSlice,
} from '../../stores';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { daysOptions } from './constants';
import {
  NotificationCancelModal,
  NotificationEditVehicleTypeModal,
  NotificationInfoModal,
  NotificationsExportModal,
} from './modals';
import { StyledFiltersBtnsGrid } from './styles';
import { NotificationsIssuedTable } from './table';

export const NotificationsManagement: React.FC = () => {
  const dispatch = useDispatch();
  const { filters, filtersLoading } = useSelector(
    (state) => state.notificationsFiltersReducer,
  );
  const filtersValues = useSelector(
    (state) => state.notificationsFiltersValuesReducer,
  );
  const { notificationsPage, notificationsListLoading } = useSelector(
    (state) => state.notificationsListReducer,
  );
  // const { notificationsIrregularityId } = useSelector(
  //   (state) => state.notificationsListReducer,
  // );
  const responsiveMode = useResponsiveMode();
  const [expandedAccordeon, setExpandedAccordeon] = useState(false);
  const [formData, setFormData] = useState({
    days: filtersValues.days,
    startDate: moment(filtersValues.startDateTime).format('yyyy-MM-DD'),
    startTime: moment(filtersValues.startDateTime).format('HH:mm'),
    finalDate: moment(filtersValues.finalDateTime).format('yyyy-MM-DD'),
    finalTime: moment(filtersValues.finalDateTime).format('HH:mm'),
    notificationId: filtersValues.notificationId,
    plate: filtersValues.plate,
    situationId: filtersValues.situationId,
    routeAreaId: filtersValues.routeAreaId,
    routeId: filtersValues.routeId,
    userName: filtersValues.userName,
    serviceOrderOrigin: filtersValues.serviceOrderOrigin,
    print: filtersValues.print === '1' ? true : false,
  });

  useEffect(() => {
    const values = {
      ...omit(filtersValues, ['days']),
      page: notificationsPage.toString(),
    };

    const irregularityId = localStorage.getItem('irregularity');
    if (irregularityId) {
      dispatch(
        notificationsListSlice.actions.setIrregularityId(irregularityId),
      );
    }

    dispatch(fetchNotificationsFilters());
    dispatch(fetchNotificationsList(values)).then(() => {
      dispatch(fetchNotificationsTotals(omit(values, ['page']))).then(() => {
        if (irregularityId) {
          dispatch(notificationsListSlice.actions.setIrregularityId(null));
          localStorage.removeItem('irregularity');
        }
      });
    });
  }, []);

  useEffect(() => {
    const values = {
      days: formData.days,
      startDateTime: moment(formData.startDate + ' ' + formData.startTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      finalDateTime: moment(
        formData.finalDate + ' ' + formData.finalTime + ':59',
      )
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      plate: formData.plate,
      notificationId: formData.notificationId,
      situationId: formData.situationId,
      userName: formData.userName,
      serviceOrderOrigin: formData.serviceOrderOrigin,
      routeAreaId: findIndex(filters?.areas, [
        'id',
        parseInt(formData.routeAreaId),
      ]).toString(),
      routeId: formData.routeId,
      print: formData.print === true ? '1' : '0',
      page: notificationsPage.toString(),
    };

    dispatch(
      notificationsFiltersValuesSlice.actions.setNotificationsFiltersValues(
        values,
      ),
    );
  }, [formData]);

  const handleSubmit = () => {
    const values = {
      ...filtersValues,
      routeAreaId: formData.routeAreaId || '0',
      page: '1',
    };

    dispatch(
      notificationsFiltersValuesSlice.actions.setNotificationsFiltersValues(
        values,
      ),
    );
    dispatch(notificationsListSlice.actions.setNotificationsPage(1));
    dispatch(fetchNotificationsList(omit(values, ['days']))).then(() => {
      dispatch(fetchNotificationsTotals(omit(values, ['days', 'page'])));
    });
    // dispatch(fetchNotificationsList(omit(values, ['days']))).then(() => {
    //   dispatch(fetchNotificationsTotals(omit(values, ['days', 'page']))).then(
    //     () => {
    //       notificationsIrregularityId &&
    //         dispatch(notificationsListSlice.actions.setIrregularityId(null));
    //     },
    //   );
    // });
    setExpandedAccordeon(false);
  };

  const handleReset = () => {
    const startDate = moment().format('yyyy-MM-DD');
    const startTime = moment().format('00:00');
    const finalDate = moment().format('yyyy-MM-DD');
    const finalTime = moment().format('23:59');
    const values = {
      days: '0',
      startDateTime: moment(startDate + ' ' + startTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      finalDateTime: moment(finalDate + ' ' + finalTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      plate: '',
      userName: '',
      serviceOrderOrigin: '0',
      routeAreaId: '0',
      routeId: '0',
      print: formData.print === true ? '1' : '0',
      notificationId: '0',
      situationId: '0',
      page: '1',
    };

    setFormData({
      days: '0',
      startDate: startDate,
      startTime: startTime,
      finalDate: finalDate,
      finalTime: finalTime,
      notificationId: '',
      plate: '',
      situationId: '0',
      routeAreaId: '0',
      routeId: '0',
      userName: '',
      serviceOrderOrigin: '0',
      print: false,
    });
    dispatch(
      notificationsFiltersValuesSlice.actions.setNotificationsFiltersValues(
        values,
      ),
    );
    dispatch(fetchNotificationsList(omit(values, ['days']))).then(() => {
      dispatch(fetchNotificationsTotals(omit(values, ['days', 'page'])));
    });
    // dispatch(fetchNotificationsList(omit(values, ['days']))).then(() => {
    //   dispatch(fetchNotificationsTotals(omit(values, ['days', 'page']))).then(
    //     () => {
    //       notificationsIrregularityId &&
    //         dispatch(notificationsListSlice.actions.setIrregularityId(null));
    //     },
    //   );
    // });
  };

  const handleSelectCustomizedDays = (value: string, field: string) => {
    if (field === 'startTime' || field === 'finalTime') {
      setFormData({
        ...formData,
        [field]: value,
      });
    } else {
      let daysValue = '1000';

      const startDateTime = moment(
        `${field === 'startDate' ? value : formData.startDate}`,
        'yyyy-MM-DD',
      );
      const finalDateTime = moment(
        `${field === 'finalDate' ? value : formData.finalDate}`,
        'yyyy-MM-DD',
      );
      const diff = moment(finalDateTime).diff(startDateTime);

      if (diff === 0 && value === moment().format('yyyy-MM-DD').toString()) {
        daysValue = '0';
      } else if (diff === 1296000000) {
        daysValue = '15';
      } else if (diff === 2592000000) {
        daysValue = '30';
      } else if (diff === 5184000000) {
        daysValue = '60';
      } else if (diff === 7776000000) {
        daysValue = '90';
      }

      setFormData({
        ...formData,
        days: daysValue,
        [field]: value,
      });
    }
  };

  return (
    <PageWrapper>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <CircleNotificationsIcon fontSize='large' sx={{ marginRight: 2 }} />
            Gerenciar notificações
          </Typography>
        </Grid>
      </Grid>
      {responsiveMode !== 'phone' ? (
        <Grid container>
          <FiltersGrid loading={!filters && filtersLoading} loadingRows={3}>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Filtro de dias'
                options={daysOptions}
                value={formData.days}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    days: value,
                    startDate: moment()
                      .subtract(parseInt(value), 'days')
                      .format('yyyy-MM-DD'),
                    startTime: moment().format('00:00'),
                    finalDate: moment().format('yyyy-MM-DD'),
                    finalTime: moment().format('23:59'),
                  });
                }}
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <DatePicker
                label='Data inicial'
                value={formData.startDate}
                onChange={(value) =>
                  handleSelectCustomizedDays(value, 'startDate')
                }
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <TimePicker
                label='Hora inicial'
                value={formData.startTime}
                onChange={(value) =>
                  handleSelectCustomizedDays(value, 'startTime')
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <DatePicker
                label='Data final'
                value={formData.finalDate}
                onChange={(value) =>
                  handleSelectCustomizedDays(value, 'finalDate')
                }
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <TimePicker
                label='Hora final'
                value={formData.finalTime}
                onChange={(value) =>
                  handleSelectCustomizedDays(value, 'finalTime')
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                data-testid='id'
                type='text'
                margin='normal'
                label='Identificador'
                value={formData.notificationId}
                onChange={(value) =>
                  setFormData({ ...formData, notificationId: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                data-testid='plate'
                type='text'
                margin='normal'
                label='Placa'
                value={formData.plate}
                onChange={(value) => setFormData({ ...formData, plate: value })}
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Situação'
                value={formData.situationId}
                options={filters && filters.situations}
                onChange={(value) =>
                  setFormData({ ...formData, situationId: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Área'
                value={formData.routeAreaId}
                options={filters && filters.areas}
                onChange={(value) => {
                  setFormData({ ...formData, routeAreaId: value });
                }}
              />
            </Grid>
            <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Trecho'
                value={formData.routeId}
                options={filters && filters.routes}
                onChange={(value) =>
                  setFormData({ ...formData, routeId: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                data-testid='userName'
                type='text'
                margin='normal'
                label='Usuário'
                value={formData.userName}
                onChange={(value) =>
                  setFormData({ ...formData, userName: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Switch
                label='Impressão'
                checked={formData.print}
                onChange={() =>
                  setFormData({
                    ...formData,
                    print: !formData.print,
                  })
                }
              />
            </Grid>
            <StyledFiltersBtnsGrid
              item
              lg={1.5}
              md={4}
              sm={4}
              xs={12}
              margin={1}
            >
              <Button
                variant='contained'
                label='Filtrar'
                startIcon={<FilterAltIcon />}
                onClick={handleSubmit}
                disabled={notificationsListLoading}
              />
              <IconButton
                color='secondary'
                aria-label='Remover filtros'
                onClick={handleReset}
                disabled={notificationsListLoading}
              >
                <CancelIcon fontSize='large' />
              </IconButton>
            </StyledFiltersBtnsGrid>
          </FiltersGrid>
        </Grid>
      ) : (
        <Accordion
          expanded={expandedAccordeon}
          setExpanded={setExpandedAccordeon}
          title='Filtros'
        >
          <FiltersGrid loading={filtersLoading} loadingRows={3}>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Filtro de dias'
                options={daysOptions}
                value={formData.days}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    days: value,
                    startDate: moment()
                      .subtract(parseInt(value), 'days')
                      .format('yyyy-MM-DD'),
                  })
                }
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <DatePicker
                label='Data inicial'
                value={formData.startDate}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    startDate: value,
                  })
                }
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <TimePicker
                label='Hora inicial'
                value={formData.startTime}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    startTime: value,
                  });
                }}
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <DatePicker
                label='Data final'
                value={formData.finalDate}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    finalDate: value,
                  })
                }
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <TimePicker
                label='Hora final'
                value={formData.finalTime}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    finalTime: value,
                  })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                data-testid='id'
                type='text'
                margin='normal'
                label='Identificador'
                value={formData.notificationId}
                onChange={(value) =>
                  setFormData({ ...formData, notificationId: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                data-testid='plate'
                type='text'
                margin='normal'
                label='Placa'
                value={formData.plate}
                onChange={(value) => setFormData({ ...formData, plate: value })}
              />
            </Grid>
            <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
              <Dropdown
                label='Situação'
                value={formData.situationId}
                options={filters && filters.situations}
                onChange={(value) =>
                  setFormData({ ...formData, situationId: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Área'
                value={formData.routeAreaId}
                options={filters && filters.areas}
                onChange={(value) => {
                  setFormData({ ...formData, routeAreaId: value });
                }}
              />
            </Grid>
            <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
              <DropdownInput
                label='Trecho'
                value={formData.routeId}
                options={filters && filters.routes}
                onChange={(value) =>
                  setFormData({ ...formData, routeId: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                data-testid='userName'
                type='text'
                margin='normal'
                label='Usuário'
                value={formData.userName}
                onChange={(value) =>
                  setFormData({ ...formData, userName: value })
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Switch
                label='Impressão'
                checked={formData.print}
                onChange={() =>
                  setFormData({
                    ...formData,
                    print: !formData.print,
                  })
                }
              />
            </Grid>
            <StyledFiltersBtnsGrid
              item
              lg={1.5}
              md={4}
              sm={4}
              xs={12}
              margin={1}
            >
              <Button
                variant='contained'
                label='Filtrar'
                startIcon={<FilterAltIcon />}
                sx={{ width: responsiveMode === 'phone' ? '100%' : 'auto' }}
                onClick={handleSubmit}
                disabled={notificationsListLoading}
              />
              <IconButton
                color='secondary'
                aria-label='Remover filtros'
                onClick={handleReset}
                disabled={notificationsListLoading}
              >
                <CancelIcon fontSize='large' />
              </IconButton>
            </StyledFiltersBtnsGrid>
          </FiltersGrid>
        </Accordion>
      )}
      <Grid container margin={3}>
        <NotificationsIssuedTable />
      </Grid>
      <NotificationInfoModal />
      <NotificationCancelModal />
      <NotificationsExportModal />
      <NotificationEditVehicleTypeModal />
    </PageWrapper>
  );
};
