// All user related database operations can be defined here.
import { omit } from 'lodash';
import { api } from '..';
import {
  fetchUsersBlockUrl,
  fetchUsersCancelUrl,
  fetchUsersCreateUrl,
  fetchUsersFiltersUrl,
  fetchUsersInfoUrl,
  fetchUsersListUrl,
  fetchUsersUpdateUrl,
  fetchUsersRoutesUrl,
  fetchUsersAddRoutesUrl,
} from './constants';
import {
  UsersBlockResponseType,
  UsersCancelResponseType,
  UsersCreateResponseType,
  UsersFiltersResponseType,
  UsersInfoResponseType,
  UsersListResponseType,
  UsersUpdateResponseType,
  UsersRoutesResponseType,
  UsersRoutesAddResponseType,
} from './types';

/**
 * Function to fetch the details of the user based on userId.
 */
export const fetchUsersFiltersService = async () => {
  return await api
    .get<UsersFiltersResponseType>(fetchUsersFiltersUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchUsersListService = async (params: {
  userName: string | undefined;
  login: string | undefined;
  areaId: string | undefined;
  profileId: string | undefined;
  page: string;
}) => {
  return await api
    .get<UsersListResponseType>(fetchUsersListUrl, { params: params })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchUsersCreateService = async (params: {
  login: string;
  name: string;
  email: string;
  profileId: number;
  areas: number[];
  password: number;
  passwordConfirmation: number;
}) => {
  return await api
    .post<UsersCreateResponseType>(fetchUsersCreateUrl, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchUsersInfoService = async (params: { userId: string }) => {
  return await api
    .get<UsersInfoResponseType>(`${fetchUsersInfoUrl}/${params.userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchUsersCancelService = async (params: { userId: string }) => {
  return await api
    .delete<UsersCancelResponseType>(`${fetchUsersCancelUrl}/${params.userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchUsersBlockService = async (params: {
  userId: string;
  block: string;
}) => {
  return await api
    .put<UsersBlockResponseType>(
      `${fetchUsersBlockUrl}/${params.userId}/block`,
      { block: params.block },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchUsersUpdateService = async (params: {
  userId: string;
  login: string;
  name: string;
  email: string;
  profileId: number;
  areas: number[];
}) => {
  return await api
    .put<UsersUpdateResponseType>(
      `${fetchUsersUpdateUrl}/${params.userId}`,
      JSON.stringify(omit(params, ['userId'])),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchUsersRoutesService = async (params: { userId: string }) => {
  return await api
    .get<UsersRoutesResponseType>(
      `${fetchUsersRoutesUrl}/${params.userId}/routes`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchUsersAddRoutesService = async (params: {
  userId: string;
  routes: number[];
}) => {
  return await api
    .put<UsersRoutesAddResponseType>(
      `${fetchUsersAddRoutesUrl}/${params.userId}/connect/routes`,
      { routes: params.routes },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};
