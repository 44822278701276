import * as Yup from 'yup';

export const passwordSchema = Yup.object({
  password: Yup.string()
    .required('Insira sua senha atual')
    .required('A senha atual é obrigatória'),
  newPassword: Yup.string()
    .required('Insira sua nova senha')
    .required('A nova senha é obrigatória')
    .oneOf(
      [Yup.ref('newPasswordConfirmation'), null],
      'As senhas devem ser iguais',
    ),
  newPasswordConfirmation: Yup.string()
    .required('Confirme sua nova senha')
    .required('A confirmação da nova senha é obrigatória')
    .oneOf([Yup.ref('newPassword'), null], 'As senhas devem ser iguais'),
});

export const editSchema = Yup.object({
  name: Yup.string().required('O nome é obrigatório'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('O email é obrigatório'),
});
