/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { NotificationsFinesExportResponseType } from '../../../../services/Notifications/types';
import { notificationsListSlice } from '../../../../stores';
import { logoUrl } from '../../../../theme';

export const exportNotificationsFinesToHTML = async (
  apiData: NotificationsFinesExportResponseType,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  worker: (apiData: NotificationsFinesExportResponseType) => Promise<string>,
): Promise<void> => {
  dispatch(notificationsListSlice.actions.setNotificationsExportPercentage(20));

  const handledApiData = await worker(apiData);

  dispatch(notificationsListSlice.actions.setNotificationsExportPercentage(70));

  const content = `
  <!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>Detalhar Notificação</title>
      <style>

        /* -------------------------------------
          GLOBAL RESETS
        ------------------------------------- */
        img {
          border: none;
          -ms-interpolation-mode: bicubic;
          max-width: 100%;
        }

        body {
          background-color: #f6f6f6;
          font-family: 'Arial', sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          line-height: 1.4;
          margin: 0;
          padding: 0;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }

        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }

          table td {
            font-family: 'Arial', sans-serif;
            font-size: 14px;
            vertical-align: top;
          }

        /* -------------------------------------
          BODY & CONTAINER
        ------------------------------------- */

        .body {
          background-color: #f6f6f6;
          width: 100%;
        }

        /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
        .container {
          display: block;
          Margin: 0 auto !important;
          /* makes it centered */
          max-width: 580px;
          padding: 10px;
          width: 580px;
        }

        /* This should also be a block element, so that it will fill 100% of the .container */
        .content {
          box-sizing: border-box;
          display: block;
          Margin: 0 auto;
          max-width: 580px;
          padding: 10px;
        }

        /* -------------------------------------
          HEADER, FOOTER, MAIN
        ------------------------------------- */
        .main {
          background: #fff;
          border-radius: 3px;
          width: 100%;
        }

          .main tbody tr .wrapper {
            border-bottom: solid 1px #ddd;
            display: block;
          }

        .wrapper {
          box-sizing: border-box;
          padding: 10px;
        }

        .footer {
          clear: both;
          padding-top: 10px;
          text-align: center;
          width: 100%;
        }

          .footer td,
          .footer p,
          .footer span,
          .footer a {
            color: #999999;
            font-size: 12px;
            text-align: center;
          }

        /* -------------------------------------
          TYPOGRAPHY
        ------------------------------------- */
        h1,
        h2,
        h3,
        h4 {
          color: #000000;
          font-family: 'Arial', sans-serif;
          font-weight: 600;
          line-height: 1.4;
          margin: 0;
          Margin-bottom: 15px;
        }

        h1 {
          font-size: 35px;
          font-weight: 300;
          text-align: center;
          text-transform: capitalize;
        }

        p,
        ul,
        ol {
          font-family: 'Arial', sans-serif;
          font-size: 14px;
          font-weight: normal;
          margin: 0;
          Margin-bottom: 15px;
        }

          p li,
          ul li,
          ol li {
            list-style-position: inside;
            margin-left: 5px;
          }

        a {
          color: #3498db;
          text-decoration: underline;
        }

        /* -------------------------------------
          BUTTONS
        ------------------------------------- */
        .btn {
          box-sizing: border-box;
          width: 100%;
        }

          .btn > tbody > tr > td {
            padding-bottom: 15px;
          }

          .btn table {
            width: auto;
          }

            .btn table td {
              background-color: #ffffff;
              border-radius: 5px;
              text-align: center;
            }

          .btn a {
            background-color: #ffffff;
            border: solid 1px #3498db;
            border-radius: 5px;
            box-sizing: border-box;
            color: #3498db;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            padding: 12px 25px;
            text-decoration: none;
            text-transform: capitalize;
          }

        .btn-primary table td {
          background-color: #3498db;
        }

        .btn-primary a {
          background-color: #3498db;
          border-color: #3498db;
          color: #ffffff;
        }

        /* -------------------------------------
          OTHER STYLES THAT MIGHT BE USEFUL
        ------------------------------------- */
        .list li {
          margin: 5px 0;
        }

        .last {
          margin-bottom: 0;
        }

        .first {
          margin-top: 0;
        }

        .align-center {
          text-align: center;
        }

        .align-right {
          text-align: right;
        }

        .align-left {
          text-align: left;
        }

        .clear {
          clear: both;
        }

        .mt0 {
          margin-top: 0;
        }

        .mb0 {
          margin-bottom: 0;
        }

        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0;
        }

        .powered-by a {
          text-decoration: none;
        }

        hr {
          border: 0;
          border-bottom: 1px solid #f6f6f6;
          Margin: 20px 0;
        }

        /* -------------------------------------
          RESPONSIVE AND MOBILE FRIENDLY STYLES
        ------------------------------------- */
        @media only screen and (max-width: 620px) {
          table[class=body] h1 {
            font-size: 28px !important;
            margin-bottom: 10px !important;
          }

          table[class=body] p,
          table[class=body] ul,
          table[class=body] ol,
          table[class=body] td,
          table[class=body] span,
          table[class=body] a {
            font-size: 16px !important;
          }

          table[class=body] .wrapper,
          table[class=body] .article {
            padding: 10px !important;
          }

          table[class=body] .content {
            padding: 0 !important;
          }

          table[class=body] .container {
            padding: 0 !important;
            width: 100% !important;
          }

          table[class=body] .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }

          table[class=body] .btn table {
            width: 100% !important;
          }

          table[class=body] .btn a {
            width: 100% !important;
          }

          table[class=body] .img-responsive {
            height: auto !important;
            max-width: 100% !important;
            width: auto !important;
          }
        }

        /* -------------------------------------
          PRESERVE THESE STYLES IN THE HEAD
        ------------------------------------- */
        @media all {
          .ExternalClass {
            width: 100%;
          }

            .ExternalClass,
            .ExternalClass p,
            .ExternalClass span,
            .ExternalClass font,
            .ExternalClass td,
            .ExternalClass div {
              line-height: 100%;
            }

          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }

          .btn-primary table td:hover {
            background-color: #34495e !important;
          }

          .btn-primary a:hover {
            background-color: #34495e !important;
            border-color: #34495e !important;
          }
        }
      </style>
    </head>
    <body class="">
      <table border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <div class="content">
              <div class="img-container" style="text-align:center">
                <img src=${logoUrl} alt="Logo" height="66" width="100" />
              </div>
              <!-- START CENTERED WHITE CONTAINER -->
              <table class="main">
                <!-- START MAIN CONTENT AREA -->
                ${handledApiData}
                <!-- END MAIN CONTENT AREA -->
              </table>
              <!-- END CENTERED WHITE CONTAINER -->
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </body>
    </html>
  `;
  dispatch(
    notificationsListSlice.actions.setNotificationsExportPercentage(100),
  );

  const fileType = 'text/plain;charset=utf-8';
  const fileExtension = '.html';

  const blob = new Blob([content], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `notificações-multas-${moment().format('yyyy-MM-DD_hh-mm')}` +
      fileExtension,
  );
};

export const handleApiDataNotificationsFinesToHTML = (
  apiData: NotificationsFinesExportResponseType,
) => {
  let handledApiData = '';

  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[8] +
      dateTime[9] +
      '/' +
      dateTime[5] +
      dateTime[6] +
      '/' +
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3] +
      ' ' +
      dateTime.slice(11)
    );
  };

  apiData.map((item) => {
    handledApiData =
      handledApiData +
      `
      <tr>
        <td>
          <h2>Notificação (${item.id})</h2>
          <ul class="list" style="list-style: none; padding-left: 0">
            <li><strong>Veículo: </strong>
              ${item.plate}
            </li>
            <li>
              <strong>Situação: </strong>
              ${
                item.situation === 'C'
                  ? 'Cancelada'
                  : item.situation === 'I'
                  ? 'Irregular'
                  : item.situation === 'P'
                  ? 'Paga'
                  : 'Regular'
              }
            </li>
            <li>
              <strong>Usuário: </strong>
              ${item.userName} (${item.userLogin})
            </li>
            <li><strong>Data/Horário da notificação:</strong>
              ${formatDateTime(item.registerDateTime)}
            </li>
            <li>
              <strong>Trecho: </strong>
              ${item.routeName}
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td class="wrapper">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td>
                <ul class="list" style="list-style: none; padding-left: 0">
                  <li>
                    <strong>Data/Horário: </strong>
                    ${formatDateTime(item.registerDateTime)}
                  </li>
                  <li>
                    <strong>Usuário: </strong>
                    ${item.userName} (${item.userLogin})
                  </li>
                  <li>
                    <strong>Trecho: </strong>
                    ${item.routeName}
                  </li>
                  <li>
                    <img src="${item.plateImg}" alt='Imagem da placa' />
                  </li>
                  <li>
                    <img src="${item.locationImg}" alt='Imagem do local' />
                  </li>
                  <br/>
                </ul>
              </td>
            </tr>
          </table>
          <hr />
        </td>
      </tr>
      `;
  });

  return handledApiData;
};
