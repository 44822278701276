/* eslint-disable pii/no-phone-number */
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Grid, IconButton, Typography } from '@mui/material';
import { omit } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Dropdown,
  DatePicker,
  TimePicker,
  PageWrapper,
  Form,
  FiltersGrid,
  Accordion,
} from '../../components';
import {
  useDispatch,
  fetchSessionsFilters,
  fetchSessionsList,
  useSelector,
  sessionsFiltersValuesSlice,
  sessionsListSlice,
} from '../../stores';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { daysOptions } from './constants';
import {
  SessionCloseModal,
  SessionInfoModal,
  SessionsExportModal,
} from './modals';
import { StyledFiltersBtnsGrid } from './styles';
import { HistoryTable } from './table';

export const SessionsHistory: React.FC = () => {
  const dispatch = useDispatch();
  const { filters, filtersLoading } = useSelector(
    (state) => state.sessionsFiltersReducer,
  );
  const filtersValues = useSelector(
    (state) => state.sessionsFiltersValuesReducer,
  );
  const { sessionsPage, sessionsListLoading } = useSelector(
    (state) => state.sessionsListReducer,
  );
  const responsiveMode = useResponsiveMode();
  const [expandedAccordeon, setExpandedAccordeon] = useState(false);
  const [formData, setFormData] = useState({
    days: filtersValues.days,
    startDate: moment(filtersValues.startDateTime).format('yyyy-MM-DD'),
    startTime: moment(filtersValues.startDateTime).format('HH:mm'),
    finalDate: moment(filtersValues.finalDateTime).format('yyyy-MM-DD'),
    finalTime: moment(filtersValues.finalDateTime).format('HH:mm'),
    username: filtersValues.userName,
    situation: filtersValues.situation,
  });

  useEffect(() => {
    dispatch(fetchSessionsFilters());
    dispatch(fetchSessionsList(omit(filtersValues, ['days'])));
  }, []);

  useEffect(() => {
    const values = {
      days: formData.days,
      userName: formData.username,
      startDateTime: moment(formData.startDate + ' ' + formData.startTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      finalDateTime: moment(
        formData.finalDate + ' ' + formData.finalTime + ':59',
      )
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      situation: formData.situation,
      page: sessionsPage,
    };

    dispatch(
      sessionsFiltersValuesSlice.actions.setSessionsFiltersValues(values),
    );
  }, [formData]);

  const handleSubmit = () => {
    const values = {
      ...filtersValues,
      page: '1',
    };

    dispatch(
      sessionsFiltersValuesSlice.actions.setSessionsFiltersValues(values),
    );
    dispatch(sessionsListSlice.actions.setSessionsPage(1));
    dispatch(fetchSessionsList(omit(values, ['days'])));
    setExpandedAccordeon(false);
  };

  const handleReset = () => {
    const startDate = moment().subtract(1, 'days').format('yyyy-MM-DD');
    const startTime = moment().format('00:00');
    const finalDate = moment().format('yyyy-MM-DD');
    const finalTime = moment().format('23:59');
    const values = {
      days: '',
      userName: '',
      startDateTime: moment(startDate + ' ' + startTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      finalDateTime: moment(finalDate + ' ' + finalTime)
        .format('yyyy-MM-DD HH:mm:ss')
        .toString(),
      situation: '',
      page: '1',
    };

    setFormData({
      days: '0',
      startDate: startDate,
      startTime: startTime,
      finalDate: finalDate,
      finalTime: finalTime,
      username: '',
      situation: '',
    });
    dispatch(
      sessionsFiltersValuesSlice.actions.setSessionsFiltersValues(values),
    );
    dispatch(sessionsListSlice.actions.setSessionsPage(1));
    dispatch(fetchSessionsList(omit(values, ['days'])));
  };

  const handleSelectCustomizedDays = (value: string, field: string) => {
    if (field === 'startTime' || field === 'finalTime') {
      setFormData({
        ...formData,
        [field]: value,
      });
    } else {
      let daysValue = '1000';

      const startDateTime = moment(
        `${field === 'startDate' ? value : formData.startDate}`,
        'yyyy-MM-DD',
      );
      const finalDateTime = moment(
        `${field === 'finalDate' ? value : formData.finalDate}`,
        'yyyy-MM-DD',
      );
      const diff = moment(finalDateTime).diff(startDateTime);

      if (diff === 0 && value === moment().format('yyyy-MM-DD').toString()) {
        daysValue = '0';
      } else if (diff === 1296000000) {
        daysValue = '15';
      } else if (diff === 2592000000) {
        daysValue = '30';
      } else if (diff === 5184000000) {
        daysValue = '60';
      } else if (diff === 7776000000) {
        daysValue = '90';
      }

      setFormData({
        ...formData,
        days: daysValue,
        [field]: value,
      });
    }
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <CalendarMonthOutlinedIcon
              fontSize='large'
              sx={{ marginRight: 2 }}
            />
            Histórico de sessões
          </Typography>
        </Grid>
      </Grid>
      {responsiveMode !== 'phone' ? (
        <Grid container>
          <Form onSubmit={handleSubmit}>
            <FiltersGrid loading={!filters && filtersLoading} loadingRows={2}>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Filtro de dias'
                  options={daysOptions}
                  value={formData.days}
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      days: value,
                      startDate: moment()
                        .subtract(parseInt(value), 'days')
                        .format('yyyy-MM-DD'),
                      startTime: moment().format('00:00'),
                      finalDate: moment().format('yyyy-MM-DD'),
                      finalTime: moment().format('23:59'),
                    });
                  }}
                />
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <DatePicker
                  label='Data inicial'
                  value={formData.startDate}
                  onChange={(value) =>
                    handleSelectCustomizedDays(value, 'startDate')
                  }
                />
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <TimePicker
                  label='Hora inicial'
                  value={formData.startTime}
                  onChange={(value) =>
                    handleSelectCustomizedDays(value, 'startTime')
                  }
                />
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <DatePicker
                  label='Data final'
                  value={formData.finalDate}
                  onChange={(value) =>
                    handleSelectCustomizedDays(value, 'finalDate')
                  }
                />
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <TimePicker
                  label='Hora final'
                  value={formData.finalTime}
                  onChange={(value) =>
                    handleSelectCustomizedDays(value, 'finalTime')
                  }
                />
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <Input
                  data-testid='username'
                  type='text'
                  margin='normal'
                  label='Usuário'
                  value={formData.username}
                  onChange={(value) =>
                    setFormData({ ...formData, username: value })
                  }
                />
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Situação'
                  value={formData.situation}
                  options={filters && filters.situations}
                  onChange={(value) =>
                    setFormData({ ...formData, situation: value })
                  }
                />
              </Grid>
              <StyledFiltersBtnsGrid
                item
                lg={2}
                md={3}
                sm={4}
                xs={12}
                margin={1}
              >
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAltIcon />}
                  onClick={handleSubmit}
                  disabled={sessionsListLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleReset}
                  disabled={sessionsListLoading}
                >
                  <CancelIcon fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </FiltersGrid>
          </Form>
        </Grid>
      ) : (
        <Accordion
          expanded={expandedAccordeon}
          setExpanded={setExpandedAccordeon}
          title='Filtros'
        >
          <Form onSubmit={handleSubmit}>
            <FiltersGrid loading={filtersLoading} loadingRows={1}>
              <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Filtro de dias'
                  options={daysOptions}
                  value={formData.days}
                  onChange={(value) =>
                    setFormData({
                      ...formData,
                      days: value,
                      startDate: moment()
                        .subtract(parseInt(value), 'days')
                        .format('yyyy-MM-DD'),
                    })
                  }
                />
              </Grid>
              <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
                <DatePicker
                  label='Data inicial'
                  value={formData.startDate}
                  onChange={(value) =>
                    setFormData({
                      ...formData,
                      startDate: value,
                    })
                  }
                />
              </Grid>
              <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
                <TimePicker
                  label='Hora inicial'
                  value={formData.startTime}
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      startTime: value,
                    });
                  }}
                />
              </Grid>
              <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
                <DatePicker
                  label='Data final'
                  value={formData.finalDate}
                  onChange={(value) =>
                    setFormData({
                      ...formData,
                      finalDate: value,
                    })
                  }
                />
              </Grid>
              <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
                <TimePicker
                  label='Hora final'
                  value={formData.finalTime}
                  onChange={(value) =>
                    setFormData({
                      ...formData,
                      finalTime: value,
                    })
                  }
                />
              </Grid>
              <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
                <Input
                  data-testid='username'
                  type='text'
                  margin='normal'
                  label='Usuário'
                  value={formData.username}
                  onChange={(value) =>
                    setFormData({ ...formData, username: value })
                  }
                />
              </Grid>
              <Grid item lg={1.3} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Situação'
                  value={formData.situation}
                  options={filters && filters.situations}
                  onChange={(value) =>
                    setFormData({ ...formData, situation: value })
                  }
                />
              </Grid>
              <StyledFiltersBtnsGrid
                item
                lg={1.5}
                md={4}
                sm={4}
                xs={12}
                margin={1}
              >
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAltIcon />}
                  onClick={handleSubmit}
                  sx={{ width: responsiveMode === 'phone' ? '100%' : 'auto' }}
                  disabled={sessionsListLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleReset}
                  disabled={sessionsListLoading}
                >
                  <CancelIcon fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </FiltersGrid>
          </Form>
        </Accordion>
      )}
      <Grid container margin={3}>
        <HistoryTable />
      </Grid>
      <SessionInfoModal />
      <SessionCloseModal />
      <SessionsExportModal />
    </PageWrapper>
  );
};
