import { styled, SwitchProps as MuiSwitchProps } from '@mui/material';
import shadows from '@mui/material/styles/shadows';
import { palette } from '../../theme';

export const SwitchThumb = styled('div')<MuiSwitchProps>(({ checked }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: checked ? palette.secondary.main : palette.divider,
  boxShadow: shadows[1],
}));

export const StyledSwitchWrapper = styled('div')(() => ({
  display: 'flex',
  flexdirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));
