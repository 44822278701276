import CancelIcon from '@mui/icons-material/Cancel';
import { Box, IconButton, Modal as MuiModal, Typography } from '@mui/material';
import React, { JSXElementConstructor, ReactElement } from 'react';
import { palette } from '../../theme';

export const Modal = (props: {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  open: boolean;
  onClose: (arg0: any) => void;
  title: string;
  width?: number;
  noCloseButton?: boolean;
}) => {
  const { children, open, onClose, title, width, noCloseButton } = props;

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: width || 450,
          bgcolor: 'background.main',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          paddingBottom: 5,
          // minHeight: 500,
        }}
      >
        <Typography
          id='modal-modal-title'
          variant='h5'
          sx={{
            mb: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: palette.text.main,
          }}
        >
          {title}
          {!noCloseButton && (
            <IconButton aria-label='Fechar modal' onClick={onClose}>
              <CancelIcon />
            </IconButton>
          )}
        </Typography>
        {children}
      </Box>
    </MuiModal>
  );
};
