import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { find, findIndex, map } from 'lodash';
import {
  fetchPermissionsFiltersService,
  fetchPermissionsListService,
  fetchPermissionsUpdateService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { PermissionsStateType } from './types';

const initialState: PermissionsStateType = {
  permissionsList: null,
  permissionsListLoading: false,
  permissionsFiltersLoading: false,
  permissionsUpdateLoading: false,
  profiles: [],
  selectedProfileId: 0,
};

export const fetchPermissionsList = createAsyncThunk(
  'permissionsList',
  async (params: { profileId: string }) => {
    return await fetchPermissionsListService(params);
  },
);

export const fetchPermissionsFilters = createAsyncThunk(
  'permissionsFilters',
  async () => {
    return await fetchPermissionsFiltersService();
  },
);

export const fetchPermissionsUpdate = createAsyncThunk(
  'permissionsUpdate',
  async (params: { profileId: number; permissions: number[] }) => {
    return await fetchPermissionsUpdateService(params);
  },
);

export const permissionsSlice = createSlice({
  name: 'permissionsList',
  initialState,
  reducers: {
    setSelectedProfile(state, action: PayloadAction<number>) {
      state.selectedProfileId = action.payload;
    },
    togglePermission(state, action: PayloadAction<number>) {
      const idx = findIndex(state.permissionsList, ['id', action.payload]);
      if (state.permissionsList) {
        state.permissionsList[idx].allowed =
          !state.permissionsList[idx].allowed;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPermissionsList.pending, (state) => {
      return {
        ...state,
        permissionsList: null,
        permissionsListLoading: true,
      };
    }),
      builder.addCase(fetchPermissionsList.fulfilled, (state, res) => {
        const handledPage = map(res.payload.data.permissions, (item) => {
          return {
            ...item,
            allowed:
              find(res.payload.data.profilePermissions, (profilePermission) => {
                if (profilePermission === item.id) return true;
              }) === undefined
                ? false
                : true,
          };
        });
        return {
          ...state,
          permissionsList: handledPage,
          permissionsListLoading: false,
        };
      }),
      builder.addCase(fetchPermissionsList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          permissionsListLoading: false,
        };
      });

    builder.addCase(fetchPermissionsFilters.pending, (state) => {
      return {
        ...state,
        permissionsFiltersLoading: true,
      };
    }),
      builder.addCase(fetchPermissionsFilters.fulfilled, (state, res) => {
        const handledProfiles: {
          id: number;
          value: string;
        }[] = [];
        map(res.payload.data.profiles, (item) => {
          if (item.value !== 'Todos' && item.value !== 'Gerente') {
            handledProfiles.push(item);
          }
        });
        // handledProfiles.shift();

        return {
          ...state,
          profiles: handledProfiles,
          permissionsFiltersLoading: false,
          selectedProfileId: handledProfiles[0].id,
        };
      }),
      builder.addCase(fetchPermissionsFilters.rejected, (state) => {
        return {
          ...state,
          permissionsFiltersLoading: false,
        };
      });

    builder.addCase(fetchPermissionsUpdate.pending, (state) => {
      return {
        ...state,
        permissionsUpdateLoading: true,
      };
    }),
      builder.addCase(fetchPermissionsUpdate.fulfilled, (state, res) => {
        toast.success('Permissões editadas com sucesso.');
        return {
          ...state,
          permissionsUpdateLoading: false,
        };
      }),
      builder.addCase(fetchPermissionsUpdate.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          permissionsUpdateLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedProfile, togglePermission } =
  permissionsSlice.actions;
