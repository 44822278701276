import { useWorker } from '@koale/useworker';
import { Download, Save } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components';
import { CREDENTIALS_CREATE } from '../../routes';
import {
  useDispatch,
  useSelector,
  clearCredentials,
  credentialsListSlice,
  fetchCredentialsExportExcel,
  fetchCredentialsExportHtml,
} from '../../stores';
import {
  exportCredentialsToCSV,
  handleApiDataCredentialsToCSV,
} from './ExportCredentialsToCSV';
import {
  exportCredentialsToHTML,
  handleApiDataCredentialsToHTML,
} from './ExportCredentialsToHTML';

export const CredentialsTableToolbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const credentialsFiltersValues = useSelector(
    (state) => state.credentialsFiltersValuesReducer,
  );
  const { routes } = useSelector(
    (state) => state.credentialsFiltersReducer.filters,
  );
  const { credentialsExportExcelLoading, credentialsExportHtmlLoading } =
    useSelector(
      (state: { credentialsListSliceReducer: any }) =>
        state.credentialsListSliceReducer,
    );
  const [exportCredentialsToCSVWorker] = useWorker(
    handleApiDataCredentialsToCSV,
  );
  const [exportCredentialsToHTMLWorker] = useWorker(
    handleApiDataCredentialsToHTML,
  );

  const handleExportExcel = () => {
    dispatch(fetchCredentialsExportExcel(credentialsFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload) {
          exportCredentialsToCSV(
            res.payload,
            routes,
            dispatch,
            exportCredentialsToCSVWorker,
          ).then(() => {
            dispatch(credentialsListSlice.actions.toggleExportModalIsOpen());
          });
        } else dispatch(credentialsListSlice.actions.toggleExportModalIsOpen());
      },
    );
  };

  const handleExportHtml = () => {
    dispatch(fetchCredentialsExportHtml(credentialsFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload) {
          exportCredentialsToHTML(
            res.payload,
            routes,
            dispatch,
            exportCredentialsToHTMLWorker,
          ).then(() => {
            dispatch(credentialsListSlice.actions.toggleExportModalIsOpen());
          });
        } else dispatch(credentialsListSlice.actions.toggleExportModalIsOpen());
      },
    );
  };

  return (
    <Grid container margin={2} justifyContent='space-between'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Button
          variant='contained'
          label='Exportar em excel'
          startIcon={<Download />}
          onClick={() => handleExportExcel()}
          loading={credentialsExportExcelLoading}
        />
        <Button
          variant='contained'
          label='Exportar em html'
          startIcon={<Download />}
          onClick={() => handleExportHtml()}
          loading={credentialsExportHtmlLoading}
        />
      </div>
      <Button
        variant='contained'
        label='Cadastrar nova credencial'
        startIcon={<Save />}
        onClick={() => {
          dispatch(clearCredentials());
          navigate(CREDENTIALS_CREATE);
        }}
      />
    </Grid>
  );
};
