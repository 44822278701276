import { TextField } from '@mui/material';
import React from 'react';

export const Input = (props: {
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  onClick?: () => void;
  margin?: 'none' | 'normal' | 'dense';
  errorMessage?: string;
  type?: string;
  shrink?: boolean;
  InputProps?: any;
  select?: boolean;
  disabled?: boolean;
  startAdornment?: any;
  variant?: 'outlined' | 'filled' | 'standard';
  mapRef?: React.RefObject<HTMLDivElement>;
  [x: string]: any;
}) => {
  const {
    onChange,
    errorMessage,
    shrink,
    onClick,
    margin,
    mapRef,
    ...otherProps
  } = props;

  return (
    <div>
      <TextField
        ref={mapRef}
        onChange={(e) => onChange && onChange(e.target.value)}
        color='primary'
        fullWidth
        variant='filled'
        size='small'
        margin={margin === undefined ? 'normal' : margin}
        error={errorMessage !== undefined}
        helperText={errorMessage}
        InputLabelProps={{ shrink: shrink }}
        onClick={onClick}
        autoComplete='disabled'
        inputProps={{
          autocomplete: 'no',
        }}
        {...otherProps}
      />
    </div>
  );
};
