import DeleteIcon from '@mui/icons-material/Delete';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Button as MuiButton } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, TableText, Switch } from '../../../components';
import { ROUTE_UPDATE } from '../../../routes';
import {
  useDispatch,
  useSelector,
  routesListSlice,
  fetchRoutesList,
  fetchRouteBlock,
  fetchRouteInfo,
  routeSlice,
} from '../../../stores';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { RoutesTableToolbar } from './RoutesTableToolbar';

export const RoutesTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { routesList, routesListLoading, routesPage } = useSelector(
    (state) => state.routesListReducer,
  );
  const { filters } = useSelector((state) => state.routesFiltersReducer);
  const filtersValues = useSelector(
    (state) => state.routesFiltersValuesReducer,
  );
  const responsiveMode = useResponsiveMode();
  const [rows, setRows] = useState<any[]>([]);
  const [loadingSwitches, setLoadingSwitches] = useState<boolean[]>([]);

  const { areaId, configurationId, operation, routeName } = filtersValues;

  useEffect(() => {
    if (routesList && routesList !== undefined) {
      setRows(routesList.page);
    } else setRows([]);
  }, [routesList]);

  const handleEdit = (routeId: GridRowId) => {
    dispatch(fetchRouteInfo({ routeId: routeId.toString() })).then(() => {
      navigate(ROUTE_UPDATE);
    });
  };

  const handleCancel = (routeId: GridRowId) => {
    dispatch(fetchRouteInfo({ routeId: routeId.toString() }));
    dispatch(routeSlice.actions.toggleRouteCancelIsOpen());
  };

  const handleCheckAppBlock = (id: string, checked: boolean): void => {
    const handledLoadingSwitches = loadingSwitches;
    handledLoadingSwitches[parseInt(id)] = true;
    setLoadingSwitches(handledLoadingSwitches);

    dispatch(fetchRouteBlock({ routeId: id, block: checked ? '0' : '1' })).then(
      (response) => {
        const handledLoadingSwitches = loadingSwitches;
        handledLoadingSwitches[parseInt(id)] = false;
        setLoadingSwitches(handledLoadingSwitches);
        dispatch(
          routesListSlice.actions.setRouteBlock({
            routeId: response.meta.arg.routeId,
          }),
        );
      },
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => <TableText label={params.row.name} size={80} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'areaName',
      headerName: 'Área',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => <TableText label={params.row.areaName} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'configurationName',
      headerName: 'Configuração',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <TableText label={params.row.configurationName} size={40} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'operation',
      headerName: 'Ativado',
      // type: 'date',
      editable: false,
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <Switch
          color='secondary'
          checked={params.row.operation === 0 ? false : true}
          onChange={(event, checked) =>
            handleCheckAppBlock(params.id.toString(), checked)
          }
          loading={loadingSwitches[params.row.id]}
        />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: responsiveMode === 'large-screen' ? 200 : 120,
      renderCell: (params) => {
        return responsiveMode === 'large-screen' ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant='contained'
              label='Editar'
              startIcon={<EditIcon />}
              size='small'
              onClick={() => handleEdit(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
            <Button
              variant='contained'
              color='secondary'
              label='Excluir'
              startIcon={<DeleteIcon />}
              size='small'
              onClick={() => handleCancel(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <MuiButton
              variant='contained'
              size='small'
              onClick={() => handleEdit(params.id)}
              sx={{
                minWidth: '30px',
                marginLeft: '5px',
                marginRight: '5px',
              }}
            >
              <EditTwoToneIcon />
            </MuiButton>
            <MuiButton
              variant='contained'
              color='secondary'
              size='small'
              onClick={() => handleCancel(params.id)}
              sx={{
                minWidth: '30px',
              }}
            >
              <DeleteTwoToneIcon />
            </MuiButton>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => <TableText label={params.row.name} size={30} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'areaName',
      headerName: 'Área',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => <TableText label={params.row.areaName} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'configurationName',
      headerName: 'Configuração',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <TableText label={params.row.configurationName} size={50} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'operation',
      headerName: 'Ativado',
      // type: 'date',
      editable: false,
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <Switch
          color='secondary'
          checked={params.row.operation === 0 ? false : true}
          onChange={(event, checked) =>
            handleCheckAppBlock(params.id.toString(), checked)
          }
          loading={loadingSwitches[params.row.id]}
        />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <MuiButton
              variant='contained'
              color='secondary'
              size='small'
              onClick={() => handleCancel(params.id)}
              sx={{
                minWidth: '30px',
              }}
            >
              <DeleteTwoToneIcon />
            </MuiButton>
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  return (
    <Table
      page={routesPage}
      changePage={(value: number) =>
        routesListSlice.actions.setRoutesPage(value)
      }
      rows={rows}
      rowCount={routesList ? routesList.size : 0}
      fetchRows={() => {
        dispatch(
          fetchRoutesList({
            routeName: routeName,
            areaId: areaId,
            configurationId: configurationId,
            operation: operation,
          }),
        );
      }}
      columns={
        responsiveMode === 'phone' || responsiveMode === 'tablet'
          ? columnsResponsive
          : columns
      }
      toolbar={responsiveMode !== 'phone' ? <RoutesTableToolbar /> : undefined}
      loading={routesListLoading}
    />
  );
};
