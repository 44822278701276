import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Store';

import { fetchActionsHistoryListService } from '../../services/Actions';

export type ActionsListStateType = {
  isListLoading: boolean
  size: number
  currentPage: number
  page: {
    actionName: string
    id: number
    observation: string
    userLogin: string
    userName: string
  }[]
}

const initialState: ActionsListStateType = {
  isListLoading: false,
  size: 0,
  currentPage: 1,
  page: []
}

export const fetchActionsHistoryList = createAsyncThunk(
  'actionsHistoryList',
  async (params: {
    userName?: string
    profileId?: number
    permissionId?: number
    startDateTime?: string
    finalDateTime?: string
  }, {
    getState
  }) => {
    const state = getState() as RootState;

    return await fetchActionsHistoryListService({
      ...params,
      page: state.actionsListReducer.currentPage
    });
  }
);

export const actionsListSlice = createSlice({
  name: 'actionsList',
  initialState,
  reducers: {
    setActionsHistoryListCurrentPage: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        currentPage: action.payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchActionsHistoryList.pending, state => {
      return {
        ...state,
        isListLoading: true
      }
    });
    builder.addCase(fetchActionsHistoryList.rejected, state => {
      return {
        ...state,
        isListLoading: false
      }
    });
    builder.addCase(fetchActionsHistoryList.fulfilled, (state, res) => {
      return {
        ...state,
        ...res.payload,
        isListLoading: false
      }
    });
  }
});

export const { setActionsHistoryListCurrentPage } = actionsListSlice.actions;