import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import { GridColDef } from '@mui/x-data-grid';
import { map } from 'lodash';
import React from 'react';
import { Button, Table, TableText, Switch } from '../../components';
import {
  useDispatch,
  useSelector,
  permissionsSlice,
  fetchPermissionsUpdate,
  fetchPermissionsList,
} from '../../stores';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { StyledFiltersBtnsGrid } from './styles';

export const PermissionsTable: React.FC = () => {
  const dispatch = useDispatch();
  const { permissionsList, permissionsListLoading } = useSelector(
    (state) => state.permissionsReducer,
  );
  const responsiveMode = useResponsiveMode();

  const handleCheckAppBlock = (id: string): void => {
    dispatch(permissionsSlice.actions.togglePermission(parseInt(id)));
    // dispatch(fetchUserBlock({ userId: id, block: checked ? '1' : '0' })).then(
    //   (response) => {
    //     const handledLoadingSwitches = loadingSwitches;
    //     handledLoadingSwitches[parseInt(id)] = false;
    //     setLoadingSwitches(handledLoadingSwitches);
    //     dispatch(
    //       usersListSlice.actions.setUserBlock({
    //         userId: response.meta.arg.userId,
    //       }),
    //     );
    //   },
    // );
  };

  const columns: GridColDef[] = [
    {
      field: 'page',
      headerName: 'Página',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => <TableText label={params.row.name} size={30} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'action',
      headerName: 'Ação',
      editable: false,
      sortable: false,
      flex: 5,
      renderCell: (params) => (
        <TableText label={params.row.description} size={90} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'permission',
      headerName: 'Permitir',
      // type: 'date',
      editable: false,
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <Switch
          color='secondary'
          checked={params.row.allowed}
          onChange={() => handleCheckAppBlock(params.id.toString())}
          // loading={loadingSwitches[params.row.id]}
        />
      ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive: GridColDef[] = [
    {
      field: 'page',
      headerName: 'Página',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => <TableText label={params.row.name} size={30} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'action',
      headerName: 'Ação',
      editable: false,
      sortable: false,
      flex: 5,
      renderCell: (params) => (
        <TableText label={params.row.description} size={90} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'permission',
      headerName: 'Permitir',
      // type: 'date',
      editable: false,
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <Switch
          color='secondary'
          checked={params.row.allowed}
          onChange={() => handleCheckAppBlock(params.id.toString())}
          // loading={loadingSwitches[params.row.id]}
        />
      ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  return (
    <Table
      paginationMode='client'
      page={1}
      changePage={() => {}}
      rows={permissionsList ? permissionsList : []}
      rowCount={0}
      columns={
        responsiveMode === 'phone' || responsiveMode === 'tablet'
          ? columnsResponsive
          : columns
      }
      loading={permissionsListLoading}
      pageSize={100}
      noPagination
    />
  );
};
