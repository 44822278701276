import { Cancel } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Skeleton, Typography } from '@mui/material';
import { times } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import VMasker from 'vanilla-masker';
import { Button, Modal } from '../../../components';
import { CREDENTIALS_MANAGEMENT } from '../../../routes';
import {
  useDispatch,
  useSelector,
  fetchCredentialsDelete,
  credentialsSlice,
  fetchCredentialsList,
} from '../../../stores';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const DeactivateCredentialModal: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const credential = useSelector((state) => state.credentialsReducer);
  const filtersValues = useSelector(
    (state) => state.credentialsFiltersValuesReducer,
  );
  const { isCredentialsLoading, credentialDeactivateIsOpen } = credential;

  const handleClose = () => {
    dispatch(credentialsSlice.actions.toggleCredentialDeactivateIsOpen());
  };

  const handleSubmit = () => {
    dispatch(fetchCredentialsDelete({ id: credential.id })).then((res) => {
      if (res.type == 'credentialsDelete/fulfilled') {
        dispatch(
          fetchCredentialsList({
            plate: filtersValues.plate,
            userName: filtersValues.name,
            userDocument: VMasker.toNumber(filtersValues.document),
            address: filtersValues.street,
            routeId: filtersValues.routesId,
            validity: filtersValues.validitiesId,
          }),
        ).then(() => navigate(CREDENTIALS_MANAGEMENT));
      }
    });
  };

  return (
    <Modal
      open={credentialDeactivateIsOpen}
      onClose={handleClose}
      title='Deseja desativar essa credencial?'
    >
      <>
        {isCredentialsLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              {times(3, () => (
                <Skeleton animation='wave' height={29} width='100%' />
              ))}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Nome:
                </Typography>
                <Typography>{credential?.userName}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  CPF:
                </Typography>
                <Typography>{credential?.userDocument}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Telefone:
                </Typography>
                <Typography>{credential?.userPhone}</Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: '10px',
          }}
        >
          <Button
            variant='contained'
            label='Desativar'
            startIcon={<Cancel />}
            type='submit'
            color='error'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={isCredentialsLoading}
            onClick={handleSubmit}
          />
          <Button
            color='secondary'
            variant='contained'
            type='reset'
            startIcon={<ArrowBackIcon />}
            label='Voltar'
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleClose}
          />
        </Box>
      </>
    </Modal>
  );
};
