/**
 * LinkRoute is just any name for all the Link tags you wil
 * use throughout the app.
 * It is recommended to keep the styling and other stuff consistant.
 * All Link tags that are pointing to routes within this app should be
 * improted from react-router-dom.
 * All Link tags that are pointing to an external public address should
 * be improted from material-ui.
 */
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  linkWrapper: {
    display: 'inline-block',
  },
}));

export const LinkRoute = (
  props: JSX.IntrinsicAttributes &
    LinkProps &
    React.RefAttributes<HTMLAnchorElement>,
) => {
  const classes = useStyles();
  return (
    <Link {...props} className={classes.linkWrapper}>
      <Typography color='primary'>{props.children}</Typography>
    </Link>
  );
};
