import { Autocomplete } from '@mui/material';
import { filter, includes, isString, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Input } from '../Input';
import { AutocompleteType } from './types';

export const DropdownInput = (props: {
  label: string;
  options: any;
  value: string;
  onChange: (e: any) => void;
  errorMessage?: string;
}) => {
  const { options, onChange, errorMessage, ...otherProps } = props;
  const [objectValue, setObjectValue] = useState<AutocompleteType | undefined>(
    options && props.value
      ? options[parseInt(props.value)]
      : options && !props.value
      ? options[0]
      : {},
  );
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (options && props.value === '0') {
      setObjectValue(options && options[0]);
    }
  }, [props.value]);

  if (options) {
    if (options.length > 50000) {
      return (
        <Autocomplete
          fullWidth
          size='small'
          getOptionLabel={(option) =>
            isString(option) ? option : option.value
          }
          options={options}
          value={objectValue}
          onChange={(event, newValue: AutocompleteType | null) => {
            if (newValue) {
              setObjectValue(newValue);
              onChange(newValue.id.toString());
            } else {
              setObjectValue(options[0]);
              onChange('0');
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <Input
              {...params}
              label={props.label}
              value={objectValue ? objectValue.value : ''}
              error={errorMessage !== undefined && errorMessage !== ''}
              helperText={errorMessage}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <span>{option.value}</span>
            </li>
          )}
          loading
          loadingText='Continue digitando...'
          filterOptions={(options, state) => {
            if (state.inputValue.length > 2) {
              return filter(options, function (option) {
                return includes(
                  toLower(option.value),
                  toLower(state.inputValue),
                );
              });
            } else return [];
          }}
        />
      );
    } else {
      return (
        <Autocomplete
          fullWidth
          size='small'
          getOptionLabel={(option) =>
            isString(option) ? option : option.value
          }
          options={options}
          defaultValue={options && options[props.value]}
          value={objectValue}
          onChange={(event, newValue: AutocompleteType | null) => {
            if (newValue) {
              setObjectValue(newValue);
              onChange(newValue.id.toString());
            } else {
              setObjectValue(options[0]);
              onChange('0');
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <Input
              {...params}
              label={props.label}
              value={objectValue ? objectValue.value : ''}
              error={errorMessage !== undefined && errorMessage !== ''}
              helperText={errorMessage}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <span>{option.value}</span>
            </li>
          )}
        />
      );
    }
  } else {
    return null;
  }
};
