import { useWorker } from '@koale/useworker';
import { Box } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, DatePicker, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  routesListSlice,
  fetchRoutesExportOperatingVacancies,
} from '../../../stores';
import {
  exportOperatingVacanciesToCSV,
  handleApiDataOperatingVacanciesToCSV,
} from '../table/exports';

export const RoutesOperatingVacanciesExportModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    routesOperatingVacanciesExportIsOpen,
    routesExportOperatingVacanciesLoading,
  } = useSelector((state) => state.routesListReducer);
  const [startDate, setStartDate] = useState(
    moment().startOf('month').format('yyyy-MM-DD'),
  );
  const [finalDate, setFinalDate] = useState(
    moment().endOf('month').format('yyyy-MM-DD'),
  );
  const [exportRoutesOperatingVacanciesToCSVWorker] = useWorker(
    handleApiDataOperatingVacanciesToCSV,
  );

  const handleClose = () => {
    dispatch(
      routesListSlice.actions.toggleRoutesOperatingVacanciesExportIsOpen(),
    );
  };

  const handleSubmit = () => {
    dispatch(
      fetchRoutesExportOperatingVacancies({
        startDate: startDate,
        finalDate: finalDate,
      }),
    ).then((res: PayloadAction<any>) => {
      if (res.payload.data) {
        exportOperatingVacanciesToCSV(
          res.payload.data,
          dispatch,
          exportRoutesOperatingVacanciesToCSVWorker,
        ).then(() => {
          dispatch(routesListSlice.actions.toggleExportModalIsOpen());
          handleClose();
        });
      } else {
        dispatch(routesListSlice.actions.toggleExportModalIsOpen());
        handleClose();
      }
    });
  };

  return (
    <Modal
      open={routesOperatingVacanciesExportIsOpen}
      onClose={handleClose}
      title='Vagas operantes'
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DatePicker
          label='Data inicial'
          value={startDate}
          onChange={(value) => setStartDate(value)}
        />
        <DatePicker
          label='Data final'
          value={finalDate}
          onChange={(value) => setFinalDate(value)}
        />
        <Button
          variant='contained'
          label='Enviar'
          type='submit'
          style={{ width: '100%', margin: 0, marginTop: 30 }}
          loading={routesExportOperatingVacanciesLoading}
          onClick={handleSubmit}
        />
      </Box>
    </Modal>
  );
};
