// All user related database operations can be defined here.
import { omit } from 'lodash';
import { api } from '..';
import {
  fetchPermissionsFiltersUrl,
  fetchPermissionsUpdateUrl,
  fetchPermissionsListUrl,
} from './constants';
import {
  PermissionsFiltersResponseType,
  PermissionsListResponseType,
  PermissionsUpdateResponseType,
} from './types';

export const fetchPermissionsFiltersService = async () => {
  return await api
    .get<PermissionsFiltersResponseType>(fetchPermissionsFiltersUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchPermissionsListService = async (params: {
  profileId: string;
}) => {
  return await api
    .get<PermissionsListResponseType>(
      `${fetchPermissionsListUrl}/${params.profileId}`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchPermissionsUpdateService = async (params: {
  profileId: number;
  permissions: number[];
}) => {
  return await api
    .put<PermissionsUpdateResponseType>(
      `${fetchPermissionsUpdateUrl}/${params.profileId}`,
      { permissions: params.permissions },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};
