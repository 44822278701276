/* eslint-disable pii/no-phone-number */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, InputAdornment, Grid, Typography } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  DropdownInput,
  DropdownMultiInput,
  FiltersGrid,
  Form,
  Input,
  PageWrapper,
} from '../../../components';
import { USER_CREATE, USER_UPDATE } from '../../../routes';
import {
  fetchUserCreate,
  fetchUserUpdate,
  fetchUsersFilters,
  useDispatch,
  userSlice,
  useSelector,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleErrors } from '../../../utils/Helpers';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { StyledFiltersBtnsGrid } from '../styles';
import { createSchema, updateSchema } from './validations';

export const UserUpsert: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { usersListLoading } = useSelector((state) => state.usersListReducer);
  const { filters, filtersLoading } = useSelector(
    (state) => state.usersFiltersReducer,
  );
  const { userInfo } = useSelector((state) => state.userReducer);
  const responsiveMode = useResponsiveMode();
  const location = useLocation();
  const [formData, setFormData] = useState<{
    name: string;
    email: string;
    login: string;
    password: string;
    passwordConfirmation: string;
    profileId: string;
    areas: { id: string; value: string }[];
  }>({
    name: userInfo && location.pathname === USER_UPDATE ? userInfo.name : '',
    email: userInfo && location.pathname === USER_UPDATE ? userInfo.email : '',
    login: userInfo && location.pathname === USER_UPDATE ? userInfo.login : '',
    password: '',
    passwordConfirmation: '',
    profileId:
      userInfo && location.pathname === USER_UPDATE ? userInfo.profileId : '',
    areas: userInfo && location.pathname === USER_UPDATE ? userInfo.areas : [],
  });
  const [areasFilters, setAreasFilters] = useState<
    { id: string; value: string }[]
  >([]);
  const [profilesFilters, setProfilesFilters] = useState<
    { id: string; value: string }[]
  >([]);
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {},
  );
  const [title, setTitle] = useState('Adicionar usuário');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  useEffect(() => {
    dispatch(fetchUsersFilters()).then(async (res: PayloadAction<any>) => {
      const handledAreasFilters: { id: string; value: string }[] = [];
      const handledProfilesFilters: { id: string; value: string }[] = [];

      map(res.payload.data.areas, (item) => {
        handledAreasFilters.push({
          id: item.id.toString(),
          value: item.id === 0 ? '' : item.value,
        });
      });
      map(res.payload.data.profiles, (item) => {
        handledProfilesFilters.push({
          id: item.id.toString(),
          value: item.id === 0 ? '' : item.value,
        });
      });

      setAreasFilters(handledAreasFilters);
      setProfilesFilters(handledProfilesFilters);
    });
    if (location.pathname === USER_UPDATE) {
      setTitle('Editar usuário');
    }
  }, []);

  const handleSubmit = () => {
    setErrors({});

    if (location.pathname === USER_UPDATE) {
      updateSchema
        .validate(formData, { abortEarly: false })
        .then(() => {
          const areasAsNumber: number[] = [];
          map(formData.areas, (item) => {
            areasAsNumber.push(parseInt(item.id));
          });
          dispatch(
            fetchUserUpdate({
              userId: userInfo ? userInfo.id.toString() : '0',
              login: formData.login,
              name: formData.name,
              email: formData.email,
              profileId: parseInt(formData.profileId),
              areas: areasAsNumber,
            }),
          ).then(() => {
            navigate(-1);
          });
        })
        .catch((err) => {
          setErrors(handleErrors(err));
        });
    } else {
      createSchema
        .validate(formData, { abortEarly: false })
        .then(() => {
          const areasAsNumber: number[] = [];
          map(formData.areas, (item) => {
            areasAsNumber.push(parseInt(item.id));
          });
          dispatch(
            fetchUserCreate({
              login: formData.login,
              name: formData.name,
              email: formData.email,
              profileId: parseInt(formData.profileId),
              areas: areasAsNumber,
              password: parseInt(formData.password),
              passwordConfirmation: parseInt(formData.passwordConfirmation),
            }),
          ).then(() => {
            navigate(-1);
          });
        })
        .catch((err) => {
          setErrors(handleErrors(err));
        });
    }
  };

  const handleReturn = () => {
    dispatch(userSlice.actions.resetUserInfo());
    navigate(-1);
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <PeopleAltIcon fontSize='large' sx={{ marginRight: 2 }} />
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container margin={3}>
        <Form onSubmit={handleSubmit}>
          <FiltersGrid
            loading={
              filtersLoading ||
              areasFilters.length === 0 ||
              profilesFilters.length === 0
            }
            loadingRows={1}
          >
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <Input
                data-testid='name'
                type='text'
                margin='normal'
                label='Nome'
                value={formData.name}
                onChange={(value) => setFormData({ ...formData, name: value })}
                errorMessage={errors && errors.name}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <Input
                data-testid='email'
                type='text'
                margin='normal'
                label='Email'
                value={formData.email}
                onChange={(value) => setFormData({ ...formData, email: value })}
                errorMessage={errors && errors.email}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <DropdownInput
                label='Perfil'
                value={formData.profileId}
                options={profilesFilters}
                onChange={(value) => {
                  setFormData({ ...formData, profileId: value });
                }}
                errorMessage={errors && errors.profileId}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <DropdownMultiInput
                options={areasFilters}
                defaultValue={
                  userInfo && location.pathname === USER_UPDATE
                    ? userInfo.areas
                    : []
                }
                label='Áreas'
                value={userInfo ? userInfo.areas : []}
                onChange={(value) => {
                  setFormData({ ...formData, areas: value });
                  // setSelectedAreas(value);
                }}
                errorMessage={errors && errors.areas}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <Input
                data-testid='login'
                type='text'
                margin='normal'
                label='Login'
                value={formData.login}
                onChange={(value) => setFormData({ ...formData, login: value })}
                errorMessage={errors && errors.login}
              />
            </Grid>
            {location.pathname === USER_CREATE && (
              <>
                <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
                  <Input
                    data-testid='password'
                    type={showPassword ? 'text' : 'password'}
                    label='Senha'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={formData.password}
                    onChange={(InputProps) =>
                      setFormData({ ...formData, password: InputProps })
                    }
                    errorMessage={errors && errors.password}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
                  <Input
                    data-testid='passwordConfirmation'
                    type={showPasswordConfirmation ? 'text' : 'password'}
                    label='Confirme a Senha'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() =>
                              setShowPasswordConfirmation(
                                !showPasswordConfirmation,
                              )
                            }
                            onMouseDown={() =>
                              setShowPasswordConfirmation(
                                !showPasswordConfirmation,
                              )
                            }
                          >
                            {showPasswordConfirmation ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={formData.passwordConfirmation}
                    onChange={(InputProps) =>
                      setFormData({
                        ...formData,
                        passwordConfirmation: InputProps,
                      })
                    }
                    errorMessage={errors && errors.passwordConfirmation}
                  />
                </Grid>
              </>
            )}
            <StyledFiltersBtnsGrid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              margin={1}
            >
              <Button
                variant='contained'
                label='Salvar'
                startIcon={<SaveIcon />}
                onClick={handleSubmit}
                sx={{ width: '100%' }}
                disabled={usersListLoading}
              />
              <Button
                color='secondary'
                variant='contained'
                label='Voltar'
                startIcon={<ArrowBackIcon />}
                onClick={handleReturn}
                sx={{ width: '100%' }}
                disabled={usersListLoading}
              />
            </StyledFiltersBtnsGrid>
          </FiltersGrid>
        </Form>
      </Grid>
    </PageWrapper>
  );
};
