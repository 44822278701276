import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { UsersFiltersValuesStateType } from './types';

const initialState: UsersFiltersValuesStateType = {
  login: '',
  userName: '',
  areaId: '',
  profileId: '',
};

export const usersFiltersValuesSlice = createSlice({
  name: 'usersFiltersValues',
  initialState,
  reducers: {
    setUsersFiltersValues(
      state,
      action: PayloadAction<UsersFiltersValuesStateType>,
    ) {
      state.login = action.payload.login;
      state.userName = action.payload.userName;
      state.areaId = action.payload.areaId;
      state.profileId = action.payload.profileId;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUsersFiltersValues } = usersFiltersValuesSlice.actions;

// // now available:
// filtersValuesSlice.actions.setUsersFiltersValues();
// // also available:
// filtersValuesSlice.caseReducers.increment(0, { type: 'increment', payload: 5 });
