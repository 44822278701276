import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { map } from 'lodash';
import moment from 'moment';
import {
  fetchSessionsExportExcelService,
  fetchSessionsExportHTMLService,
  fetchSessionsListService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from '../Store';
import { SessionsListStateType } from './types';

const initialState: SessionsListStateType = {
  sessionsList: null,
  sessionsListLoading: false,
  sessionsExportExcelLoading: false,
  sessionsExportHTMLLoading: false,
  sessionsExportPercentage: 0,
  sessionsExportModalIsOpen: false,
  sessionsPage: 1,
};

export const fetchSessionsList = createAsyncThunk(
  'sessionsList',
  async (
    params: {
      userName: string;
      startDateTime: string;
      finalDateTime: string;
      situation: string;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    return await fetchSessionsListService({
      ...params,
      page: state.sessionsListReducer.sessionsPage.toString(),
    });
  },
);

export const fetchSessionsExportExcel = createAsyncThunk(
  'sessionsExportExcel',
  async (params: {
    userName: string;
    startDateTime: string;
    finalDateTime: string;
    situation: string;
  }) => {
    return await fetchSessionsExportExcelService(params);
  },
);

export const fetchSessionsExportHTML = createAsyncThunk(
  'sessionsExportHTML',
  async (params: {
    userName: string;
    startDateTime: string;
    finalDateTime: string;
    situation: string;
  }) => {
    return await fetchSessionsExportHTMLService(params);
  },
);

export const sessionsListSlice = createSlice({
  name: 'sessionsList',
  initialState,
  reducers: {
    setSessionsPage(state, action: PayloadAction<number>) {
      state.sessionsPage = action.payload;
    },
    setSessionsExportPercentage(state, action: PayloadAction<number>) {
      state.sessionsExportPercentage = action.payload;
    },
    closeExportModal(state) {
      state.sessionsExportModalIsOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSessionsList.pending, (state) => {
      return {
        ...state,
        sessionsList: null,
        sessionsListLoading: true,
      };
    }),
      builder.addCase(fetchSessionsList.fulfilled, (state, res) => {
        const size = res.payload.data.size;
        const page: typeof res.payload.data.page = map(
          res.payload.data.page,
          (item) => {
            const startDateTime = moment(item.startDateTime)
              .format('DD/MM/YYYY HH:mm:ss')
              .toString();
            const finalDateTime = moment(item.finalDateTime)
              .format('DD/MM/YYYY HH:mm:ss')
              .toString();
            return {
              ...item,
              startDateTime: startDateTime,
              finalDateTime:
                finalDateTime === '01/01/0001 12:00:00' ||
                finalDateTime === '01/01/0001 00:00:00'
                  ? '--'
                  : finalDateTime,
            };
          },
        );
        return {
          ...state,
          sessionsList: { page, size },
          sessionsListLoading: false,
        };
      }),
      builder.addCase(fetchSessionsList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          sessionsListLoading: false,
        };
      });

    builder.addCase(fetchSessionsExportExcel.pending, (state) => {
      return {
        ...state,
        sessionsExportExcelLoading: true,
        sessionsExportModalIsOpen: true,
        sessionsExportPercentage: 0,
      };
    }),
      builder.addCase(fetchSessionsExportExcel.fulfilled, (state, res) => {
        return {
          ...state,
          sessionsExportExcelLoading: false,
          sessionsExportPercentage: 10,
        };
      }),
      builder.addCase(fetchSessionsExportExcel.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          sessionsExportExcelLoading: false,
          sessionsExportModalIsOpen: false,
          sessionsExportPercentage: 0,
        };
      });

    builder.addCase(fetchSessionsExportHTML.pending, (state) => {
      return {
        ...state,
        sessionsExportHTMLLoading: true,
        sessionsExportModalIsOpen: true,
        sessionsExportPercentage: 0,
      };
    }),
      builder.addCase(fetchSessionsExportHTML.fulfilled, (state, res) => {
        return {
          ...state,
          sessionsExportHTMLLoading: false,
          sessionsExportPercentage: 10,
        };
      }),
      builder.addCase(fetchSessionsExportHTML.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          sessionsExportHTMLLoading: false,
          sessionsExportModalIsOpen: false,
          sessionsExportPercentage: 0,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = sessionsListSlice.actions;
