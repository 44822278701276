/* eslint-disable pii/no-phone-number */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import {
  Grid,
  Typography,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { map } from 'lodash';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Checkbox, PageWrapper } from '../../../components';
import {
  useDispatch,
  userSlice,
  useSelector,
  fetchUserRoutes,
  fetchUserAddRoutes,
} from '../../../stores';
import { palette } from '../../../theme';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { StyledFiltersBtnsGrid } from '../styles';

export const UserRoutes: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    userInfo,
    userRoutesLoading,
    userAreasRoutesList,
    userAddRoutesLoading,
    userAreasRoutesAllChecked,
  } = useSelector((state) => state.userReducer);
  const responsiveMode = useResponsiveMode();

  useEffect(() => {
    dispatch(fetchUserRoutes({ userId: userInfo?.id.toString() || '' }));
  }, []);

  const handleSubmit = () => {
    const routesParams: number[] = [];
    map(userAreasRoutesList, (item) => {
      map(item.routes, (route) => {
        if (route.checked) routesParams.push(parseInt(route.id));
      });
    });

    dispatch(
      fetchUserAddRoutes({
        userId: userInfo ? userInfo.id.toString() : '0',
        routes: routesParams,
      }),
    ).then(() => {
      navigate(-1);
    });
  };

  const handleReturn = () => {
    dispatch(userSlice.actions.resetUserInfo());
    navigate(-1);
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <EditRoadIcon fontSize='large' sx={{ marginRight: 2 }} />
            Editar trechos de usuário
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography color={palette.text.main} margin={1}>
            <b>Usuário:</b> {userInfo?.name}
          </Typography>
          <br />
          <Typography color={palette.text.main} margin={1}>
            <b>Login:</b> {userInfo?.login}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Checkbox
            label='Marcar todos'
            checked={userAreasRoutesAllChecked}
            onChange={() => {
              dispatch(userSlice.actions.toggleUserAllAreasAndRoutes());
              dispatch(userSlice.actions.toggleUserOpenAll());
            }}
          />
        </Grid>
      </Grid>
      <Grid container margin={2}>
        {userRoutesLoading ? (
          <>
            <Skeleton
              animation='wave'
              height={60}
              sx={{ width: '100%', margin: 0, padding: 0 }}
            />
            <Skeleton
              animation='wave'
              height={60}
              sx={{ width: '100%', margin: 0, padding: 0 }}
            />
            <Skeleton
              animation='wave'
              height={60}
              sx={{ width: '100%', margin: 0, padding: 0 }}
            />
            <Skeleton
              animation='wave'
              height={60}
              sx={{ width: '100%', margin: 0, padding: 0 }}
            />
          </>
        ) : (
          map(userAreasRoutesList, (item, areaIndex) => {
            if (item.routes.length !== 0) {
              return (
                <Accordion
                  sx={{ width: '100%' }}
                  id={`category-${item.id}`}
                  expanded={item.open}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    onClick={() => {
                      dispatch(
                        userSlice.actions.toggleUserOpen({
                          areaIdx: areaIndex,
                        }),
                      );
                    }}
                  >
                    <Typography>{item.value}</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      paddingBottom: '30px',
                    }}
                  >
                    <div
                      style={{
                        borderBottom: '1px solid ' + palette.divider,
                        paddingBottom: '10px',
                      }}
                    >
                      <Checkbox
                        label='Marcar todos'
                        checked={item.allChecked}
                        onChange={() => {
                          dispatch(
                            userSlice.actions.toggleUserAllRoutes({
                              areaIdx: areaIndex,
                            }),
                          );
                        }}
                      />
                    </div>
                    <div
                      style={{
                        paddingTop: '10px',
                        maxHeight: '50vh',
                        overflowY: 'scroll',
                        borderBottom: '1px solid ' + palette.divider,
                        paddingBottom: '10px',
                      }}
                    >
                      {map(item.routes, (route, routeIndex) => {
                        return (
                          <Checkbox
                            label={route.value}
                            checked={route.checked}
                            onChange={() => {
                              dispatch(
                                userSlice.actions.toggleUserRoute({
                                  areaIdx: areaIndex,
                                  routeIdx: routeIndex,
                                }),
                              );
                            }}
                          />
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            }
          })
        )}

        <StyledFiltersBtnsGrid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          margin={1}
          sx={{ marginTop: '20px' }}
        >
          <Button
            variant='contained'
            label='Salvar'
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
            sx={{ width: '100%' }}
            loading={userAddRoutesLoading}
            disabled={userAddRoutesLoading}
          />
          <Button
            color='secondary'
            variant='contained'
            label='Voltar'
            startIcon={<ArrowBackIcon />}
            onClick={handleReturn}
            sx={{ width: '100%' }}
            disabled={userAddRoutesLoading}
          />
        </StyledFiltersBtnsGrid>
      </Grid>
    </PageWrapper>
  );
};
