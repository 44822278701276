/* eslint-disable pii/no-phone-number */
import DashboardIcon from '@mui/icons-material/Dashboard';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Grid, Skeleton, Typography } from '@mui/material';
import { map, replace } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  PageWrapper,
  FiltersGrid,
  DropdownMultiInput,
  Button,
} from '../../components';
import {
  administrativeDashboardSlice,
  fetchAdministrativeDashboardMapInfo,
  fetchAdministrativeDashboardAreas,
  fetchAdministrativeDashboardNotifications,
  fetchAdministrativeDashboardSales,
  fetchAdministrativeDashboardSessionsLastWeek,
  fetchAdministrativeDashboardSessionsToday,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { useInterval } from '../../utils/useInterval';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { KPIBox } from './KPIBox';
import { Map } from './Map';
// import MapComp from './MapComp';
import { StyledMapGrid } from './styles';

export const AdministrativeDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const {
    administrativeDashboardAreas,
    administrativeDashboardFilteredAreas,
    administrativeDashboardRoutesCoords,
    administrativeDashboardOperatorsCoords,
    administrativeDashboardNotificationsToday,
    administrativeDashboardNotificationsLastWeek,
    administrativeDashboardRecenterMap,
    administrativeDashboardSalesToday,
    administrativeDashboardSalesLastWeek,
    administrativeDashboardSessionsToday,
    administrativeDashboardSessionsLastWeek,
    administrativeDashboardLoading,
    administrativeDashboardAreasLoading,
    administrativeDashboardRequestLoading,
    administrativeDashboardNotificationsLoading,
    administrativeDashboardSalesLoading,
    administrativeDashboardSessionsLastWeekLoading,
    administrativeDashboardSessionsTodayLoading,
  } = useSelector((state) => state.administrativeDashboardReducer);
  const responsiveMode = useResponsiveMode();
  // const isTabOnFocus = useTabOnFocus();
  const [selectedAreas, setSelectedAreas] = useState<
    { id: string; value: string }[]
  >([]);
  const [pageMayRender, setPageMayRender] = useState(false);
  // const [updateInterval, setUpdateInterval] = useState<
  //   NodeJS.Timer | undefined
  // >();
  const TIME_OF_INTERVAL = 180000;

  useEffect(() => {
    const initializeAdministrativeDashboard = async () => {
      // dispatch(administrativeDashboardSlice.actions.setRecenterMap(false));
      dispatch(
        administrativeDashboardSlice.actions.setAdministrativeDashboardLoadingsTrue(),
      );
      dispatch(fetchAdministrativeDashboardAreas());
    };

    initializeAdministrativeDashboard();
  }, []);

  useEffect(() => {
    setSelectedAreas(administrativeDashboardAreas);
    const operatingAreasIds = map(administrativeDashboardAreas, 'id');
    dispatch(
      fetchAdministrativeDashboardMapInfo({ areas: operatingAreasIds }),
    ).then(() => {
      setPageMayRender(true);
      dispatch(
        fetchAdministrativeDashboardNotifications({ areas: operatingAreasIds }),
      ).then(() => {
        dispatch(
          fetchAdministrativeDashboardSales({ areas: operatingAreasIds }),
        ).then(() => {
          dispatch(
            fetchAdministrativeDashboardSessionsToday({
              areas: operatingAreasIds,
            }),
          ).then(() => {
            dispatch(
              fetchAdministrativeDashboardSessionsLastWeek({
                areas: operatingAreasIds,
              }),
            );
          });
        });
      });
    });
  }, [administrativeDashboardAreas]);

  const handleUpdate = () => {
    const operatingAreasIds = map(administrativeDashboardFilteredAreas, 'id');

    dispatch(
      administrativeDashboardSlice.actions.setAdministrativeDashboardLoadingsTrue(),
    );
    dispatch(
      fetchAdministrativeDashboardMapInfo({ areas: operatingAreasIds }),
    ).then(() => {
      dispatch(
        fetchAdministrativeDashboardNotifications({ areas: operatingAreasIds }),
      ).then(() => {
        dispatch(
          fetchAdministrativeDashboardSales({ areas: operatingAreasIds }),
        ).then(() => {
          dispatch(
            fetchAdministrativeDashboardSessionsToday({
              areas: operatingAreasIds,
            }),
          ).then(() => {
            dispatch(
              fetchAdministrativeDashboardSessionsLastWeek({
                areas: operatingAreasIds,
              }),
            );
          });
        });
      });
    });
  };

  useInterval(handleUpdate, TIME_OF_INTERVAL);

  const handleSubmit = () => {
    const operatingAreasIds = map(selectedAreas, 'id');

    dispatch(
      administrativeDashboardSlice.actions.setAdministrativeDashboardFilteredAreas(
        selectedAreas,
      ),
    );
    dispatch(administrativeDashboardSlice.actions.setRecenterMap(true));
    dispatch(
      administrativeDashboardSlice.actions.setAdministrativeDashboardLoadingsTrue(),
    );
    dispatch(
      fetchAdministrativeDashboardMapInfo({ areas: operatingAreasIds }),
    ).then(() => {
      dispatch(
        fetchAdministrativeDashboardNotifications({ areas: operatingAreasIds }),
      ).then(() => {
        dispatch(
          fetchAdministrativeDashboardSales({ areas: operatingAreasIds }),
        ).then(() => {
          dispatch(
            fetchAdministrativeDashboardSessionsToday({
              areas: operatingAreasIds,
            }),
          ).then(() => {
            dispatch(
              fetchAdministrativeDashboardSessionsLastWeek({
                areas: operatingAreasIds,
              }),
            );
          });
        });
      });
    });
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h6'}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            {/* <CalendarMonthOutlinedIcon
              fontSize='large'
              sx={{ marginRight: 2 }}
            /> */}
            <DashboardIcon fontSize='large' sx={{ marginRight: 2 }} />
            Painel de controle administrativo
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <FiltersGrid
          loading={administrativeDashboardLoading}
          loadingRows={3}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            margin={1}
            sx={{
              display: 'flex',
              flexDirection:
                responsiveMode === 'phone' || responsiveMode === 'tablet'
                  ? 'column'
                  : 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {administrativeDashboardAreasLoading ? (
              <Skeleton
                animation='wave'
                width='100%'
                sx={{ padding: 0, margin: 0, minHeight: '147px' }}
              >
                <DropdownMultiInput
                  options={administrativeDashboardAreas}
                  defaultValue={administrativeDashboardAreas}
                  label='Áreas'
                  value={selectedAreas}
                  onChange={(value) => {
                    setSelectedAreas(value);
                  }}
                />
              </Skeleton>
            ) : (
              <DropdownMultiInput
                options={administrativeDashboardAreas}
                defaultValue={administrativeDashboardAreas}
                label='Áreas'
                value={selectedAreas}
                onChange={(value) => {
                  setSelectedAreas(value);
                }}
              />
            )}
            <Button
              variant='contained'
              startIcon={<FilterAltIcon />}
              label='Filtrar'
              sx={{ minWidth: '150px' }}
              loading={administrativeDashboardRequestLoading}
              onClick={handleSubmit}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection:
                responsiveMode === 'phone' || responsiveMode === 'tablet'
                  ? 'column'
                  : 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 5,
            }}
          >
            <KPIBox
              title='Notificações'
              value={administrativeDashboardNotificationsToday?.toString()}
              diffValue={
                administrativeDashboardNotificationsToday &&
                administrativeDashboardNotificationsLastWeek
                  ? (
                      administrativeDashboardNotificationsLastWeek -
                      administrativeDashboardNotificationsToday
                    ).toString()
                  : '0'
              }
              loading={administrativeDashboardNotificationsLoading}
            />
            <KPIBox
              title='Vendas'
              value={
                <>
                  <Typography variant={'h6'} color='primary'>
                    R$&nbsp;
                  </Typography>
                  <Typography variant={'h4'} color='primary'>
                    {replace(
                      administrativeDashboardSalesToday?.toFixed(2).toString(),
                      '.',
                      ',',
                    ).toString()}
                  </Typography>
                </>
              }
              // diffValue={(salesLastWeek - salesToday).toString()}
              diffValue={(
                administrativeDashboardSalesLastWeek -
                administrativeDashboardSalesToday
              ).toString()}
              loading={administrativeDashboardSalesLoading}
            />
            <KPIBox
              title='Usuários na operação'
              value={administrativeDashboardSessionsToday?.toString()}
              diffValue={
                administrativeDashboardSessionsToday &&
                administrativeDashboardSessionsLastWeek
                  ? (
                      administrativeDashboardSessionsLastWeek -
                      administrativeDashboardSessionsToday
                    ).toString()
                  : '0'
              }
              loading={administrativeDashboardSessionsTodayLoading}
            />
          </Grid>
          {!pageMayRender ? (
            <Skeleton
              animation='wave'
              width='100%'
              sx={{ padding: 0, margin: 0 }}
              variant='rectangular'
            >
              <Map
                routes={administrativeDashboardRoutesCoords}
                operators={administrativeDashboardOperatorsCoords}
                recenterMap={administrativeDashboardRecenterMap}
                setRecenterMapAsFalse={() =>
                  dispatch(
                    administrativeDashboardSlice.actions.setRecenterMap(false),
                  )
                }
              />
            </Skeleton>
          ) : (
            <StyledMapGrid item lg={12} md={12} sm={12} xs={12}>
              <Map
                routes={administrativeDashboardRoutesCoords}
                operators={administrativeDashboardOperatorsCoords}
                recenterMap={administrativeDashboardRecenterMap}
                setRecenterMapAsFalse={() =>
                  dispatch(
                    administrativeDashboardSlice.actions.setRecenterMap(false),
                  )
                }
              />
            </StyledMapGrid>
          )}
        </FiltersGrid>
      </Grid>
      {/* )} */}
    </PageWrapper>
  );
};
