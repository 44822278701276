/* eslint-disable pii/no-phone-number */
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid, Typography, Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { PageWrapper } from '../../components';
import {
  useDispatch,
  fetchConfigurationsList,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { ConfigurationInfoModal, ConfigurationCancelModal } from './modals';
import { SavedConfigurationsTable } from './table';

export const ConfigurationsManagement: React.FC = () => {
  const dispatch = useDispatch();
  const { configurationsListLoading } = useSelector(
    (state) => state.configurationsReducer,
  );
  const responsiveMode = useResponsiveMode();

  useEffect(() => {
    dispatch(fetchConfigurationsList());
  }, []);

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <SettingsIcon fontSize='large' sx={{ marginRight: 2 }} />
            Gerenciamento de configurações
          </Typography>
        </Grid>
      </Grid>
      <Grid container margin={3}>
        {configurationsListLoading ? (
          <Skeleton animation='wave' width='100%' height='300px' />
        ) : (
          <SavedConfigurationsTable />
        )}
      </Grid>
      <ConfigurationInfoModal />
      <ConfigurationCancelModal />
    </PageWrapper>
  );
};
