import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { map, split } from 'lodash';
import {
  fetchAdministrativeDashboardMapInfoService,
  fetchAdministrativeDashboardAreasService,
  fetchAdministrativeDashboardNotificationsService,
  fetchAdministrativeDashboardSalesService,
  fetchAdministrativeDashboardSessionsLastWeekService,
  fetchAdministrativeDashboardSessionsTodayService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import {
  AdministrativeDashboardDefaultFetchType,
  AdministrativeDashboardStateType,
} from './types';

const initialState: AdministrativeDashboardStateType = {
  administrativeDashboardAreas: [],
  administrativeDashboardFilteredAreas: [],
  administrativeDashboardRoutesCoords: [],
  administrativeDashboardOperatorsCoords: [],
  administrativeDashboardNotificationsToday: 0,
  administrativeDashboardNotificationsLastWeek: 0,
  administrativeDashboardRecenterMap: false,
  administrativeDashboardSalesToday: 0,
  administrativeDashboardSalesLastWeek: 0,
  administrativeDashboardSessionsToday: 0,
  administrativeDashboardSessionsLastWeek: 0,
  administrativeDashboardLoading: false,
  administrativeDashboardMapInfoLoading: false,
  administrativeDashboardRequestLoading: false,
  administrativeDashboardAreasLoading: false,
  administrativeDashboardNotificationsLoading: false,
  administrativeDashboardSalesLoading: false,
  administrativeDashboardSessionsLastWeekLoading: false,
  administrativeDashboardSessionsTodayLoading: false,
};

export const fetchAdministrativeDashboardMapInfo = createAsyncThunk(
  'administrativeDashboardMapInfo',
  async (params: AdministrativeDashboardDefaultFetchType) => {
    return await fetchAdministrativeDashboardMapInfoService(params);
  },
);

export const fetchAdministrativeDashboardAreas = createAsyncThunk(
  'administrativeDashboardAreas',
  async () => {
    return await fetchAdministrativeDashboardAreasService();
  },
);

export const fetchAdministrativeDashboardNotifications = createAsyncThunk(
  'administrativeDashboardNotifications',
  async (params: AdministrativeDashboardDefaultFetchType) => {
    return await fetchAdministrativeDashboardNotificationsService(params);
  },
);

export const fetchAdministrativeDashboardSales = createAsyncThunk(
  'administrativeDashboardSales',
  async (params: AdministrativeDashboardDefaultFetchType) => {
    return await fetchAdministrativeDashboardSalesService(params);
  },
);

export const fetchAdministrativeDashboardSessionsLastWeek = createAsyncThunk(
  'administrativeDashboardSessionsLastWeek',
  async (params: AdministrativeDashboardDefaultFetchType) => {
    return await fetchAdministrativeDashboardSessionsLastWeekService(params);
  },
);

export const fetchAdministrativeDashboardSessionsToday = createAsyncThunk(
  'administrativeDashboardSessionsToday',
  async (params: AdministrativeDashboardDefaultFetchType) => {
    return await fetchAdministrativeDashboardSessionsTodayService(params);
  },
);

export const administrativeDashboardSlice = createSlice({
  name: 'administrativeDashboard',
  initialState,
  reducers: {
    setAdministrativeDashboardLoadingsTrue(state) {
      // state.administrativeDashboardAreasLoading = true;
      state.administrativeDashboardNotificationsLoading = true;
      state.administrativeDashboardSalesLoading = true;
      state.administrativeDashboardSessionsLastWeekLoading = true;
      state.administrativeDashboardSessionsTodayLoading = true;
    },
    setRecenterMap(state, action: PayloadAction<boolean>) {
      state.administrativeDashboardRecenterMap = action.payload;
    },
    setAdministrativeDashboardFilteredAreas(
      state,
      action: PayloadAction<{ id: string; value: string }[]>,
    ) {
      state.administrativeDashboardFilteredAreas = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdministrativeDashboardMapInfo.pending, (state) => {
      return {
        ...state,
        administrativeDashboardOperatorsCoords: [],
        administrativeDashboardMapInfoLoading: true,
      };
    }),
      builder.addCase(
        fetchAdministrativeDashboardMapInfo.fulfilled,
        (state, res) => {
          const handledRoutesCoords: {
            id: number;
            name: string;
            areaName: string;
            color: string;
            coordinates: { latitude: number; longitude: number }[];
          }[] = [];
          map(res.payload.data.routes, (item) => {
            const splitCoords = split(item.coordinates, '|');
            const handledCoords = map(splitCoords, (splitCoord) => {
              const coords = split(splitCoord, ';');
              return {
                latitude: parseFloat(coords[0]),
                longitude: parseFloat(coords[1]),
              };
            });

            if (item.id !== 0) {
              handledRoutesCoords.push({
                id: item.id,
                name: item.name,
                areaName: item.areaName,
                color: item.color,
                coordinates: handledCoords,
              });
            }
          });

          const handledOperatorsCoords: {
            sessionId: string;
            id: number;
            login: string;
            name: string;
            latitude: number;
            longitude: number;
            startDateTime: string;
            finalDateTime: string;
          }[] = [];
          map(res.payload.data.users, (item) => {
            handledOperatorsCoords.push({
              ...item,
              latitude: item.latitude,
              longitude: item.longitude,
            });
          });
          // handledOperatorsCoords.push({
          //   sessionId: '123456',
          //   id: 123,
          //   login: 'loginteste',
          //   name: 'Fulano da Silva',
          //   latitude: -22.888517 + Math.random() / 100,
          //   longitude: -43.123674 + Math.random() / 100,
          //   startDateTime: '',
          //   finalDateTime: '',
          // });

          return {
            ...state,
            administrativeDashboardRoutesCoords: handledRoutesCoords,
            administrativeDashboardOperatorsCoords: handledOperatorsCoords,
            administrativeDashboardMapInfoLoading: false,
          };
        },
      ),
      builder.addCase(
        fetchAdministrativeDashboardMapInfo.rejected,
        (state, res) => {
          // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
          return {
            ...state,
            administrativeDashboardMapInfoLoading: false,
          };
        },
      );

    builder.addCase(fetchAdministrativeDashboardAreas.pending, (state) => {
      return {
        ...state,
        administrativeDashboardAreasLoading: true,
      };
    }),
      builder.addCase(
        fetchAdministrativeDashboardAreas.fulfilled,
        (state, res) => {
          const handledAreas: { id: string; value: string }[] = [];
          map(res.payload.data.areas, (item) => {
            if (item.id !== 0) {
              handledAreas.push({
                id: item.id.toString(),
                value: item.value,
              });
            }
          });

          return {
            ...state,
            administrativeDashboardAreas: handledAreas,
            administrativeDashboardAreasLoading: false,
          };
        },
      ),
      builder.addCase(
        fetchAdministrativeDashboardAreas.rejected,
        (state, res) => {
          // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
          return {
            ...state,
            administrativeDashboardAreasLoading: false,
          };
        },
      );

    builder.addCase(
      fetchAdministrativeDashboardNotifications.pending,
      (state) => {
        return {
          ...state,
          administrativeDashboardNotificationsLoading: true,
        };
      },
    ),
      builder.addCase(
        fetchAdministrativeDashboardNotifications.fulfilled,
        (state, res) => {
          return {
            ...state,
            administrativeDashboardNotificationsToday: res.payload.data.today,
            administrativeDashboardNotificationsLastWeek:
              res.payload.data.lastWeek,
            administrativeDashboardNotificationsLoading: false,
          };
        },
      ),
      builder.addCase(
        fetchAdministrativeDashboardNotifications.rejected,
        (state, res) => {
          // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
          return {
            ...state,
            administrativeDashboardNotificationsLoading: false,
          };
        },
      );

    builder.addCase(fetchAdministrativeDashboardSales.pending, (state) => {
      return {
        ...state,
        administrativeDashboardSalesLoading: true,
      };
    }),
      builder.addCase(
        fetchAdministrativeDashboardSales.fulfilled,
        (state, res) => {
          return {
            ...state,
            administrativeDashboardSalesToday: res.payload.data.today,
            administrativeDashboardSalesLastWeek: res.payload.data.lastWeek,
            administrativeDashboardSalesLoading: false,
          };
        },
      ),
      builder.addCase(
        fetchAdministrativeDashboardSales.rejected,
        (state, res) => {
          // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
          return {
            ...state,
            administrativeDashboardSalesLoading: false,
          };
        },
      );

    builder.addCase(
      fetchAdministrativeDashboardSessionsLastWeek.pending,
      (state) => {
        return {
          ...state,
          administrativeDashboardSessionsLastWeekLoading: true,
        };
      },
    ),
      builder.addCase(
        fetchAdministrativeDashboardSessionsLastWeek.fulfilled,
        (state, res) => {
          return {
            ...state,
            administrativeDashboardSessionsLastWeek: res.payload.data.count,
            administrativeDashboardSessionsLastWeekLoading: false,
          };
        },
      ),
      builder.addCase(
        fetchAdministrativeDashboardSessionsLastWeek.rejected,
        (state, res) => {
          // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
          return {
            ...state,
            administrativeDashboardSessionsLastWeekLoading: false,
          };
        },
      );

    builder.addCase(
      fetchAdministrativeDashboardSessionsToday.pending,
      (state) => {
        return {
          ...state,
          administrativeDashboardSessionsTodayLoading: true,
        };
      },
    ),
      builder.addCase(
        fetchAdministrativeDashboardSessionsToday.fulfilled,
        (state, res) => {
          return {
            ...state,
            administrativeDashboardSessionsToday: res.payload.data.count,
            administrativeDashboardSessionsTodayLoading: false,
          };
        },
      ),
      builder.addCase(
        fetchAdministrativeDashboardSessionsToday.rejected,
        (state, res) => {
          // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
          return {
            ...state,
            administrativeDashboardSessionsTodayLoading: false,
          };
        },
      );
  },
});

// Action creators are generated for each case reducer function
export const { setAdministrativeDashboardLoadingsTrue, setRecenterMap } =
  administrativeDashboardSlice.actions;
