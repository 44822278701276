import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Skeleton } from '@mui/material';
import { times } from 'lodash';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Button, ColorPicker, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  fetchAreasList,
  areaSlice,
  fetchAreaEdit,
} from '../../../stores';
import { handleErrors } from '../../../utils/Helpers';

export const EditAreaModal: React.FC = () => {
  const dispatch = useDispatch();
  const { areaInfo, areaInfoLoading, areaEditIsOpen, areaEditLoading } =
    useSelector((state) => state.areaReducer);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    color: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Insira o nome da área')
      .required('O nome é obrigatório'),
    color: Yup.string()
      .required('Selecione uma cor')
      .required('A cor é obrigatória'),
  });

  const handleClose = () => {
    setErrors({});
    setFormData({
      name: '',
      description: '',
      color: '',
    });
    dispatch(areaSlice.actions.toggleAreaEditIsOpen());
  };

  const handleSubmit = () => {
    if (areaInfo) {
      setErrors({});
      validationSchema
        .validate(formData, { abortEarly: false })
        .then(() =>
          dispatch(
            fetchAreaEdit({
              ...formData,
              areaId: areaInfo.id.toString(),
            }),
          ).then((result) => {
            handleClose();
            if (result.meta.requestStatus === 'fulfilled') {
              dispatch(fetchAreasList());
            }
          }),
        )
        .catch((err) => {
          setErrors(handleErrors(err));
        });
    }
  };

  const handleReturn = () => {
    setErrors({});
    setFormData({
      name: '',
      description: '',
      color: '',
    });
    dispatch(areaSlice.actions.toggleAreaEditIsOpen());
  };

  useEffect(() => {
    setFormData({
      name: areaInfo ? areaInfo.name : '',
      description: areaInfo && areaInfo.description ? areaInfo.description : '',
      color: areaInfo ? areaInfo.color : '',
    });
  }, [areaInfo]);

  return (
    <Modal open={areaEditIsOpen} onClose={handleClose} title='Editar área'>
      <>
        {areaInfoLoading ? (
          <Box>
            {times(3, () => (
              <Skeleton animation='wave' width='100%'>
                <Input
                  data-testid='areaName'
                  type='text'
                  margin='normal'
                  label='Nome'
                  value={formData.name}
                  onChange={(value) =>
                    setFormData({ ...formData, name: value })
                  }
                  errorMessage={errors && errors.name}
                />
              </Skeleton>
            ))}
            {times(2, () => (
              <Skeleton animation='wave' width='100%'>
                <Button
                  variant='contained'
                  label='Salvar'
                  type='submit'
                  style={{ width: '100%', margin: 0, marginTop: 10 }}
                  loading={areaEditLoading}
                  onClick={handleSubmit}
                />
              </Skeleton>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              mt: '10px',
            }}
          >
            <Input
              data-testid='areaName'
              type='text'
              margin='normal'
              label='Nome'
              value={formData.name}
              onChange={(value) => setFormData({ ...formData, name: value })}
              errorMessage={errors && errors.name}
            />
            <Input
              data-testid='areaDescription'
              type='text'
              margin='normal'
              label='Descrição (opcional)'
              value={formData.description}
              onChange={(value) =>
                setFormData({ ...formData, description: value })
              }
            />
            <ColorPicker
              data-testid='areaColor'
              type='text'
              margin='normal'
              label='Cor'
              value={formData.color}
              onChange={(value) => setFormData({ ...formData, color: value })}
              errorMessage={errors && errors.color}
            />
            <Button
              variant='contained'
              label='Salvar'
              startIcon={<SaveIcon />}
              type='submit'
              style={{ width: '100%', margin: 0, marginTop: 30 }}
              loading={areaEditLoading}
              onClick={handleSubmit}
            />
            <Button
              color='secondary'
              variant='contained'
              startIcon={<ArrowBackIcon />}
              label='Voltar'
              type='reset'
              style={{ width: '100%', margin: 0, marginTop: 10 }}
              onClick={handleReturn}
            />
          </Box>
        )}
      </>
    </Modal>
  );
};
