import { styled, Box, Container, FormGroup } from '@mui/material';
import { palette } from '../../theme';

export const StyledWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: palette.primary.main,
}));

export const StyledMainBox = styled(Box)(() => ({
  width: 425,
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 9,
  overflow: 'hidden',
}));

export const StyledBodyContainer = styled(Container)(() => ({
  height: '100%',
}));

export const StyledBodyBox = styled(Container)(() => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
}));

export const StyledButtonsBox = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 10,
  marginBottom: 60,
}));

export const StyledFormGroup = styled('form')(() => ({
  width: '100%',
  marginTop: 2,
  marginBottom: 2,
}));

export const StyledLogo = styled('img')(() => ({
  width: 150,
}));

export const StyledLogoWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 30,
}));
