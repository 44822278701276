import { api } from '..';

import {
  fetchActionsHistoryListUrl,
  fetchActionsHistoryFiltersUrl,
  fetchActionsHistoryViewUrl
} from './constants';

import {
  ActionsListPageResponseType,
  ActionsFiltersType,
  ActionsViewResponseType
} from './types';

export const fetchActionsHistoryListService = async (params: {
  userName?: string
  profileId?: number
  permissionId?: number
  startDateTime?: string
  finalDateTime?: string
  page?: number
}) => {
  const response = await api.get<ActionsListPageResponseType>(fetchActionsHistoryListUrl, { params });
  return response.data;
}

export const fetchActionsHistoryFiltersService = async () => {
  const response = await api.get<ActionsFiltersType>(fetchActionsHistoryFiltersUrl);
  return response.data;
}

export const fetchActionsHistoryViewService = async (params: {
  id: number
}) => {
  const response = await api.get<ActionsViewResponseType>(`${fetchActionsHistoryViewUrl}/${params.id}`);
  return response.data;
}