import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchAreasListService, fetchAreasCreateService } from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from '../Store';
import { AreasStateType } from './types';
import { map } from 'lodash';

const initialState: AreasStateType = {
  areasList: null,
  areasPage: 1,
  areasListLoading: false,
  areasCreateIsOpen: false,
  areasCreateLoading: false,
};

export const fetchAreasList = createAsyncThunk('areasList', async () => {
  return await fetchAreasListService();
});

export const fetchAreasCreate = createAsyncThunk(
  'areasNew',
  async (params: {
    name: string;
    description: string | null;
    color: string;
  }) => {
    return await fetchAreasCreateService(params);
  },
);

export const areasSlice = createSlice({
  name: 'areas',
  initialState,
  reducers: {
    toggleAreasCreateIsOpen(state) {
      state.areasCreateIsOpen
        ? (state.areasCreateIsOpen = false)
        : (state.areasCreateIsOpen = true);
    },
    setAreasPage(state, action: PayloadAction<number>) {
      state.areasPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAreasList.pending, (state) => {
      return {
        ...state,
        areasList: null,
        areasListLoading: true,
      };
    }),
      builder.addCase(fetchAreasList.fulfilled, (state, res) => {
        const handledList = map(res.payload.data, (item) => {
          return {
            ...item,
            description: item.description || '--',
          };
        });
        return {
          ...state,
          areasList: handledList,
          areasListLoading: false,
        };
      }),
      builder.addCase(fetchAreasList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          areasListLoading: false,
        };
      });

    builder.addCase(fetchAreasCreate.pending, (state) => {
      return {
        ...state,
        areasCreateLoading: true,
      };
    }),
      builder.addCase(fetchAreasCreate.fulfilled, (state, res) => {
        return {
          ...state,
          areasCreateLoading: false,
          areasCreateIsOpen: false,
        };
      }),
      builder.addCase(fetchAreasCreate.rejected, (state) => {
        return {
          ...state,
          areasCreateLoading: false,
          areasCreateIsOpen: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { toggleAreasCreateIsOpen, setAreasPage } = areasSlice.actions;
