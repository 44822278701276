import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import SaveIcon from '@mui/icons-material/Save';
import {
  Typography,
  Box,
  InputAdornment,
  IconButton,
  List,
  Divider,
  CircularProgress,
  Grid,
  Paper,
} from '@mui/material';
import { map, omit, times } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button,
  Input,
  TimePicker,
  PageWrapper,
  Checkbox,
} from '../../components';
import { NOTIFICATIONS_MANAGEMENT } from '../../routes';
import {
  useDispatch,
  useSelector,
  notificationSlice,
  fetchNotificationsList,
  fetchNotificationEditRecords,
} from '../../stores';
import { palette } from '../../theme';
import { handleDateTime, handleErrors } from '../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from './styles';

export const NotificationEditRecords: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = useSelector((state) => state.authReducer);
  const {
    notificationInfoForEditRecords,
    notificationEditRecordsLoading,
    notificationInfoForEditRecordsLoading,
  } = useSelector((state) => state.notificationReducer);
  const filtersValues = useSelector(
    (state) => state.notificationsFiltersValuesReducer,
  );
  const [formData, setFormData] = useState({
    password: '',
    time: moment(notificationInfoForEditRecords?.registerDateTime).format(
      'HH:mm',
    ),
    observation: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [blockCheckboxes, setBlockCheckboxes] = useState(false);

  const validationSchema = Yup.object({
    observation: Yup.string().required('Insira uma observação'),
    password: Yup.string()
      .required('Insira sua senha')
      .required('A senha é obrigatória'),
  });

  useEffect(() => {
    const recordsNotChecked: {
      id: number;
      registerDateTime: string;
      routeName: string;
      checked: boolean;
    }[] = [];
    map(notificationInfoForEditRecords?.records, (record) => {
      if (!record.checked) recordsNotChecked.push(record);
    });
    if (recordsNotChecked.length === 1) setBlockCheckboxes(true);
    else setBlockCheckboxes(false);
  }, [notificationInfoForEditRecords]);

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        validationSchema
          .validate(formData, { abortEarly: false })
          .then(() => {
            if (notificationInfoForEditRecords) {
              const checkedRecordIds: number[] = [];
              map(notificationInfoForEditRecords?.records, (record) => {
                if (!record.checked) checkedRecordIds.push(record.id);
              });
              dispatch(
                fetchNotificationEditRecords({
                  notificationId: notificationInfoForEditRecords.id.toString(),
                  registerTime: `${formData.time}:00`,
                  records: checkedRecordIds,
                  login: login,
                  password: formData.password,
                  observation: formData.observation,
                }),
              ).then((res) => {
                if (res.meta.requestStatus !== 'rejected') {
                  dispatch(
                    fetchNotificationsList(omit(filtersValues, ['days'])),
                  );
                  setFormData({
                    password: '',
                    time: moment(
                      notificationInfoForEditRecords?.registerDateTime,
                    ).format('HH:mm'),
                    observation: '',
                  });
                  navigate(NOTIFICATIONS_MANAGEMENT);
                }
              });
            }
          })
          .catch((err) => {
            setErrors(handleErrors(err));
          });
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    setErrors({});
    setFormData({
      password: '',
      time: moment(notificationInfoForEditRecords?.registerDateTime).format(
        'HH:mm',
      ),
      observation: '',
    });
    notificationSlice.actions.setNotificationEditRecordsId({
      notificationId: '',
    });
    navigate(NOTIFICATIONS_MANAGEMENT);
  };

  return (
    <PageWrapper>
      {notificationInfoForEditRecordsLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
              <Typography
                variant='h5'
                margin={1}
                display='flex'
                justifyContent='flex-start'
                alignItems='center'
                color={palette.text.main}
              >
                <CircleNotificationsIcon
                  fontSize='large'
                  sx={{ marginRight: 2 }}
                />
                Editar registros da irregularidade
              </Typography>
            </Grid>
          </Grid>
          <Paper
            style={{
              width: '100%',
              marginTop: '8px',
              padding: '20px',
              borderRadius: '9px',
            }}
          >
            <StyledModalWrapper>
              <StyledModalInnerWrapper>
                <StyledModalTextRow>
                  <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                    Placa:
                  </Typography>
                  <Typography>
                    {notificationInfoForEditRecords?.plate}
                  </Typography>
                </StyledModalTextRow>
                <StyledModalTextRow>
                  <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                    Data e hora de registro:
                  </Typography>
                  <Typography>
                    {handleDateTime(
                      notificationInfoForEditRecords?.registerDateTime,
                    )}
                  </Typography>
                </StyledModalTextRow>
              </StyledModalInnerWrapper>
            </StyledModalWrapper>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                mt: '10px',
              }}
            >
              <TimePicker
                label='Editar horário do primeiro registro'
                value={formData.time}
                disabled={notificationEditRecordsLoading}
                onChange={(value) => setFormData({ ...formData, time: value })}
              />
              <Typography color='primary' sx={{ mt: 2 }}>
                Selecione os registros que deseja remover
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  maxHeight: '300px',
                  overflow: 'auto',
                }}
              >
                <List
                  component='nav'
                  aria-label='mailbox folders'
                  sx={{ width: '100%' }}
                >
                  {map(
                    notificationInfoForEditRecords?.records,
                    (item, index) => {
                      return (
                        <>
                          <Divider />
                          <Checkbox
                            label={`${item.routeName} (${moment(
                              item.registerDateTime,
                            ).format('HH:mm:ss')})`}
                            checked={item.checked}
                            disabled={
                              notificationEditRecordsLoading ||
                              (blockCheckboxes && !item.checked)
                            }
                            onChange={() => {
                              dispatch(
                                notificationSlice.actions.toggleNotificationRegister(
                                  { index: index },
                                ),
                              );
                            }}
                          />
                          <Divider />
                        </>
                      );
                    },
                  )}
                </List>
              </Box>
              <Input
                label='Insira uma observação (obrigatório)'
                value={formData.observation}
                disabled={notificationEditRecordsLoading}
                onChange={(InputProps) =>
                  setFormData({ ...formData, observation: InputProps })
                }
                errorMessage={errors && errors.observation}
              />
              <Input
                data-testid='password'
                type={showPassword ? 'text' : 'password'}
                label='Insira sua senha'
                disabled={notificationEditRecordsLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formData.password}
                onChange={(InputProps) =>
                  setFormData({ ...formData, password: InputProps })
                }
                errorMessage={errors && errors.password}
              />
              <Button
                variant='contained'
                label='Salvar'
                startIcon={<SaveIcon />}
                type='submit'
                style={{ width: '100%', margin: 0, marginTop: 30 }}
                loading={notificationEditRecordsLoading}
                onClick={handleSubmit}
              />
              <Button
                color='secondary'
                variant='contained'
                startIcon={<ArrowBackIcon />}
                label='Voltar'
                type='reset'
                disabled={notificationEditRecordsLoading}
                style={{ width: '100%', margin: 0, marginTop: 10 }}
                onClick={handleReturn}
              />
            </Box>
          </Paper>
        </>
      )}
    </PageWrapper>
  );
};
