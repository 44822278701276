import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';
import { Typography, Box, Skeleton } from '@mui/material';
import { times } from 'lodash';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  saleSlice,
  fetchSaleSendEmail,
} from '../../../stores';
import { handleErrors } from '../../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const SaleSendEmailModal: React.FC = () => {
  const dispatch = useDispatch();
  const { saleInfo, saleSendEmailLoading, saleSendEmailIsOpen } = useSelector(
    (state) => state.saleReducer,
  );
  const [formData, setFormData] = useState({
    email: '',
    name: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Insira o email para envio')
      .required('O email é obrigatório'),
    name: Yup.string()
      .required('Insira o nome do destinatário')
      .required('O nome é obrigatório'),
  });

  const handleClose = () => {
    setErrors({});
    setFormData({
      email: '',
      name: '',
    });
    saleSlice.actions.setSaleSendEmailId({
      saleId: '',
    });
    dispatch(saleSlice.actions.toggleSaleSendEmailIsOpen());
  };

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(
        () =>
          saleInfo &&
          dispatch(
            fetchSaleSendEmail({
              saleId: saleInfo.id,
              email: formData.email,
              name: formData.name,
            }),
          ).then(() => {
            setErrors({});
            setFormData({
              email: '',
              name: '',
            });
            saleSlice.actions.setSaleSendEmailId({
              saleId: '',
            });
          }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    setErrors({});
    setFormData({
      email: '',
      name: '',
    });
    saleSlice.actions.setSaleSendEmailId({
      saleId: '',
    });
    dispatch(saleSlice.actions.toggleSaleSendEmailIsOpen());
    dispatch(saleSlice.actions.toggleSaleInfoIsOpen());
  };

  return (
    <Modal
      open={saleSendEmailIsOpen}
      onClose={handleClose}
      title='Enviar comprovante por email'
    >
      <>
        <>
          {saleSendEmailLoading ? (
            <StyledModalWrapper>
              <StyledModalInnerWrapper>
                {times(1, () => (
                  <Skeleton animation='wave' height={29} width='100%' />
                ))}
              </StyledModalInnerWrapper>
            </StyledModalWrapper>
          ) : (
            <StyledModalWrapper>
              <StyledModalInnerWrapper>
                <StyledModalTextRow>
                  <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                    Veículo:
                  </Typography>
                  <Typography>{saleInfo?.vehicle}</Typography>
                </StyledModalTextRow>
              </StyledModalInnerWrapper>
            </StyledModalWrapper>
          )}
        </>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: '10px',
          }}
        >
          <Input
            data-testid='email'
            type='text'
            margin='normal'
            label='Email para envio'
            value={formData.email}
            onChange={(value) => setFormData({ ...formData, email: value })}
            errorMessage={errors.email}
          />
          <Input
            data-testid='name'
            type='text'
            margin='normal'
            label='Nome do destinatário'
            value={formData.name}
            onChange={(value) => setFormData({ ...formData, name: value })}
            errorMessage={errors.name}
          />
          <Button
            variant='contained'
            startIcon={<EmailIcon />}
            label='Enviar'
            type='submit'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={saleSendEmailLoading}
            onClick={handleSubmit}
          />
          <Button
            color='secondary'
            variant='contained'
            startIcon={<ArrowBackIcon />}
            label='Voltar'
            type='reset'
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleReturn}
          />
        </Box>
      </>
    </Modal>
  );
};
