// All user related database operations can be defined here.
import { omit } from 'lodash';
import { api } from '..';
import {
  fetchConfigurationsListUrl,
  fetchConfigurationsCreateUrl,
  fetchConfigurationsCancelUrl,
  fetchConfigurationsUpdateUrl,
  fetchConfigurationsInfoUrl,
  fetchConfigurationsTypesListUrl,
} from './constants';
import {
  ConfigurationsCancelResponseType,
  ConfigurationsCreateResponseType,
  ConfigurationsUpdateResponseType,
  ConfigurationsInfoResponseType,
  ConfigurationsListResponseType,
  ConfigurationsTypesListResponseType,
} from './types';

export const fetchConfigurationsListService = async () => {
  return await api
    .get<ConfigurationsListResponseType>(fetchConfigurationsListUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchConfigurationsCreateService = async (params: {
  name: string;
  prices: {
    type: string;
    period: string;
    price: string;
  }[];
  workingPeriods: {
    startTime: string;
    endTime: string;
    days: string[];
  }[];
}) => {
  return await api
    .post<ConfigurationsCreateResponseType>(
      fetchConfigurationsCreateUrl,
      params,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchConfigurationsTypesListService = async () => {
  return await api
    .get<ConfigurationsTypesListResponseType>(fetchConfigurationsTypesListUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchConfigurationsInfoService = async (params: {
  configurationId: string;
}) => {
  return await api
    .get<ConfigurationsInfoResponseType>(
      `${fetchConfigurationsInfoUrl}/${params.configurationId}`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchConfigurationsCancelService = async (params: {
  configurationId: string;
}) => {
  return await api
    .delete<ConfigurationsCancelResponseType>(
      `${fetchConfigurationsCancelUrl}/${params.configurationId}`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchConfigurationsUpdateService = async (params: {
  configurationId: string;
  name: string;
  prices: {
    type: string;
    period: string;
    price: string;
  }[];
  workingPeriods: {
    startTime: string;
    endTime: string;
    days: string[];
  }[];
}) => {
  return await api
    .put<ConfigurationsUpdateResponseType>(
      `${fetchConfigurationsUpdateUrl}/${params.configurationId}`,
      JSON.stringify(omit(params, ['configurationId'])),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};
