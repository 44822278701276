import React from 'react';
import { useStyles } from './styles';

type FileUploadProps = {
  placeholder?: string;
  onUpload?: (form: FormData, file: File) => void;
};

export const FileUpload: React.FC<FileUploadProps> = (props) => {
  const classes = useStyles();

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];

    event.preventDefault();

    const formData = new FormData();
    formData.append('file', file);

    if (props.onUpload) {
      return props.onUpload(formData, file);
    }
  };

  return (
    <form className={classes.form}>
      <input type='file' onChange={handleUploadFile} />
    </form>
  );
};
