import InfoIcon from '@mui/icons-material/Info';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Button as MuiButton } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Table, TableText } from '../../../components';
import {
  useDispatch,
  useSelector,
  fetchNotificationInfo,
  fetchNotificationsList,
  notificationSlice,
  notificationsListSlice,
} from '../../../stores';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { NotificationsIssuedTableToolbar } from './NotificationsIssuedTableToolbar';

export const NotificationsIssuedTable: React.FC = () => {
  const dispatch = useDispatch();
  const {
    notificationsList,
    notificationsListLoading,
    notificationsTotalsSum,
  } = useSelector((state) => state.notificationsListReducer);
  const notificationsFiltersValues = useSelector(
    (state) => state.notificationsFiltersValuesReducer,
  );
  const { notificationsPage } = useSelector(
    (state) => state.notificationsListReducer,
  );
  const responsiveMode = useResponsiveMode();
  const [rows, setRows] = useState<any[]>([]);

  const {
    startDateTime,
    finalDateTime,
    plate,
    notificationId,
    situationId,
    routeAreaId,
    routeId,
    userName,
    serviceOrderOrigin,
    print,
  } = notificationsFiltersValues;

  useEffect(() => {
    if (notificationsList && notificationsList !== undefined) {
      setRows(notificationsList.page);
    } else setRows([]);
  }, [notificationsList]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Identificador',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.id} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'vehicle',
      headerName: 'Veículo',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.vehicle} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'registerDateTime',
      headerName: 'Data de criação',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.registerDateTime} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'userName',
      headerName: 'Usuário',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.userName} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'situation',
      headerName: 'Situação',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.situation} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'routeName',
      headerName: 'Trecho',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => (
        <TableText label={params.row.routeName} size={75} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      editable: false,
      sortable: false,
      // flex: 1,
      renderCell: (params) => {
        return responsiveMode === 'large-screen' ? (
          <MuiButton
            variant='contained'
            startIcon={<InfoIcon />}
            size='small'
            onClick={() => handleInfo(params.id, params.row.situation)}
            sx={{ paddingLeft: 1, paddingRight: 1 }}
          >
            Detalhar
          </MuiButton>
        ) : (
          <MuiButton
            variant='contained'
            size='small'
            onClick={() => handleInfo(params.id, params.row.situation)}
            // sx={{
            //   minWidth: 0,
            // }}
          >
            <InfoTwoToneIcon />
          </MuiButton>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Identificador',
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'registerDateTime',
      headerName: 'Data de criação',
      editable: false,
      sortable: false,
      flex: 2,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'situation',
      headerName: 'Situação',
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <MuiButton
          variant='contained'
          size='small'
          onClick={() => handleInfo(params.id, params.row.situation)}
          sx={{
            minWidth: 0,
          }}
        >
          <InfoTwoToneIcon />
        </MuiButton>
      ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  const handleInfo = (notificationId: GridRowId, situation: string) => {
    dispatch(
      fetchNotificationInfo({ notificationId: notificationId.toString() }),
    );
    dispatch(
      notificationSlice.actions.setNotificationInfoSituation({
        situation: situation,
      }),
    );
    dispatch(notificationSlice.actions.toggleNotificationInfoIsOpen());
  };

  return (
    <Table
      page={notificationsPage}
      changePage={(value: number) =>
        notificationsListSlice.actions.setNotificationsPage(value)
      }
      rows={rows}
      rowCount={notificationsTotalsSum}
      fetchRows={() =>
        dispatch(
          fetchNotificationsList({
            startDateTime: startDateTime,
            finalDateTime: finalDateTime,
            plate: plate,
            userName: userName,
            serviceOrderOrigin: serviceOrderOrigin,
            routeAreaId: routeAreaId,
            routeId: routeId,
            print: print,
            notificationId: notificationId,
            situationId: situationId,
          }),
        )
      }
      columns={
        responsiveMode === 'phone' || responsiveMode === 'tablet'
          ? columnsResponsive
          : columns
      }
      toolbar={
        responsiveMode === 'phone' ||
        responsiveMode === 'tablet' ? undefined : (
          <NotificationsIssuedTableToolbar />
        )
      }
      loading={notificationsListLoading}
    />
  );
};
