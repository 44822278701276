/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { SalesExportResponseType } from '../../../../services/Sales/types';
import { SalesFiltersType, salesListSlice } from '../../../../stores';
import { delay } from '../../../../utils/Helpers';

export const exportSalesToCSV = async (
  apiData: SalesExportResponseType,
  filters: SalesFiltersType | null,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  worker: (
    apiData: SalesExportResponseType,
    filters: SalesFiltersType | null,
  ) => Promise<string>,
): Promise<void> => {
  dispatch(salesListSlice.actions.setSalesExportPercentage(20));

  const handledApiData = await worker(apiData, filters);
  dispatch(salesListSlice.actions.setSalesExportPercentage(60));

  const fileType = 'data:text/csv;charset=utf-8;';
  const fileExtension = '.csv';
  dispatch(salesListSlice.actions.setSalesExportPercentage(100));
  await delay(100);

  const blob = new Blob([handledApiData], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `vendas-${moment().format('yyyy-MM-DD_hh-mm')}` + fileExtension,
  );

  return;
};

export const handleApiDataSalesToCSV = (
  apiData: SalesExportResponseType,
  filters: SalesFiltersType | null,
) => {
  const auxRoutes: any[] = [];
  const auxUsers: any[] = [];
  const auxDrivers: any[] = [];

  let handledApiData = `"Placa";"Hora registro";"Hora inicial";"Periodo";"Usuario";"Origem";"Trecho";"Reimpressao";"Sem Placa";"Forma de pagamento";"Ordem de pagamento";"Codigo PIX";"Valor pago";"Valor";"Irregularidade";"Maquina";\r\n`;

  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[8] +
      dateTime[9] +
      '/' +
      dateTime[5] +
      dateTime[6] +
      '/' +
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3] +
      ' ' +
      dateTime.slice(11)
    );
  };

  const calculatePeriod = (start: string, end: string) => {
    if (start === '01/01/0001 12:00:00' || end === '01/01/0001 12:00:00') {
      return '--';
    }
    const date1 = new Date(start).getTime();
    const date2 = new Date(end).getTime();
    const diffTime = Math.abs(date2 - date1);
    // if (Number.isNaN(diffTime)) return '00:00:00';

    let seconds = Math.floor(diffTime / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // const formatMoney = (money: number) => {
  //   // eslint-disable-next-line lodash/prefer-lodash-method
  //   return `R$${money.toFixed(2).toString().replace('.', ',')}`;
  // };

  // const formatPOS = (posMachine: number) => {
  //   return posMachine === 0 ? 'Todas' : posMachine === 1 ? 'Lio' : 'Getnet';
  // };

  // eslint-disable-next-line lodash/prefer-lodash-method
  apiData.map((item, index) => {
    const registerDateTime = formatDateTime(item.registerDateTime);
    const entryDateTime = formatDateTime(item.entryDateTime);
    const exitDateTime = formatDateTime(item.exitDateTime);

    let route = null;
    let user = null;
    let driver = null;

    if (auxRoutes[item.routeId] === undefined) {
      // eslint-disable-next-line lodash/prefer-lodash-method
      auxRoutes[item.routeId] = filters?.routes.find((auxItem) => {
        return auxItem.id === item.routeId;
      });
    }
    if (item.userId && auxUsers[item.userId] === undefined) {
      // eslint-disable-next-line lodash/prefer-lodash-method
      auxUsers[item.userId] = filters?.users.find((auxItem) => {
        return auxItem.id === item.userId;
      });
    }
    if (item.driverId && auxDrivers[item.driverId] === undefined) {
      // eslint-disable-next-line lodash/prefer-lodash-method
      auxDrivers[item.driverId] = filters?.drivers.find((auxItem) => {
        return auxItem.id === item.driverId;
      });
    }

    route = auxRoutes[item.routeId];
    user = item.userId && auxUsers[item.userId];
    driver = item.driverId && auxDrivers[item.driverId];

    const period = calculatePeriod(item.entryDateTime, item.exitDateTime);

    handledApiData =
      handledApiData +
      `"${item.plate} (${
        item.type
      })";"${registerDateTime}";"${entryDateTime}";"${period}";"${
        user ? user.value : driver.value
      }";"${item.userId ? 'Operacional' : 'Motorista'}";"${route?.value}";"${
        item.reprint === 0 ? 'Nenhuma' : item.reprintObservation
      }";"${item.hasNoPlate === 0 ? 'Nenhuma' : item.hasNoPlateObservation}";"${
        item.paymentMethod
      }";"";"";"${
        item.paidPrice ? item.paidPrice.toString().replace('.', ',') : '0'
      }";"${item.price ? item.price.toString().replace('.', ',') : '0'}";"${
        item.irregularities ? item.irregularities.slice(1, -1) : 'Nenhuma'
      }";"${item.posMachine}";\r\n`;
  });

  return handledApiData;
};
