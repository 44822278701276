import React from 'react';
import { StyledDatePicker } from './styles';

export const DatePicker = (props: {
  label: string;
  value: string;
  onChange: (value: any) => void;
}) => {
  return <StyledDatePicker {...props} type='date' shrink />;
};
