import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Skeleton, Typography } from '@mui/material';
import { times } from 'lodash';
import React from 'react';
import { Button, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  fetchAreasList,
  areaSlice,
  fetchAreaCancel,
} from '../../../stores';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const CancelAreaModal: React.FC = () => {
  const dispatch = useDispatch();
  const { areaInfo, areaInfoLoading, areaCancelIsOpen, areaCancelLoading } =
    useSelector((state) => state.areaReducer);

  const handleClose = () => {
    dispatch(areaSlice.actions.toggleAreaCancelIsOpen());
  };

  const handleSubmit = () => {
    if (areaInfo) {
      dispatch(fetchAreaCancel({ areaId: areaInfo.id.toString() })).then(
        (result) => {
          handleClose();
          if (result.meta.requestStatus === 'fulfilled') {
            dispatch(fetchAreasList());
          }
        },
      );
    }
  };

  const handleReturn = () => {
    dispatch(areaSlice.actions.toggleAreaCancelIsOpen());
  };

  return (
    <Modal
      open={areaCancelIsOpen}
      onClose={handleClose}
      title='Deseja excluir essa área?'
    >
      <>
        {areaInfoLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              {times(3, () => (
                <Skeleton animation='wave' height={29} width='100%' />
              ))}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Nome:
                </Typography>
                <Typography>{areaInfo?.name}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Descrição:
                </Typography>
                <Typography>{areaInfo?.description || '--'}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Cor:
                </Typography>
                <Typography>{areaInfo?.color}</Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: '10px',
          }}
        >
          <Button
            variant='contained'
            label='Excluir'
            startIcon={<DeleteIcon />}
            type='submit'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={areaCancelLoading}
            onClick={handleSubmit}
          />
          <Button
            color='secondary'
            variant='contained'
            type='reset'
            startIcon={<ArrowBackIcon />}
            label='Voltar'
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleReturn}
          />
        </Box>
      </>
    </Modal>
  );
};
