import React, { FormEventHandler } from 'react';
import { StyledForm } from './styles';

export const Form = (props: {
  children: React.ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
}) => {
  const { children, onSubmit } = props;
  return (
    <StyledForm
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(event);
      }}
    >
      {children}
    </StyledForm>
  );
};
