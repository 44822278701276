import { AccountCircle, Logout } from '@mui/icons-material';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import DirectionsCarFilledTwoToneIcon from '@mui/icons-material/DirectionsCarFilledTwoTone';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  CssBaseline,
  MenuItem,
  Menu,
  List,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Typography,
  SwipeableDrawer,
  Button,
} from '@mui/material';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { useState } from 'react';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as SecureStorage from 'react-secure-storage';
import projectPackage from '../../../../package.json';
import {
  ACTIONS_HISTORY,
  ADMINISTRATIVE_DASHBOARD,
  AREAS_MANAGEMENT,
  CONFIGURATIONS_MANAGEMENT,
  CREDENTIALS_MANAGEMENT,
  NOTIFICATIONS_MANAGEMENT,
  NOTIFICATIONS_EDIT_RECORDS,
  OPERATIONAL_DASHBOARD,
  PERMISSIONS_MANAGEMENT,
  PROFILE,
  ROUTES_MANAGEMENT,
  SALES_MANAGEMENT,
  SESSIONS_HISTORY,
  USERS_MANAGEMENT,
} from '../../../routes';
import { authSlice, Store } from '../../../stores';
import { palette, logo } from '../../../theme';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import {
  StyledAppBar,
  StyledLogo,
  StyledToolbar,
  StyledLogoWrapper,
  StyledIconButton,
  StyledDrawerHeader,
  StyledDrawer,
} from './styles';

export const MenuBars = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const responsiveMode = useResponsiveMode();
  const popupDashboardState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  });
  const popupUserState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  });
  const popupVehicleControlState = usePopupState({
    variant: 'popover',
    popupId: 'vehicleControl',
  });
  const popupMapControlState = usePopupState({
    variant: 'popover',
    popupId: 'mapControl',
  });

  const isAuthenticated =
    SecureStorage.default.getItem('id') !== null &&
    SecureStorage.default.getItem('token') !== null;

  const [anchorTopRightMenu, setAnchorTopRightMenu] =
    useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const toggleLeftBarDrawer =
    (value: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(value);
    };

  const handleLogout = () => {
    setAnchorTopRightMenu(null);
    Store.dispatch(authSlice.actions.logout());
    SecureStorage.default.removeItem('token');
    SecureStorage.default.removeItem('id');
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <CssBaseline />

      {/* Top Bar */}
      <StyledAppBar
        position='absolute'
        open={responsiveMode !== 'phone' ? open : false}
      >
        <StyledToolbar variant='dense'>
          <StyledLogoWrapper>
            <StyledIconButton
              aria-label='toggle drawer'
              onClick={toggleLeftBarDrawer(true)}
              edge='start'
              sx={{
                ...(responsiveMode !== 'phone' && open && { display: 'none' }),
              }}
            >
              <MenuIcon fontSize='medium' />
            </StyledIconButton>
            <Button focusRipple sx={{ padding: 0, marginLeft: open ? 2.5 : 2 }}>
              <StyledLogo
                src={logo}
                alt='logo'
                loading='lazy'
                onClick={() => navigate(ADMINISTRATIVE_DASHBOARD)}
              />
            </Button>
          </StyledLogoWrapper>
          <StyledIconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={(event) => setAnchorTopRightMenu(event.currentTarget)}
            sx={{
              color: 'text.main',
            }}
          >
            <AccountCircle fontSize='large' />
          </StyledIconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorTopRightMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorTopRightMenu)}
            onClose={() => setAnchorTopRightMenu(null)}
          >
            <MenuItem onClick={() => navigate(PROFILE)}>
              <ListItemIcon>
                <PersonIcon fontSize='small' />
              </ListItemIcon>{' '}
              Perfil
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize='small' />
              </ListItemIcon>
              Sair
            </MenuItem>
            <Divider />
            <MenuItem>Versão {projectPackage.version}</MenuItem>
          </Menu>
        </StyledToolbar>
      </StyledAppBar>

      {/* Side Bar */}
      {isAuthenticated && responsiveMode !== 'phone' && (
        <StyledDrawer
          variant='permanent'
          sx={{
            '& .MuiDrawer-paper': {
              borderWidth: 0,
              backgroundColor: palette.text.main,
            },
          }}
          open={open}
        >
          <StyledDrawerHeader>
            <StyledIconButton
              onClick={toggleLeftBarDrawer(open ? false : true)}
            >
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon
                  fontSize='large'
                  sx={{ color: 'background.main' }}
                />
              ) : (
                <ChevronLeftIcon
                  fontSize='large'
                  sx={{ color: 'background.main' }}
                />
              )}
            </StyledIconButton>
          </StyledDrawerHeader>
          <Divider />
          <List sx={{ paddingTop: 0 }}>
            <ListItemButton
              {...bindTrigger(popupDashboardState)}
              sx={{
                minHeight: 60,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'divider',
                }}
              >
                <DashboardTwoToneIcon
                  color={
                    location.pathname === ADMINISTRATIVE_DASHBOARD ||
                    location.pathname === OPERATIONAL_DASHBOARD
                      ? 'secondary'
                      : 'inherit'
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary='Painel de controle'
                sx={{
                  opacity: open ? 1 : 0,
                  color:
                    location.pathname === ADMINISTRATIVE_DASHBOARD ||
                    location.pathname === OPERATIONAL_DASHBOARD
                      ? palette.secondary.main
                      : palette.background.main,
                }}
              />
            </ListItemButton>
            <Divider />
            <ListItemButton
              {...bindTrigger(popupUserState)}
              sx={{
                minHeight: 60,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'divider',
                }}
              >
                <AccountCircleTwoToneIcon
                  color={
                    location.pathname === USERS_MANAGEMENT ||
                    location.pathname === SESSIONS_HISTORY ||
                    location.pathname === ACTIONS_HISTORY ||
                    location.pathname === PERMISSIONS_MANAGEMENT
                      ? 'secondary'
                      : 'inherit'
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary='Usuário'
                sx={{
                  opacity: open ? 1 : 0,
                  color:
                    location.pathname === USERS_MANAGEMENT ||
                    location.pathname === SESSIONS_HISTORY ||
                    location.pathname === ACTIONS_HISTORY ||
                    location.pathname === PERMISSIONS_MANAGEMENT
                      ? palette.secondary.main
                      : palette.background.main,
                }}
              />
            </ListItemButton>
            <Divider />
            <ListItemButton
              {...bindTrigger(popupVehicleControlState)}
              sx={{
                minHeight: 60,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'divider',
                }}
              >
                <DirectionsCarFilledTwoToneIcon
                  color={
                    location.pathname === SALES_MANAGEMENT ||
                    location.pathname === NOTIFICATIONS_MANAGEMENT ||
                    location.pathname === NOTIFICATIONS_EDIT_RECORDS ||
                    location.pathname === CREDENTIALS_MANAGEMENT
                      ? 'secondary'
                      : 'inherit'
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary='Controle de veículos'
                sx={{
                  opacity: open ? 1 : 0,
                  color:
                    location.pathname === SALES_MANAGEMENT ||
                    location.pathname === NOTIFICATIONS_MANAGEMENT ||
                    location.pathname === NOTIFICATIONS_EDIT_RECORDS ||
                    location.pathname === CREDENTIALS_MANAGEMENT
                      ? palette.secondary.main
                      : palette.background.main,
                }}
              />
            </ListItemButton>
            <Divider />
            <ListItemButton
              {...bindTrigger(popupMapControlState)}
              sx={{
                minHeight: 60,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'divider',
                }}
              >
                <MapTwoToneIcon
                  color={
                    location.pathname === AREAS_MANAGEMENT ||
                    location.pathname === CONFIGURATIONS_MANAGEMENT ||
                    location.pathname === ROUTES_MANAGEMENT
                      ? 'secondary'
                      : 'inherit'
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary='Gerenciar mapa'
                sx={{
                  opacity: open ? 1 : 0,
                  color:
                    location.pathname === AREAS_MANAGEMENT ||
                    location.pathname === CONFIGURATIONS_MANAGEMENT ||
                    location.pathname === ROUTES_MANAGEMENT
                      ? palette.secondary.main
                      : palette.background.main,
                }}
              />
            </ListItemButton>
            <Divider />
          </List>
        </StyledDrawer>
      )}

      {/* Side Bar Responsive */}
      {isAuthenticated && responsiveMode === 'phone' && (
        <SwipeableDrawer
          anchor='left'
          open={open}
          onClose={toggleLeftBarDrawer(false)}
          onOpen={toggleLeftBarDrawer(true)}
          sx={{
            '& .MuiDrawer-paper': {
              borderWidth: 0,
              backgroundColor: palette.text.main,
            },
          }}
        >
          <Box
            sx={{
              width: responsiveMode === 'phone' ? 275 : 250,
            }}
            role='presentation'
            // onClick={toggleLeftBarDrawer(false)}
            // onKeyDown={toggleLeftBarDrawer(false)}
          >
            <StyledDrawerHeader>
              <StyledIconButton
                onClick={toggleLeftBarDrawer(open ? false : true)}
              >
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon
                    fontSize='large'
                    sx={{ color: 'background.main' }}
                  />
                ) : (
                  <ChevronLeftIcon
                    fontSize='large'
                    sx={{ color: 'background.main' }}
                  />
                )}
              </StyledIconButton>
            </StyledDrawerHeader>
            <Divider />
            <List sx={{ paddingTop: 0 }}>
              <ListItemButton
                {...bindTrigger(popupDashboardState)}
                sx={{
                  minHeight: 60,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'divider',
                  }}
                >
                  <AccountCircleTwoToneIcon
                    color={
                      location.pathname === ADMINISTRATIVE_DASHBOARD ||
                      location.pathname === OPERATIONAL_DASHBOARD
                        ? 'secondary'
                        : 'inherit'
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary='Painel de controle'
                  sx={{
                    opacity: open ? 1 : 0,
                    color:
                      location.pathname === ADMINISTRATIVE_DASHBOARD ||
                      location.pathname === OPERATIONAL_DASHBOARD
                        ? palette.secondary.main
                        : palette.background.main,
                  }}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                {...bindTrigger(popupUserState)}
                sx={{
                  minHeight: 60,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'divider',
                  }}
                >
                  <AccountCircleTwoToneIcon
                    color={
                      location.pathname === USERS_MANAGEMENT ||
                      location.pathname === SESSIONS_HISTORY ||
                      location.pathname === PERMISSIONS_MANAGEMENT
                        ? 'secondary'
                        : 'inherit'
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary='Usuário'
                  sx={{
                    opacity: open ? 1 : 0,
                    color:
                      location.pathname === USERS_MANAGEMENT ||
                      location.pathname === SESSIONS_HISTORY ||
                      location.pathname === PERMISSIONS_MANAGEMENT
                        ? palette.secondary.main
                        : palette.background.main,
                  }}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                {...bindTrigger(popupVehicleControlState)}
                sx={{
                  minHeight: 60,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'divider',
                  }}
                >
                  <DirectionsCarFilledTwoToneIcon
                    color={
                      location.pathname === SALES_MANAGEMENT ||
                      location.pathname === NOTIFICATIONS_MANAGEMENT ||
                      location.pathname === NOTIFICATIONS_EDIT_RECORDS
                        ? 'secondary'
                        : 'inherit'
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary='Controle de veículos'
                  sx={{
                    opacity: open ? 1 : 0,
                    color:
                      location.pathname === SALES_MANAGEMENT ||
                      location.pathname === NOTIFICATIONS_MANAGEMENT ||
                      location.pathname === NOTIFICATIONS_EDIT_RECORDS
                        ? palette.secondary.main
                        : palette.background.main,
                  }}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                {...bindTrigger(popupMapControlState)}
                sx={{
                  minHeight: 60,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'divider',
                  }}
                >
                  <MapTwoToneIcon
                    color={
                      // location.pathname === SALES_MANAGEMENT ||
                      location.pathname === AREAS_MANAGEMENT
                        ? 'secondary'
                        : 'inherit'
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary='Gerenciar mapa'
                  sx={{
                    opacity: open ? 1 : 0,
                    color:
                      // location.pathname === SALES_MANAGEMENT ||
                      location.pathname === AREAS_MANAGEMENT
                        ? palette.secondary.main
                        : palette.background.main,
                  }}
                />
              </ListItemButton>
              <Divider />
            </List>
          </Box>
        </SwipeableDrawer>
      )}

      {/* Menus */}
      <Menu
        {...bindMenu(popupDashboardState)}
        anchorOrigin={{
          vertical: responsiveMode === 'phone' ? 'bottom' : 'top',
          horizontal: responsiveMode === 'phone' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: responsiveMode === 'phone' ? 'bottom' : 'top',
          horizontal: responsiveMode === 'phone' ? 'left' : 'right',
        }}
        sx={{
          marginTop: responsiveMode === 'phone' ? '105px' : '1px',
          marginLeft:
            responsiveMode === 'phone' ? '0px' : open ? '224px' : '50px',
          '& .MuiMenu-paper': {
            borderRadius: 0,
            left: responsiveMode === 'phone' ? '0px !important' : 'inherit',
          },
          '& .MuiMenu-list': {
            backgroundColor: palette.text.main,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(ADMINISTRATIVE_DASHBOARD);
            popupUserState.close;
          }}
          color={
            location.pathname === ADMINISTRATIVE_DASHBOARD
              ? 'secondary'
              : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === ADMINISTRATIVE_DASHBOARD
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Painel de controle administrativo
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(OPERATIONAL_DASHBOARD);
            popupUserState.close;
          }}
          color={
            location.pathname === OPERATIONAL_DASHBOARD
              ? 'secondary'
              : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === OPERATIONAL_DASHBOARD
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Painel de controle operacional
          </Typography>
        </MenuItem>
      </Menu>

      <Menu
        {...bindMenu(popupUserState)}
        anchorOrigin={{
          vertical: responsiveMode === 'phone' ? 'bottom' : 'top',
          horizontal: responsiveMode === 'phone' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: responsiveMode === 'phone' ? 'bottom' : 'top',
          horizontal: responsiveMode === 'phone' ? 'left' : 'right',
        }}
        sx={{
          marginTop: responsiveMode === 'phone' ? '165px' : '1px',
          marginLeft:
            responsiveMode === 'phone' ? '0px' : open ? '190px' : '50px',
          '& .MuiMenu-paper': {
            borderRadius: 0,
            left: responsiveMode === 'phone' ? '0px !important' : 'inherit',
          },
          '& .MuiMenu-list': {
            backgroundColor: palette.text.main,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(USERS_MANAGEMENT);
            popupUserState.close;
          }}
          color={
            location.pathname === USERS_MANAGEMENT ? 'secondary' : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === USERS_MANAGEMENT
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Gerenciar usuários
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(ACTIONS_HISTORY);
            popupUserState.close;
          }}
          color={
            location.pathname === ACTIONS_HISTORY ? 'secondary' : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === ACTIONS_HISTORY
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Histórico de ações
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(SESSIONS_HISTORY);
            popupUserState.close;
          }}
          color={
            location.pathname === SESSIONS_HISTORY ? 'secondary' : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === SESSIONS_HISTORY
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Histórico de sessões
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(PERMISSIONS_MANAGEMENT);
            popupUserState.close;
          }}
          color={
            location.pathname === PERMISSIONS_MANAGEMENT
              ? 'secondary'
              : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === PERMISSIONS_MANAGEMENT
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Gerenciar permissões
          </Typography>
        </MenuItem>
      </Menu>

      <Menu
        {...bindMenu(popupVehicleControlState)}
        anchorOrigin={{
          vertical: responsiveMode === 'phone' ? 'bottom' : 'top',
          horizontal: responsiveMode === 'phone' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: responsiveMode === 'phone' ? 'bottom' : 'top',
          horizontal: responsiveMode === 'phone' ? 'left' : 'right',
        }}
        sx={{
          marginTop: responsiveMode === 'phone' ? '165px' : '1px',
          marginLeft:
            responsiveMode === 'phone' ? '0px' : open ? '195px' : '50px',
          '& .MuiMenu-paper': {
            borderRadius: 0,
            left: responsiveMode === 'phone' ? '0px !important' : 'inherit',
          },
          '& .MuiMenu-list': {
            backgroundColor: palette.text.main,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(SALES_MANAGEMENT);
            popupVehicleControlState.close;
          }}
          color={
            location.pathname === SALES_MANAGEMENT ? 'secondary' : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === SALES_MANAGEMENT
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Gerenciar vendas
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(NOTIFICATIONS_MANAGEMENT);
            popupVehicleControlState.close;
          }}
          color={
            location.pathname === NOTIFICATIONS_MANAGEMENT ||
            location.pathname === NOTIFICATIONS_EDIT_RECORDS
              ? 'secondary'
              : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === NOTIFICATIONS_MANAGEMENT ||
                location.pathname === NOTIFICATIONS_EDIT_RECORDS
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Gerenciar notificações
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            navigate(CREDENTIALS_MANAGEMENT);
            popupVehicleControlState.close;
          }}
          color={
            location.pathname === CREDENTIALS_MANAGEMENT
              ? 'secondary'
              : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === CREDENTIALS_MANAGEMENT
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Gerenciar credenciais
          </Typography>
        </MenuItem>
      </Menu>

      <Menu
        {...bindMenu(popupMapControlState)}
        anchorOrigin={{
          vertical: responsiveMode === 'phone' ? 'bottom' : 'top',
          horizontal: responsiveMode === 'phone' ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: responsiveMode === 'phone' ? 'bottom' : 'top',
          horizontal: responsiveMode === 'phone' ? 'left' : 'right',
        }}
        sx={{
          marginTop: responsiveMode === 'phone' ? '165px' : '1px',
          marginLeft:
            responsiveMode === 'phone' ? '0px' : open ? '209px' : '50px',
          '& .MuiMenu-paper': {
            borderRadius: 0,
            left: responsiveMode === 'phone' ? '0px !important' : 'inherit',
          },
          '& .MuiMenu-list': {
            backgroundColor: palette.text.main,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(AREAS_MANAGEMENT);
            popupMapControlState.close;
          }}
          color={
            location.pathname === AREAS_MANAGEMENT ? 'secondary' : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === AREAS_MANAGEMENT
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Gerenciar áreas
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(CONFIGURATIONS_MANAGEMENT);
            popupMapControlState.close;
          }}
          color={
            location.pathname === CONFIGURATIONS_MANAGEMENT
              ? 'secondary'
              : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === CONFIGURATIONS_MANAGEMENT
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Gerenciar configurações
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(ROUTES_MANAGEMENT);
            popupMapControlState.close;
          }}
          color={
            location.pathname === ROUTES_MANAGEMENT ? 'secondary' : 'background'
          }
          sx={{
            height: responsiveMode !== 'phone' ? '42px' : 'inherit',
            width: responsiveMode === 'phone' ? '275px' : 'inherit',
            borderRadius: 0,
            backgroundColor: palette.text.main,
          }}
        >
          <Typography
            sx={{
              color:
                location.pathname === ROUTES_MANAGEMENT
                  ? palette.secondary.main
                  : palette.background.main,
            }}
          >
            Gerenciar trechos
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
