// All user related database operations can be defined here.
import { omit } from 'lodash';
import { api } from '..';
import {
  fetchRoutesBlockUrl,
  fetchRoutesCancelUrl,
  fetchRoutesCreateUrl,
  fetchRoutesFiltersUrl,
  fetchRoutesInfoUrl,
  fetchRoutesListUrl,
  fetchRoutesUpdateUrl,
  fetchRoutesMapInfoUrl,
  fetchRoutesExportOperatingVacanciesUrl,
  fetchRoutesExportActiveVacanciesUrl,
} from './constants';
import {
  RoutesBlockResponseType,
  RoutesCancelResponseType,
  RoutesCreateResponseType,
  RoutesExportActiveVacanciesResponseType,
  RoutesExportOperatingVacanciesResponseType,
  RoutesFiltersResponseType,
  RoutesInfoResponseType,
  RoutesListResponseType,
  RoutesMapInfoResponseType,
  RoutesUpdateResponseType,
} from './types';

/**
 * Function to fetch the details of the user based on userId.
 */
export const fetchRoutesFiltersService = async () => {
  return await api
    .get<RoutesFiltersResponseType>(fetchRoutesFiltersUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchRoutesListService = async (params: {
  routeName: string | undefined;
  areaId: string | undefined;
  configurationId: string | undefined;
  operation: string | undefined;
  page: string;
}) => {
  return await api
    .get<RoutesListResponseType>(fetchRoutesListUrl, { params: params })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchRoutesCreateService = async (params: {
  name: string;
  areaId: number;
  configurationId: number;
  coordinates: {
    address: string;
    latitude: number;
    longitude: number;
  }[];
  vacancies: {
    quantity: number;
    type: string;
  }[];
}) => {
  return await api
    .post<RoutesCreateResponseType>(fetchRoutesCreateUrl, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchRoutesInfoService = async (params: { routeId: string }) => {
  return await api
    .get<RoutesInfoResponseType>(`${fetchRoutesInfoUrl}/${params.routeId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchRoutesCancelService = async (params: { routeId: string }) => {
  return await api
    .delete<RoutesCancelResponseType>(
      `${fetchRoutesCancelUrl}/${params.routeId}`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchRoutesBlockService = async (params: {
  routeId: string;
  block: string;
}) => {
  return await api
    .put<RoutesBlockResponseType>(
      `${fetchRoutesBlockUrl}/${params.routeId}/block`,
      { block: params.block },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchRoutesUpdateService = async (params: {
  name: string;
  areaId: number;
  configurationId: number;
  coordinates: {
    address: string;
    latitude: number;
    longitude: number;
  }[];
  vacancies: {
    quantity: number;
    type: string;
  }[];
  routeId: string;
}) => {
  return await api
    .put<RoutesUpdateResponseType>(
      `${fetchRoutesUpdateUrl}/${params.routeId}`,
      JSON.stringify(omit(params, ['routeId'])),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchRoutesMapInfoService = async () => {
  return await api
    .get<RoutesMapInfoResponseType>(fetchRoutesMapInfoUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchRoutesExportOperatingVacanciesService = async (params: {
  startDate: string;
  finalDate: string;
}) => {
  return await api
    .get<RoutesExportOperatingVacanciesResponseType>(
      fetchRoutesExportOperatingVacanciesUrl,
      { params: params },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchRoutesExportActiveVacanciesService = async () => {
  return await api
    .get<RoutesExportActiveVacanciesResponseType>(
      fetchRoutesExportActiveVacanciesUrl,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};
