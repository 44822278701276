import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography, Box } from '@mui/material';
import React from 'react';
import { Button, Modal, ProgressCircle } from '../../../components';
import {
  useDispatch,
  fetchSessionsList,
  useSelector,
  sessionSlice,
  fetchSessionClose,
} from '../../../stores';
import { handleDateTime } from '../../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalLoadingWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const SessionCloseModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    sessionInfo,
    sessionCloseLoading,
    sessionCloseIsOpen,
    sessionIdToClose,
  } = useSelector((state) => state.sessionReducer);
  const { userName, startDateTime, finalDateTime, situation } = useSelector(
    (state) => state.sessionsFiltersValuesReducer,
  );

  const handleCancelSession = () => {
    dispatch(fetchSessionClose({ sessionId: sessionIdToClose }));
    dispatch(
      fetchSessionsList({
        userName: userName,
        startDateTime: startDateTime,
        finalDateTime: finalDateTime,
        situation: situation,
      }),
    ).finally(() => {
      dispatch(
        sessionSlice.actions.setSessionCloseId({
          sessionIdToClose: '',
        }),
      );
      dispatch(sessionSlice.actions.toggleSessionCloseIsOpen());
      dispatch(
        fetchSessionsList({
          userName: userName,
          startDateTime: startDateTime,
          finalDateTime: finalDateTime,
          situation: situation,
        }),
      );
    });
  };

  const handleClose = () => {
    sessionSlice.actions.setSessionCloseId({
      sessionIdToClose: '',
    });
    dispatch(sessionSlice.actions.toggleSessionCloseIsOpen());
  };

  const handleReturn = () => {
    sessionSlice.actions.setSessionCloseId({
      sessionIdToClose: '',
    });
    dispatch(sessionSlice.actions.toggleSessionCloseIsOpen());
    dispatch(sessionSlice.actions.toggleSessionInfoIsOpen());
  };

  return (
    <Modal
      open={sessionCloseIsOpen}
      onClose={handleClose}
      title='Deseja encerrar essa sessão?'
    >
      <>
        {sessionCloseLoading ? (
          <StyledModalLoadingWrapper>
            <ProgressCircle color='primary' />
          </StyledModalLoadingWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Nome:
                </Typography>
                <Typography>{sessionInfo?.userName}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Entrada:
                </Typography>
                <Typography>
                  {handleDateTime(sessionInfo?.startDateTime)}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Saída:
                </Typography>
                <Typography>
                  {handleDateTime(sessionInfo?.finalDateTime)}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Máquina:
                </Typography>
                <Typography>{sessionInfo?.deviceId}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Localização:
                </Typography>
                <Typography>
                  Lat: {sessionInfo?.latitude} <br /> Lon:{' '}
                  {sessionInfo?.longitude}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Trechos:
                </Typography>
                <Typography>{sessionInfo?.routes}</Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Button
            variant='contained'
            label='Encerrar'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={sessionCloseLoading}
            onClick={handleCancelSession}
          />
          <Button
            color='secondary'
            variant='contained'
            startIcon={<ArrowBackIcon />}
            label='Voltar'
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleReturn}
          />
        </Box>
      </>
    </Modal>
  );
};
