import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import 'react-toastify/dist/ReactToastify.css';
import { RoutesFiltersValuesStateType } from './types';

const initialState: RoutesFiltersValuesStateType = {
  areaId: '',
  configurationId: '',
  operation: '',
  routeName: '',
};

export const routesFiltersValuesSlice = createSlice({
  name: 'routesFiltersValues',
  initialState,
  reducers: {
    setRoutesFiltersValues(
      state,
      action: PayloadAction<RoutesFiltersValuesStateType>,
    ) {
      state.areaId = action.payload.areaId;
      state.configurationId = action.payload.configurationId;
      state.operation = action.payload.operation;
      state.routeName = action.payload.routeName;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRoutesFiltersValues } = routesFiltersValuesSlice.actions;

// // now available:
// filtersValuesSlice.actions.setRoutesFiltersValues();
// // also available:
// filtersValuesSlice.caseReducers.increment(0, { type: 'increment', payload: 5 });
