import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { fetchCredentialsFiltersService } from '../../services';

type FilterType = {
  id: string;
  value: string;
};

export type CredentialsFiltersStateType = {
  filters: {
    routes: FilterType[];
    validities: FilterType[];
    status: {
      [x: string]: string;
    };
  };
  isLoading: boolean;
  isFilterButtonActive: boolean;
};

const initialState: CredentialsFiltersStateType = {
  filters: {
    routes: [],
    validities: [],
    status: {},
  },
  isLoading: false,
  isFilterButtonActive: false,
};

export const fetchCredentialsFilters = createAsyncThunk(
  'credentialsFilters',
  async () => {
    return await fetchCredentialsFiltersService();
  },
);

export const credentialsFiltersSlice = createSlice({
  name: 'credentialsFilters',
  initialState,
  reducers: {
    setIsCredentialsFiltersButtonActive: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      return {
        ...state,
        isFilterButtonActive: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCredentialsFilters.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(fetchCredentialsFilters.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    });
    builder.addCase(fetchCredentialsFilters.fulfilled, (state, res) => {
      const { routes, validities, status } = res.payload;

      let handledStatus = {};
      map(status, (item) => {
        if (item.id === '') {
          handledStatus = {
            ...handledStatus,
            Todos: item.value,
          };
        } else {
          handledStatus = {
            ...handledStatus,
            [item.id]: item.value,
          };
        }
      });

      console.log(handledStatus);

      return {
        ...state,
        filters: {
          routes,
          validities,
          status: handledStatus,
        },
        isLoading: false,
      };
    });
  },
});

export const { setIsCredentialsFiltersButtonActive } =
  credentialsFiltersSlice.actions;
