import GoogleMapReact from 'google-map-react';
import { findIndex, forEach, map as lodashMap } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Marker } from './Marker';
import { StyledGoogleMapDiv } from './styles';

export const Map = (props: {
  routes: {
    id: number;
    name: string;
    areaName: string;
    color: string;
    coordinates: {
      latitude: number;
      longitude: number;
    }[];
  }[];
  operators: {
    sessionId: string;
    id: number;
    login: string;
    name: string;
    latitude: number;
    longitude: number;
    startDateTime: string;
    finalDateTime: string;
  }[];
  recenterMap: boolean;
  setRecenterMapAsFalse: () => { payload: boolean; type: string };
}) => {
  const [gMaps, setGMaps] = useState();
  const [gMap, setGMap] = useState<google.maps.Map>();
  const [markers, setMarkers] = useState<JSX.Element[]>([]);
  const [lines, setLines] = useState<
    { id: number; line: google.maps.Polyline }[]
  >([]);
  const [isMapStarting, setIsMapStarting] = useState<boolean>(true);

  useEffect(() => {
    const newMarkers: JSX.Element[] = [];
    lodashMap(props.operators, (item) => {
      newMarkers.push(
        <Marker
          key={item.id}
          lat={item.latitude}
          lng={item.longitude}
          info={{ ...item }}
        />,
      );
    });
    setMarkers(newMarkers);
  }, [props.operators]);

  useEffect(() => {
    if (
      gMaps &&
      gMap &&
      isMapStarting &&
      lines.length === 0 &&
      props.routes.length !== 0
    ) {
      handleStartRoutes();
      handleDrawRoutes();
      handleMapBounds();
      setIsMapStarting(false);
    }
  }, [gMaps, props.routes]);

  useEffect(() => {
    if (gMaps && gMap) {
      handleDrawRoutes();
      if (props.recenterMap) {
        handleMapBounds();
        props.setRecenterMapAsFalse();
      }
    }
  }, [props.routes]);

  const createMapOptions = () => {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatr ixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      zoomControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
  };

  const handleStartRoutes = () => {
    if (gMap) {
      const newLines = lines;
      // Starting up lines array if first time
      forEach(props.routes, function (item) {
        const coordinates: { lat: number; lng: number }[] = [];

        // eslint-disable-next-line lodash/prefer-map
        forEach(item.coordinates, (coords) => {
          coordinates.push({
            lat: coords.latitude,
            lng: coords.longitude,
          });
        });

        const contentString = `<div><h4>${item.name}</h4></div>`;

        // Line
        const polyline = new google.maps.Polyline({
          path: coordinates,
          geodesic: true,
          strokeColor: item.color,
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });

        // InfoWindow
        const infoWindow = new google.maps.InfoWindow({
          content: contentString,
        });

        const location = new google.maps.LatLng(
          (coordinates[0].lat + coordinates[coordinates.length - 1].lat) / 2,
          (coordinates[0].lng + coordinates[coordinates.length - 1].lng) / 2,
        );

        google.maps.event.addListener(polyline, 'mouseover', () => {
          infoWindow.setPosition(location);
          infoWindow.open(gMap);
        });

        google.maps.event.addListener(polyline, 'mouseout', () => {
          infoWindow.close();
        });

        newLines.push({ id: item.id, line: polyline });
      });

      setLines(newLines);
    }
  };

  const handleDrawRoutes = () => {
    if (gMap) {
      // Drawing lines
      lodashMap(lines, (item, index) => {
        const lineIdx = findIndex(props.routes, ['id', item.id]);
        if (lineIdx === -1) {
          lines[index].line.setMap(null);
        } else {
          lines[index].line.setMap(gMap);
        }
      });
    }
  };

  const handleMapBounds = () => {
    if (gMap) {
      // Setting map bounds
      const bounds = new google.maps.LatLngBounds();
      forEach(props.routes, function (item) {
        const coordinates: { lat: number; lng: number }[] = [];

        // eslint-disable-next-line lodash/prefer-map
        forEach(item.coordinates, (coords) => {
          coordinates.push({
            lat: coords.latitude,
            lng: coords.longitude,
          });
        });

        const location = new google.maps.LatLng(
          (coordinates[0].lat + coordinates[coordinates.length - 1].lat) / 2,
          (coordinates[0].lng + coordinates[coordinates.length - 1].lng) / 2,
        );

        bounds.extend(location);
      });
      gMap.fitBounds(bounds);
    }
  };

  return (
    <StyledGoogleMapDiv>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: `${process.env.REACT_APP_GOOGLE_KEY}`,
        }}
        defaultCenter={{ lat: 0.0, lng: 0.0 }}
        zoom={10}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setIsMapStarting(true);
          setGMap(map);
          setGMaps(maps);
        }}
      >
        {markers}
      </GoogleMapReact>
    </StyledGoogleMapDiv>
  );
};
