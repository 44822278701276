import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchAreasCancelService,
  fetchAreasEditService,
  fetchAreasInfoService,
} from '../../services';
import { AreaStateType } from './types';

const initialState: AreaStateType = {
  areaInfo: null,
  areaInfoIsOpen: false,
  areaInfoLoading: false,
  areaIdToCancel: '',
  areaCancelIsOpen: false,
  areaCancelLoading: false,
  areaIdToEdit: '',
  areaEditIsOpen: false,
  areaEditLoading: false,
};

export const fetchAreaInfo = createAsyncThunk(
  'areaInfo',
  async (params: { areaId: string }) => {
    return await fetchAreasInfoService(params);
  },
);

export const fetchAreaCancel = createAsyncThunk(
  'areaCancel',
  async (params: { areaId: string }) => {
    return await fetchAreasCancelService(params);
  },
);

export const fetchAreaEdit = createAsyncThunk(
  'areaEdit',
  async (params: {
    areaId: string;
    name: string;
    description: string | undefined;
    color: string;
  }) => {
    return await fetchAreasEditService(params);
  },
);

export const areaSlice = createSlice({
  name: 'areaInfo',
  initialState,
  reducers: {
    setAreaCancelId(state, action: PayloadAction<{ areaId: string }>) {
      state.areaIdToCancel = action.payload.areaId;
    },
    toggleAreaCancelIsOpen(state) {
      state.areaCancelIsOpen
        ? (state.areaCancelIsOpen = false)
        : (state.areaCancelIsOpen = true);
    },
    setAreaEditId(state, action: PayloadAction<{ areaId: string }>) {
      state.areaIdToEdit = action.payload.areaId;
    },
    toggleAreaEditIsOpen(state) {
      state.areaEditIsOpen
        ? (state.areaEditIsOpen = false)
        : (state.areaEditIsOpen = true);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAreaInfo.pending, (state) => {
      return {
        ...state,
        areaInfoLoading: true,
      };
    }),
      builder.addCase(fetchAreaInfo.fulfilled, (state, res) => {
        return {
          ...state,
          areaInfo: {
            id: res.payload.data.id,
            name: res.payload.data.name,
            description: res.payload.data.description,
            color: res.payload.data.color,
          },
          areaInfoLoading: false,
        };
      }),
      builder.addCase(fetchAreaInfo.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          areaInfoLoading: false,
        };
      });

    builder.addCase(fetchAreaCancel.pending, (state) => {
      return {
        ...state,
        areaCancelLoading: true,
      };
    }),
      builder.addCase(fetchAreaCancel.fulfilled, (state, res) => {
        toast.success('Área cancelada com sucesso.');
        return {
          ...state,
          areaCancelLoading: false,
        };
      }),
      builder.addCase(fetchAreaCancel.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          areaCancelLoading: false,
        };
      });

    builder.addCase(fetchAreaEdit.pending, (state) => {
      return {
        ...state,
        areaEditLoading: true,
      };
    }),
      builder.addCase(fetchAreaEdit.fulfilled, (state, res) => {
        toast.success('Área editada com sucesso.');
        return {
          ...state,
          areaEditLoading: false,
        };
      }),
      builder.addCase(fetchAreaEdit.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          areaEditLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleAreaCancelIsOpen,
  setAreaCancelId,
  toggleAreaEditIsOpen,
  setAreaEditId,
} = areaSlice.actions;

export default areaSlice.reducer;
