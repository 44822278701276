import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { map, replace } from 'lodash';
import {
  fetchSalesExportExcelService,
  fetchSalesListService,
  fetchSalesExportCommissionsTxtService,
  fetchSalesExportCommissionsHtmlService,
  fetchSalesExportSalesTxtService,
  fetchSalesExportSalesHtmlService,
  fetchSalesExportOperationalHtmlService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import {
  handleDateTime,
  handleDateTimeDiff,
  handleMoney,
} from '../../utils/Helpers';
import { RootState } from '../Store';
import { SalesFiltersValuesFetchType, SalesListStateType } from './types';

const initialState: SalesListStateType = {
  salesList: null,
  salesListLoading: false,
  salesExportExcelLoading: false,
  salesExportCommissionsTxtLoading: false,
  salesExportCommissionsHtmlLoading: false,
  salesExportSalesTxtLoading: false,
  salesExportSalesTxtNewLoading: false,
  salesExportSalesHtmlLoading: false,
  salesExportOperationalHtmlLoading: false,
  salesOperationalExportIsOpen: false,
  salesOperationalExportPeriod: 1,
  salesExportPercentage: 0,
  salesExportModalIsOpen: false,
  salesPage: 1,
};

export const fetchSalesList = createAsyncThunk(
  'salesList',
  async (params: SalesFiltersValuesFetchType, { getState }) => {
    const state = getState() as RootState;
    return await fetchSalesListService({
      ...params,
      paymentMethod:
        params.paymentMethod === 'Todos' ? '' : params.paymentMethod,
      page: state.salesListReducer.salesPage.toString(),
    });
  },
);

export const fetchSalesExportExcel = createAsyncThunk(
  'salesExportExcel',
  async (
    params: {
      userName: string;
      startDateTime: string;
      finalDateTime: string;
      plate: string;
      serviceOrderOrigin: string;
      paymentMethod: string;
      routeAreaId: string;
      routeId: string;
      reprint: string;
      hasNotPlate: string;
      hasIrregularity: string;
      hasDiscount: string;
      profileId: string;
      posMachine: string;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const res = await fetchSalesExportExcelService(params);
    return { ...res, filters: state.salesFiltersReducer.filters };
  },
);

export const fetchSalesExportCommissionsTxt = createAsyncThunk(
  'salesExportCommissionsTxt',
  async (
    params: {
      userName: string;
      startDateTime: string;
      finalDateTime: string;
      plate: string;
      serviceOrderOrigin: string;
      paymentMethod: string;
      routeAreaId: string;
      routeId: string;
      reprint: string;
      hasNotPlate: string;
      hasIrregularity: string;
      hasDiscount: string;
      profileId: string;
      posMachine: string;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const res = await fetchSalesExportCommissionsTxtService(params);
    return { ...res, filters: state.salesFiltersReducer.filters };
  },
);

export const fetchSalesExportCommissionsHtml = createAsyncThunk(
  'salesExportCommissionsHtml',
  async (
    params: {
      userName: string;
      startDateTime: string;
      finalDateTime: string;
      plate: string;
      serviceOrderOrigin: string;
      paymentMethod: string;
      routeAreaId: string;
      routeId: string;
      reprint: string;
      hasNotPlate: string;
      hasIrregularity: string;
      hasDiscount: string;
      profileId: string;
      posMachine: string;
    },
    { getState },
  ) => {
    // const state = getState() as RootState;
    const res = await fetchSalesExportCommissionsHtmlService(params);

    return {
      ...res,
      startDate: params.startDateTime,
      finalDate: params.finalDateTime,
      // updatePercentage: (value: number) => {
      //   state.salesListReducer.salesExportPercentage = value;
      // },
    };
  },
);

export const fetchSalesExportSalesTxt = createAsyncThunk(
  'salesExportSalesTxt',
  async (
    params: {
      userName: string;
      startDateTime: string;
      finalDateTime: string;
      plate: string;
      serviceOrderOrigin: string;
      paymentMethod: string;
      routeAreaId: string;
      routeId: string;
      reprint: string;
      hasNotPlate: string;
      hasIrregularity: string;
      hasDiscount: string;
      profileId: string;
      posMachine: string;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const res = await fetchSalesExportSalesTxtService(params);
    return { ...res, filters: state.salesFiltersReducer.filters };
  },
);

export const fetchSalesExportSalesHtml = createAsyncThunk(
  'salesExportSalesHtml',
  async (
    params: {
      userName: string;
      startDateTime: string;
      finalDateTime: string;
      plate: string;
      serviceOrderOrigin: string;
      paymentMethod: string;
      routeAreaId: string;
      routeId: string;
      reprint: string;
      hasNotPlate: string;
      hasIrregularity: string;
      hasDiscount: string;
      profileId: string;
      posMachine: string;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const res = await fetchSalesExportSalesHtmlService(params);
    return { ...res, filters: state.salesFiltersReducer.filters };
  },
);

export const fetchSalesExportOperationalHtml = createAsyncThunk(
  'salesExportOperationalHtml',
  async (
    params: {
      userName: string;
      startDateTime: string;
      finalDateTime: string;
      plate: string;
      serviceOrderOrigin: string;
      paymentMethod: string;
      routeAreaId: string;
      routeId: string;
      reprint: string;
      hasNotPlate: string;
      hasIrregularity: string;
      hasDiscount: string;
      profileId: string;
      posMachine: string;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    const res = await fetchSalesExportOperationalHtmlService(params);
    return {
      ...res,
      filters: state.salesFiltersReducer.filters,
      period: state.salesListReducer.salesOperationalExportPeriod,
    };
  },
);

export const salesListSlice = createSlice({
  name: 'salesList',
  initialState,
  reducers: {
    setSalesPage(state, action: PayloadAction<number>) {
      state.salesPage = action.payload;
    },
    toggleSalesOperationalExportIsOpen(state) {
      state.salesOperationalExportIsOpen
        ? (state.salesOperationalExportIsOpen = false)
        : (state.salesOperationalExportIsOpen = true);
    },
    setSalesOperationalExportPeriod(state, action: PayloadAction<string>) {
      state.salesOperationalExportPeriod = parseInt(action.payload);
    },
    setSalesExportPercentage(state, action: PayloadAction<number>) {
      state.salesExportPercentage = action.payload;
    },
    toggleExportModalIsOpen(state) {
      state.salesExportModalIsOpen = !state.salesExportModalIsOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalesList.pending, (state) => {
      return {
        ...state,
        salesList: null,
        salesListLoading: true,
      };
    }),
      builder.addCase(fetchSalesList.fulfilled, (state, res) => {
        const size = res.payload.data.size;
        const totalAmount = res.payload.data.totalAmount;
        const page = map(res.payload.data.page, (item) => {
          return {
            ...item,
            registerDateTime: handleDateTime(item.registerDateTime),
            entryDateTime: handleDateTime(item.entryDateTime),
            exitDateTime: handleDateTime(item.exitDateTime),
            price: handleMoney(item.price),
            paidPrice: handleMoney(item.paidPrice),
            routeId: item.routeId,
            userId: item.userId,
            driverId: item.driverId,
            vehicle: item.type
              ? `${item.plate} (${item.type})`
              : `${item.plate}`,
            period: handleDateTimeDiff(item.entryDateTime, item.exitDateTime),
            irregularities: item.irregularities
              ? replace(replace(item.irregularities, '[', ''), ']', '')
              : '--',
          };
        });
        return {
          ...state,
          salesList: { page, size, totalAmount },
          salesListLoading: false,
        };
      }),
      builder.addCase(fetchSalesList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          salesListLoading: false,
        };
      });

    builder.addCase(fetchSalesExportExcel.pending, (state) => {
      return {
        ...state,
        salesExportExcelLoading: true,
        salesExportModalIsOpen: true,
        salesExportPercentage: 0,
      };
    }),
      builder.addCase(fetchSalesExportExcel.fulfilled, (state, res) => {
        return {
          ...state,
          salesExportExcelLoading: false,
          salesExportPercentage: 10,
        };
      }),
      builder.addCase(fetchSalesExportExcel.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          salesExportExcelLoading: false,
          salesExportModalIsOpen: false,
          salesExportPercentage: 0,
        };
      });

    builder.addCase(fetchSalesExportCommissionsTxt.pending, (state) => {
      return {
        ...state,
        salesExportCommissionsTxtLoading: true,
        salesExportModalIsOpen: true,
        salesExportPercentage: 0,
      };
    }),
      builder.addCase(
        fetchSalesExportCommissionsTxt.fulfilled,
        (state, res) => {
          return {
            ...state,
            salesExportCommissionsTxtLoading: false,
            salesExportPercentage: 10,
          };
        },
      ),
      builder.addCase(fetchSalesExportCommissionsTxt.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          salesExportCommissionsTxtLoading: false,
          salesExportModalIsOpen: false,
          salesExportPercentage: 0,
        };
      });

    builder.addCase(fetchSalesExportCommissionsHtml.pending, (state) => {
      return {
        ...state,
        salesExportCommissionsHtmlLoading: true,
        salesExportModalIsOpen: true,
        salesExportPercentage: 0,
      };
    }),
      builder.addCase(
        fetchSalesExportCommissionsHtml.fulfilled,
        (state, res) => {
          return {
            ...state,
            salesExportCommissionsHtmlLoading: false,
            salesExportPercentage: 10,
          };
        },
      ),
      builder.addCase(fetchSalesExportCommissionsHtml.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          salesExportCommissionsHtmlLoading: false,
          salesExportModalIsOpen: false,
          salesExportPercentage: 0,
        };
      });

    builder.addCase(fetchSalesExportSalesTxt.pending, (state) => {
      return {
        ...state,
        salesExportSalesTxtLoading: true,
        salesExportModalIsOpen: true,
        salesExportPercentage: 0,
      };
    }),
      builder.addCase(fetchSalesExportSalesTxt.fulfilled, (state, res) => {
        return {
          ...state,
          salesExportSalesTxtLoading: false,
          salesExportPercentage: 10,
        };
      }),
      builder.addCase(fetchSalesExportSalesTxt.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          salesExportSalesTxtLoading: false,
          salesExportModalIsOpen: false,
          salesExportPercentage: 0,
        };
      });

    builder.addCase(fetchSalesExportSalesHtml.pending, (state) => {
      return {
        ...state,
        salesExportSalesHtmlLoading: true,
        salesExportModalIsOpen: true,
        salesExportPercentage: 0,
      };
    }),
      builder.addCase(fetchSalesExportSalesHtml.fulfilled, (state, res) => {
        return {
          ...state,
          salesExportSalesHtmlLoading: false,
          salesExportPercentage: 10,
        };
      }),
      builder.addCase(fetchSalesExportSalesHtml.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          salesExportSalesHtmlLoading: false,
          salesExportModalIsOpen: false,
          salesExportPercentage: 0,
        };
      });

    builder.addCase(fetchSalesExportOperationalHtml.pending, (state) => {
      return {
        ...state,
        salesExportOperationalHtmlLoading: true,
        salesExportModalIsOpen: true,
        salesExportPercentage: 0,
      };
    }),
      builder.addCase(
        fetchSalesExportOperationalHtml.fulfilled,
        (state, res) => {
          return {
            ...state,
            salesExportOperationalHtmlLoading: false,
            salesExportPercentage: 10,
          };
        },
      ),
      builder.addCase(fetchSalesExportOperationalHtml.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          salesExportOperationalHtmlLoading: false,
          salesExportModalIsOpen: false,
          salesExportPercentage: 0,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = salesListSlice.actions;
