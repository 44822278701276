import { actionsSlice } from './Actions';
import { actionsFiltersSlice } from './ActionsFilters';
import { actionsFiltersValuesSlice } from './ActionsFiltersValues';
import { actionsListSlice } from './ActionsList';
import { administrativeDashboardSlice } from './AdministrativeDashboard';
import { areaSlice } from './Area';
import { areasSlice } from './Areas';
import { authSlice } from './Auth';
import { configurationSlice } from './Configuration';
import { configurationsSlice } from './Configurations';
import { credentialsSlice } from './Credentials';
import { credentialsFiltersSlice } from './CredentialsFilters';
import { credentialsFiltersValuesSlice } from './CredentialsFiltersValues';
import { credentialsListSlice } from './CredentialsList';
import { notificationSlice } from './Notification';
import { notificationsFiltersSlice } from './NotificationsFilters';
import { notificationsFiltersValuesSlice } from './NotificationsFiltersValues';
import { notificationsListSlice } from './NotificationsList';
import { operationalDashboardSlice } from './OperationalDashboard';
import { permissionsSlice } from './Permissions';
import { routeSlice } from './Route';
import { routesFiltersSlice } from './RoutesFilters';
import { routesFiltersValuesSlice } from './RoutesFiltersValues';
import { routesListSlice } from './RoutesList';
import { routesMapSlice } from './RoutesMap';
import { saleSlice } from './Sale';
import { salesFiltersSlice } from './SalesFilters';
import { salesFiltersValuesSlice } from './SalesFiltersValues';
import { salesListSlice } from './SalesList';
import { sessionSlice } from './Session';
import { sessionsFiltersSlice } from './SessionsFilters';
import { sessionsFiltersValuesSlice } from './SessionsFiltersValues';
import { sessionsListSlice } from './SessionsList';
import { userSlice } from './User';
import { usersFiltersSlice } from './UsersFilters';
import { usersFiltersValuesSlice } from './UsersFiltersValues';
import { usersListSlice } from './UsersList';

export const actionsReducer = actionsSlice.reducer;
export const actionsListReducer = actionsListSlice.reducer;
export const actionsFiltersReducer = actionsFiltersSlice.reducer;
export const actionsFiltersValuesReducer = actionsFiltersValuesSlice.reducer;
export const administrativeDashboardReducer =
  administrativeDashboardSlice.reducer;
export const areaReducer = areaSlice.reducer;
export const areasReducer = areasSlice.reducer;
export const authReducer = authSlice.reducer;
export const configurationReducer = configurationSlice.reducer;
export const configurationsReducer = configurationsSlice.reducer;
export const credentialsReducer = credentialsSlice.reducer;
export const credentialsFiltersReducer = credentialsFiltersSlice.reducer;
export const credentialsFiltersValuesReducer =
  credentialsFiltersValuesSlice.reducer;
export const credentialsListSliceReducer = credentialsListSlice.reducer;
export const notificationReducer = notificationSlice.reducer;
export const notificationsFiltersReducer = notificationsFiltersSlice.reducer;
export const notificationsFiltersValuesReducer =
  notificationsFiltersValuesSlice.reducer;
export const notificationsListReducer = notificationsListSlice.reducer;
export const operationalDashboardReducer = operationalDashboardSlice.reducer;
export const permissionsReducer = permissionsSlice.reducer;
export const routeReducer = routeSlice.reducer;
export const routesFiltersReducer = routesFiltersSlice.reducer;
export const routesFiltersValuesReducer = routesFiltersValuesSlice.reducer;
export const routesListReducer = routesListSlice.reducer;
export const routesMapReducer = routesMapSlice.reducer;
export const saleReducer = saleSlice.reducer;
export const salesFiltersReducer = salesFiltersSlice.reducer;
export const salesFiltersValuesReducer = salesFiltersValuesSlice.reducer;
export const salesListReducer = salesListSlice.reducer;
export const sessionReducer = sessionSlice.reducer;
export const sessionsFiltersReducer = sessionsFiltersSlice.reducer;
export const sessionsFiltersValuesReducer = sessionsFiltersValuesSlice.reducer;
export const sessionsListReducer = sessionsListSlice.reducer;
export const userReducer = userSlice.reducer;
export const usersFiltersReducer = usersFiltersSlice.reducer;
export const usersFiltersValuesReducer = usersFiltersValuesSlice.reducer;
export const usersListReducer = usersListSlice.reducer;

export * from './Actions';
export * from './ActionsList';
export * from './ActionsFilters';
export * from './ActionsFiltersValues';
export * from './AdministrativeDashboard';
export * from './Area';
export * from './Areas';
export * from './Auth';
export * from './Configuration';
export * from './Configurations';
export * from './Credentials';
export * from './CredentialsFilters';
export * from './CredentialsFiltersValues';
export * from './CredentialsList';
export * from './NotificationsList';
export * from './NotificationsFilters';
export * from './NotificationsFiltersValues';
export * from './Notification';
export * from './OperationalDashboard';
export * from './Permissions';
export * from './RoutesList';
export * from './RoutesFilters';
export * from './RoutesFiltersValues';
export * from './RoutesMap';
export * from './Route';
export * from './SalesList';
export * from './SalesFilters';
export * from './SalesFiltersValues';
export * from './Sale';
export * from './SessionsList';
export * from './SessionsFilters';
export * from './SessionsFiltersValues';
export * from './Session';
export * from './UsersList';
export * from './UsersFilters';
export * from './UsersFiltersValues';
export * from './User';
export * from './types';
