/* eslint-disable no-invalid-this */
import { find, indexOf } from 'lodash';
import * as Yup from 'yup';

export const pageSchema = Yup.object({
  name: Yup.string()
    .required('Insira um nome')
    .required('O nome é obrigatório'),
  prices: Yup.array().min(1, 'Pelo menos 1').required('required'),
  workingPeriods: Yup.array().min(1, 'Pelo menos 1').required('required'),
});

export const priceSchema = Yup.object({
  type: Yup.string().required('Selecione uma categoria'),
  period: Yup.string().required('Selecione um período'),
  price: Yup.string().required('Insira um preço'),
});

export const workingPeriodSchema = Yup.object({
  startTime: Yup.string().required('Selecione um horário inicial'),
  endTime: Yup.string().required('Selecione um horário final'),
});
