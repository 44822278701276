import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUsersFiltersService } from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { UsersFiltersStateType } from './types';
import { map } from 'lodash';

const initialState: UsersFiltersStateType = {
  filters: null,
  filtersLoading: false,
};

export const fetchUsersFilters = createAsyncThunk('usersFilters', async () => {
  return await fetchUsersFiltersService();
});

export const usersFiltersSlice = createSlice({
  name: 'usersFilters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsersFilters.pending, (state) => {
      return {
        ...state,
        filtersLoading: true,
      };
    }),
      builder.addCase(fetchUsersFilters.fulfilled, (state, res) => {
        const handledAreas: { id: string; value: string }[] = [];
        const handledProfiles: { id: string; value: string }[] = [];
        map(res.payload.data.areas, (item) => {
          handledAreas.push({
            id: item.id.toString(),
            value: item.value,
          });
        });
        map(res.payload.data.profiles, (item) => {
          handledProfiles.push({
            id: item.id.toString(),
            value: item.value,
          });
        });

        return {
          ...state,
          filters: { areas: handledAreas, profiles: handledProfiles },
          filtersLoading: false,
        };
      }),
      builder.addCase(fetchUsersFilters.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          filtersLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = usersFiltersSlice.actions;
