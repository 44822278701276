import * as Yup from 'yup';

const validationSchema = Yup.object({
  username: Yup.string()
    .required('Insira seu login')
    // .email('Insira um email válido')
    .required('O login é obrigatório'),
  password: Yup.string()
    .required('Insira sua senha')
    // .min(8, 'Senha deve ter no mínimo 8 caracteres')
    .required('A senha é obrigatória'),
});

export default validationSchema;
