import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { map, times } from 'lodash';
import React from 'react';
import { Modal } from '../../../components';
import { useDispatch, useSelector, configurationSlice } from '../../../stores';
import { palette } from '../../../theme';
import { handleMoney } from '../../../utils/Helpers';
import useScreenSize from '../../../utils/useScreenSize';

export const ConfigurationInfoModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    configurationInfo,
    configurationInfoIsOpen,
    configurationInfoLoading,
  } = useSelector((state) => state.configurationReducer);
  const screenSize = useScreenSize();

  const handleClose = () => {
    dispatch(configurationSlice.actions.toggleConfigurationInfoIsOpen());
  };

  const handleWeekDays = (days: string[]) => {
    let handledDays = '';
    if (days.length > 0) {
      map(days, (day) => {
        switch (day) {
          case '1':
            handledDays += 'Seg ';
            break;
          case '2':
            handledDays += 'Ter ';
            break;
          case '3':
            handledDays += 'Qua ';
            break;
          case '4':
            handledDays += 'Qui ';
            break;
          case '5':
            handledDays += 'Sex ';
            break;
          case '6':
            handledDays += 'Sab ';
            break;
          default:
            handledDays += 'Dom ';
            break;
        }
      });
    }
    return handledDays;
  };

  return (
    <Modal
      open={configurationInfoIsOpen}
      onClose={handleClose}
      title='Detalhamento'
      width={screenSize <= 600 ? screenSize - 20 : 600}
    >
      <>
        {configurationInfoLoading ? (
          <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
            {times(10, () => (
              <Skeleton animation='wave' height={29} width='100%' />
            ))}
          </TableContainer>
        ) : (
          <>
            <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
              <Table
                sx={{ minWidth: 500 }}
                size='small'
                aria-label='a dense table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align='left'
                      sx={{
                        borderBottom: 0.1,
                        borderColor: palette.divider,
                      }}
                      colSpan={3}
                    >
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '18px', fontWeight: 'bold' }}
                      >
                        {configurationInfo?.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Dias da semana
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: '120px' }}>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Horário Inicial
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: '120px' }}>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Horário Final
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(configurationInfo?.workingPeriods, (item) => {
                    return (
                      <TableRow
                        sx={{
                          '&:nth-of-type(odd)': {
                            backgroundColor: palette.action.hover,
                          },
                          '&:last-child td, &:last-child th': {
                            borderBottom: 0.1,
                            borderColor: palette.divider,
                          },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {handleWeekDays(item.days)}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {item.startTime}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {item.endTime}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Categoria
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Período/Fração
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Preço
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(configurationInfo?.prices, (item) => {
                    return (
                      <TableRow
                        sx={{
                          '&:nth-of-type(odd)': {
                            backgroundColor: palette.action.hover,
                          },
                          // hide last border
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {item.type}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {item.period}
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          {handleMoney(parseFloat(item.price))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </>
    </Modal>
  );
};
