/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { CredentialsExportResponseType } from '../../services/Credentials/types';
import { credentialsListSlice } from '../../stores';
import { delay } from '../../utils/Helpers';

export const exportCredentialsToCSV = async (
  apiData: CredentialsExportResponseType,
  routes: { id: string; value: string }[],
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  worker: (
    apiData: CredentialsExportResponseType,
    routes: { id: string; value: string }[],
  ) => Promise<string>,
): Promise<void> => {
  dispatch(credentialsListSlice.actions.setCredentialsExportPercentage(20));

  const handledApiData = await worker(apiData, routes);
  dispatch(credentialsListSlice.actions.setCredentialsExportPercentage(60));

  const fileType = 'data:text/csv;charset=utf-8;';
  const fileExtension = '.csv';
  dispatch(credentialsListSlice.actions.setCredentialsExportPercentage(100));
  await delay(100);

  const blob = new Blob([handledApiData], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `credenciais-${moment().format('yyyy-MM-DD_hh-mm')}` + fileExtension,
  );

  return;
};

export const handleApiDataCredentialsToCSV = (
  apiData: CredentialsExportResponseType,
  routes: {
    id: string;
    value: string;
  }[],
) => {
  let handledApiData = `"Numero da credencial";"Placa";"Nome";"CPF";"Logradouro";"Numero";"Complemento";"Bairro";"Trechos";"Validade";\r\n`;

  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[8] +
      dateTime[9] +
      '/' +
      dateTime[5] +
      dateTime[6] +
      '/' +
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3]
    );
  };

  const formatRoutes = (routesNames: string[]) => {
    if (routesNames.length > 0) {
      let formattedRoutes = '';
      routesNames.map((item) => {
        formattedRoutes = formattedRoutes + item + ' | ';
      });
      return formattedRoutes.substring(0, formattedRoutes.length - 3);
    } else {
      return 'Nenhum';
    }
  };

  // eslint-disable-next-line lodash/prefer-lodash-method
  apiData.map((item) => {
    const validityDateTime = formatDateTime(item.validity);
    const routesNames: string[] = [];

    if (item.routes) {
      const routesIds = item.routes.split('|');

      routesIds.map((item) => {
        const route = routes.find((route) => {
          return route.id.toString() === item;
        });

        if (route) routesNames.push(route.value);
      });
    }

    handledApiData =
      handledApiData +
      `"${item.number}";"${item.vehiclePlate}";"${item.userName}";"${
        item.userDocument
      }";"${item.address}";"${item.addressNumber}";"${
        item.addressComplement
      }";"${item.district}";"${
        /* item.routes */ formatRoutes(routesNames)
      }";"${validityDateTime}";\r\n`;
  });

  return handledApiData;
};
