export const fetchSalesFiltersUrl = `/serviceorders/list/filters`;
export const fetchSalesListUrl = `/serviceorders`;
export const fetchSalesInfoUrl = `/serviceorders`;
export const fetchSalesEditPaymentUrl = `/serviceorders`;
export const fetchSalesSendEmailUrl = `/serviceorders`;
export const fetchSalesExportExcelUrl = `/serviceorders/export/excel`;
export const fetchSalesExportCommissionsTxtUrl = `/serviceorders/export/txt/commissions`;
export const fetchSalesExportCommissionsHtmlUrl = `/serviceorders/export/html/commissions`;
export const fetchSalesExportSalesTxtUrl = `/serviceorders/export/txt/sales`;
export const fetchSalesExportSalesHtmlUrl = `/serviceorders/export/html/sales`;
export const fetchSalesExportOperationalHtmlUrl = `/serviceorders/export/html/operational`;
