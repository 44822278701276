import { Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { title } from '../../../theme';
import { StyledPaper } from './styles';

export const Footer = () => {
  return (
    <StyledPaper elevation={3}>
      <Typography color='white' sx={{ fontSize: 12 }}>
        {title} © {moment().year()}
      </Typography>
    </StyledPaper>
  );
};
