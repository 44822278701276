import { styled, Grid, Typography } from '@mui/material';

export const StyledFiltersBtnsGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  // border: '1px solid ' + palette.divider,
}));

export const StyledTableHeader = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  // border: '1px solid ' + palette.divider,
}));

export const StyledModalLoadingWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexdirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
}));

export const StyledModalWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  maxHeight: 300,
  borderRadius: '10px',
  border: `1px solid rgba(0, 0, 0, 0.2)`,
}));

export const StyledModalInnerWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 10px;
  ::-webkit-scrollbar {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
`;

export const StyledModalTextRow = styled(Typography)`
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const StyledActionsWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
}));
