import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Skeleton, Typography, Button as MuiButton } from '@mui/material';
import { times } from 'lodash';
import React from 'react';
import { Modal } from '../../../components';
import { useDispatch, useSelector, sessionSlice } from '../../../stores';
import { handleDateTime, handleMapLink } from '../../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const SessionInfoModal: React.FC = () => {
  const dispatch = useDispatch();
  const { sessionInfo, sessionInfoLoading, sessionInfoIsOpen } = useSelector(
    (state) => state.sessionReducer,
  );

  const handleCancel = () => {
    if (sessionInfo) {
      dispatch(
        sessionSlice.actions.setSessionCloseId({
          sessionIdToClose: sessionInfo.id,
        }),
      );
      dispatch(sessionSlice.actions.toggleSessionInfoIsOpen());
      dispatch(sessionSlice.actions.toggleSessionCloseIsOpen());
    }
  };

  return (
    <Modal
      open={sessionInfoIsOpen}
      onClose={() => {
        dispatch(sessionSlice.actions.toggleSessionInfoIsOpen());
      }}
      title='Detalhamento'
    >
      <>
        {sessionInfoLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              {times(6, () => (
                <Skeleton animation='wave' height={29} width='100%' />
              ))}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Nome:
                </Typography>
                <Typography>{sessionInfo?.userName}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Entrada:
                </Typography>
                <Typography>
                  {handleDateTime(sessionInfo?.startDateTime)}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Saída:
                </Typography>
                <Typography>
                  {handleDateTime(sessionInfo?.finalDateTime)}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Máquina:
                </Typography>
                <Typography>{sessionInfo?.deviceId}</Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Localização:
                </Typography>
                <a
                  href={handleMapLink(
                    sessionInfo?.latitude.toString(),
                    sessionInfo?.longitude.toString(),
                  )}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Typography>Ver no mapa</Typography>
                </a>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Trechos:
                </Typography>
                <Typography>{sessionInfo?.routes}</Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        {sessionInfo?.finalDateTime === '0001-01-01 00:00:00' && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <MuiButton
              variant='contained'
              color='secondary'
              startIcon={<CancelIcon />}
              onClick={() => handleCancel()}
              sx={{ width: '100%', margin: 0.5 }}
            >
              Encerrar
            </MuiButton>
          </Box>
        )}
      </>
    </Modal>
  );
};
