import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { ButtonGroup, styled } from '@mui/material';
import { transparentize } from 'polished';
import { palette } from '../../theme';

export const StyledButtonGroup = styled(ButtonGroup)(() => ({
  margin: 8,
}));

export const StyledButton = styled(LoadingButton)<LoadingButtonProps>(() => ({
  height: 35,
  color: 'background.main',
  '& .MuiLoadingButton-loadingIndicator': {
    color: transparentize(0.4, palette.background.main),
  },
  '&:disabled': {
    backgroundColor: palette.primary,
    color: 'white',
  },
}));
