/* eslint-disable pii/no-phone-number */
import CancelIcon from '@mui/icons-material/Cancel';
import DirectionsIcon from '@mui/icons-material/Directions';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  PageWrapper,
  Form,
  FiltersGrid,
  Accordion,
  DropdownInput,
  Checkbox,
} from '../../components';
import {
  useDispatch,
  fetchRoutesFilters,
  fetchRoutesList,
  useSelector,
  routesFiltersValuesSlice,
  routesListSlice,
} from '../../stores';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import {
  CancelRouteModal,
  RoutesExportModal,
  RoutesOperatingVacanciesExportModal,
} from './modals';
import { StyledFiltersBtnsGrid } from './styles';
import { RoutesTable } from './table';

export const RoutesManagement: React.FC = () => {
  const dispatch = useDispatch();
  const { filters, filtersLoading } = useSelector(
    (state) => state.routesFiltersReducer,
  );
  const filtersValues = useSelector(
    (state) => state.routesFiltersValuesReducer,
  );
  const { routesPage, routesListLoading } = useSelector(
    (state) => state.routesListReducer,
  );
  const responsiveMode = useResponsiveMode();
  const [expandedAccordeon, setExpandedAccordeon] = useState(false);
  const [formData, setFormData] = useState({
    areaId: filtersValues.areaId,
    configurationId: filtersValues.configurationId,
    operation: filtersValues.operation,
    routeName: filtersValues.routeName,
  });

  useEffect(() => {
    dispatch(fetchRoutesFilters());
    const params = {
      areaId: formData.areaId,
      configurationId: formData.configurationId,
      operation: formData.operation,
      routeName: formData.routeName,
    };
    dispatch(fetchRoutesList(params));
  }, []);

  useEffect(() => {
    const values = {
      areaId: formData.areaId,
      configurationId: formData.configurationId,
      operation: formData.operation,
      routeName: formData.routeName,
    };

    dispatch(routesFiltersValuesSlice.actions.setRoutesFiltersValues(values));
  }, [formData]);

  const handleSubmit = () => {
    const values = {
      ...filtersValues,
      page: 1,
    };
    dispatch(routesFiltersValuesSlice.actions.setRoutesFiltersValues(values));
    dispatch(routesListSlice.actions.setRoutesPage(1));
    dispatch(fetchRoutesList(values));
    setExpandedAccordeon(false);
  };

  const handleReset = () => {
    const values = {
      areaId: '',
      configurationId: '',
      operation: '0',
      routeName: '',
      page: 1,
    };
    setFormData({
      areaId: '0',
      configurationId: '0',
      operation: '0',
      routeName: '',
    });
    dispatch(routesFiltersValuesSlice.actions.setRoutesFiltersValues(values));
    dispatch(routesListSlice.actions.setRoutesPage(1));
    dispatch(fetchRoutesList(values));
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <DirectionsIcon fontSize='large' sx={{ marginRight: 2 }} />
            Gerenciamento de trechos
          </Typography>
        </Grid>
      </Grid>
      {responsiveMode !== 'phone' ? (
        <Grid container>
          <Form onSubmit={handleSubmit}>
            <FiltersGrid loading={!filters && filtersLoading} loadingRows={2}>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <Input
                  data-testid='name'
                  type='text'
                  margin='normal'
                  label='Nome'
                  value={formData.routeName}
                  onChange={(value) =>
                    setFormData({ ...formData, routeName: value })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Área'
                  value={formData.areaId}
                  options={filters && filters.areas}
                  onChange={(value) => {
                    setFormData({ ...formData, areaId: value });
                  }}
                />
              </Grid>
              <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Configuração'
                  value={formData.configurationId}
                  options={filters && filters.configurations}
                  onChange={(value) =>
                    setFormData({ ...formData, configurationId: value })
                  }
                />
              </Grid>
              <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Operação'
                  value={formData.operation}
                  options={filters && filters.operations}
                  onChange={(value) =>
                    setFormData({ ...formData, operation: value })
                  }
                />
              </Grid>
              <StyledFiltersBtnsGrid
                item
                lg={2}
                md={3}
                sm={4}
                xs={12}
                margin={1}
              >
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAltIcon />}
                  onClick={handleSubmit}
                  disabled={routesListLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleReset}
                  disabled={routesListLoading}
                >
                  <CancelIcon fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </FiltersGrid>
          </Form>
        </Grid>
      ) : (
        <Accordion
          expanded={expandedAccordeon}
          setExpanded={setExpandedAccordeon}
          title='Filtros'
        >
          <Form onSubmit={handleSubmit}>
            <FiltersGrid loading={filtersLoading} loadingRows={1}>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <Input
                  data-testid='name'
                  type='text'
                  margin='normal'
                  label='Nome'
                  value={formData.routeName}
                  onChange={(value) =>
                    setFormData({ ...formData, routeName: value })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Área'
                  value={formData.areaId}
                  options={filters && filters.areas}
                  onChange={(value) => {
                    setFormData({ ...formData, areaId: value });
                  }}
                />
              </Grid>
              <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Configuração'
                  value={formData.configurationId}
                  options={filters && filters.configurations}
                  onChange={(value) =>
                    setFormData({ ...formData, configurationId: value })
                  }
                />
              </Grid>
              <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Operação'
                  value={formData.operation}
                  options={filters && filters.operations}
                  onChange={(value) =>
                    setFormData({ ...formData, operation: value })
                  }
                />
              </Grid>
              <StyledFiltersBtnsGrid
                item
                lg={1.5}
                md={4}
                sm={4}
                xs={12}
                margin={1}
              >
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAltIcon />}
                  onClick={handleSubmit}
                  sx={{ width: responsiveMode === 'phone' ? '100%' : 'auto' }}
                  disabled={routesListLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleReset}
                  disabled={routesListLoading}
                >
                  <CancelIcon fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </FiltersGrid>
          </Form>
        </Accordion>
      )}
      <Grid container margin={3}>
        <RoutesTable />
      </Grid>
      <CancelRouteModal />
      <RoutesOperatingVacanciesExportModal />
      <RoutesExportModal />
    </PageWrapper>
  );
};
