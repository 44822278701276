import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Skeleton, Typography } from '@mui/material';
import { times } from 'lodash';
import React from 'react';
import { Button, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  fetchConfigurationsList,
  configurationSlice,
  fetchConfigurationCancel,
} from '../../../stores';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const ConfigurationCancelModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    configurationInfo,
    configurationInfoLoading,
    configurationCancelIsOpen,
    configurationCancelLoading,
  } = useSelector((state) => state.configurationReducer);

  const handleClose = () => {
    dispatch(configurationSlice.actions.toggleConfigurationCancelIsOpen());
  };

  const handleSubmit = () => {
    if (configurationInfo) {
      dispatch(
        fetchConfigurationCancel({
          configurationId: configurationInfo.id.toString(),
        }),
      ).then((result) => {
        handleClose();
        if (result.meta.requestStatus === 'fulfilled') {
          dispatch(fetchConfigurationsList());
        }
      });
    }
  };

  const handleReturn = () => {
    dispatch(configurationSlice.actions.toggleConfigurationCancelIsOpen());
  };

  return (
    <Modal
      open={configurationCancelIsOpen}
      onClose={handleClose}
      title='Deseja excluir essa configuração?'
    >
      <>
        {configurationInfoLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              {times(3, () => (
                <Skeleton animation='wave' height={29} width='100%' />
              ))}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Nome:
                </Typography>
                <Typography>{configurationInfo?.name}</Typography>
              </StyledModalTextRow>
              {/* <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Descrição:
                </Typography>
                <Typography>
                  {configurationInfo?.description || '--'}
                </Typography>
              </StyledModalTextRow>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Cor:
                </Typography>
                <Typography>{configurationInfo?.color}</Typography>
              </StyledModalTextRow> */}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: '10px',
          }}
        >
          <Button
            variant='contained'
            label='Excluir'
            startIcon={<DeleteIcon />}
            type='submit'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={configurationCancelLoading}
            onClick={handleSubmit}
          />
          <Button
            color='secondary'
            variant='contained'
            type='reset'
            label='Voltar'
            startIcon={<ArrowBackIcon />}
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleReturn}
          />
        </Box>
      </>
    </Modal>
  );
};
