import { useState, useEffect } from 'react';

export default function useResponsiveMode(): // eslint-disable-next-line prettier/prettier
  'phone' | 'tablet' | 'small-screen' | 'large-screen' {
  // eslint-disable-next-line prettier/prettier
  function handleResize(): 'phone' | 'tablet' | 'small-screen' | 'large-screen' {
    const { innerWidth } = window;
    return innerWidth <= 550
      ? 'phone'
      : innerWidth <= 850
      ? 'tablet'
      : innerWidth <= 1150
      ? 'small-screen'
      : 'large-screen';
  }

  const [mode, setMode] = useState(handleResize());

  useEffect(() => {
    window.addEventListener('resize', () => setMode(handleResize));
    return () =>
      window.removeEventListener('resize', () => setMode(handleResize));
  }, []);

  return mode;
}
