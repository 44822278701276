import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import {
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Skeleton,
} from '@mui/material';
import { omit, times } from 'lodash';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, Dropdown, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  saleSlice,
  fetchSaleEditPayment,
  fetchSalesList,
} from '../../../stores';
import { handleErrors } from '../../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const SaleEditPaymentModal: React.FC = () => {
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state.authReducer);
  const { filters } = useSelector((state) => state.salesFiltersReducer);
  const { saleInfo, saleEditPaymentLoading, saleEditPaymentIsOpen } =
    useSelector((state) => state.saleReducer);
  const filtersValues = useSelector((state) => state.salesFiltersValuesReducer);
  const [formData, setFormData] = useState({
    paymentMethod: '',
    password: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object({
    paymentMethod: Yup.string()
      .required('Selecione o novo método de pagamento')
      .required('O método de pagamento é obrigatório'),
    password: Yup.string()
      .required('Insira sua senha')
      .required('A senha é obrigatória'),
  });

  const handleClose = () => {
    setErrors({});
    setFormData({
      paymentMethod: '',
      password: '',
    });
    saleSlice.actions.setSaleEditPaymentId({
      saleId: '',
    });
    dispatch(saleSlice.actions.toggleSaleEditPaymentIsOpen());
  };

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(
        () =>
          saleInfo &&
          dispatch(
            fetchSaleEditPayment({
              saleId: saleInfo.id,
              paymentMethod: formData.paymentMethod,
              login: login,
              password: formData.password,
            }),
          ).then(() => {
            handleClose();
            dispatch(fetchSalesList(omit(filtersValues, ['days'])));
          }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    setErrors({});
    setFormData({
      paymentMethod: '',
      password: '',
    });
    saleSlice.actions.setSaleEditPaymentId({
      saleId: '',
    });
    dispatch(saleSlice.actions.toggleSaleEditPaymentIsOpen());
    dispatch(saleSlice.actions.toggleSaleInfoIsOpen());
  };

  return (
    <Modal
      open={saleEditPaymentIsOpen}
      onClose={handleClose}
      title='Edição de venda'
    >
      <>
        {saleEditPaymentLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              {times(1, () => (
                <Skeleton animation='wave' height={29} width='100%' />
              ))}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Veículo:
                </Typography>
                <Typography>{saleInfo?.vehicle}</Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: '10px',
          }}
        >
          <Dropdown
            label='Novo método de pagamento'
            value={formData.paymentMethod}
            options={omit(filters?.paymentMethods, [
              '',
              'Todos',
              'CREDITO',
              'DINHEIRO',
            ])}
            onChange={(value) =>
              setFormData({ ...formData, paymentMethod: value })
            }
            errorMessage={errors && errors.paymentMethod}
          />
          <Input
            data-testid='password'
            type={showPassword ? 'text' : 'password'}
            label='Insira sua senha'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formData.password}
            onChange={(InputProps) =>
              setFormData({ ...formData, password: InputProps })
            }
            errorMessage={errors && errors.password}
          />
          <Button
            variant='contained'
            label='Salvar'
            startIcon={<SaveIcon />}
            type='submit'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={saleEditPaymentLoading}
            onClick={handleSubmit}
          />
          <Button
            color='secondary'
            variant='contained'
            startIcon={<ArrowBackIcon />}
            label='Voltar'
            type='reset'
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleReturn}
          />
        </Box>
      </>
    </Modal>
  );
};
