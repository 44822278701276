/* eslint-disable pii/no-phone-number */
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Grid, Typography, Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { PageWrapper } from '../../components';
import { useDispatch, fetchAreasList, useSelector } from '../../stores';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { CreateAreaModal, EditAreaModal, CancelAreaModal } from './modals';
import { SavedAreasTable } from './table';

export const AreasManagement: React.FC = () => {
  const dispatch = useDispatch();
  const { areasListLoading } = useSelector((state) => state.areasReducer);
  const responsiveMode = useResponsiveMode();

  useEffect(() => {
    dispatch(fetchAreasList());
  }, []);

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <RoomOutlinedIcon fontSize='large' sx={{ marginRight: 2 }} />
            Gerenciamento de áreas
          </Typography>
        </Grid>
      </Grid>
      <Grid container margin={3}>
        {areasListLoading ? (
          <Skeleton animation='wave' width='100%' height='300px' />
        ) : (
          <SavedAreasTable />
        )}
      </Grid>
      <CreateAreaModal />
      <EditAreaModal />
      <CancelAreaModal />
    </PageWrapper>
  );
};
