import { InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CompactPicker } from 'react-color';
import { Input } from '../Input';

export const ColorPicker = (props: {
  label: string;
  value: string;
  onChange?: (value: string) => void;
  margin?: 'none' | 'normal' | 'dense';
  errorMessage?: string;
  type?: string;
  shrink?: boolean;
  InputProps?: any;
  select?: boolean;
  disabled?: boolean;
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [pickedColor, setPickedColor] = useState<string>(props.value);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  useEffect(() => {
    setPickedColor(props.value);
  }, [props.value]);

  return (
    <div>
      <Input
        {...props}
        onClick={handleClick}
        value={pickedColor}
        InputProps={{
          startAdornment: pickedColor !== '' && (
            <InputAdornment position='start'>
              <div
                style={{
                  backgroundColor: pickedColor,
                  borderRadius: '50%',
                  width: '10px',
                  height: '10px',
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      {displayColorPicker ? (
        <div
          style={{
            position: 'relative',
            top: '-10px',
            right: '0px',
            bottom: '0px',
            left: '5px',
          }}
        >
          <div
            style={{
              position: 'absolute',
              zIndex: '2',
            }}
          >
            <div
              style={{
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              }}
              onClick={handleClose}
            />
            <CompactPicker
              onChange={(color, event) => {
                props.onChange && props.onChange(color.hex);
                setPickedColor(color.hex);
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
