import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import JSZip from 'jszip';
import { includes, map, replace, split } from 'lodash';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { SalesExportSalesTxtResponseType } from '../../../../services/Sales/types';
import { salesListSlice } from '../../../../stores';

export const exportSalesToTXT = async (
  apiData: SalesExportSalesTxtResponseType,
  routes: {
    id: number;
    value: string;
    areaId: number;
  }[],
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  worker: (
    apiData: SalesExportSalesTxtResponseType,
    routes: {
      id: number;
      value: string;
      areaId: number;
    }[],
  ) => Promise<
    {
      paymentMethod: string;
      registerDate: string;
      sumPrice: number;
      areaId: number;
      areaName: string;
      areaColor: string;
      areaClientNumber: string;
      areaTypeRecipe: string;
      areaCostCenter: string;
    }[]
  >,
): Promise<void> => {
  dispatch(salesListSlice.actions.setSalesExportPercentage(20));

  let handledApiData: {
    paymentMethod: string;
    registerDate: string;
    sumPrice: number;
    areaId: number;
    areaName: string;
    areaColor: string;
    areaClientNumber: string;
    areaTypeRecipe: string;
    areaCostCenter: string;
  }[] = [];
  if (
    apiData.sales &&
    apiData.txtSalesDocument &&
    apiData.txtSalesItem &&
    apiData.areas
  ) {
    handledApiData = await worker(apiData, routes);
  }
  dispatch(salesListSlice.actions.setSalesExportPercentage(100));

  // Generating and zipping files
  const zip = new JSZip();
  map(handledApiData, (item) => {
    let txt = createSalesExportTxtLine(apiData.txtSalesDocument, item);
    txt = txt + '\n'; // Add line break
    txt = txt + createSalesExportTxtLine(apiData.txtSalesItem, item);
    // txt = txt.substring(0, txt.length - 1); // Remove last character

    zip.file(
      `${item.areaName} ${item.registerDate} ${item.paymentMethod}.txt`,
      txt,
    );
  });

  // Exporting
  const content = await zip.generateAsync({ type: 'blob' });

  FileSaver.saveAs(
    content,
    `relatório-vendas-${moment().format('yyyy-MM-DD_hh-mm')}` + '.zip',
  );
};

export const handleApiDataSalesToTXT = (
  apiData: SalesExportSalesTxtResponseType,
  routes: {
    id: number;
    value: string;
    areaId: number;
  }[],
) => {
  // Handling data to create array of objects with all info needed for next steps
  const sales: {
    // routeId: number;
    paymentMethod: string;
    registerDate: string;
    sumPrice: number;
    areaId: number;
    areaName: string;
    areaColor: string;
    areaClientNumber: string;
    areaTypeRecipe: string;
    areaCostCenter: string;
  }[] = [];
  // eslint-disable-next-line lodash/prefer-lodash-method
  apiData.sales.map((item) => {
    // eslint-disable-next-line lodash/prefer-lodash-method
    const route = routes.find((auxItem) => {
      return auxItem.id === item.routeId;
    });
    const area =
      route &&
      // eslint-disable-next-line lodash/prefer-lodash-method
      apiData.areas.find((auxItem) => {
        return auxItem.id === route.areaId;
      });
    if (area) {
      // eslint-disable-next-line lodash/prefer-lodash-method
      const saleIdx = sales.findIndex((auxItem) => {
        return (
          // eslint-disable-next-line lodash/prefer-matches
          auxItem.areaId === area.id &&
          auxItem.paymentMethod === item.paymentMethod &&
          auxItem.registerDate === item.registerDate
        );
      });
      if (saleIdx === -1) {
        sales.push({
          paymentMethod: item.paymentMethod,
          registerDate: item.registerDate,
          sumPrice: item.sumPrice,
          areaId: area.id,
          areaName: area.name,
          areaColor: area.color,
          areaClientNumber: area.clientNumber,
          areaTypeRecipe: area.typeRecipe,
          areaCostCenter: area.costCenter,
        });
      } else {
        sales[saleIdx].sumPrice += item.sumPrice;
      }
    }
  });

  return sales;
};

const createSalesExportTxtLine = (
  document: {
    field: string;
    start: number;
    size: number;
    type: string;
    mandatory: string;
  }[],
  sale: {
    paymentMethod: string;
    registerDate: string;
    sumPrice: number;
    areaId: number;
    areaName: string;
    areaColor: string;
    areaClientNumber: string;
    areaTypeRecipe: string;
    areaCostCenter: string;
  },
) => {
  let txt = '';
  map(document, (item) => {
    if (item.mandatory === 'S') {
      switch (item.field) {
        // Campos fixos do cabecalho
        case 'TipoD': {
          txt = txt + handleNormalizeTxtFieldForReport(item, 'D');
          break;
        }
        case 'NrCli': {
          txt =
            txt + handleNormalizeTxtFieldForReport(item, sale.areaClientNumber);
          break;
        }
        case 'Documento': {
          switch (sale.paymentMethod) {
            case 'CREDITO': {
              txt =
                txt +
                handleNormalizeTxtFieldForReport(
                  item,
                  moment(sale.registerDate).format('DDMMyyyy').toString() +
                    'CC',
                );
              break;
            }
            case 'DEBITO': {
              txt =
                txt +
                handleNormalizeTxtFieldForReport(
                  item,
                  moment(sale.registerDate).format('DDMMyyyy').toString() +
                    'CD',
                );
              break;
            }
            case 'PIX': {
              txt =
                txt +
                handleNormalizeTxtFieldForReport(
                  item,
                  moment(sale.registerDate).format('DDMMyyyy').toString() +
                    'PX',
                );
              break;
            }
            default: {
              txt =
                txt +
                handleNormalizeTxtFieldForReport(
                  item,
                  moment(sale.registerDate).format('DDMMyyyy').toString(),
                );
              break;
            }
          }
          break;
        }
        case 'Parcela': {
          txt = txt + handleNormalizeTxtFieldForReport(item, '1');
          break;
        }
        case 'CodTpDoc': {
          txt = txt + handleNormalizeTxtFieldForReport(item, 'CTL');
          break;
        }
        case 'Emissao': {
          txt =
            txt +
            handleNormalizeTxtFieldForReport(
              item,
              moment(sale.registerDate).format('DD/MM/yyyy'),
            );
          break;
        }
        case 'Saida': {
          txt =
            txt +
            handleNormalizeTxtFieldForReport(
              item,
              moment(sale.registerDate).format('DD/MM/yyyy'),
            );
          break;
        }
        case 'Vencimento': {
          txt =
            txt +
            handleNormalizeTxtFieldForReport(
              item,
              moment(sale.registerDate).format('DD/MM/yyyy'),
            );
          break;
        }
        case 'Quitado': {
          txt = txt + handleNormalizeTxtFieldForReport(item, 'N');
          break;
        }
        case 'Status': {
          txt = txt + handleNormalizeTxtFieldForReport(item, 'N');
          break;
        }
        case 'Serie': {
          txt = txt + handleNormalizeTxtFieldForReport(item, '0');
          break;
        }
        default:
          break;
      }

      switch (item.field) {
        // Campos fixos do corpo
        case 'TipoI': {
          txt = txt + handleNormalizeTxtFieldForReport(item, 'I');
          break;
        }
        case 'Item': {
          txt = txt + handleNormalizeTxtFieldForReport(item, '1');
          break;
        }
        case 'TipoReceita': {
          txt =
            txt + handleNormalizeTxtFieldForReport(item, sale.areaTypeRecipe);
          break;
        }
        // HERE-------
        case 'Valor': {
          // Convertendo para número separado por ponto
          txt =
            txt +
            handleNormalizeTxtFieldForReport(
              item,
              sale.sumPrice.toString(),
              // sale.sumPrice.toString(CultureInfo.InvariantCulture),
            );
          break;
        }
        // ------------
        case 'CodCusto': {
          txt =
            txt + handleNormalizeTxtFieldForReport(item, sale.areaCostCenter);
          break;
        }
        case 'CodCustoFIN': {
          txt =
            txt + handleNormalizeTxtFieldForReport(item, sale.areaCostCenter);
          break;
        }
        default:
          break;
      }
    } else {
      txt = txt + handleNormalizeTxtFieldForReport(item, '');
    }
  });
  return txt.substring(0, txt.length - 1); // Remove last character
};

const handleNormalizeTxtFieldForReport = (
  field: {
    field: string;
    start: number;
    size: number;
    type: string;
    mandatory: string;
  },
  content: string,
) => {
  let limit = !content || content === '' ? 0 : content.length;

  if (field.type === 'DC' || field.type === 'IC') {
    while (limit < field.size) {
      content = ' ' + content;
      limit++;
    }
  } else if (field.type === 'DN' || field.type === 'IN') {
    let isDecimal = false;

    if (field.size % 1 === 0) {
      isDecimal = false;
    } else {
      isDecimal = true;
    }

    if (isDecimal) {
      if (content === '') {
        content = '0.0';
      } else {
        if (!includes(content, '.') && !includes(content, ',')) {
          content += '.0';
        }
      }

      const fieldSizeAsString = replace(field.size.toString(), ',', '.');
      const fieldSizeSplit = split(
        // field.size.toString(CultureInfo.InvariantCulture),
        fieldSizeAsString,
        '.',
      );

      const sizeDecimal = parseInt(fieldSizeSplit[1]);
      let limitDecimal = split(content, '.')[1].length;

      const newFieldSize = parseInt(fieldSizeSplit[0]) - sizeDecimal - 1;
      limit = split(content, '.')[0].length;

      while (limit < newFieldSize) {
        content = '0' + content;
        limit++;
      }
      while (limitDecimal < sizeDecimal) {
        content = content + '0';
        limitDecimal++;
      }
    } else {
      while (limit < field.size) {
        content = '0' + content;
        limit++;
      }
    }
  } else if (field.type === 'DD' || field.type === 'ID') {
    if (content === '') {
      content = '  /  /    ';
    }
  }

  content = content + ',';

  return content;
};
