export const fetchRoutesFiltersUrl = `/routes/list/filters`;
export const fetchRoutesCreateUrl = `/routes`;
export const fetchRoutesListUrl = `/routes`;
export const fetchRoutesInfoUrl = `/routes`;
export const fetchRoutesCancelUrl = `/routes`;
export const fetchRoutesBlockUrl = `/routes`;
export const fetchRoutesUpdateUrl = `/routes`;
export const fetchRoutesMapInfoUrl = `/routes/map/info`;
export const fetchRoutesExportOperatingVacanciesUrl = `/routes/export/operating/slots`;
export const fetchRoutesExportActiveVacanciesUrl = `/routes/export/active/slots`;
