import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { find, findIndex, invokeMap, map, orderBy, toLower } from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchUsersInfoService,
  fetchUsersCancelService,
  fetchUsersBlockService,
  fetchUsersCreateService,
  fetchUsersUpdateService,
  fetchUsersRoutesService,
  fetchUsersAddRoutesService,
} from '../../services';
import { RootState } from '../Store';
import { UserIdType, UserStateType } from './types';

const initialState: UserStateType = {
  userInfo: null,
  userInfoLoading: false,
  userCreateLoading: false,
  userCancelLoading: false,
  userUpdateLoading: false,
  userRoutesLoading: false,
  userAddRoutesLoading: false,
  userInfoIsOpen: false,
  userCancelIsOpen: false,
  userIdToCancel: '',
  userAreasRoutesList: [],
  userAreasRoutesAllChecked: false,
};

export const fetchUserInfo = createAsyncThunk(
  'usersInfo',
  async (params: { userId: string }, { getState }) => {
    const state = getState() as RootState;
    const res = await fetchUsersInfoService(params);
    return { ...res, filters: state.usersFiltersReducer.filters };
  },
);

export const fetchUserCreate = createAsyncThunk(
  'userCreate',
  async (params: {
    login: string;
    name: string;
    email: string;
    profileId: number;
    areas: number[];
    password: number;
    passwordConfirmation: number;
  }) => {
    return await fetchUsersCreateService(params);
  },
);

export const fetchUserCancel = createAsyncThunk(
  'usersCancel',
  async (params: { userId: string }) => {
    return await fetchUsersCancelService(params);
  },
);

export const fetchUserBlock = createAsyncThunk(
  'usersBlock',
  async (params: { userId: string; block: string }) => {
    return await fetchUsersBlockService(params);
  },
);

export const fetchUserUpdate = createAsyncThunk(
  'userUpdate',
  async (params: {
    userId: string;
    login: string;
    name: string;
    email: string;
    profileId: number;
    areas: number[];
  }) => {
    return await fetchUsersUpdateService(params);
  },
);

export const fetchUserRoutes = createAsyncThunk(
  'userRoutes',
  async (params: { userId: string }) => {
    return await fetchUsersRoutesService(params);
  },
);

export const fetchUserAddRoutes = createAsyncThunk(
  'userAddRoutes',
  async (params: { userId: string; routes: number[] }) => {
    return await fetchUsersAddRoutesService(params);
  },
);

export const userSlice = createSlice({
  name: 'usersInfo',
  initialState,
  reducers: {
    toggleUserInfoIsOpen(state) {
      state.userInfoIsOpen
        ? (state.userInfoIsOpen = false)
        : (state.userInfoIsOpen = true);
    },
    toggleUserCancelIsOpen(state) {
      state.userCancelIsOpen
        ? (state.userCancelIsOpen = false)
        : (state.userCancelIsOpen = true);
    },
    setUserCancelId(state, action: PayloadAction<UserIdType>) {
      state.userIdToCancel = action.payload.userIdToCancel;
    },
    resetUserInfo(state) {
      state.userInfo = null;
    },
    toggleUserRoute(
      state,
      action: PayloadAction<{ areaIdx: number; routeIdx: number }>,
    ) {
      state.userAreasRoutesList[action.payload.areaIdx].routes[
        action.payload.routeIdx
      ].checked =
        !state.userAreasRoutesList[action.payload.areaIdx].routes[
          action.payload.routeIdx
        ].checked;
    },
    toggleUserAllRoutes(state, action: PayloadAction<{ areaIdx: number }>) {
      const handledUserAreaRoutes = map(
        state.userAreasRoutesList[action.payload.areaIdx].routes,
        (item) => {
          return {
            ...item,
            checked:
              !state.userAreasRoutesList[action.payload.areaIdx].allChecked,
          };
        },
      );
      state.userAreasRoutesList[action.payload.areaIdx] = {
        ...state.userAreasRoutesList[action.payload.areaIdx],
        routes: handledUserAreaRoutes,
        allChecked:
          !state.userAreasRoutesList[action.payload.areaIdx].allChecked,
      };
    },
    toggleUserAllAreasAndRoutes(state) {
      const handledUserAreaRoutes = map(state.userAreasRoutesList, (item) => {
        const routes = map(item.routes, (route) => {
          return {
            ...route,
            checked: !state.userAreasRoutesAllChecked,
          };
        });
        return {
          ...item,
          routes: routes,
          allChecked: !state.userAreasRoutesAllChecked,
        };
      });
      state.userAreasRoutesList = handledUserAreaRoutes;
      state.userAreasRoutesAllChecked = !state.userAreasRoutesAllChecked;
    },
    toggleUserOpen(state, action: PayloadAction<{ areaIdx: number }>) {
      state.userAreasRoutesList[action.payload.areaIdx].open =
        !state.userAreasRoutesList[action.payload.areaIdx].open;
    },
    toggleUserOpenAll(state) {
      const handledUserAreas = map(state.userAreasRoutesList, (item) => {
        return {
          ...item,
          open: state.userAreasRoutesAllChecked,
        };
      });
      state.userAreasRoutesList = handledUserAreas;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.pending, (state) => {
      return {
        ...state,
        userInfoLoading: true,
      };
    }),
      builder.addCase(fetchUserInfo.fulfilled, (state, res) => {
        const handledAreas: { id: string; value: string }[] = [];
        map(res.payload.data.areas, (item) => {
          if (res.payload.filters) {
            const area = find(res.payload.filters.areas, {
              id: item.toString(),
            });
            if (area) {
              handledAreas.push({
                id: area.id,
                value: area.value,
              });
            }
          }
        });

        return {
          ...state,
          userInfo: {
            id: res.payload.data.id,
            login: res.payload.data.login,
            name: res.payload.data.name,
            email: res.payload.data.email,
            appBlock: res.payload.data.appBlock,
            profileId: res.payload.data.profileId.toString(),
            areas: handledAreas,
          },
          userInfoLoading: false,
        };
      }),
      builder.addCase(fetchUserInfo.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          userInfoLoading: false,
        };
      });

    builder.addCase(fetchUserCreate.pending, (state) => {
      return {
        ...state,
        userCreateLoading: true,
      };
    }),
      builder.addCase(fetchUserCreate.fulfilled, (state, res) => {
        toast.success('Usuário criado com sucesso');
        return {
          ...state,
          userCreateLoading: false,
        };
      }),
      builder.addCase(fetchUserCreate.rejected, (state) => {
        return {
          ...state,
          userCreateLoading: false,
        };
      });

    builder.addCase(fetchUserCancel.pending, (state) => {
      return {
        ...state,
        userCancelLoading: true,
      };
    }),
      builder.addCase(fetchUserCancel.fulfilled, (state, res) => {
        toast.success('Usuário removido com sucesso.');
        return {
          ...state,
          userInfo: {
            id: 0,
            login: '',
            name: '',
            email: '',
            appBlock: 0,
            profileId: '0',
            areas: [],
          },
          userCancelLoading: false,
        };
      }),
      builder.addCase(fetchUserCancel.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          usersCancelLoading: false,
        };
      });

    builder.addCase(fetchUserBlock.pending, (state) => {
      return {
        ...state,
      };
    }),
      builder.addCase(fetchUserBlock.fulfilled, (state) => {
        return {
          ...state,
        };
      }),
      builder.addCase(fetchUserBlock.rejected, (state) => {
        return {
          ...state,
        };
      });

    builder.addCase(fetchUserUpdate.pending, (state) => {
      return {
        ...state,
        userUpdateLoading: true,
      };
    }),
      builder.addCase(fetchUserUpdate.fulfilled, (state, res) => {
        toast.success('Usuário editado com sucesso.');
        return {
          ...state,
          userUpdateLoading: false,
        };
      }),
      builder.addCase(fetchUserUpdate.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          userUpdateLoading: false,
        };
      });

    builder.addCase(fetchUserRoutes.pending, (state) => {
      return {
        ...state,
        userRoutesLoading: true,
      };
    }),
      builder.addCase(fetchUserRoutes.fulfilled, (state, res) => {
        const orderedAreas = res.payload.data.areas.sort((a, b) => {
          if (toLower(a.value) < toLower(b.value)) return -1;
          if (toLower(a.value) > toLower(b.value)) return 1;
          return 0;
        });

        const orderedRoutes = res.payload.data.routes.sort((a, b) => {
          if (toLower(a.value) < toLower(b.value)) return -1;
          if (toLower(a.value) > toLower(b.value)) return 1;
          return 0;
        });

        const handledAreasRoutes: {
          id: string;
          value: string;
          allChecked: boolean;
          open: boolean;
          routes: { id: string; value: string; checked: boolean }[];
        }[] = [];

        map(orderedAreas, (item) => {
          if (item.id !== 0) {
            handledAreasRoutes.push({
              id: item.id.toString(),
              value: item.value,
              allChecked: true,
              open: false,
              routes: [],
            });
          }
        });

        let allAreasAndRoutesChecked = true;

        map(orderedRoutes, (item) => {
          if (item.id !== 0) {
            const idx = findIndex(handledAreasRoutes, [
              'id',
              item.areaId.toString(),
            ]);
            if (idx !== -1) {
              const isChecked =
                find(res.payload.data.userRoutes, (userRoute) => {
                  return userRoute === item.id;
                }) !== undefined
                  ? true
                  : false;

              handledAreasRoutes[idx].routes.push({
                id: item.id.toString(),
                value: item.value,
                checked: isChecked,
              });

              if (!handledAreasRoutes[idx].open && isChecked) {
                handledAreasRoutes[idx].open = true;
              }

              if (!isChecked) {
                handledAreasRoutes[idx].allChecked = false;
                allAreasAndRoutesChecked = false;
              }
            }
          }
        });
        const handledUserRoutes = invokeMap(
          res.payload.data.userRoutes,
          'toString',
        );

        return {
          ...state,
          userAreasRoutesList: handledAreasRoutes,
          userRoutes: handledUserRoutes,
          userRoutesLoading: false,
          userAreasRoutesAllChecked: allAreasAndRoutesChecked,
        };
      }),
      builder.addCase(fetchUserRoutes.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          userRoutesLoading: false,
        };
      });

    builder.addCase(fetchUserAddRoutes.pending, (state) => {
      return {
        ...state,
        userAddRoutesLoading: true,
      };
    }),
      builder.addCase(fetchUserAddRoutes.fulfilled, (state, res) => {
        toast.success('Trechos associados ao usuário com sucesso.');
        return {
          ...state,
          userAddRoutesLoading: false,
        };
      }),
      builder.addCase(fetchUserAddRoutes.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          userAddRoutesLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleUserInfoIsOpen,
  toggleUserCancelIsOpen,
  setUserCancelId,
  resetUserInfo,
  toggleUserRoute,
  toggleUserAllRoutes,
  toggleUserAllAreasAndRoutes,
  toggleUserOpen,
  toggleUserOpenAll,
} = userSlice.actions;
