// All user related database operations can be defined here.
import { api } from '..';
import {
  fetchOperationalDashboardUsersUrl,
  fetchOperationalDashboardAreasUrl,
  fetchOperationalDashboardNotificationsUrl,
  fetchOperationalDashboardSalesUrl,
} from './constants';
import {
  OperationalDashboardUsersResponse,
  OperationalDashboardAreasResponse,
  OperationalDashboardNotificationsResponse,
  OperationalDashboardSalesResponse,
} from './types';

export const fetchOperationalDashboardUsersService = async (params: {
  areas: string[];
}) => {
  return await api
    .get<OperationalDashboardUsersResponse>(fetchOperationalDashboardUsersUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchOperationalDashboardAreasService = async () => {
  return await api
    .get<OperationalDashboardAreasResponse>(fetchOperationalDashboardAreasUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchOperationalDashboardNotificationsService = async (params: {
  areas: string[];
  userId: string;
}) => {
  return await api
    .get<OperationalDashboardNotificationsResponse>(
      `${fetchOperationalDashboardNotificationsUrl}?notificationUserId=${params.userId}`,
      {
        params: {
          areas: params.areas,
        },
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchOperationalDashboardSalesService = async (params: {
  areas: string[];
  userId: string;
}) => {
  return await api
    .get<OperationalDashboardSalesResponse>(
      `${fetchOperationalDashboardSalesUrl}?saleUserId=${params.userId}`,
      {
        params: {
          areas: params.areas,
        },
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
