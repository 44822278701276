import {
  ListAlt,
  FilterAlt,
  CancelRounded,
  Save,
  Edit,
  Download,
} from '@mui/icons-material';
import {
  Button as MuiButton,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import { map } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VMasker from 'vanilla-masker';

import {
  PageWrapper,
  FiltersGrid,
  Input,
  DropdownInput,
  Button,
  Table,
  TableText,
  Accordion,
  Dropdown,
} from '../../components';

import { CREDENTIALS_CREATE, CREDENTIALS_UPDATE } from '../../routes/constants';
import { CredentialsListPageResponseType } from '../../services/Credentials/types';
import {
  useDispatch,
  useSelector,
  fetchCredentialsFilters,
  fetchCredentialsList,
  setCredentialsFiltersValues,
  resetCredentialsFiltersValues,
  setCredentialsListCurrentPage,
  setCredentialId,
  clearCredentials,
} from '../../stores';
import { palette } from '../../theme';

import useResponsiveMode from '../../utils/useResponsiveMode';
import { CredentialsTableToolbar } from './CredentialsTableToolbar';
import { CredentialsExportModal } from './modals';
import { StyledFiltersBtnsGrid } from './styles';

export const CredentialsManagement: React.FC = () => {
  const columns = [
    {
      field: 'number',
      headerName: 'Número',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: CredentialsListPageResponseType }) => (
        <TableText label={params.row.number.toString()} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'plate',
      headerName: 'Placa',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: CredentialsListPageResponseType }) => (
        <TableText label={params.row.plate} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'userName',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: CredentialsListPageResponseType }) => (
        <TableText label={params.row.userName} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'address',
      headerName: 'Logradouro',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: CredentialsListPageResponseType }) => (
        <TableText label={params.row.address} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'district',
      headerName: 'Bairro',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: CredentialsListPageResponseType }) => (
        <TableText label={params.row.district} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'routes',
      headerName: 'Trechos',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: CredentialsListPageResponseType }) => {
        const routesId = params?.row?.routes?.split('|') || [];

        const routesValues =
          filters &&
          filters.routes.filter((route) =>
            routesId?.includes(route.id.toString()),
          );

        const routesNames = routesValues
          ?.map((route) => route.value)
          .join(' | ');

        return <TableText label={routesNames} />;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'validity',
      headerName: 'Data de validade',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: CredentialsListPageResponseType }) => (
        <TableText label={moment(params.row.validity).format('DD/MM/YYYY')} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      editable: false,
      sortable: false,
      renderCell: (params: { row: CredentialsListPageResponseType }) => (
        <MuiButton
          variant='contained'
          startIcon={<Edit />}
          size='small'
          disabled={params.row.status === 0}
          onClick={() => handleEdit(params.row.id)}
          sx={{ paddingLeft: 1, paddingRight: 1 }}
        >
          Editar
        </MuiButton>
      ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive = [
    {
      field: 'number',
      headerName: 'Número',
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'plate',
      headerName: 'Placa',
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'userName',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'address',
      headerName: 'Logradouro',
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'district',
      headerName: 'Bairro',
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'routes',
      headerName: 'Trechos',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: CredentialsListPageResponseType }) => {
        const routesId = params?.row?.routes?.split('|') || [];

        const routesValues =
          filters &&
          filters.routes.filter((route) =>
            routesId?.includes(route.id.toString()),
          );

        const routesNames = routesValues
          ?.map((route) => route.value)
          .join(' | ');

        return <TableText label={routesNames} />;
      },
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'validity',
      headerName: 'Data de validade',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params: { row: CredentialsListPageResponseType }) => (
        <TableText label={moment(params.row.validity).format('DD/MM/YYYY')} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      editable: false,
      sortable: false,
      renderCell: (params: { row: CredentialsListPageResponseType }) => (
        <MuiButton
          variant='contained'
          size='small'
          disabled={params.row.status === 0}
          onClick={() => handleEdit(params.row.id)}
        >
          <Edit />
        </MuiButton>
      ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  const responsiveMode = useResponsiveMode();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isFilterAccordionExpanded, setIsFilterAccordionExpanded] =
    useState<boolean>(false);

  const { isLoading, filters } = useSelector(
    (state) => state.credentialsFiltersReducer,
  );

  const filtersValues = useSelector(
    (state) => state.credentialsFiltersValuesReducer,
  );

  const { isListLoading, size, currentPage, page } = useSelector(
    (state) => state.credentialsListSliceReducer,
  );

  useEffect(() => {
    dispatch(fetchCredentialsFilters());
  }, []);

  useEffect(() => {
    const params = {
      number: filtersValues.number,
      plate: filtersValues.plate,
      userName: filtersValues.name,
      userDocument: VMasker.toNumber(filtersValues.document),
      address: filtersValues.street,
      district: filtersValues.district,
      routeId: filtersValues.routesId,
      validity: filtersValues.validitiesId,
      status:
        filtersValues.status === 'Todos' ? undefined : filtersValues.status,
    };

    dispatch(fetchCredentialsList(params));
  }, []);

  const handleFilter = () => {
    dispatch(setCredentialsListCurrentPage(1));

    dispatch(
      fetchCredentialsList({
        number: filtersValues.number,
        plate: filtersValues.plate,
        userName: filtersValues.name,
        userDocument: VMasker.toNumber(filtersValues.document),
        address: filtersValues.street,
        district: filtersValues.district,
        routeId: filtersValues.routesId,
        validity: filtersValues.validitiesId,
        status: filtersValues.status === 'Todos' ? '' : filtersValues.status,
      }),
    );
  };

  const handleClearFilter = () => {
    dispatch(setCredentialsListCurrentPage(1));
    dispatch(resetCredentialsFiltersValues());
    dispatch(fetchCredentialsList({}));
  };

  const handleEdit = (id: number) => {
    dispatch(clearCredentials());
    dispatch(setCredentialId(id));
    navigate(CREDENTIALS_UPDATE);
  };

  return (
    <PageWrapper>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <ListAlt fontSize='large' sx={{ marginRight: 2 }} />
            Gerenciar credenciais
          </Typography>
        </Grid>
      </Grid>
      {responsiveMode !== 'phone' ? (
        <Grid container>
          <FiltersGrid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                type='text'
                margin='normal'
                label='Número'
                value={filtersValues.number?.toString()}
                onChange={(value) =>
                  dispatch(
                    setCredentialsFiltersValues({
                      ...filtersValues,
                      number: value,
                    }),
                  )
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                type='text'
                margin='normal'
                label='Placa'
                value={filtersValues.plate}
                onChange={(value) =>
                  dispatch(
                    setCredentialsFiltersValues({
                      ...filtersValues,
                      plate: value,
                    }),
                  )
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                type='text'
                margin='normal'
                label='Nome do credenciado'
                value={filtersValues.name}
                onChange={(value) =>
                  dispatch(
                    setCredentialsFiltersValues({
                      ...filtersValues,
                      name: value,
                    }),
                  )
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                type='text'
                margin='normal'
                label='CPF do credenciado'
                value={VMasker.toPattern(
                  filtersValues.document,
                  '999.999.999-99',
                )}
                onChange={(value) =>
                  dispatch(
                    setCredentialsFiltersValues({
                      ...filtersValues,
                      document: value,
                    }),
                  )
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                type='text'
                margin='normal'
                label='Logradouro'
                value={filtersValues.street}
                onChange={(value) =>
                  dispatch(
                    setCredentialsFiltersValues({
                      ...filtersValues,
                      street: value,
                    }),
                  )
                }
              />
            </Grid>
            <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
              <Input
                type='text'
                margin='normal'
                label='Bairro'
                value={filtersValues.district}
                onChange={(value) =>
                  dispatch(
                    setCredentialsFiltersValues({
                      ...filtersValues,
                      district: value,
                    }),
                  )
                }
              />
            </Grid>
            <Grid
              container
              xs={12}
              margin={1}
              justifyContent='center'
              alignItems='center'
            >
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Trechos'
                  options={filters && filters.routes}
                  value={filtersValues.routesId}
                  onChange={(value) =>
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        routesId: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Validade'
                  options={filters && filters.validities}
                  value={filtersValues.validitiesId}
                  onChange={(value) =>
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        validitiesId: value,
                      }),
                    )
                  }
                />
              </Grid>

              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Situação'
                  options={filters && filters.status}
                  value={filtersValues.status}
                  onChange={(value) => {
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        status: value,
                      }),
                    );
                  }}
                />
              </Grid>
              <StyledFiltersBtnsGrid>
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAlt />}
                  onClick={handleFilter}
                  disabled={isLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleClearFilter}
                  disabled={isLoading}
                >
                  <CancelRounded fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </Grid>
          </FiltersGrid>
        </Grid>
      ) : (
        <Accordion
          title='Filtros'
          expanded={isFilterAccordionExpanded}
          setExpanded={setIsFilterAccordionExpanded}
        >
          <Grid container>
            <FiltersGrid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Número'
                  value={
                    filtersValues.number ? filtersValues.number.toString() : ''
                  }
                  onChange={(value) =>
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        number: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Placa'
                  value={filtersValues.plate}
                  onChange={(value) =>
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        plate: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Nome do credenciado'
                  value={filtersValues.name}
                  onChange={(value) =>
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        name: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='CPF do credenciado'
                  value={VMasker.toPattern(
                    filtersValues.document,
                    '999.999.999-99',
                  )}
                  onChange={(value) =>
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        document: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Logradouro'
                  value={filtersValues.street}
                  onChange={(value) =>
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        street: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Input
                  type='text'
                  margin='normal'
                  label='Bairro'
                  value={filtersValues.district}
                  onChange={(value) =>
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        district: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Trechos'
                  options={filters && filters.routes}
                  value={filtersValues.routesId}
                  onChange={(value) =>
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        routesId: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Validade'
                  options={filters && filters.validities}
                  value={filtersValues.validitiesId}
                  onChange={(value) =>
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        validitiesId: value,
                      }),
                    )
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <Dropdown
                  label='Situação'
                  options={filters && filters.status}
                  value={filtersValues.status}
                  onChange={(value) => {
                    dispatch(
                      setCredentialsFiltersValues({
                        ...filtersValues,
                        status: value,
                      }),
                    );
                  }}
                />
              </Grid>
              <StyledFiltersBtnsGrid>
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAlt />}
                  onClick={handleFilter}
                  disabled={isLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleClearFilter}
                  disabled={isLoading}
                >
                  <CancelRounded fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </FiltersGrid>
          </Grid>
        </Accordion>
      )}
      <Grid container marginTop={5} marginBottom={5}>
        <Table
          loading={isListLoading}
          changePage={(page: number) =>
            dispatch(setCredentialsListCurrentPage(page))
          }
          page={currentPage}
          fetchRows={() => {
            dispatch(
              fetchCredentialsList({
                number: filtersValues.number,
                plate: filtersValues.plate,
                userName: filtersValues.name,
                userDocument: VMasker.toNumber(filtersValues.document),
                address: filtersValues.street,
                district: filtersValues.district,
                routeId: filtersValues.routesId,
                validity: filtersValues.validitiesId,
                status:
                  filtersValues.status === 'Todos' ? '' : filtersValues.status,
              }),
            );
          }}
          rowCount={size}
          rows={page}
          columns={
            responsiveMode === 'phone' || responsiveMode === 'tablet'
              ? columnsResponsive
              : columns
          }
          toolbar={<CredentialsTableToolbar />}
        />
      </Grid>
      <CredentialsExportModal />
    </PageWrapper>
  );
};
