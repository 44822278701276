import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { findIndex, map, sortBy, split } from 'lodash';
import {
  fetchOperationalDashboardUsersService,
  fetchOperationalDashboardAreasService,
  fetchOperationalDashboardNotificationsService,
  fetchOperationalDashboardSalesService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { handleMoney } from '../../utils/Helpers';
import { OperationalDashboardStateType } from './types';

const initialState: OperationalDashboardStateType = {
  operationalDashboardAreas: [],
  operationalDashboardFilteredAreas: [],
  operationalDashboardUsersList: [],
  operationalDashboardUsersFullList: [],
  operationalDashboardUsersPage: 1,
  operationalDashboardLoading: false,
  operationalDashboardRequestLoading: false,
  operationalDashboardUsersLoading: false,
  operationalDashboardAreasLoading: false,
  operationalDashboardReloadResources: true,
};

export const fetchOperationalDashboardUsers = createAsyncThunk(
  'operationalDashboardUsers',
  async (params: { areas: string[] }) => {
    return await fetchOperationalDashboardUsersService(params);
  },
);

export const fetchOperationalDashboardAreas = createAsyncThunk(
  'operationalDashboardAreas',
  async () => {
    return await fetchOperationalDashboardAreasService();
  },
);

export const fetchOperationalDashboardNotifications = createAsyncThunk(
  'operationalDashboardNotifications',
  async (params: { areas: string[]; userId: string }) => {
    const response = await fetchOperationalDashboardNotificationsService(
      params,
    );
    return {
      ...response,
      userId: params.userId,
    };
  },
);

export const fetchOperationalDashboardSales = createAsyncThunk(
  'operationalDashboardSales',
  async (params: { areas: string[]; userId: string }) => {
    const response = await fetchOperationalDashboardSalesService(params);
    return {
      ...response,
      userId: params.userId,
    };
  },
);

export const operationalDashboardSlice = createSlice({
  name: 'operationalDashboard',
  initialState,
  reducers: {
    setOperationalDashboardUsersPage(state, action: PayloadAction<number>) {
      state.operationalDashboardUsersPage = action.payload;
    },
    setOperationalDashboardReloadResources(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.operationalDashboardReloadResources = action.payload;
    },
    setOperationalDashboardFilteredAreas(
      state,
      action: PayloadAction<{ id: string; value: string }[]>,
    ) {
      state.operationalDashboardFilteredAreas = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOperationalDashboardUsers.pending, (state) => {
      return {
        ...state,
        operationalDashboardUsersLoading: true,
      };
    }),
      builder.addCase(
        fetchOperationalDashboardUsers.fulfilled,
        (state, res) => {
          const handledUsers = sortBy(
            map(res.payload.data, (item) => {
              // map(simulation, (item) => {
              return {
                id: item.id.toString(),
                name: item.name,
              };
            }),
            ['name'],
          );

          const handledUsersFull = sortBy(
            map(handledUsers, (item) => {
              return {
                id: item.id.toString(),
                name: item.name,
                notificationsToday: undefined,
                notificationsLastWeek: undefined,
                notificationsDiffValue: undefined,
                salesToday: undefined,
                salesLastWeek: undefined,
                salesDiffValue: undefined,
              };
            }),
            ['name'],
          );

          return {
            ...state,
            operationalDashboardUsersList: handledUsers,
            operationalDashboardUsersFullList: handledUsersFull,
            operationalDashboardUsersLoading: false,
            operationalDashboardReloadResources: true,
          };
        },
      ),
      builder.addCase(fetchOperationalDashboardUsers.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          operationalDashboardUsersLoading: false,
        };
      });

    builder.addCase(fetchOperationalDashboardAreas.pending, (state) => {
      return {
        ...state,
        operationalDashboardAreasLoading: true,
      };
    }),
      builder.addCase(
        fetchOperationalDashboardAreas.fulfilled,
        (state, res) => {
          const handledAreas: { id: string; value: string }[] = [];
          map(res.payload.data.areas, (item) => {
            if (item.id !== 0) {
              handledAreas.push({
                id: item.id.toString(),
                value: item.value,
              });
            }
          });

          return {
            ...state,
            operationalDashboardAreas: handledAreas,
            operationalDashboardAreasLoading: false,
          };
        },
      ),
      builder.addCase(fetchOperationalDashboardAreas.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          operationalDashboardAreasLoading: false,
        };
      });

    builder.addCase(fetchOperationalDashboardNotifications.pending, (state) => {
      return {
        ...state,
        operationalDashboardNotificationsLoading: true,
      };
    }),
      builder.addCase(
        fetchOperationalDashboardNotifications.fulfilled,
        (state, res) => {
          const newUsers = [...state.operationalDashboardUsersFullList];
          const idx = findIndex(newUsers, (item) => {
            return item.id == res.payload.userId;
          });

          newUsers[idx] = {
            ...newUsers[idx],
            notificationsToday: res.payload.data.today.toString(),
            notificationsLastWeek: res.payload.data.lastWeek.toString(),
            notificationsDiffValue: (
              res.payload.data.today - res.payload.data.lastWeek
            ).toString(),
          };
          return {
            ...state,
            operationalDashboardUsersFullList: newUsers,
            operationalDashboardNotificationsLoading: false,
          };
        },
      ),
      builder.addCase(
        fetchOperationalDashboardNotifications.rejected,
        (state, res) => {
          // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
          return {
            ...state,
            operationalDashboardNotificationsLoading: false,
          };
        },
      );

    builder.addCase(fetchOperationalDashboardSales.pending, (state) => {
      return {
        ...state,
        operationalDashboardSalesLoading: true,
      };
    }),
      builder.addCase(
        fetchOperationalDashboardSales.fulfilled,
        (state, res) => {
          const newUsers = [...state.operationalDashboardUsersFullList];
          const idx = findIndex(newUsers, (item) => {
            return item.id == res.payload.userId;
          });

          newUsers[idx] = {
            ...newUsers[idx],
            salesToday: handleMoney(res.payload.data.today),
            salesLastWeek: handleMoney(res.payload.data.lastWeek),
            salesDiffValue: (
              res.payload.data.today - res.payload.data.lastWeek
            ).toString(),
          };

          return {
            ...state,
            operationalDashboardUsersFullList: newUsers,
            operationalDashboardSalesLoading: false,
          };
        },
      ),
      builder.addCase(fetchOperationalDashboardSales.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          operationalDashboardSalesLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setOperationalDashboardUsersPage,
  setOperationalDashboardReloadResources,
} = operationalDashboardSlice.actions;
