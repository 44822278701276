import InfoIcon from '@mui/icons-material/Info';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Button as MuiButton } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Button, Table, TableText } from '../../../components';
import {
  fetchSessionsList,
  useDispatch,
  useSelector,
  fetchSessionInfo,
  sessionSlice,
  sessionsListSlice,
} from '../../../stores';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { HistoryTableToolbar } from './HistoryTableToolbar';

export const HistoryTable: React.FC = () => {
  const dispatch = useDispatch();
  const { sessionsList, sessionsListLoading } = useSelector(
    (state) => state.sessionsListReducer,
  );
  const filtersValues = useSelector(
    (state) => state.sessionsFiltersValuesReducer,
  );
  const { sessionsPage } = useSelector((state) => state.sessionsListReducer);
  const responsiveMode = useResponsiveMode();
  const [rows, setRows] = useState<any[]>([]);

  const { userName, startDateTime, finalDateTime, situation } = filtersValues;

  useEffect(() => {
    if (sessionsList && sessionsList !== undefined) {
      setRows(sessionsList.page);
    } else setRows([]);
  }, [sessionsList]);

  const handleInfo = (sessionId: GridRowId) => {
    dispatch(fetchSessionInfo({ sessionId: sessionId.toString() }));
    dispatch(sessionSlice.actions.toggleSessionInfoIsOpen());
  };

  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => (
        <TableText label={params.row.userName} size={50} />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'userLogin',
      headerName: 'Login',
      editable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => <TableText label={params.row.userLogin} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'deviceId',
      headerName: 'Máquina POS',
      editable: false,
      sortable: false,
      width: 115,
      renderCell: (params) => <TableText label={params.row.deviceId} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'startDateTime',
      headerName: 'Entrada',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => <TableText label={params.row.startDateTime} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'finalDateTime',
      headerName: 'Saída',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => <TableText label={params.row.finalDateTime} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return responsiveMode === 'large-screen' ? (
          <Button
            variant='contained'
            label='Detalhar'
            startIcon={<InfoIcon />}
            size='small'
            onClick={() => handleInfo(params.id)}
            sx={{ paddingLeft: 1, paddingRight: 1 }}
          />
        ) : (
          <MuiButton
            variant='contained'
            size='small'
            onClick={() => handleInfo(params.id)}
            // sx={{
            //   minWidth: 0,
            // }}
          >
            <InfoTwoToneIcon />
          </MuiButton>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 3,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'startDateTime',
      headerName: 'Entrada',
      // type: 'date',
      editable: false,
      sortable: false,
      width: 100,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'finalDateTime',
      headerName: 'Saída',
      // type: 'date',
      editable: false,
      sortable: false,
      width: 100,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: '',
      // description: 'This column has a value getter and is not sortable.',
      width: 50,
      sortable: false,
      renderCell: (params) => (
        <MuiButton
          variant='contained'
          size='small'
          onClick={() => handleInfo(params.id)}
          sx={{
            minWidth: 0,
          }}
        >
          <InfoTwoToneIcon />
        </MuiButton>
      ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  return (
    <Table
      page={sessionsPage}
      changePage={(value: number) =>
        sessionsListSlice.actions.setSessionsPage(value)
      }
      rows={rows}
      rowCount={sessionsList ? sessionsList.size : 0}
      fetchRows={() => {
        dispatch(
          fetchSessionsList({
            userName: userName,
            startDateTime: startDateTime,
            finalDateTime: finalDateTime,
            situation: situation,
          }),
        );
      }}
      columns={
        responsiveMode === 'phone' || responsiveMode === 'tablet'
          ? columnsResponsive
          : columns
      }
      toolbar={
        responsiveMode === 'phone' ||
        responsiveMode === 'tablet' ? undefined : (
          <HistoryTableToolbar />
        )
      }
      loading={sessionsListLoading}
    />
  );
};
