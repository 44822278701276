import { Tooltip, Typography } from '@mui/material';
import React from 'react';

export const TableText = (props: { label: string; size?: number }) => {
  const maxSize = props.size || 30;

  return props.label?.length >= maxSize ? (
    <Tooltip title={props.label}>
      <Typography
        id='modal-modal-description'
        sx={{
          fontSize: '13px',
        }}
        noWrap
      >
        {props.label}
      </Typography>
    </Tooltip>
  ) : (
    <Typography
      id='modal-modal-description'
      sx={{
        fontSize: '13px',
      }}
    >
      {props.label}
    </Typography>
  );
};
