/* eslint-disable pii/no-phone-number */
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  PageWrapper,
  Form,
  FiltersGrid,
  DropdownMultiInput,
} from '../../components';
import {
  authSlice,
  fetchAuthAreas,
  fetchAuthUpdateProfile,
  useDispatch,
  useSelector,
} from '../../stores';
import { palette } from '../../theme';
import { handleErrors } from '../../utils/Helpers';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { PasswordChangeModal } from './PasswordChangeModal';
import { StyledBtnsGrid } from './styles';
import { editSchema } from './validations';

export const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authReducer);
  const responsiveMode = useResponsiveMode();
  const [formData, setFormData] = useState({
    name: authData.name,
    email: authData.email,
    login: authData.login,
    profileDescription: authData.profileDescription,
    operatingAreas: authData.operatingAreas,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    dispatch(fetchAuthAreas());
  }, []);

  const handleSubmit = () => {
    setErrors({});
    editSchema
      .validate(formData, { abortEarly: false })
      .then(() =>
        dispatch(
          fetchAuthUpdateProfile({
            userId: authData.id.toString(),
            email: formData.email,
            name: formData.name,
          }),
        ),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h6'}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            {/* <CalendarMonthOutlinedIcon
              fontSize='large'
              sx={{ marginRight: 2 }}
            /> */}
            <PersonIcon fontSize='large' sx={{ marginRight: 2 }} />
            Perfil
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Form onSubmit={handleSubmit}>
          <FiltersGrid loading={authData.authLoading} loadingRows={4}>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              paddingLeft={1}
              paddingRight={1}
            >
              <Input
                data-testid='name'
                type='text'
                margin='normal'
                label='Nome'
                value={formData.name}
                onChange={(value) => setFormData({ ...formData, name: value })}
                errorMessage={errors && errors.name}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              paddingLeft={1}
              paddingRight={1}
            >
              <Input
                data-testid='email'
                type='text'
                margin='normal'
                label='Email'
                value={formData.email}
                onChange={(value) => setFormData({ ...formData, email: value })}
                errorMessage={errors && errors.email}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              paddingLeft={1}
              paddingRight={1}
            >
              <Input
                data-testid='login'
                type='text'
                margin='normal'
                label='Login'
                disabled
                value={formData.login}
                onChange={(value) => setFormData({ ...formData, login: value })}
              />
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              paddingLeft={1}
              paddingRight={1}
            >
              <Input
                data-testid='profile'
                type='text'
                margin='normal'
                label='Perfil'
                disabled
                value={formData.profileDescription}
                onChange={(value) =>
                  setFormData({ ...formData, profileDescription: value })
                }
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              paddingLeft={1}
              paddingRight={1}
            >
              <DropdownMultiInput
                disabled
                readOnly
                options={authData.areas}
                defaultValue={authData.operatingAreas}
                label='Áreas de atuação'
                value={formData.operatingAreas}
                onChange={(value) =>
                  setFormData({ ...formData, operatingAreas: value })
                }
              />
            </Grid>

            <StyledBtnsGrid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                marginTop: responsiveMode === 'phone' ? 2 : 0,
                marginBottom: responsiveMode === 'phone' ? 1 : 2,
              }}
            >
              <Button
                sx={{ width: '100%' }}
                color='secondary'
                variant='contained'
                label='Alterar senha'
                startIcon={<EditIcon />}
                onClick={() =>
                  dispatch(authSlice.actions.togglePasswordChangeIsOpen())
                }
              />
            </StyledBtnsGrid>
            <StyledBtnsGrid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ marginBottom: responsiveMode === 'phone' ? 3 : 2 }}
            >
              <Button
                sx={{ width: '100%' }}
                variant='contained'
                label='Salvar'
                loading={authData.authUpdateProfileLoading}
                startIcon={<SaveIcon />}
                onClick={handleSubmit}
              />
            </StyledBtnsGrid>
          </FiltersGrid>
        </Form>
      </Grid>
      <PasswordChangeModal />
    </PageWrapper>
  );
};
