import { TextField, InputAdornment, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import VMasker from 'vanilla-masker';

import { fetchAddress } from '../../services/PostalCode';
import { FetchAddressResponse } from '../../services/PostalCode/types';

type CEPProps = {
  id: string;
  name: string;
  label?: string;
  placeholder?: string;
  value: string;
  error?: boolean;
  helperText?: string;
  margin?: 'none' | 'normal' | 'dense';
  onChange: (postalCode: FetchAddressResponse) => void;
};

export const CEP: React.FC<CEPProps> = (props) => {
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    const onlyNumbersCEP: string = VMasker.toNumber(value);

    if (onlyNumbersCEP.length == 8) {
      setIsLoading(true);

      (async () => {
        const address = await fetchAddress(onlyNumbersCEP);

        setIsLoading(false);

        if (address.erro) {
          toast.error('O CEP digitado é inválido');
          return props.onChange(address);
        }

        props.onChange(address);
      })();
    }
  }, [value]);

  return (
    <TextField
      fullWidth
      color='primary'
      variant='filled'
      size='small'
      type='text'
      id={props.id}
      name={props.name}
      margin={props.margin === undefined ? 'normal' : props.margin}
      placeholder={props.placeholder || '00000-000'}
      label={props.label || 'CEP'}
      value={VMasker.toPattern(value, '99999-999')}
      error={props.error}
      helperText={props.helperText}
      onChange={(event) => setValue(event.target.value)}
      InputProps={{
        startAdornment: isLoading && (
          <InputAdornment position='start'>
            <CircularProgress color='primary' size={24} />
          </InputAdornment>
        ),
      }}
    />
  );
};
