import {
  Box,
  PaletteColor,
  Skeleton,
  TypeText,
  Typography,
} from '@mui/material';
import { map } from 'lodash';
import React from 'react';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { StyledWrapperBox } from './styles';

interface StackedBarProps {
  loading: boolean;
  total: number;
  data: {
    value: number;
    description: string;
    color: PaletteColor | TypeText;
  }[];
}

export const StackedBar = (props: StackedBarProps) => {
  const responsiveMode = useResponsiveMode();

  const handleWidth = (value: number) => {
    return Math.floor((value * 100) / props.total) + 1;
  };

  return props.loading ? (
    <StyledWrapperBox>
      <Skeleton animation='wave' height={38} width={'100%'} />
    </StyledWrapperBox>
  ) : props.data.length === 0 ? (
    <StyledWrapperBox>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          backgroundColor: palette.background.main,
          padding: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          color={palette.background.contrastText}
          sx={{ fontSize: responsiveMode === 'phone' ? 12 : 14 }}
        >
          Nenhuma notificação
        </Typography>
      </Box>
    </StyledWrapperBox>
  ) : (
    <StyledWrapperBox>
      {map(props.data, (item, index) => {
        return (
          <Box
            key={item.value + index}
            sx={{
              height: '100%',
              width: `${handleWidth(item.value)}%`,
              backgroundColor: item.color.main,
              padding: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              noWrap
              color={item.color.contrastText}
              sx={{
                fontSize: responsiveMode === 'phone' ? 10 : 14,
              }}
            >
              {item.description}
            </Typography>
          </Box>
        );
      })}
    </StyledWrapperBox>
  );
};
