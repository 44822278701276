import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { fetchNotificationsFiltersService } from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationsFiltersStateType } from './types';

const initialState: NotificationsFiltersStateType = {
  filters: null,
  filtersLoading: false,
};

export const fetchNotificationsFilters = createAsyncThunk(
  'notificationsFilters',
  async () => {
    return await fetchNotificationsFiltersService();
  },
);

export const notificationsFiltersSlice = createSlice({
  name: 'notificationsFilters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationsFilters.pending, (state) => {
      return {
        ...state,
        filtersLoading: true,
      };
    }),
      builder.addCase(fetchNotificationsFilters.fulfilled, (state, res) => {
        const { serviceOrderOrigins, situations, areas, routes, vehicleTypes } =
          res.payload.data;

        let handledServiceOrderOrigins = {};
        map(serviceOrderOrigins, (item) => {
          handledServiceOrderOrigins = {
            ...handledServiceOrderOrigins,
            [item.id]: item.value,
          };
        });
        let handledSituations = {};
        map(situations, (item) => {
          item.id === ''
            ? (handledSituations = {
                ...handledSituations,
                '0': item.value,
              })
            : (handledSituations = {
                ...handledSituations,
                [item.id]: item.value,
              });
        });
        let handledVehicleTypes = {};
        map(vehicleTypes, (item) => {
          if (item.id === '') {
            handledVehicleTypes = {
              ...handledVehicleTypes,
              Todos: item.value,
            };
          } else {
            handledVehicleTypes = {
              ...handledVehicleTypes,
              [item.id]: item.value,
            };
          }
        });

        const filters = {
          serviceOrderOrigins: handledServiceOrderOrigins,
          situations: handledSituations,
          areas: areas,
          routes: routes,
          vehicleTypes: handledVehicleTypes,
        };

        return {
          ...state,
          filters: filters,
          filtersLoading: false,
        };
      }),
      builder.addCase(fetchNotificationsFilters.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          filtersLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = notificationsFiltersSlice.actions;
