import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchActionsHistoryViewService } from '../../services/Actions';

export type ActionsStateType = {
  isActionsLoading: boolean
  id: number
  userName: string
  userLogin: string
  userProfile: string
  actionName: string
  permissionName: string
  observation: string
  dateTime: string
}

const initialState: ActionsStateType = {
  isActionsLoading: false,
  id: 0,
  userName: '',
  userLogin: '',
  userProfile: '',
  actionName: '',
  permissionName: '',
  observation: '',
  dateTime: ''
}

export const fetchActionsHistoryView = createAsyncThunk(
  'actionsHistoryView',
  async (params: {
    id: number
  }) => {
    return await fetchActionsHistoryViewService(params);
  }
);

export const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    clearActions: () => {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchActionsHistoryView.pending, state => {
      return {
        ...state,
        isActionsLoading: true
      }
    });
    builder.addCase(fetchActionsHistoryView.rejected, state => {
      return {
        ...state,
        isActionsLoading: false
      }
    });
    builder.addCase(fetchActionsHistoryView.fulfilled, (state, res) => {
      return {
        ...state,
        ...res.payload,
        isActionsLoading: false
      }
    });
  }
});

export const { clearActions } = actionsSlice.actions;