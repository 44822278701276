import { useWorker } from '@koale/useworker';
import { Box } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { Button, Dropdown, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  salesListSlice,
  fetchSalesExportOperationalHtml,
} from '../../../stores';
import {
  exportSalesOperationalToHTML,
  handleApiDataSalesOperationalToHTMLPartOne,
  handleApiDataSalesOperationalToHTMLPartTwo,
  handleApiDataSalesOperationalToHTMLPartThree,
  handleApiDataSalesOperationalToHTMLPartFour,
  handleApiDataSalesOperationalToHTMLPartFive,
} from '../table/exports';

export const SaleOperationalExportModal: React.FC = () => {
  const dispatch = useDispatch();
  const { salesOperationalExportIsOpen, salesExportOperationalHtmlLoading } =
    useSelector((state) => state.salesListReducer);
  const salesFiltersValues = useSelector(
    (state) => state.salesFiltersValuesReducer,
  );
  const [period, setPeriod] = useState('1');
  const [exportSalesOperationalToHTMLPartOneWorker] = useWorker(
    handleApiDataSalesOperationalToHTMLPartOne,
  );
  const [exportSalesOperationalToHTMLPartTwoWorker] = useWorker(
    handleApiDataSalesOperationalToHTMLPartTwo,
  );
  const [exportSalesOperationalToHTMLPartThreeWorker] = useWorker(
    handleApiDataSalesOperationalToHTMLPartThree,
  );
  const [exportSalesOperationalToHTMLPartFourWorker] = useWorker(
    handleApiDataSalesOperationalToHTMLPartFour,
  );
  const [exportSalesOperationalToHTMLPartFiveWorker] = useWorker(
    handleApiDataSalesOperationalToHTMLPartFive,
  );
  const periodOptions = {
    '1': '1 hora',
    '2': '2 horas',
    '3': '3 horas',
    '4': '4 horas',
    '5': '5 horas',
    '6': '6 horas',
    '7': '7 horas',
    '8': '8 horas',
    '9': '9 horas',
    '10': '10 horas',
    '11': '11 horas',
    '12': '12 horas',
  };

  const handleClose = () => {
    setPeriod('1');
    dispatch(salesListSlice.actions.toggleSalesOperationalExportIsOpen());
    dispatch(salesListSlice.actions.setSalesOperationalExportPeriod('1'));
  };

  const handleSubmit = () => {
    dispatch(salesListSlice.actions.setSalesOperationalExportPeriod(period));
    dispatch(fetchSalesExportOperationalHtml(salesFiltersValues)).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          exportSalesOperationalToHTML(
            res.payload.data,
            res.payload.filters,
            res.payload.period,
            dispatch,
            exportSalesOperationalToHTMLPartOneWorker,
            exportSalesOperationalToHTMLPartTwoWorker,
            exportSalesOperationalToHTMLPartThreeWorker,
            exportSalesOperationalToHTMLPartFourWorker,
            exportSalesOperationalToHTMLPartFiveWorker,
          ).then(() => {
            dispatch(salesListSlice.actions.toggleExportModalIsOpen());
            handleClose();
          });
        } else {
          dispatch(salesListSlice.actions.toggleExportModalIsOpen());
          handleClose();
        }
      },
    );
  };

  return (
    <Modal
      open={salesOperationalExportIsOpen}
      onClose={handleClose}
      title='Apoio operacional'
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Dropdown
          label='Selecione o período'
          value={period}
          options={periodOptions}
          onChange={(value) => setPeriod(value)}
          // errorMessage={errors && errors.paymentMethod}
        />
        <Button
          variant='contained'
          label='Enviar'
          type='submit'
          style={{ width: '100%', margin: 0, marginTop: 30 }}
          loading={salesExportOperationalHtmlLoading}
          onClick={handleSubmit}
        />
      </Box>
    </Modal>
  );
};
