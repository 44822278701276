import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import keys from 'lodash/keys';
import map from 'lodash/map';
import React, { useState } from 'react';

export const Dropdown = (props: {
  label: string;
  options: any;
  value: string;
  onChange: (e: any) => void;
  errorMessage?: string;
}) => {
  const { options, onChange, label, ...otherProps } = props;

  return (
    <div style={{ margin: 5 }}>
      <FormControl variant='filled' fullWidth size='small' margin='normal'>
        <InputLabel>{label}</InputLabel>
        <Select
          {...otherProps}
          onChange={(newValue) => onChange(newValue.target.value)}
        >
          {map(keys(options), (item, pos) => {
            return (
              <MenuItem key={pos} value={item} disabled={item === '1000'}>
                {options[item]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
