import { styled, Paper } from '@mui/material';
import { palette } from '../../../theme';

export const StyledPaper = styled(Paper)(() => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  height: 40,
  borderRadius: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: palette.text.main,
}));
