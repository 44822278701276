import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { fetchSalesFiltersService } from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { SalesFiltersStateType } from './types';

const initialState: SalesFiltersStateType = {
  filters: null,
  filtersLoading: false,
};

export const fetchSalesFilters = createAsyncThunk('salesFilters', async () => {
  return await fetchSalesFiltersService();
});

export const salesFiltersSlice = createSlice({
  name: 'salesFilters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSalesFilters.pending, (state) => {
      return {
        ...state,
        filtersLoading: true,
      };
    }),
      builder.addCase(fetchSalesFilters.fulfilled, (state, res) => {
        const {
          serviceOrderOrigins,
          paymentMethods,
          posMachines,
          profiles,
          areas,
          routes,
          users,
          drivers,
        } = res.payload.data;
        let handledServiceOrderOrigins = {};
        map(serviceOrderOrigins, (item) => {
          handledServiceOrderOrigins = {
            ...handledServiceOrderOrigins,
            [item.id]: item.value,
          };
        });
        let handledPaymentMethods = {};
        map(paymentMethods, (item) => {
          if (item.id === '') {
            handledPaymentMethods = {
              ...handledPaymentMethods,
              Todos: item.value,
            };
          } else {
            handledPaymentMethods = {
              ...handledPaymentMethods,
              [item.id]: item.value,
            };
          }
        });
        let handledPosMachines = {};
        map(posMachines, (item) => {
          handledPosMachines = {
            ...handledPosMachines,
            [item.id]: item.value,
          };
        });
        let handledProfiles = {};
        map(profiles, (item) => {
          handledProfiles = {
            ...handledProfiles,
            [item.id]: item.value,
          };
        });

        const filters = {
          serviceOrderOrigins: handledServiceOrderOrigins,
          paymentMethods: handledPaymentMethods,
          posMachines: handledPosMachines,
          profiles: handledProfiles,
          areas: areas,
          routes: routes,
          users: users,
          drivers: drivers,
        };

        return {
          ...state,
          filters: filters,
          filtersLoading: false,
        };
      }),
      builder.addCase(fetchSalesFilters.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          filtersLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = salesFiltersSlice.actions;
