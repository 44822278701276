import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { fetchSessionsFiltersService } from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { SessionsFiltersStateType } from './types';

const initialState: SessionsFiltersStateType = {
  filters: null,
  filtersLoading: false,
};

export const fetchSessionsFilters = createAsyncThunk(
  'sessionsFilters',
  async () => {
    return await fetchSessionsFiltersService();
  },
);

export const sessionsFiltersSlice = createSlice({
  name: 'sessionsFilters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSessionsFilters.pending, (state) => {
      return {
        ...state,
        filtersLoading: true,
      };
    }),
      builder.addCase(fetchSessionsFilters.fulfilled, (state, res) => {
        const situations = res.payload.data.situations;

        let handledSituations = {};
        map(situations, (item) => {
          handledSituations = { ...handledSituations, [item.id]: item.value };
        });

        const filters = {
          situations: handledSituations,
        };

        return {
          ...state,
          filters: filters,
          filtersLoading: false,
        };
      }),
      builder.addCase(fetchSessionsFilters.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          filtersLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = sessionsFiltersSlice.actions;
