import { AxiosError, AxiosRequestConfig } from 'axios';
import * as SecureStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import { api } from '.';
import { authSlice, Store } from '../stores';
import { fetchLoginUrl } from './Auth/constants';

const OnRequestSuccess = async (config: AxiosRequestConfig) => {
  if (config.url !== fetchLoginUrl) {
    const accessToken = SecureStorage.default.getItem('token');
    const userId = SecureStorage.default.getItem('id');

    if (!config.headers) config.headers = {};
    config.headers.Authorization = accessToken ? `${accessToken}` : '';
    config.url = `${config.baseURL}/${userId}${config.url}`;
  }

  return config;
};

const OnResponseFail = async (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 401) {
      SecureStorage.default.removeItem('login');
      SecureStorage.default.removeItem('token');
      SecureStorage.default.removeItem('id');
      window.dispatchEvent(new Event('storage'));
      Store.dispatch(authSlice.actions.logout());
      // toast.error(error.response.data.msg);
    } else if (error.response.status === 404) {
      toast.error('Requisição não encontrada.');
    } else if (
      error.response.status === 400 ||
      error.response.status === 403 ||
      error.response.data.msg
    ) {
      if (
        error.config.url?.match(/\/credentials\/licensed\/.*/) &&
        error.response.data.msg === 'Nenhuma credencial com esse CPF.'
      ) {
        toast.warning(error.response.data.msg);
      } else {
        toast.error(error.response.data.msg);
      }
    } else if (error.response.status === 524) {
      toast.error('Tempo de requisição excedido (524).');
    } else {
      toast.error('Ocorreu um erro. Tente novamente mais tarde.');
    }
  }

  return Promise.reject(error);
  // throw error;
};

export const setupInterceptors = () => {
  api.interceptors.request.use(OnRequestSuccess, undefined);
  api.interceptors.response.use(undefined, OnResponseFail);
};
