import { includes, map, replace, split } from 'lodash';
import Maskara from 'maskara';
import moment from 'moment';
import { ValidationError } from 'yup';

export const handleErrors = (errors: ValidationError | undefined) => {
  let errorMessages = {};
  if (errors) {
    map(errors.inner, (item) => {
      if (item.path) {
        errorMessages = { ...errorMessages, [item.path]: item.message };
      }
    });
  }
  return errorMessages;
};

export const handleDateTime = (dateTime: string | undefined) => {
  if (dateTime === undefined) return '--';
  const formattedDateTime = moment(dateTime)
    .format('DD/MM/YYYY HH:mm:ss')
    .toString();
  return formattedDateTime === '01/01/0001 12:00:00' ? '--' : formattedDateTime;
};

export const handleMapLink = (
  latitude: string | undefined,
  longitude: string | undefined,
) => {
  if (latitude === undefined || longitude === undefined) return '';
  return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
};

export const handleMoney = (value: number | undefined) => {
  if (value === undefined) return 'R$0.00';
  return `R$${replace(value.toFixed(2).toString(), '.', ',')}`;
};

export const handlePhone = (phone: string | undefined) => {
  if (phone === undefined) return '--';
  return Maskara.apply(phone, '(00) 00000-0000');
};

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const handleDateTimeDiff = (start: string, end: string) => {
  const diff = moment.utc(end).diff(moment.utc(start), 'seconds');
  const hours = Math.floor(diff / 3600).toString();
  const minutes = Math.floor((diff % 3600) / 60).toString();
  const seconds = Math.round(
    ((diff % 3600) / 60 - Math.floor((diff % 3600) / 60)) * 60,
  ).toString();
  return `${hours.length < 2 ? ('0' + hours).slice(-2) : hours}:${
    minutes.length < 2 ? ('0' + minutes).slice(-2) : minutes
  }:${seconds.length < 2 ? ('0' + seconds).slice(-2) : seconds}`;
};

export const createSalesExportTxtLine = (
  document: {
    field: string;
    start: number;
    size: number;
    type: string;
    mandatory: string;
  }[],
  sale: {
    paymentMethod: string;
    registerDate: string;
    sumPrice: number;
    areaId: number;
    areaName: string;
    areaColor: string;
    areaClientNumber: string;
    areaTypeRecipe: string;
    areaCostCenter: string;
  },
) => {
  let txt = '';
  map(document, (item) => {
    if (item.mandatory === 'S') {
      switch (item.field) {
        // Campos fixos do cabecalho
        case 'TipoD': {
          txt = txt + handleNormalizeTxtFieldForReport(item, 'D');
          break;
        }
        case 'NrCli': {
          txt =
            txt + handleNormalizeTxtFieldForReport(item, sale.areaClientNumber);
          break;
        }
        case 'Documento': {
          switch (sale.paymentMethod) {
            case 'CREDITO': {
              txt =
                txt +
                handleNormalizeTxtFieldForReport(
                  item,
                  moment(sale.registerDate).format('DDMMyyyy').toString() +
                    'CC',
                );
              break;
            }
            case 'DEBITO': {
              txt =
                txt +
                handleNormalizeTxtFieldForReport(
                  item,
                  moment(sale.registerDate).format('DDMMyyyy').toString() +
                    'CD',
                );
              break;
            }
            case 'PIX': {
              txt =
                txt +
                handleNormalizeTxtFieldForReport(
                  item,
                  moment(sale.registerDate).format('DDMMyyyy').toString() +
                    'PX',
                );
              break;
            }
            default: {
              txt =
                txt +
                handleNormalizeTxtFieldForReport(
                  item,
                  moment(sale.registerDate).format('DDMMyyyy').toString(),
                );
              break;
            }
          }
          break;
        }
        case 'Parcela': {
          txt = txt + handleNormalizeTxtFieldForReport(item, '1');
          break;
        }
        case 'CodTpDoc': {
          txt = txt + handleNormalizeTxtFieldForReport(item, 'CTL');
          break;
        }
        case 'Emissao': {
          txt =
            txt +
            handleNormalizeTxtFieldForReport(
              item,
              moment(sale.registerDate).format('DD/MM/yyyy'),
            );
          break;
        }
        case 'Saida': {
          txt =
            txt +
            handleNormalizeTxtFieldForReport(
              item,
              moment(sale.registerDate).format('DD/MM/yyyy'),
            );
          break;
        }
        case 'Vencimento': {
          txt =
            txt +
            handleNormalizeTxtFieldForReport(
              item,
              moment(sale.registerDate).format('DD/MM/yyyy'),
            );
          break;
        }
        case 'Quitado': {
          txt = txt + handleNormalizeTxtFieldForReport(item, 'N');
          break;
        }
        case 'Status': {
          txt = txt + handleNormalizeTxtFieldForReport(item, 'N');
          break;
        }
        case 'Serie': {
          txt = txt + handleNormalizeTxtFieldForReport(item, '0');
          break;
        }
        default:
          break;
      }

      switch (item.field) {
        // Campos fixos do corpo
        case 'TipoI': {
          txt = txt + handleNormalizeTxtFieldForReport(item, 'I');
          break;
        }
        case 'Item': {
          txt = txt + handleNormalizeTxtFieldForReport(item, '1');
          break;
        }
        case 'TipoReceita': {
          txt =
            txt + handleNormalizeTxtFieldForReport(item, sale.areaTypeRecipe);
          break;
        }
        // HERE-------
        case 'Valor': {
          // Convertendo para número separado por ponto
          txt =
            txt +
            handleNormalizeTxtFieldForReport(
              item,
              sale.sumPrice.toString(),
              // sale.sumPrice.toString(CultureInfo.InvariantCulture),
            );
          break;
        }
        // ------------
        case 'CodCusto': {
          txt =
            txt + handleNormalizeTxtFieldForReport(item, sale.areaCostCenter);
          break;
        }
        case 'CodCustoFIN': {
          txt =
            txt + handleNormalizeTxtFieldForReport(item, sale.areaCostCenter);
          break;
        }
        default:
          break;
      }
    } else {
      txt = txt + handleNormalizeTxtFieldForReport(item, '');
    }
  });
  return txt.substring(0, txt.length - 1); // Remove last character
};

const handleNormalizeTxtFieldForReport = (
  field: {
    field: string;
    start: number;
    size: number;
    type: string;
    mandatory: string;
  },
  content: string,
) => {
  let limit = !content || content === '' ? 0 : content.length;

  if (field.type === 'DC' || field.type === 'IC') {
    while (limit < field.size) {
      content = ' ' + content;
      limit++;
    }
  } else if (field.type === 'DN' || field.type === 'IN') {
    let isDecimal = false;

    if (field.size % 1 === 0) {
      isDecimal = false;
    } else {
      isDecimal = true;
    }

    if (isDecimal) {
      if (content === '') {
        content = '0.0';
      } else {
        if (!includes(content, '.') && !includes(content, ',')) {
          content += '.0';
        }
      }

      const fieldSizeAsString = replace(field.size.toString(), ',', '.');
      const fieldSizeSplit = split(
        // field.size.toString(CultureInfo.InvariantCulture),
        fieldSizeAsString,
        '.',
      );

      const sizeDecimal = parseInt(fieldSizeSplit[1]);
      let limitDecimal = split(content, '.')[1].length;

      const newFieldSize = parseInt(fieldSizeSplit[0]) - sizeDecimal - 1;
      limit = split(content, '.')[0].length;

      while (limit < newFieldSize) {
        content = '0' + content;
        limit++;
      }
      while (limitDecimal < sizeDecimal) {
        content = content + '0';
        limitDecimal++;
      }
    } else {
      while (limit < field.size) {
        content = '0' + content;
        limit++;
      }
    }
  } else if (field.type === 'DD' || field.type === 'ID') {
    if (content === '') {
      content = '  /  /    ';
    }
  }

  content = content + ',';

  return content;
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const rgbToHex = (r: number, g: number, b: number) => {
  const rhex = r.toString(16);
  const ghex = g.toString(16);
  const bhex = b.toString(16);
  return '#' + rhex + ghex + bhex;
};

export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const groupArr = (data: string | any[], n: number) => {
  const group: any[][] = [];
  for (let i = 0, j = 0; i < data.length; i++) {
    if (i >= n && i % n === 0) {
      j++;
    }
    group[j] = group[j] || [];
    group[j].push(data[i]);
  }
  return group;
};

export const generateRandomId = () => {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
};
