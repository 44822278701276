import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchSessionsInfoService,
  fetchSessionsCloseService,
} from '../../services';
import { SessionIdType, SessionStateType } from './types';

const initialState: SessionStateType = {
  sessionInfo: null,
  sessionInfoLoading: false,
  sessionCloseLoading: false,
  sessionInfoIsOpen: false,
  sessionCloseIsOpen: false,
  sessionIdToClose: '',
};

export const fetchSessionInfo = createAsyncThunk(
  'sessionsInfo',
  async (params: { sessionId: string }) => {
    return await fetchSessionsInfoService(params);
  },
);

export const fetchSessionClose = createAsyncThunk(
  'sessionsClose',
  async (params: { sessionId: string }) => {
    return await fetchSessionsCloseService(params);
  },
);

export const sessionSlice = createSlice({
  name: 'sessionsInfo',
  initialState,
  reducers: {
    toggleSessionInfoIsOpen(state) {
      state.sessionInfoIsOpen
        ? (state.sessionInfoIsOpen = false)
        : (state.sessionInfoIsOpen = true);
    },
    toggleSessionCloseIsOpen(state) {
      state.sessionCloseIsOpen
        ? (state.sessionCloseIsOpen = false)
        : (state.sessionCloseIsOpen = true);
    },
    setSessionCloseId(state, action: PayloadAction<SessionIdType>) {
      state.sessionIdToClose = action.payload.sessionIdToClose;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSessionInfo.pending, (state) => {
      return {
        ...state,
        sessionInfoLoading: true,
      };
    }),
      builder.addCase(fetchSessionInfo.fulfilled, (state, res) => {
        return {
          ...state,
          sessionInfo: {
            id: res.payload.data.id,
            userName: res.payload.data.userName,
            startDateTime: res.payload.data.startDateTime,
            finalDateTime: res.payload.data.finalDateTime,
            deviceId: res.payload.data.deviceId,
            latitude: res.payload.data.latitude,
            longitude: res.payload.data.longitude,
            routes: res.payload.data.routes,
          },
          sessionInfoLoading: false,
        };
      }),
      builder.addCase(fetchSessionInfo.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          sessionInfoLoading: false,
        };
      });

    builder.addCase(fetchSessionClose.pending, (state) => {
      return {
        ...state,
        sessionCloseLoading: true,
      };
    }),
      builder.addCase(fetchSessionClose.fulfilled, (state, res) => {
        toast.success('Sessão encerrada com sucesso.');
        return {
          ...state,
          sessionInfo: {
            id: '',
            userName: '',
            startDateTime: '',
            finalDateTime: '',
            deviceId: '',
            latitude: 0,
            longitude: 0,
            routes: [],
          },
          sessionCloseLoading: false,
        };
      }),
      builder.addCase(fetchSessionClose.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          sessionsCloseLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleSessionInfoIsOpen,
  toggleSessionCloseIsOpen,
  setSessionCloseId,
} = sessionSlice.actions;
