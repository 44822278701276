import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchCredentialsExportExcelService,
  fetchCredentialsExportHtmlService,
  fetchCredentialsListService,
} from '../../services/Credentials';
import { RootState } from '../Store';
import { CredentialFiltersValueStateType } from './CredentialsFiltersValues';

export type CredentialsListStateType = {
  isListLoading: boolean;
  size: number;
  currentPage: number;
  page: {
    id: number;
    number: number;
    plate: string;
    userName: string;
    address: string;
    district: string;
    validity: string;
    status: 0 | 1;
    routes: string;
  }[];
  credentialsExportExcelLoading: boolean;
  credentialsExportHtmlLoading: boolean;
  credentialsExportModalIsOpen: boolean;
  credentialsExportPercentage: number;
};

const initialState: CredentialsListStateType = {
  isListLoading: false,
  size: 0,
  currentPage: 1,
  page: [],
  credentialsExportExcelLoading: false,
  credentialsExportHtmlLoading: false,
  credentialsExportModalIsOpen: false,
  credentialsExportPercentage: 0,
};

export const fetchCredentialsList = createAsyncThunk(
  'fetchCredentialsList',
  async (
    params: {
      number?: string;
      plate?: string;
      userName?: string;
      userDocument?: string;
      address?: string;
      district?: string;
      routeId?: string;
      validity?: string;
      status?: '' | 'N' | 'S';
    },
    { getState },
  ) => {
    const state = getState() as RootState;

    return await fetchCredentialsListService({
      ...params,
      page: state.credentialsListSliceReducer.currentPage.toString(),
    });
  },
);

export const fetchCredentialsExportExcel = createAsyncThunk(
  'credentialsExportExcel',
  async (params: CredentialFiltersValueStateType) => {
    return await fetchCredentialsExportExcelService(params);
  },
);

export const fetchCredentialsExportHtml = createAsyncThunk(
  'credentialsExportHtml',
  async (params: CredentialFiltersValueStateType) => {
    return await fetchCredentialsExportHtmlService(params);
  },
);

export const credentialsListSlice = createSlice({
  name: 'credentialsList',
  initialState,
  reducers: {
    setCredentialsListCurrentPage: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        currentPage: action.payload,
      };
    },
    setCredentialsExportPercentage(state, action: PayloadAction<number>) {
      state.credentialsExportPercentage = action.payload;
    },
    closeExportModal(state) {
      state.credentialsExportModalIsOpen = false;
    },
    toggleExportModalIsOpen(state) {
      state.credentialsExportModalIsOpen
        ? (state.credentialsExportModalIsOpen = false)
        : (state.credentialsExportModalIsOpen = true);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCredentialsList.pending, (state) => {
      return {
        ...state,
        isListLoading: true,
      };
    });
    builder.addCase(fetchCredentialsList.rejected, (state) => {
      return {
        ...state,
        isListLoading: false,
      };
    });
    builder.addCase(fetchCredentialsList.fulfilled, (state, res) => {
      const { size, page } = res.payload;

      return {
        ...state,
        isListLoading: false,
        size,
        page,
      };
    });

    builder.addCase(fetchCredentialsExportExcel.pending, (state) => {
      return {
        ...state,
        credentialsExportExcelLoading: true,
        credentialsExportModalIsOpen: true,
        credentialsExportPercentage: 0,
      };
    }),
      builder.addCase(fetchCredentialsExportExcel.fulfilled, (state, res) => {
        return {
          ...state,
          credentialsExportExcelLoading: false,
          credentialsExportPercentage: 10,
        };
      }),
      builder.addCase(fetchCredentialsExportExcel.rejected, (state) => {
        return {
          ...state,
          credentialsExportExcelLoading: false,
          credentialsExportModalIsOpen: false,
          credentialsExportPercentage: 0,
        };
      });

    builder.addCase(fetchCredentialsExportHtml.pending, (state) => {
      return {
        ...state,
        credentialsExportHtmlLoading: true,
        credentialsExportModalIsOpen: true,
        credentialsExportPercentage: 0,
      };
    }),
      builder.addCase(fetchCredentialsExportHtml.fulfilled, (state, res) => {
        return {
          ...state,
          credentialsExportHtmlLoading: false,
          credentialsExportPercentage: 10,
        };
      }),
      builder.addCase(fetchCredentialsExportHtml.rejected, (state) => {
        return {
          ...state,
          credentialsExportHtmlLoading: false,
          credentialsExportModalIsOpen: false,
          credentialsExportPercentage: 0,
        };
      });
  },
});

export const { setCredentialsListCurrentPage } = credentialsListSlice.actions;
