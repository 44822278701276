/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import JSZip from 'jszip';
import { map } from 'lodash';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { SalesExportOperationalHtmlResponseType } from '../../../../services/Sales/types';
import { SalesFiltersType, salesListSlice } from '../../../../stores';
import { logoUrl } from '../../../../theme';

export const exportSalesOperationalToHTML = async (
  apiData: SalesExportOperationalHtmlResponseType,
  filters: SalesFiltersType | null,
  granularity: number,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  workerOne: (
    apiData: SalesExportOperationalHtmlResponseType,
    filters: SalesFiltersType | null,
  ) => Promise<
    {
      route: any;
      user: any;
      registerDateTime: string;
      price: number;
      routeId: number;
      userId: number;
    }[]
  >,
  workerTwo: (
    preparedApiData: {
      route: any;
      user: any;
      registerDateTime: string;
      price: number;
      routeId: number;
      userId: number;
    }[],
  ) => Promise<{
    [key: string]: {
      route: any;
      user: any;
      registerDateTime: string;
      price: number;
      routeId: number;
      userId: number;
    }[];
  }>,
  workerThree: (
    orderedApiData: {
      [key: string]: {
        route: any;
        user: any;
        registerDateTime: string;
        price: number;
        routeId: number;
        userId: number;
      }[];
    },
    apiData: SalesExportOperationalHtmlResponseType,
    filters: SalesFiltersType | null,
    granularity: number,
  ) => Promise<
    {
      areaId: number;
      areaName: string;
      registerDate: string;
      total: number;
      operational: {
        userId: number;
        userName: string;
        userTotal: number;
        firstRegisterDateTime: string;
        lastRegisterDateTime: string;
        rows: {
          startTime: string;
          endTime: string;
          firstRegisterDateTime: string;
          lastRegisterDateTime: string;
          rowTotal: number;
        }[];
      }[];
    }[]
  >,
  workerFour: (
    handledData: {
      areaId: number;
      areaName: string;
      registerDate: string;
      total: number;
      operational: {
        userId: number;
        userName: string;
        userTotal: number;
        firstRegisterDateTime: string;
        lastRegisterDateTime: string;
        rows: {
          startTime: string;
          endTime: string;
          firstRegisterDateTime: string;
          lastRegisterDateTime: string;
          rowTotal: number;
        }[];
      }[];
    }[],
  ) => Promise<
    {
      areaId: number;
      areaName: string;
      registerDate: string;
      total: number;
      operational: {
        userId: number;
        userName: string;
        userTotal: number;
        firstRegisterDateTime: string;
        lastRegisterDateTime: string;
        rows: {
          startTime: string;
          endTime: string;
          firstRegisterDateTime: string;
          lastRegisterDateTime: string;
          rowTotal: number;
        }[];
      }[];
    }[]
  >,
  workerFive: (
    handledData: {
      areaId: number;
      areaName: string;
      registerDate: string;
      total: number;
      operational: {
        userId: number;
        userName: string;
        userTotal: number;
        firstRegisterDateTime: string;
        lastRegisterDateTime: string;
        rows: {
          startTime: string;
          endTime: string;
          firstRegisterDateTime: string;
          lastRegisterDateTime: string;
          rowTotal: number;
        }[];
      }[];
    }[],
  ) => Promise<
    {
      title: string;
      body: string;
    }[]
  >,
): Promise<void> => {
  dispatch(salesListSlice.actions.setSalesExportPercentage(20));

  let handledApiData: {
    title: string;
    body: string;
  }[] = [];
  // if (apiData.salesUser && apiData.salesDriver) {
  handledApiData = await workerOne(apiData, filters).then(
    async (workerOneReturn) => {
      dispatch(salesListSlice.actions.setSalesExportPercentage(30));
      return workerTwo(workerOneReturn).then(async (workerTwoReturn) => {
        dispatch(salesListSlice.actions.setSalesExportPercentage(50));
        return workerThree(workerTwoReturn, apiData, filters, granularity).then(
          async (workerThreeReturn) => {
            dispatch(salesListSlice.actions.setSalesExportPercentage(60));
            return workerFour(workerThreeReturn).then(
              async (workerFourReturn) => {
                dispatch(salesListSlice.actions.setSalesExportPercentage(80));
                return workerFive(workerFourReturn);
              },
            );
          },
        );
      });
    },
  );
  // }

  dispatch(salesListSlice.actions.setSalesExportPercentage(90));

  const zip = new JSZip();

  map(handledApiData, function (item) {
    zip.file(`${item.title}.html`, item.body);
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(100));

  const content = await zip.generateAsync({ type: 'blob' });

  FileSaver.saveAs(
    content,
    `apoio-operacional-${moment().format('yyyy-MM-DD_hh-mm')}` + '.zip',
  );
};

export const handleApiDataSalesOperationalToHTMLPartOne = (
  apiData: SalesExportOperationalHtmlResponseType,
  filters: SalesFiltersType | null,
) => {
  const auxRoutes: any[] = [];
  const auxUsers: any[] = [];

  const preparedApiData = apiData.map((item) => {
    if (auxRoutes[item.routeId] === undefined) {
      auxRoutes[item.routeId] = filters?.routes.find((auxItem) => {
        return auxItem.id === item.routeId;
      });
    }
    if (item.userId && auxUsers[item.userId] === undefined) {
      auxUsers[item.userId] = filters?.users.find((auxItem) => {
        return auxItem.id === item.userId;
      });
    }

    return {
      ...item,
      route: auxRoutes[item.routeId],
      user: auxUsers[item.userId],
    };
  });

  return preparedApiData;
};

export const handleApiDataSalesOperationalToHTMLPartTwo = (
  preparedApiData: {
    route: any;
    user: any;
    registerDateTime: string;
    price: number;
    routeId: number;
    userId: number;
  }[],
) => {
  // Grouping apiData by areaId, registerDatetime
  const orderedApiData: {
    [key: string]: {
      route: any;
      user: any;
      registerDateTime: string;
      price: number;
      routeId: number;
      userId: number;
    }[];
  } = {};

  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3] +
      '-' +
      dateTime[8] +
      dateTime[9] +
      '-' +
      dateTime[5] +
      dateTime[6]
    );
  };

  preparedApiData.forEach((item) => {
    const idx = `"${item.route.areaId}+${formatDateTime(
      item.registerDateTime,
    )}"`;
    if (orderedApiData[idx] === undefined) orderedApiData[idx] = [];
    orderedApiData[idx].push(item);
  });
  return orderedApiData;
};

export const handleApiDataSalesOperationalToHTMLPartThree = (
  orderedApiData: {
    [key: string]: {
      route: any;
      user: any;
      registerDateTime: string;
      price: number;
      routeId: number;
      userId: number;
    }[];
  },
  apiData: SalesExportOperationalHtmlResponseType,
  filters: SalesFiltersType | null,
  granularity: number,
) => {
  const handledData: {
    areaId: number;
    areaName: string;
    registerDate: string;
    total: number;
    operational: {
      userId: number;
      userName: string;
      userTotal: number;
      firstRegisterDateTime: string;
      lastRegisterDateTime: string;
      rows: {
        startTime: string;
        endTime: string;
        firstRegisterDateTime: string;
        lastRegisterDateTime: string;
        rowTotal: number;
      }[];
    }[];
  }[] = [];

  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3] +
      '-' +
      dateTime[8] +
      dateTime[9] +
      '-' +
      dateTime[5] +
      dateTime[6]
    );
  };

  // Populating handledData
  const orderedApiDataKeys = Object.keys(orderedApiData);
  orderedApiDataKeys.map((apiDataKey) => {
    const areaObj = filters?.areas.find((auxItem) => {
      return auxItem.id === orderedApiData[apiDataKey][0].route.areaId;
    });

    if (areaObj) {
      handledData.push({
        areaId: orderedApiData[apiDataKey][0].route.areaId,
        areaName: areaObj.value,
        registerDate: formatDateTime(
          orderedApiData[apiDataKey][0].registerDateTime,
        ),
        total: 0,
        operational: [],
      });

      const currDataIdx = handledData.length - 1;
      const orderedUsers: {
        [key: string]: {
          route: any;
          user: any;
          registerDateTime: string;
          price: number;
          routeId: number;
          userId: number;
        }[];
      } = {};

      orderedApiData[apiDataKey].forEach((item) => {
        const idx = item.userId;
        if (orderedUsers[idx] === undefined) orderedUsers[idx] = [];
        orderedUsers[idx].push(item);
      });

      const orderedUsersKeys = Object.keys(orderedUsers);
      orderedUsersKeys.map((key) => {
        // Readying auxiliar rows array
        const rows: {
          startTime: string;
          endTime: string;
          firstRegisterDateTime: string;
          lastRegisterDateTime: string;
          rowTotal: number;
        }[] = [];
        let i = 0;
        let j = 0;
        while (i < 24) {
          i += granularity;
          if (i > 24) i = 24;
          rows.push({
            startTime: j.toString(),
            endTime: i.toString(),
            firstRegisterDateTime: 'Nenhuma',
            lastRegisterDateTime: 'Nenhuma',
            rowTotal: 0,
          });
          j = i;
        }

        // Run users and add all to row in handledData
        orderedUsers[key].map((user, index) => {
          // Pushes new user if first iteration
          if (index === 0) {
            handledData[currDataIdx].operational.push({
              userId: user.user.id,
              userName: user.user.value,
              userTotal: 0,
              firstRegisterDateTime: 'Nenhuma',
              lastRegisterDateTime: 'Nenhuma',
              rows: rows,
            });
          }

          const currOperationalIdx =
            handledData[currDataIdx].operational.length - 1;

          // Converting user.registerDateTime to seconds for calculation
          const registerDateTime = new Date(user.registerDateTime);
          const itemHourInSec =
            registerDateTime.getHours() * 3600 +
            registerDateTime.getMinutes() * 60 +
            registerDateTime.getSeconds();
          // const itemHourInSec =
          //   parseInt(moment(user.registerDateTime).format('HH')) * 3600 +
          //   parseInt(moment(user.registerDateTime).format('mm')) +
          //   60 +
          //   parseInt(moment(user.registerDateTime).format('ss'));

          // Checking if it should go to first register date time of user
          if (
            handledData[currDataIdx].operational[currOperationalIdx]
              .firstRegisterDateTime === 'Nenhuma' ||
            parseInt(
              handledData[currDataIdx].operational[currOperationalIdx]
                .firstRegisterDateTime,
            ) *
              3600 <
              itemHourInSec
          ) {
            handledData[currDataIdx].operational[
              currOperationalIdx
            ].firstRegisterDateTime = user.registerDateTime;
          }

          // Checking if it should go to last register date time of user
          if (
            handledData[currDataIdx].operational[currOperationalIdx]
              .lastRegisterDateTime === 'Nenhuma' ||
            parseInt(
              handledData[currDataIdx].operational[currOperationalIdx]
                .lastRegisterDateTime,
            ) *
              3600 >
              itemHourInSec
          ) {
            handledData[currDataIdx].operational[
              currOperationalIdx
            ].lastRegisterDateTime = user.registerDateTime;
          }

          // Finding where to place item in from rows
          const rowIdx = handledData[currDataIdx].operational[
            currOperationalIdx
          ].rows.findIndex((row) => {
            return (
              parseInt(row.startTime) * 3600 <= itemHourInSec &&
              parseInt(row.endTime) * 3600 >= itemHourInSec
            );
          });

          // Filling data on rows
          const currFirstRegister =
            handledData[currDataIdx].operational[currOperationalIdx].rows[
              rowIdx
            ].firstRegisterDateTime;
          const currLastRegister =
            handledData[currDataIdx].operational[currOperationalIdx].rows[
              rowIdx
            ].lastRegisterDateTime;
          const currCheckingRegister = user.registerDateTime;

          const currFirstRegisterAsDate = new Date(
            handledData[currDataIdx].operational[currOperationalIdx].rows[
              rowIdx
            ].firstRegisterDateTime,
          );
          const currLastRegisterAsDate = new Date(
            handledData[currDataIdx].operational[currOperationalIdx].rows[
              rowIdx
            ].lastRegisterDateTime,
          );
          const currCheckingRegisterAsDate = new Date(user.registerDateTime);

          if (
            currFirstRegister === 'Nenhuma' ||
            currCheckingRegisterAsDate < currFirstRegisterAsDate
          ) {
            handledData[currDataIdx].operational[currOperationalIdx].rows[
              rowIdx
            ].firstRegisterDateTime = currCheckingRegister;
            if (currLastRegister === 'Nenhuma') {
              handledData[currDataIdx].operational[currOperationalIdx].rows[
                rowIdx
              ].lastRegisterDateTime = currFirstRegister;
            }
          }

          if (
            currLastRegister === 'Nenhuma' ||
            currCheckingRegisterAsDate > currLastRegisterAsDate
          ) {
            handledData[currDataIdx].operational[currOperationalIdx].rows[
              rowIdx
            ].lastRegisterDateTime = currCheckingRegister;
          }

          handledData[currDataIdx].operational[currOperationalIdx].rows[
            rowIdx
          ].rowTotal += user.price;

          handledData[currDataIdx].operational[currOperationalIdx].userTotal +=
            user.price;

          handledData[currDataIdx].total += user.price;
        });
      });
    }
  });

  return handledData;
};

export const handleApiDataSalesOperationalToHTMLPartFour = (
  handledData: {
    areaId: number;
    areaName: string;
    registerDate: string;
    total: number;
    operational: {
      userId: number;
      userName: string;
      userTotal: number;
      firstRegisterDateTime: string;
      lastRegisterDateTime: string;
      rows: {
        startTime: string;
        endTime: string;
        firstRegisterDateTime: string;
        lastRegisterDateTime: string;
        rowTotal: number;
      }[];
    }[];
  }[],
) => {
  // Ordering users alphabetically on each file
  handledData.map((data) => {
    const orderedOperational = data.operational.sort((a, b) => {
      const nameA = a.userName.toUpperCase(); // ignore upper and lowercase
      const nameB = b.userName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    data.operational = orderedOperational;
  });

  return handledData;
};

export const handleApiDataSalesOperationalToHTMLPartFive = (
  handledData: {
    areaId: number;
    areaName: string;
    registerDate: string;
    total: number;
    operational: {
      userId: number;
      userName: string;
      userTotal: number;
      firstRegisterDateTime: string;
      lastRegisterDateTime: string;
      rows: {
        startTime: string;
        endTime: string;
        firstRegisterDateTime: string;
        lastRegisterDateTime: string;
        rowTotal: number;
      }[];
    }[];
  }[],
) => {
  // Creating <tr>s
  const htmlStuff: {
    title: string;
    body: string;
  }[] = [];

  const formatDateTimeTitle = (dateTime: string) => {
    return `${('0' + dateTime[5] + dateTime[6]).slice(-2)}-${(
      '0' +
      +dateTime[8] +
      dateTime[9]
    ).slice(-2)}-${dateTime[0] + dateTime[1] + dateTime[2] + dateTime[3]}`;
  };

  const formatDateTimeReport = (dateTime: string) => {
    return `${('0' + dateTime[5] + dateTime[6]).slice(-2)}/${(
      '0' +
      +dateTime[8] +
      dateTime[9]
    ).slice(-2)}/${dateTime[0] + dateTime[1] + dateTime[2] + dateTime[3]}`;
  };

  const formatDateTimeTR = (dateTime: string) => {
    const date = new Date(dateTime);
    return `${('0' + date.getDate()).slice(-2)}/${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}/${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
  };

  handledData.forEach((data, index) => {
    const title = `${data.areaName} ${formatDateTimeTitle(data.registerDate)}`;
    let tr = '';

    // Creating <tr>s
    data.operational.map((item) => {
      const currUserRows: string[] = ['', '', '', ''];

      item.rows.map((row) => {
        currUserRows[0] += `<br />${('0' + row.startTime).slice(-2)}:00 - ${
          row.endTime === '24' ? '23' : ('0' + row.endTime).slice(-2)
        }${row.endTime === '24' ? ':59' : ':00'}`;

        const firstRegisterDateTimeAsDate = new Date(row.firstRegisterDateTime);
        currUserRows[1] += `<br />${
          row.firstRegisterDateTime === 'Nenhuma'
            ? row.firstRegisterDateTime
            : `${('0' + firstRegisterDateTimeAsDate.getDate()).slice(-2)}/${(
                '0' +
                (firstRegisterDateTimeAsDate.getMonth() + 1)
              ).slice(-2)}/${firstRegisterDateTimeAsDate.getFullYear()} ${(
                '0' + firstRegisterDateTimeAsDate.getHours()
              ).slice(-2)}:${(
                '0' + firstRegisterDateTimeAsDate.getMinutes()
              ).slice(-2)}:${(
                '0' + firstRegisterDateTimeAsDate.getSeconds()
              ).slice(-2)}`
        }`;

        const lastRegisterDateTimeAsDate = new Date(row.lastRegisterDateTime);
        currUserRows[2] += `<br />${
          row.lastRegisterDateTime === 'Nenhuma'
            ? row.lastRegisterDateTime
            : `${('0' + lastRegisterDateTimeAsDate.getDate()).slice(-2)}/${(
                '0' +
                (lastRegisterDateTimeAsDate.getMonth() + 1)
              ).slice(-2)}/${lastRegisterDateTimeAsDate.getFullYear()} ${(
                '0' + lastRegisterDateTimeAsDate.getHours()
              ).slice(-2)}:${(
                '0' + lastRegisterDateTimeAsDate.getMinutes()
              ).slice(-2)}:${(
                '0' + lastRegisterDateTimeAsDate.getSeconds()
              ).slice(-2)}`
        }`;
        currUserRows[3] +=
          '<br />' + row.rowTotal.toFixed(2).toString().replace('.', ',');
      });

      tr += `
        <tr>
          <td>
            <strong> ${item.userName}</strong>
            ${currUserRows[0]}
          </td>
          <td>
            <strong> ${
              item.firstRegisterDateTime === 'Nenhuma'
                ? item.firstRegisterDateTime
                : formatDateTimeTR(item.firstRegisterDateTime)
            }</strong>
            ${currUserRows[1]}
          </td>
          <td>
            <strong> ${
              item.lastRegisterDateTime === 'Nenhuma'
                ? item.lastRegisterDateTime
                : formatDateTimeTR(item.lastRegisterDateTime)
            }</strong>
            ${currUserRows[2]}
          </td>
          <td>
            <strong> ${item.userTotal
              .toFixed(2)
              .toString()
              .replace('.', ',')}</strong>
            ${currUserRows[3]}
          </td>
        </tr>
      `;
    });

    // Mounting html for this area/ date
    const fullBody = `
      <!doctype html>
      <html>
      <head>
        <meta name="viewport" content="width=device-width" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Relatório financeiro resumido</title>
        <style>
      
      
          /* -------------------------------------
            GLOBAL RESETS
          ------------------------------------- */
          img {
            border: none;
            -ms-interpolation-mode: bicubic;
            max-width: 100%;
          }
      
          body {
            background-color: white;
            font-family: sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }
      
          table {
            border-collapse: separate;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            width: 100%;
          }
      
            table td {
              font-family: sans-serif;
              font-size: 14px;
              vertical-align: top;
            }
      
          /* -------------------------------------
            BODY & CONTAINER
          ------------------------------------- */
      
          .body {
            background-color: white;
            width: 100%;
          }
      
          /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
          .container {
            display: block;
            Margin: 0 auto !important;
            /* makes it centered */
            max-width: 100%;
            padding: 10px;
            /*width: 100%;*/
          }
      
          /* This should also be a block element, so that it will fill 100% of the .container */
          .content {
            box-sizing: border-box;
            display: block;
            Margin: 0 auto;
            max-width: 100%;
            padding: 10px;
          }
      
          /* -------------------------------------
            HEADER, FOOTER, MAIN
          ------------------------------------- */
          .main {
            background: #fff;
            border-radius: 3px;
            width: 100%;
          }
      
          .wrapper {
            box-sizing: border-box;
            padding: 20px;
          }
      
          .footer {
            clear: both;
            padding-top: 10px;
            text-align: center;
            width: 100%;
          }
      
            .footer td,
            .footer p,
            .footer span,
            .footer a {
              color: #999999;
              font-size: 12px;
              text-align: center;
            }
      
          /* -------------------------------------
            TYPOGRAPHY
          ------------------------------------- */
          h2,
          h3,
          h4 {
            color: #000000;
            font-family: sans-serif;
            font-weight: 400;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 15px;
          }
      
          h1 {
            font-size: 35px;
            font-weight: 600;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 8px;
          }
      
          h3 {
            font-size: 17px;
            font-weight: 600;
          }
      
          p,
          ul,
          ol {
            font-family: sans-serif;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            Margin-bottom: 15px;
          }
      
            p li,
            ul li,
            ol li {
              list-style-position: inside;
              margin-left: 5px;
            }
      
          a {
            color: #3498db;
            text-decoration: underline;
          }
      
          /* -------------------------------------
            BUTTONS
          ------------------------------------- */
          .btn {
            box-sizing: border-box;
            width: 100%;
          }
      
            .btn > tbody > tr > td {
              padding-bottom: 15px;
            }
      
            .btn table {
              width: auto;
            }
      
              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }
      
            .btn a {
              background-color: #ffffff;
              border: solid 1px #3498db;
              border-radius: 5px;
              box-sizing: border-box;
              color: #3498db;
              cursor: pointer;
              display: inline-block;
              font-size: 14px;
              font-weight: bold;
              margin: 0;
              padding: 12px 25px;
              text-decoration: none;
              text-transform: capitalize;
            }
      
          .btn-primary table td {
            background-color: #3498db;
          }
      
          .btn-primary a {
            background-color: #3498db;
            border-color: #3498db;
            color: #ffffff;
          }
      
          /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
          ------------------------------------- */
          .last {
            margin-bottom: 0;
          }
      
          .first {
            margin-top: 0;
          }
      
          .align-center {
            text-align: center;
          }
      
          .align-right {
            text-align: right;
          }
      
          .align-left {
            text-align: left;
          }
      
          .clear {
            clear: both;
          }
      
          .mt0 {
            margin-top: 0;
          }
      
          .mb0 {
            margin-bottom: 0;
          }
      
          .preheader {
            color: transparent;
            display: none;
            height: 0;
            max-height: 0;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            mso-hide: all;
            visibility: hidden;
            width: 0;
          }
      
          .powered-by a {
            text-decoration: none;
          }
      
          hr {
            border: 0;
            border-bottom: 1px solid black;
            Margin: 30px 0 10px;
          }
      
          /* -------------------------------------
              CUSTOM CSS BY ME
            ------------------------------------- */
      
          .table-adjustment tbody td, .table-adjustment thead th {
            padding: 5px;
          }
      
          /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
          ------------------------------------- */
          @media only screen and (max-width: 620px) {
            table[class=body] h1 {
              font-size: 28px !important;
              margin-bottom: 10px !important;
            }
      
            table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
              font-size: 16px !important;
            }
      
            table[class=body] .wrapper,
            table[class=body] .article {
              padding: 10px !important;
            }
      
            table[class=body] .content {
              padding: 0 !important;
            }
      
            table[class=body] .container {
              padding: 0 !important;
              width: 100% !important;
            }
      
            table[class=body] .main {
              border-left-width: 0 !important;
              border-radius: 0 !important;
              border-right-width: 0 !important;
            }
      
            table[class=body] .btn table {
              width: 100% !important;
            }
      
            table[class=body] .btn a {
              width: 100% !important;
            }
      
            table[class=body] .img-responsive {
              height: auto !important;
              max-width: 100% !important;
              width: auto !important;
            }
          }
      
          /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
          ------------------------------------- */
          @media all {
            .ExternalClass {
              width: 100%;
            }
      
              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%;
              }
      
            .apple-link a {
              color: inherit !important;
              font-family: inherit !important;
              font-size: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
              text-decoration: none !important;
            }
      
            .btn-primary table td:hover {
              background-color: #34495e !important;
            }
      
            .btn-primary a:hover {
              background-color: #34495e !important;
              border-color: #34495e !important;
            }
          }
        </style>
      </head>
      <body class="">
        <table border="0" cellpadding="0" cellspacing="0" class="body">
          <tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
                <div class="img-container" style="text-align:center">
                  <img src=${logoUrl} alt="Logo" height="66" width="100" />
                </div>
                <!-- START CENTERED WHITE CONTAINER -->
                <table class="main">
                  <!-- START MAIN CONTENT AREA -->
                  <tr>
                    <td class="wrapper">
                      <table border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td>
                            <h1>Relatório de Apoio Operacional</h1>
                            <h3>${
                              handledData[index].areaName
                            } (${formatDateTimeReport(
      handledData[index].registerDate,
    )})</h3>
                            <table border="0" cellpadding="0" cellspacing="0">
                              <tbody>
                                <tr>
                                  <td align="left">
                                    <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                                      <thead>
                                        <tr>
                                          <th align="left">Operacional</th>
                                          <th align="left">Primeira</th>
                                          <th align="left">Ultima</th>
                                          <th align="left">Total</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        ${tr}
                                        <tr>
                                          <td><strong>Total operacional:</strong></td>
                                          <td><strong> </strong></td>
                                          <td><strong> </strong></td>
                                          <td><strong> ${data.total
                                            .toFixed(2)
                                            .toString()
                                            .replace('.', ',')}</strong></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <!-- END MAIN CONTENT AREA -->
                </table>
                <!-- END CENTERED WHITE CONTAINER -->
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </body>
      </html>
      `;
    htmlStuff.push({
      title: title,
      body: fullBody,
    });
  });

  return htmlStuff;
};
