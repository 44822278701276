/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { RoutesExportOperatingVacanciesResponseType } from '../../../../services/Routes/types';
import { routesListSlice } from '../../../../stores';

export const exportOperatingVacanciesToCSV = async (
  apiData: RoutesExportOperatingVacanciesResponseType,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  worker: (
    apiData: RoutesExportOperatingVacanciesResponseType,
  ) => Promise<string>,
): Promise<void> => {
  dispatch(routesListSlice.actions.setRoutesExportPercentage(20));

  const handledApiData = await worker(apiData);
  dispatch(routesListSlice.actions.setRoutesExportPercentage(60));

  const fileType = 'data:text/csv;charset=utf-8;';
  const fileExtension = '.csv';
  dispatch(routesListSlice.actions.setRoutesExportPercentage(100));

  const blob = new Blob([handledApiData], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `vagas-operantes-${moment().format('yyyy-MM-DD_hh-mm')}` + fileExtension,
  );

  return;
};

export const handleApiDataOperatingVacanciesToCSV = (
  apiData: RoutesExportOperatingVacanciesResponseType,
) => {
  let handledApiData = `"Area";"Vagas";\r\n`;

  apiData.map((item) => {
    handledApiData =
      handledApiData + `"${item.areaName}";"${item.quantity}";\r\n`;
  });

  return handledApiData;
};
