import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  AccordionProps as MuiAccordionProps,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';
import { StyledAccordionWrapper, StyledAccordion } from './styles';

type AccordionProps = MuiAccordionProps & {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  children: React.ReactNode;
};

export const Accordion = (props: AccordionProps) => {
  const { expanded, setExpanded, title, children, ...rest } = props;

  return (
    <StyledAccordionWrapper>
      <StyledAccordion {...rest} expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <div onClick={() => setExpanded(!expanded)} style={{ width: '100%' }}>
            <Typography color='text'>{title}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </StyledAccordion>
    </StyledAccordionWrapper>
  );
};
