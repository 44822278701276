import { LinearProgress } from '@mui/material';
import React from 'react';
import { Modal } from '../../../components';
import { useSelector } from '../../../stores';

export const NotificationsExportModal: React.FC = () => {
  const { notificationsExportPercentage, notificationsExportModalIsOpen } =
    useSelector((state) => state.notificationsListReducer);

  return (
    <Modal
      open={notificationsExportModalIsOpen}
      onClose={() => {}}
      title='Preparando exportação'
      noCloseButton
    >
      <LinearProgress
        variant='determinate'
        value={notificationsExportPercentage}
        sx={{ height: '20px', borderRadius: '40px' }}
      />
    </Modal>
  );
};
