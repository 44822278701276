// All user related database operations can be defined here.
import { omit } from 'lodash';
import { api } from '..';
import {
  fetchLoginUrl,
  fetchAuthAreasUrl,
  fetchAuthUpdateProfileUrl,
  fetchAuthUpdatePasswordUrl,
} from './constants';
import {
  LoginResponse,
  AuthAreasResponse,
  AuthUpdateProfileResponse,
  AuthUpdatePasswordResponse,
} from './types';

export const fetchLoginService = async (params: {
  username: string;
  password: string;
}) => {
  const { username, password } = params;
  return await api
    .post<LoginResponse>(
      fetchLoginUrl,
      JSON.stringify({ login: username, password: password }),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchAuthAreasService = async () => {
  return await api
    .get<AuthAreasResponse>(fetchAuthAreasUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchAuthUpdateProfileService = async (params: {
  userId: string;
  name: string;
  email: string;
}) => {
  return await api
    .put<AuthUpdateProfileResponse>(
      `${fetchAuthUpdateProfileUrl}/${params.userId}/profile`,
      omit(params, ['userId']),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchAuthUpdatePasswordService = async (params: {
  userId: string;
  oldPassword: string;
  newPassword: string;
}) => {
  return await api
    .put<AuthUpdatePasswordResponse>(
      `${fetchAuthUpdatePasswordUrl}/${params.userId}/password`,
      omit(params, ['userId']),
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
