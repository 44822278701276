import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { map, reduce } from 'lodash';
import moment from 'moment';
import {
  fetchNotificationsExportExcelService,
  fetchNotificationsExportFinesHTMLService,
  fetchNotificationsExportHTMLService,
  fetchNotificationsListService,
  fetchNotificationsTotalsService,
  fetchNotificationsExportReceiptHTMLService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { handleDateTime } from '../../utils/Helpers';
import { RootState } from '../Store';
import {
  NotificationsFiltersParamsType,
  NotificationsTotalsParamsType,
  NotificationsListStateType,
} from './types';

const initialState: NotificationsListStateType = {
  notificationsList: null,
  notificationsListLoading: false,
  notificationsTotals: null,
  notificationsTotalsSum: 0,
  notificationsTotalsLoading: false,
  notificationsExportExcelLoading: false,
  notificationsExportHTMLLoading: false,
  notificationsExportFinesHTMLLoading: false,
  notificationsExportPercentage: 0,
  notificationsExportModalIsOpen: false,
  notificationsPage: 1,
  notificationsIrregularityId: null,
  notificationsExportReceiptHTMLLoading: false,
};

export const fetchNotificationsList = createAsyncThunk(
  'notificationsList',
  async (params: NotificationsFiltersParamsType, { getState }) => {
    const state = getState() as RootState;
    if (state.notificationsListReducer.notificationsIrregularityId) {
      return await fetchNotificationsListService({
        startDateTime: moment(
          moment().subtract(1, 'year').format('yyyy-MM-DD') +
            ' ' +
            moment().format('00:00'),
        )
          .format('yyyy-MM-DD HH:mm:ss')
          .toString(),
        finalDateTime: moment(
          moment().format('yyyy-MM-DD') + ' ' + moment().format('HH:mm'),
        )
          .format('yyyy-MM-DD HH:mm:ss')
          .toString(),
        plate: '',
        notificationId:
          state.notificationsListReducer.notificationsIrregularityId,
        situationId: '',
        routeAreaId: '',
        routeId: '',
        userName: '',
        serviceOrderOrigin: '',
        print: '0',
        page: '1',
      });
    } else {
      return await fetchNotificationsListService({
        ...params,
        page: state.notificationsListReducer.notificationsPage.toString(),
      });
    }
  },
);

export const fetchNotificationsTotals = createAsyncThunk(
  'notificationsTotals',
  async (params: NotificationsTotalsParamsType) => {
    return await fetchNotificationsTotalsService(params);
  },
);

export const fetchNotificationsExportExcel = createAsyncThunk(
  'notificationsExportExcel',
  async (params: {
    userName: string;
    startDateTime: string;
    finalDateTime: string;
    plate: string;
    serviceOrderOrigin: string;
    routeAreaId: string;
    routeId: string;
    print: string;
    notificationId: string;
    situationId: string;
  }) => {
    return await fetchNotificationsExportExcelService(params);
  },
);

export const fetchNotificationsExportHTML = createAsyncThunk(
  'notificationsExportHTML',
  async (params: {
    userName: string;
    startDateTime: string;
    finalDateTime: string;
    plate: string;
    serviceOrderOrigin: string;
    routeAreaId: string;
    routeId: string;
    print: string;
    notificationId: string;
    situationId: string;
  }) => {
    return await fetchNotificationsExportHTMLService(params);
  },
);

export const fetchNotificationsExportFinesHTML = createAsyncThunk(
  'notificationsExportFinesHTML',
  async (params: {
    userName: string;
    startDateTime: string;
    finalDateTime: string;
    plate: string;
    serviceOrderOrigin: string;
    routeAreaId: string;
    routeId: string;
    print: string;
    notificationId: string;
    situationId: string;
  }) => {
    return await fetchNotificationsExportFinesHTMLService(params);
  },
);

export const fetchNotificationsExportReceiptHTML = createAsyncThunk(
  'notificationsExportReceiptHTML',
  async (params: {
    userName: string;
    startDateTime: string;
    finalDateTime: string;
    plate: string;
    serviceOrderOrigin: string;
    routeAreaId: string;
    routeId: string;
    print: string;
    notificationId: string;
    situationId: string;
  }) => {
    return await fetchNotificationsExportReceiptHTMLService(params);
  },
);
export const notificationsListSlice = createSlice({
  name: 'notificationsList',
  initialState,
  reducers: {
    setNotificationsPage(state, action: PayloadAction<number>) {
      state.notificationsPage = action.payload;
    },
    setIrregularityId(state, action: PayloadAction<string | null>) {
      state.notificationsIrregularityId = action.payload;
    },
    setNotificationsExportPercentage(state, action: PayloadAction<number>) {
      state.notificationsExportPercentage = action.payload;
    },
    closeExportModal(state) {
      state.notificationsExportModalIsOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationsList.pending, (state) => {
      return {
        ...state,
        notificationsList: null,
        notificationsListLoading: true,
      };
    }),
      builder.addCase(fetchNotificationsList.fulfilled, (state, res) => {
        const page: typeof res.payload.data.page = map(
          res.payload.data.page,
          (item) => {
            return {
              ...item,
              id: item.id.toString(),
              registerDateTime: handleDateTime(item.registerDateTime),
              vehicle: item.type ? `${item.plate} (${item.type})` : item.plate,
              situation:
                item.situation === 'C'
                  ? 'Cancelada'
                  : item.situation === 'I'
                  ? 'Irregular'
                  : item.situation === 'P'
                  ? 'Paga'
                  : 'Regular',
            };
          },
        );

        return {
          ...state,
          notificationsList: { page },
          notificationsListLoading: false,
        };
      }),
      builder.addCase(fetchNotificationsList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationsListLoading: false,
        };
      });

    builder.addCase(fetchNotificationsTotals.pending, (state) => {
      return {
        ...state,
        notificationsTotalsLoading: true,
      };
    }),
      builder.addCase(fetchNotificationsTotals.fulfilled, (state, res) => {
        return {
          ...state,
          notificationsTotals: state.notificationsIrregularityId
            ? [
                {
                  situationId: state.notificationsList
                    ? state.notificationsList.page[0].situation.charAt(0)
                    : '',
                  count: 1,
                },
              ]
            : res.payload.data,
          notificationsTotalsSum: state.notificationsIrregularityId
            ? 1
            : reduce(
                res.payload.data,
                (
                  accumulator: number,
                  object: {
                    situationId: string;
                    count: number;
                  },
                ) => {
                  return accumulator + object.count;
                },
                0,
              ),
          notificationsTotalsLoading: false,
        };
      }),
      builder.addCase(fetchNotificationsTotals.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationsTotalsLoading: false,
        };
      });

    builder.addCase(fetchNotificationsExportExcel.pending, (state) => {
      return {
        ...state,
        notificationsExportExcelLoading: true,
        notificationsExportModalIsOpen: true,
        notificationsExportPercentage: 0,
      };
    }),
      builder.addCase(fetchNotificationsExportExcel.fulfilled, (state, res) => {
        return {
          ...state,
          notificationsExportExcelLoading: false,
          notificationsExportPercentage: 10,
        };
      }),
      builder.addCase(fetchNotificationsExportExcel.rejected, (state) => {
        return {
          ...state,
          notificationsExportExcelLoading: false,
          notificationsExportModalIsOpen: false,
          notificationsExportPercentage: 0,
        };
      });

    builder.addCase(fetchNotificationsExportHTML.pending, (state) => {
      return {
        ...state,
        notificationsExportHTMLLoading: true,
        notificationsExportModalIsOpen: true,
        notificationsExportPercentage: 0,
      };
    }),
      builder.addCase(fetchNotificationsExportHTML.fulfilled, (state, res) => {
        return {
          ...state,
          notificationsExportHTMLLoading: false,
          notificationsExportPercentage: 10,
        };
      }),
      builder.addCase(fetchNotificationsExportHTML.rejected, (state) => {
        return {
          ...state,
          notificationsExportHTMLLoading: false,
          notificationsExportModalIsOpen: false,
          notificationsExportPercentage: 0,
        };
      });

    builder.addCase(fetchNotificationsExportFinesHTML.pending, (state) => {
      return {
        ...state,
        notificationsExportFinesHTMLLoading: true,
        notificationsExportModalIsOpen: true,
        notificationsExportPercentage: 0,
      };
    }),
      builder.addCase(
        fetchNotificationsExportFinesHTML.fulfilled,
        (state, res) => {
          return {
            ...state,
            notificationsExportFinesHTMLLoading: false,
            notificationsExportPercentage: 10,
          };
        },
      ),
      builder.addCase(fetchNotificationsExportFinesHTML.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationsExportFinesHTMLLoading: false,
          notificationsExportModalIsOpen: false,
          notificationsExportPercentage: 0,
        };
      });

    builder.addCase(fetchNotificationsExportReceiptHTML.pending, (state) => {
      return {
        ...state,
        notificationsExportReceiptHTMLLoading: true,
        notificationsExportModalIsOpen: true,
        notificationsExportPercentage: 0,
      };
    }),
      builder.addCase(
        fetchNotificationsExportReceiptHTML.fulfilled,
        (state, res) => {
          return {
            ...state,
            notificationsExportReceiptHTMLLoading: false,
            notificationsExportPercentage: 10,
          };
        },
      ),
      builder.addCase(fetchNotificationsExportReceiptHTML.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          notificationsExportReceiptHTMLLoading: false,
          notificationsExportModalIsOpen: false,
          notificationsExportPercentage: 0,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { setNotificationsPage, setIrregularityId } =
  notificationsListSlice.actions;
