import React from 'react';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { Footer } from './Footer';
import { MenuBars } from './MenuBars';
import { StyledOuterContainer, StyledInnerContainer } from './styles';

export interface PageWrapperProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const PageWrapper = (props: PageWrapperProps) => {
  const { children, style } = props;
  const responsiveMode = useResponsiveMode();

  return (
    <StyledOuterContainer
      style={{ ...style, overflow: 'overlay' }}
      sx={{
        paddingLeft: responsiveMode === 'phone' ? '0px' : '62px',
      }}
    >
      <StyledInnerContainer
        style={style}
        sx={{
          paddingLeft: responsiveMode === 'phone' ? '10px' : '30px',
          paddingRight: responsiveMode === 'phone' ? '10px' : '30px',
        }}
      >
        <MenuBars />
        {children}
      </StyledInnerContainer>
      <Footer />
    </StyledOuterContainer>
  );
};
