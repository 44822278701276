import SaveIcon from '@mui/icons-material/Save';
import { Box } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components';
import { CONFIGURATION_CREATE } from '../../../routes';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { StyledTableHeader } from '../styles';

export const SavedConfigurationsTableToolbar = () => {
  const navigate = useNavigate();
  const responsiveMode = useResponsiveMode();

  return (
    <StyledTableHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: responsiveMode === 'phone' ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <Box
          sx={{
            p: 0.5,
            pb: 0,
            width: responsiveMode === 'phone' ? '100%' : 'auto',
          }}
        >
          <GridToolbarQuickFilter placeholder='Filtrar...' />
        </Box>
        <Button
          variant='contained'
          label={
            responsiveMode === 'phone' || responsiveMode === 'tablet'
              ? 'Nova configuração'
              : 'Cadastrar nova configuração'
          }
          startIcon={<SaveIcon />}
          onClick={() => navigate(CONFIGURATION_CREATE)}
          sx={{ width: responsiveMode === 'phone' ? '100%' : 'auto' }}
        />
      </div>
    </StyledTableHeader>
  );
};
