import { Box, styled } from '@mui/material';

export const StyledWrapperBox = styled(Box)(() => ({
  height: 24,
  width: '100%',
  borderRadius: 6,
  marginTop: 10,
  marginBottom: 10,
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  overflow: 'hidden',
}));
