import { LinearProgress } from '@mui/material';
import React from 'react';
import { Modal } from '../../../components';
import { useSelector } from '../../../stores';

export const SessionsExportModal: React.FC = () => {
  const { sessionsExportPercentage, sessionsExportModalIsOpen } = useSelector(
    (state) => state.sessionsListReducer,
  );

  return (
    <Modal
      open={sessionsExportModalIsOpen}
      onClose={() => {}}
      title='Preparando exportação'
      noCloseButton
    >
      <LinearProgress
        variant='determinate'
        value={sessionsExportPercentage}
        sx={{ height: '20px', borderRadius: '40px' }}
      />
    </Modal>
  );
};
