import { useState, useEffect } from 'react';

export default function useScreenSize() {
  function handleResize() {
    const { innerWidth } = window;
    return innerWidth;
  }

  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', () => setScreenSize(handleResize));
    return () =>
      window.removeEventListener('resize', () => setScreenSize(handleResize));
  }, []);

  return screenSize;
}
