import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import { SessionsFiltersValuesStateType } from './types';

const initialState: SessionsFiltersValuesStateType = {
  days: '0',
  startDateTime: moment(
    moment().format('yyyy-MM-DD') + ' ' + moment().format('00:00'),
  )
    .format('yyyy-MM-DD HH:mm:ss')
    .toString(),
  finalDateTime: moment(
    moment().format('yyyy-MM-DD') + ' ' + moment().format('23:59'),
  )
    .format('yyyy-MM-DD HH:mm:ss')
    .toString(),
  userName: '',
  situation: '0',
};

export const sessionsFiltersValuesSlice = createSlice({
  name: 'sessionsFiltersValues',
  initialState,
  reducers: {
    setSessionsFiltersValues(
      state,
      action: PayloadAction<SessionsFiltersValuesStateType>,
    ) {
      state.days = action.payload.days;
      state.userName = action.payload.userName;
      state.startDateTime = action.payload.startDateTime;
      state.finalDateTime = action.payload.finalDateTime;
      state.situation = action.payload.situation;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSessionsFiltersValues } = sessionsFiltersValuesSlice.actions;

// // now available:
// filtersValuesSlice.actions.setSessionsFiltersValues();
// // also available:
// filtersValuesSlice.caseReducers.increment(0, { type: 'increment', payload: 5 });
