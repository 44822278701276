import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchConfigurationsListService,
  fetchConfigurationsCreateService,
  fetchConfigurationsTypesListService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from '../Store';
import { ConfigurationsStateType } from './types';
import { map } from 'lodash';

const initialState: ConfigurationsStateType = {
  configurationsList: null,
  configurationsTypesList: null,
  configurationsPage: 1,
  configurationsListLoading: false,
  configurationsTypesListLoading: false,
};

export const fetchConfigurationsList = createAsyncThunk(
  'configurationsList',
  async () => {
    return await fetchConfigurationsListService();
  },
);

export const fetchConfigurationsTypesList = createAsyncThunk(
  'configurationsTypesList',
  async () => {
    return await fetchConfigurationsTypesListService();
  },
);

export const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    setConfigurationsPage(state, action: PayloadAction<number>) {
      state.configurationsPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfigurationsList.pending, (state) => {
      return {
        ...state,
        configurationsList: null,
        configurationsListLoading: true,
      };
    }),
      builder.addCase(fetchConfigurationsList.fulfilled, (state, res) => {
        return {
          ...state,
          configurationsList: res.payload.data,
          configurationsListLoading: false,
        };
      }),
      builder.addCase(fetchConfigurationsList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          configurationsListLoading: false,
        };
      });

    builder.addCase(fetchConfigurationsTypesList.pending, (state) => {
      return {
        ...state,
        configurationsTypesList: null,
        configurationsTypesListLoading: true,
      };
    }),
      builder.addCase(fetchConfigurationsTypesList.fulfilled, (state, res) => {
        return {
          ...state,
          configurationsTypesList: res.payload.data,
          configurationsTypesListLoading: false,
        };
      }),
      builder.addCase(fetchConfigurationsTypesList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          configurationsTypesListLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { setConfigurationsPage } = configurationsSlice.actions;
