import { Grid, styled } from '@mui/material';
import { palette } from '../../theme';

export const StyledOuterContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  paddingTop: '59px',
  marginBottom: '40px',
  backgroundColor: palette.background.main,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  flexDirection: 'column',
}));

// export const StyledMidContainer = styled('div')(() => ({
//   width: '100%',
//   height: '100%',
//   maxWidth: '100%',
//   maxHeight: '100%',
// }));

export const StyledInnerContainer = styled(Grid)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: '30px 30px 30px 0px',
  minHeight: 'calc(100% - 44px)',
  overflow: 'scroll',
  overflowX: 'hidden',
}));
