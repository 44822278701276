import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Button, Input, Modal, ProgressCircle } from '../../components';
import {
  useDispatch,
  useSelector,
  authSlice,
  fetchAuthUpdatePassword,
} from '../../stores';
import { handleErrors } from '../../utils/Helpers';
import { StyledModalLoadingWrapper } from './styles';
import { passwordSchema } from './validations';

export const PasswordChangeModal: React.FC = () => {
  const dispatch = useDispatch();
  const { id, authUpdatePasswordLoading, passwordChangeIsOpen } = useSelector(
    (state) => state.authReducer,
  );
  const [formData, setFormData] = useState({
    password: '',
    newPassword: '',
    newPasswordConfirmation: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] =
    useState(false);

  const handleClose = () => {
    dispatch(authSlice.actions.togglePasswordChangeIsOpen());
  };

  const handleSubmit = () => {
    setErrors({});
    passwordSchema
      .validate(formData, { abortEarly: false })
      .then(() =>
        dispatch(
          fetchAuthUpdatePassword({
            userId: id.toString(),
            oldPassword: formData.password,
            newPassword: formData.newPassword,
          }),
        ).then(() => {
          handleClose();
        }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
        handleClose();
      });
  };

  return (
    <Modal
      open={passwordChangeIsOpen}
      onClose={handleClose}
      title='Mudança de senha'
    >
      <>
        {authUpdatePasswordLoading ? (
          <StyledModalLoadingWrapper>
            <ProgressCircle color='primary' />
          </StyledModalLoadingWrapper>
        ) : (
          <>
            <Typography
              id='modal-modal-description'
              variant='h6'
              sx={{ mt: 2, fontWeight: 'bold' }}
            >
              Preencha os campos abaixo
            </Typography>
          </>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Input
            data-testid='password'
            type={showPassword ? 'text' : 'password'}
            label='Senha atual'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formData.password}
            onChange={(InputProps) =>
              setFormData({ ...formData, password: InputProps })
            }
            errorMessage={errors && errors.password}
          />
          <Input
            data-testid='newPassword'
            type={showNewPassword ? 'text' : 'password'}
            label='Nova senha'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    onMouseDown={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formData.newPassword}
            onChange={(InputProps) =>
              setFormData({ ...formData, newPassword: InputProps })
            }
            errorMessage={errors && errors.newPassword}
          />
          <Input
            data-testid='newPasswordConfirmation'
            type={showNewPasswordConfirmation ? 'text' : 'password'}
            label='Confirme a nova senha'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() =>
                      setShowNewPasswordConfirmation(
                        !showNewPasswordConfirmation,
                      )
                    }
                    onMouseDown={() =>
                      setShowNewPasswordConfirmation(
                        !showNewPasswordConfirmation,
                      )
                    }
                  >
                    {showNewPasswordConfirmation ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formData.newPasswordConfirmation}
            onChange={(InputProps) =>
              setFormData({ ...formData, newPasswordConfirmation: InputProps })
            }
            errorMessage={errors && errors.newPasswordConfirmation}
          />
          <Button
            variant='contained'
            label='Salvar'
            type='submit'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={authUpdatePasswordLoading}
            onClick={handleSubmit}
          />
          <Button
            color='secondary'
            variant='contained'
            startIcon={<ArrowBackIcon />}
            label='Voltar'
            type='reset'
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleClose}
          />
        </Box>
      </>
    </Modal>
  );
};
