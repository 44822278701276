export const fetchCredentialsFiltersUrl = '/credentials/list/filters';
export const fetchCredentialsListUrl = '/credentials';
export const fetchCredentialsCreateUrl = '/credentials';
export const fetchCredentialsUploadUrl = '/credentials';
export const fetchCredentialsUploadDocumentUrl = '/credentials/upload/document';
export const fetchCredentialsDownloadDocumentUrl =
  '/credentials/download/document';
export const fetchCredentialsViewUrl = '/credentials';
export const fetchCredentialsRenewUrl = '/renew';
export const fetchCredentialsExportUrl = '/credentials/export/excel';
export const fetchCredentialsDeleteUrl = '/credentials';
export const fetchCredentialsCPFUrl = '/credentials/licensed';
