import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Button, Table, TableText } from '../../../components';
import {
  areaSlice,
  areasSlice,
  fetchAreaInfo,
  useDispatch,
  useSelector,
} from '../../../stores';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { SavedAreasTableToolbar } from './SavedAreasTableToolbar';

export const SavedAreasTable: React.FC = () => {
  const dispatch = useDispatch();
  const { areasList, areasListLoading, areasPage } = useSelector(
    (state) => state.areasReducer,
  );
  const responsiveMode = useResponsiveMode();
  const [rows, setRows] = useState<any[]>([]);
  const [filteredList, setFilteredList] = useState(areasList);

  useEffect(() => {
    if (areasList && areasList !== undefined) {
      setRows(areasList);
    } else setRows([]);
  }, [areasList]);

  const handleEdit = (areaId: GridRowId) => {
    dispatch(fetchAreaInfo({ areaId: areaId.toString() }));
    dispatch(areaSlice.actions.toggleAreaEditIsOpen());
  };

  const handleCancel = (areaId: GridRowId) => {
    dispatch(fetchAreaInfo({ areaId: areaId.toString() }));
    dispatch(areaSlice.actions.toggleAreaCancelIsOpen());
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => <TableText label={params.row.name} size={30} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'description',
      headerName: 'Descrição',
      editable: false,
      sortable: false,
      flex: 3,
      renderCell: (params) => <TableText label={params.row.description} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant='contained'
              label='Editar'
              startIcon={<EditIcon />}
              size='small'
              onClick={() => handleEdit(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
            <Button
              variant='contained'
              color='secondary'
              label='Excluir'
              startIcon={<DeleteIcon />}
              size='small'
              onClick={() => handleCancel(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      editable: false,
      sortable: false,
      flex: 2,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'description',
      headerName: 'Descrição',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 2,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: '',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant='contained'
              label='Editar'
              startIcon={<EditIcon />}
              size='small'
              onClick={() => handleEdit(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
            <Button
              variant='contained'
              color='secondary'
              label='Excluir'
              startIcon={<DeleteIcon />}
              size='small'
              onClick={() => handleCancel(params.id)}
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            />
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  return (
    <Table
      paginationMode='client'
      page={areasPage}
      changePage={(value: number) =>
        dispatch(areasSlice.actions.setAreasPage(value))
      }
      rows={rows}
      rowCount={filteredList ? filteredList.length : 0}
      columns={
        responsiveMode === 'phone' || responsiveMode === 'tablet'
          ? columnsResponsive
          : columns
      }
      toolbar={
        responsiveMode === 'phone' ||
        responsiveMode === 'tablet' ? undefined : (
          <SavedAreasTableToolbar />
        )
      }
      loading={areasListLoading}
    />
  );
};
