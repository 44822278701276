import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components';
import { USER_CREATE } from '../../../routes';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { StyledTableHeader } from '../styles';

export const UsersTableToolbar = () => {
  const navigate = useNavigate();
  const responsiveMode = useResponsiveMode();

  return (
    <StyledTableHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: responsiveMode === 'phone' ? 'column' : 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <Button
          variant='contained'
          label='Novo Usuário'
          startIcon={<SaveIcon />}
          onClick={() => navigate(USER_CREATE)}
          sx={{ width: responsiveMode === 'phone' ? '100%' : 'auto' }}
        />
      </div>
    </StyledTableHeader>
  );
};
