import { Popover, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { palette } from '../../../../theme';
import { StyledMarkerDiv, StyledMapMarker } from './styles';

export const Marker = (props: { key: number; lat: number; lng: number }) => {
  // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  return (
    <StyledMarkerDiv>
      {/* <StyledMapMarker key={props.key} onClick={handleClick}> */}
      <StyledMapMarker key={props.key}>
        {/* {props.info.login} */}
      </StyledMapMarker>
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginLeft: 1,
        }}
      >
        <div
          style={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            backgroundColor: palette.primary.main,
            // border: `3px solid ${palette.secondary.main}`,
          }}
        >
          <Typography
            sx={{ color: 'white', fontSize: '14px', paddingBottom: '5px' }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Login:</Typography>{' '}
            {props.info.login}
          </Typography>
          <Typography
            sx={{ color: 'white', fontSize: '14px', paddingBottom: '5px' }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Nome:</Typography>{' '}
            {props.info.name}
          </Typography>
          <Typography
            sx={{ color: 'white', fontSize: '14px', paddingBottom: '5px' }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Início:</Typography>{' '}
            {moment(props.info.startDateTime)
              .format('DD/MM/yyyy HH:mm')
              .toString()}
          </Typography>
          <Typography
            sx={{ color: 'white', fontSize: '14px', paddingBottom: '5px' }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>Fim:</Typography>{' '}
            {!props.info.finalDateTime ||
            props.info.finalDateTime === '0001-01-01 00:00:00'
              ? 'Em aberto'
              : moment(props.info.finalDateTime)
                  .format('DD/MM/yyyy HH:mm')
                  .toString()}
          </Typography>
        </div>
      </Popover> */}
    </StyledMarkerDiv>
  );
};
