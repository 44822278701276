import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { findIndex, map, split } from 'lodash';
import { fetchUsersListService } from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from '../Store';
import { UsersFiltersValuesFetchType, UsersListStateType } from './types';

const initialState: UsersListStateType = {
  usersList: null,
  usersListLoading: false,
  usersPage: 1,
};

export const fetchUsersList = createAsyncThunk(
  'usersList',
  async (params: UsersFiltersValuesFetchType, { getState }) => {
    const state = getState() as RootState;
    return await fetchUsersListService({
      ...params,
      page: state.usersListReducer.usersPage.toString(),
    });
  },
);

export const usersListSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    setUsersPage(state, action: PayloadAction<number>) {
      state.usersPage = action.payload;
    },
    setUserBlock(state, action: PayloadAction<{ userId: string }>) {
      if (state.usersList) {
        const idx = findIndex(state.usersList.page, {
          id: parseInt(action.payload.userId),
        });
        const page = state.usersList.page;
        page[idx] = {
          id: state.usersList.page[idx].id,
          login: state.usersList.page[idx].login,
          name: state.usersList.page[idx].name,
          appBlock: state.usersList.page[idx].appBlock === 0 ? 1 : 0,
          areas: state.usersList.page[idx].areas,
          profileId: state.usersList.page[idx].profileId,
        };
        const usersList = {
          ...state.usersList,
          page: page,
        };

        state.usersList = usersList;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsersList.pending, (state) => {
      return {
        ...state,
        usersList: null,
        usersListLoading: true,
      };
    }),
      builder.addCase(fetchUsersList.fulfilled, (state, res) => {
        const handledPage = map(res.payload.data.page, (item) => {
          return {
            id: item.id,
            login: item.login,
            name: item.name,
            appBlock: item.appBlock,
            areas: split(item.areas, '|'),
            profileId: item.profileId,
          };
        });
        return {
          ...state,
          usersList: {
            size: res.payload.data.size,
            page: handledPage,
          },
          usersListLoading: false,
        };
      }),
      builder.addCase(fetchUsersList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          usersListLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { setUsersPage, setUserBlock } = usersListSlice.actions;
