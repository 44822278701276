/**
 * theme for MUI
 * TODO: create a theme object as per designs
 */
import { ptBR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { shade } from 'polished';
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-data-grid/themeAugmentation';
import logoJump from '../assets/images/logo_jump.png';
import logoNiteroiRotativo from '../assets/images/logo_niteroi_rotativo.png';

const flavor = process.env.REACT_APP_FLAVOR;

const jumpTheme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#1E5168',
        contrastText: '#fff',
      },
      secondary: {
        main: '#13BC4B',
        contrastText: '#fff',
      },
      error: {
        main: '#e62929',
        contrastText: '#fff',
      },
      success: {
        main: '#50eb6f',
        contrastText: '#fff',
      },
      warning: {
        main: '#e8d22e',
        contrastText: '#fff',
      },
      divider: '#e0e0e0',
      text: {
        main: '#666666',
        contrastText: '#fff',
      },
      background: {
        main: '#F3F3F3',
        contrastText: '#000',
      },
      action: {
        disabledBackground: shade(0.2, '#0069a6'),
        // disabled: 'set color of text here'
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h2',
            h2: 'h2',
            h3: 'h2',
            h4: 'h2',
            h5: 'h2',
            h6: 'h2',
            subtitle1: 'h2',
            subtitle2: 'h2',
            body1: 'span',
            body2: 'span',
          },
        },
      },
    },
    typography: {
      button: {
        textTransform: 'none',
        // Using this will stop tranforming all button texts to UPPERCASE
      },
    },
  },
  ptBR,
);

const niteroiTheme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#0069a6',
        contrastText: '#fff',
      },
      secondary: {
        main: '#ea8817',
        contrastText: '#fff',
      },
      error: {
        main: '#e62929',
        contrastText: '#fff',
      },
      success: {
        main: '#1aca3d',
        contrastText: '#fff',
      },
      warning: {
        main: '#e8d22e',
        contrastText: '#fff',
      },
      divider: '#e0e0e0',
      text: {
        main: '#666666',
        contrastText: '#fff',
      },
      background: {
        main: '#F3F3F3',
        contrastText: '#000',
      },
      action: {
        disabledBackground: shade(0.2, '#0069a6'),
        // disabled: 'set color of text here'
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h2',
            h2: 'h2',
            h3: 'h2',
            h4: 'h2',
            h5: 'h2',
            h6: 'h2',
            subtitle1: 'h2',
            subtitle2: 'h2',
            body1: 'span',
            body2: 'span',
          },
        },
      },
    },
    typography: {
      button: {
        textTransform: 'none',
        // Using this will stop tranforming all button texts to UPPERCASE
      },
    },
  },
  ptBR,
);

export const palette =
  flavor === 'Niterói Rotativo' ? niteroiTheme.palette : jumpTheme.palette;

export const theme = flavor === 'Niterói Rotativo' ? niteroiTheme : jumpTheme;

export const logo =
  flavor === 'Niterói Rotativo' ? logoNiteroiRotativo : logoJump;

export const title =
  flavor === 'Niterói Rotativo' ? 'Niterói Rotativo' : 'Jump Tecnologia';

export const logoUrl =
  flavor === 'Niterói Rotativo'
    ? 'https://jumpparkapi.sfo2.digitaloceanspaces.com/uploads/logos/logo_niteroirotativo.png'
    : 'https://jumpparkapi.sfo2.digitaloceanspaces.com/uploads/jumppark/0qrsY8RG9hfwfrnzdW9GTXS5t5uw7O1BBVHIIftW.png';
