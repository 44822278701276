import { useWorker } from '@koale/useworker';
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import { PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components';
import { ROUTE_CREATE } from '../../../routes';
import {
  fetchRoutesExportActiveVacancies,
  routesListSlice,
  useDispatch,
  useSelector,
} from '../../../stores';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { StyledTableHeader } from '../styles';
import {
  exportActiveVacanciesToCSV,
  handleApiDataActiveVacanciesToCSV,
} from './exports';

export const RoutesTableToolbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { filters } = useSelector((state) => state.routesFiltersReducer);
  const responsiveMode = useResponsiveMode();
  const [exportRoutesActiveVacanciesToCSVWorker] = useWorker(
    handleApiDataActiveVacanciesToCSV,
  );

  const handleExportOperatingVacanciesCSV = () => {
    dispatch(
      routesListSlice.actions.toggleRoutesOperatingVacanciesExportIsOpen(),
    );
  };

  const handleExportActiveVacanciesCSV = () => {
    dispatch(fetchRoutesExportActiveVacancies()).then(
      (res: PayloadAction<any>) => {
        if (res.payload.data) {
          exportActiveVacanciesToCSV(
            res.payload.data,
            filters?.vacancyTypes,
            dispatch,
            exportRoutesActiveVacanciesToCSVWorker,
          ).then(() => {
            dispatch(routesListSlice.actions.toggleExportModalIsOpen());
          });
        } else dispatch(routesListSlice.actions.toggleExportModalIsOpen());
      },
    );
  };

  return (
    <StyledTableHeader>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: responsiveMode === 'phone' ? 'column' : 'row',
          justifyContent:
            responsiveMode !== 'phone' ? 'space-between' : 'flex-end',
          alignItems: 'center',
          marginTop: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Button
            variant='contained'
            label='Relatório de vagas ativas'
            startIcon={<DownloadIcon />}
            onClick={handleExportActiveVacanciesCSV}
            // loading={notificationsExportHTMLLoading}
          />
          <Button
            variant='contained'
            label='Relatório de vagas operantes'
            startIcon={<DownloadIcon />}
            onClick={handleExportOperatingVacanciesCSV}
            // loading={notificationsExportExcelLoading}
          />
        </div>
        <Button
          variant='contained'
          label='Novo trecho'
          startIcon={<SaveIcon />}
          onClick={() => navigate(ROUTE_CREATE)}
          sx={{ width: 'auto' }}
        />
      </div>
    </StyledTableHeader>
  );
};
