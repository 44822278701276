import { api } from '..';
import { CredentialFiltersValueStateType } from '../../stores';

import {
  fetchCredentialsFiltersUrl,
  fetchCredentialsListUrl,
  fetchCredentialsCreateUrl,
  fetchCredentialsUploadUrl,
  fetchCredentialsUploadDocumentUrl,
  fetchCredentialsDownloadDocumentUrl,
  fetchCredentialsViewUrl,
  fetchCredentialsRenewUrl,
  fetchCredentialsExportUrl,
  fetchCredentialsDeleteUrl,
  fetchCredentialsCPFUrl,
} from './constants';

import {
  CredentialsFiltersResponseType,
  CredentialsListResponseType,
  CredentialsCreateResponseType,
  CredentialsUpdateResponseType,
  CredentialsUploadDocumentResponseType,
  CredentialsViewResponseType,
  CredentialsRenewResponseType,
  CredentialsExportResponseType,
  CredentialsDeleteResponseType,
  CredentialsCPFResponseType,
} from './types';

export const fetchCredentialsFiltersService = async () => {
  const response = await api.get<CredentialsFiltersResponseType>(
    fetchCredentialsFiltersUrl,
  );
  return response.data;
};

export const fetchCredentialsListService = async (params: {
  number?: string;
  plate?: string;
  userName?: string;
  userDocument?: string;
  address?: string;
  routeId?: string;
  validity?: string;
  status?: '' | 'N' | 'S';
  page?: string;
}) => {
  const response = await api.get<CredentialsListResponseType>(
    fetchCredentialsListUrl,
    { params },
  );
  return response.data;
};

export const fetchCredentialsCreateService = async (params: {
  validity: string;
  delimited: number;
  vehiclePlate: string;
  vehicleBrand: string;
  vehicleColor: string;
  userName: string;
  userDocument: string;
  userPhone: string;
  documents: string;
  addressCode: string;
  address: string;
  addressNumber: string;
  addressComplement: string;
  district: string;
  city: string;
  state: string;
  observation: string;
  routes: number[];
}) => {
  const response = await api.post<CredentialsCreateResponseType>(
    fetchCredentialsCreateUrl,
    params,
  );
  return response.data;
};

export const fetchCredentialsUpdateService = async (params: {
  id: number;
  validity: string;
  delimited: number;
  vehiclePlate: string;
  vehicleBrand: string;
  vehicleColor: string;
  userName: string;
  userDocument: string;
  userPhone: string;
  documents: string;
  addressCode: string;
  address: string;
  addressNumber: string;
  addressComplement: string;
  district: string;
  city: string;
  state: string;
  observation: string;
  routes: number[];
}) => {
  const response = await api.put<CredentialsUpdateResponseType>(
    `${fetchCredentialsUploadUrl}/${params.id}`,
    params,
  );
  return response.data;
};

export const fetchCredentialsUploadDocumentService = async (
  params: FormData | undefined,
) => {
  const response = await api.post<CredentialsUploadDocumentResponseType>(
    fetchCredentialsUploadDocumentUrl,
    params,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const fetchCredentialsDownloadDocumentService = async (params: {
  fileName: string;
}) => {
  const response = await api.get(
    `${fetchCredentialsDownloadDocumentUrl}?fileName=${params.fileName}`,
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return response.data;
};

export const fetchCredentialsViewService = async (id: number) => {
  const response = await api.get<CredentialsViewResponseType>(
    `${fetchCredentialsViewUrl}/${id}`,
  );
  return response.data;
};

export const fetchCredentialsRenewService = async (params: {
  id: number;
  validity: string;
}) => {
  const { id, validity } = params;

  const response = await api.put<CredentialsRenewResponseType>(
    `/credentials/${id}${fetchCredentialsRenewUrl}`,
    { validity },
  );
  return response.data;
};

export const fetchCredentialsExportService = async (params: {
  number: number;
  plate: string;
  userName: string;
  userDocument: string;
  address: string;
  routeId: string;
  validity: string;
}) => {
  const { number, plate, userName, userDocument, address, routeId, validity } =
    params;

  const response = await api.get<CredentialsExportResponseType[]>(
    `${fetchCredentialsExportUrl}?number=${number}&plate=${plate}&userName=${userName}&userDocument=${userDocument}&address=${address}&routeId=${routeId}&validity=${validity}`,
  );
  return response.data;
};

export const fetchCredentialsExportExcelService = async (
  params: CredentialFiltersValueStateType,
) => {
  const { number, plate, name, document, street, routesId, validitiesId } =
    params;

  const response = await api.get<CredentialsExportResponseType[]>(
    `${fetchCredentialsExportUrl}?number=${number}&plate=${plate}&userName=${name}&userDocument=${document}&address=${street}&routeId=${routesId}&validity=${validitiesId}`,
  );

  return response.data;
};

export const fetchCredentialsExportHtmlService = async (
  params: CredentialFiltersValueStateType,
) => {
  const { number, plate, name, document, street, routesId, validitiesId } =
    params;

  const response = await api.get<CredentialsExportResponseType[]>(
    `${fetchCredentialsExportUrl}?number=${number}&plate=${plate}&userName=${name}&userDocument=${document}&address=${street}&routeId=${routesId}&validity=${validitiesId}`,
  );

  return response.data;
};

export const fetchCredentialsDeleteService = async (params: { id: number }) => {
  const response = await api.delete<CredentialsDeleteResponseType>(
    `${fetchCredentialsDeleteUrl}/${params.id}`,
  );
  return response.data;
};

export const fetchCredentialsCPFService = async (params: { cpf: string }) => {
  const response = await api.get<CredentialsCPFResponseType>(
    `${fetchCredentialsCPFUrl}/${params.cpf}`,
  );

  return response.data;
};
