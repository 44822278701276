import Axios from 'axios';
import VMasker from 'vanilla-masker';

import { fetchAddressUrl } from './constants';
import { FetchAddressResponse } from './types';

export const fetchAddress = async (postalCode: string) => {
  const url = fetchAddressUrl(+VMasker.toNumber(postalCode));

  const response = await Axios.get<FetchAddressResponse>(url);
  return response.data;
}