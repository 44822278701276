import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import {
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Skeleton,
} from '@mui/material';
import { omit, times } from 'lodash';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, Dropdown, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  notificationSlice,
  fetchNotificationsList,
  fetchNotificationEditVehicleType,
} from '../../../stores';
import { handleErrors } from '../../../utils/Helpers';
import {
  StyledModalInnerWrapper,
  StyledModalTextRow,
  StyledModalWrapper,
} from '../styles';

export const NotificationEditVehicleTypeModal: React.FC = () => {
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state.authReducer);
  const { filters } = useSelector((state) => state.notificationsFiltersReducer);
  const {
    notificationInfo,
    notificationEditVehicleTypeLoading,
    notificationEditVehicleTypeIsOpen,
  } = useSelector((state) => state.notificationReducer);
  const filtersValues = useSelector(
    (state) => state.notificationsFiltersValuesReducer,
  );
  const [formData, setFormData] = useState({
    type: '',
    password: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object({
    type: Yup.string()
      .required('Selecione o novo tipo do veículo')
      .required('O tipo do veiculo é obrigatório'),
    password: Yup.string()
      .required('Insira sua senha')
      .required('A senha é obrigatória'),
  });

  const handleClose = () => {
    setErrors({});
    setFormData({
      type: '',
      password: '',
    });
    notificationSlice.actions.setNotificationEditVehicleTypeId({
      notificationId: '',
    });
    dispatch(
      notificationSlice.actions.toggleNotificationEditVehicleTypeIsOpen(),
    );
  };

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(
        () =>
          notificationInfo &&
          dispatch(
            fetchNotificationEditVehicleType({
              notificationId: notificationInfo.id,
              type: formData.type,
              login: login,
              password: formData.password,
            }),
          ).then(() => {
            handleClose();
            dispatch(fetchNotificationsList(omit(filtersValues, ['days'])));
          }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    setErrors({});
    setFormData({
      type: '',
      password: '',
    });
    notificationSlice.actions.setNotificationEditVehicleTypeId({
      notificationId: '',
    });
    dispatch(
      notificationSlice.actions.toggleNotificationEditVehicleTypeIsOpen(),
    );
    dispatch(notificationSlice.actions.toggleNotificationInfoIsOpen());
  };

  return (
    <Modal
      open={notificationEditVehicleTypeIsOpen}
      onClose={handleClose}
      title='Edição de tipo de veículo'
    >
      <>
        {notificationEditVehicleTypeLoading ? (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              {times(1, () => (
                <Skeleton animation='wave' height={29} width='100%' />
              ))}
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        ) : (
          <StyledModalWrapper>
            <StyledModalInnerWrapper>
              <StyledModalTextRow>
                <Typography color='primary' sx={{ fontWeight: 'bold' }}>
                  Placa:
                </Typography>
                <Typography>
                  {notificationInfo?.plate}{' '}
                  {notificationInfo?.type && `(${notificationInfo?.type})`}
                </Typography>
              </StyledModalTextRow>
            </StyledModalInnerWrapper>
          </StyledModalWrapper>
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: '10px',
          }}
        >
          <Dropdown
            label='Novo tipo de veículo'
            value={formData.type}
            options={omit(
              filters?.vehicleTypes,
              notificationInfo?.type ? ['', notificationInfo?.type] : [''],
            )}
            onChange={(value) => setFormData({ ...formData, type: value })}
            errorMessage={errors && errors.type}
          />
          <Input
            data-testid='password'
            type={showPassword ? 'text' : 'password'}
            label='Insira sua senha'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formData.password}
            onChange={(InputProps) =>
              setFormData({ ...formData, password: InputProps })
            }
            errorMessage={errors && errors.password}
          />
          <Button
            variant='contained'
            label='Salvar'
            startIcon={<SaveIcon />}
            type='submit'
            style={{ width: '100%', margin: 0, marginTop: 30 }}
            loading={notificationEditVehicleTypeLoading}
            onClick={handleSubmit}
          />
          <Button
            color='secondary'
            variant='contained'
            startIcon={<ArrowBackIcon />}
            label='Voltar'
            type='reset'
            style={{ width: '100%', margin: 0, marginTop: 10 }}
            onClick={handleReturn}
          />
        </Box>
      </>
    </Modal>
  );
};
