import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

export const CoordsInput = (props: {
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  onChangeCoords?: (value: {
    address: string;
    lat: number;
    lng: number;
  }) => void;
  onClick?: () => void;
  margin?: 'none' | 'normal' | 'dense';
  errorMessage?: string;
  type?: string;
  shrink?: boolean;
  InputProps?: any;
  select?: boolean;
  disabled?: boolean;
  startAdornment?: any;
  variant?: 'outlined' | 'filled' | 'standard';
  [x: string]: any;
}) => {
  const {
    onChange,
    onChangeCoords,
    errorMessage,
    shrink,
    onClick,
    margin,
    ...otherProps
  } = props;

  const { ref } = usePlacesWidget({
    apiKey: `${process.env.REACT_APP_GOOGLE_KEY}`,
    onPlaceSelected: (place) => {
      if (
        place !== undefined &&
        place.geometry !== undefined &&
        place.geometry.location !== undefined
      ) {
        if (onChangeCoords) {
          onChangeCoords({
            address: place.formatted_address || '',
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
        }
      }
    },
    options: {
      types: [],
      componentRestrictions: { country: 'br' }, // show only results for country Brazil
    },
    language: 'pt-BR',
  });

  return (
    <div>
      <TextField
        inputRef={ref}
        onChange={(e) => onChange && onChange(e.target.value)}
        color='primary'
        fullWidth
        variant='filled'
        size='small'
        margin={margin === undefined ? 'normal' : margin}
        error={errorMessage !== undefined}
        helperText={errorMessage}
        InputLabelProps={{ shrink: shrink }}
        onClick={onClick}
        {...otherProps}
      />
    </div>
  );
};
