/* eslint-disable pii/no-phone-number */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Grid,
  Typography,
  Skeleton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';
import { find, forEach, includes, map, replace } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VMasker from 'vanilla-masker';
import {
  Button,
  Checkbox,
  Input,
  PageWrapper,
  TimePicker,
} from '../../../components';
import { CONFIGURATION_UPDATE } from '../../../routes';
import {
  fetchConfigurationCreate,
  fetchConfigurationUpdate,
  fetchConfigurationsTypesList,
  useDispatch,
  useSelector,
} from '../../../stores';
import { palette } from '../../../theme';
import { handleErrors } from '../../../utils/Helpers';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { periods } from './constants';
import { pageSchema, priceSchema, workingPeriodSchema } from './validations';

export const ConfigurationUpsert: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    configurationsListLoading,
    configurationsTypesList,
    configurationsTypesListLoading,
  } = useSelector((state) => state.configurationsReducer);
  const { configurationInfo } = useSelector(
    (state) => state.configurationReducer,
  );
  const responsiveMode = useResponsiveMode();
  const location = useLocation();
  const [formData, setFormData] = useState<{
    id: number;
    name: string;
    prices: {
      type: string;
      period: string;
      price: string;
      errors: { [key: string]: string | undefined };
    }[];
    workingPeriods: {
      startTime: string;
      endTime: string;
      days: {
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
      };
      errors: { [key: string]: string | undefined };
    }[];
  }>({
    id: 0,
    name: '',
    prices: [],
    workingPeriods: [],
  });
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {},
  );
  const [title, setTitle] = useState('Adicionar configuração');

  const addNewEmptyPriceRow = () => {
    const newPrices = formData.prices;
    newPrices.push({
      type: '',
      period: '',
      price: '',
      errors: {},
    });
    setFormData({
      ...formData,
      prices: newPrices,
    });
    setErrors({ ...errors, prices: '' });
  };

  const addNewEmptyWorkingPeriodRow = () => {
    const newWorkingPeriods = formData.workingPeriods;
    newWorkingPeriods.push({
      startTime: '',
      endTime: '',
      days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      errors: {},
    });
    setFormData({
      ...formData,
      workingPeriods: newWorkingPeriods,
    });
    setErrors({ ...errors, workingPeriods: '' });
  };

  useEffect(() => {
    dispatch(fetchConfigurationsTypesList());
    if (location.pathname === CONFIGURATION_UPDATE) {
      setTitle('Editar configuração');

      const handledPrices =
        configurationInfo && configurationInfo.prices
          ? map(configurationInfo.prices, (item) => {
              return {
                type: item.type,
                period: item.period,
                price: VMasker.toMoney(
                  parseFloat(item.price).toFixed(2).toString(),
                ),
                errors: {},
              };
            })
          : [];

      const handledWorkingPeriods =
        configurationInfo && configurationInfo.workingPeriods
          ? map(configurationInfo.workingPeriods, (item) => {
              return {
                startTime: item.startTime,
                endTime: item.endTime,
                days: {
                  monday: includes(item.days, '1'),
                  tuesday: includes(item.days, '2'),
                  wednesday: includes(item.days, '3'),
                  thursday: includes(item.days, '4'),
                  friday: includes(item.days, '5'),
                  saturday: includes(item.days, '6'),
                  sunday: includes(item.days, '7'),
                },
                errors: {},
              };
            })
          : [];

      setFormData({
        id:
          configurationInfo && configurationInfo.id ? configurationInfo.id : 0,
        name:
          configurationInfo && configurationInfo.name
            ? configurationInfo.name
            : '',
        prices: handledPrices,
        workingPeriods: handledWorkingPeriods,
      });
    }
  }, []);

  const periodDaysHasAtLeastOneTrue = (days: boolean[]) => {
    let hasAtLeastOneTrue = false;
    find(days, (day) => {
      if (day) hasAtLeastOneTrue = true;
    });
    return hasAtLeastOneTrue;
  };

  const handleSubmit = () => {
    setErrors({});
    let pricesHasErrors = false;
    let workingPeriodsHasErrors = false;

    forEach(formData.prices, (item: any, index: number) => {
      priceSchema.validate(item, { abortEarly: false }).catch((err) => {
        pricesHasErrors = true;
        const handledPrices = formData.prices;
        handledPrices[index].errors = handleErrors(err);
        setFormData({
          ...formData,
          prices: handledPrices,
        });
      });
    });
    forEach(formData.workingPeriods, (item: any, index: number) => {
      workingPeriodSchema.validate(item, { abortEarly: false }).catch((err) => {
        workingPeriodsHasErrors = true;
        const handledWorkingPeriods = formData.workingPeriods;
        handledWorkingPeriods[index].errors = handleErrors(err);
        if (!periodDaysHasAtLeastOneTrue(item.days)) {
          handledWorkingPeriods[index].errors = {
            ...handledWorkingPeriods[index].errors,
            days: 'Selecione pelo menos um dia',
          };
        }
        setFormData({
          ...formData,
          workingPeriods: handledWorkingPeriods,
        });
      });
    });
    pageSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        if (!pricesHasErrors && !workingPeriodsHasErrors) {
          const handledPrices = map(formData.prices, (item) => {
            return {
              type: item.type,
              period: item.period,
              price: replace(item.price.replaceAll('.', ''), ',', '.'),
            };
          });
          const handledWorkingPeriods: {
            startTime: string;
            endTime: string;
            days: string[];
          }[] = map(formData.workingPeriods, (item) => {
            const days = [];
            if (item.days.monday) days.push('1');
            if (item.days.tuesday) days.push('2');
            if (item.days.wednesday) days.push('3');
            if (item.days.thursday) days.push('4');
            if (item.days.friday) days.push('5');
            if (item.days.saturday) days.push('6');
            if (item.days.sunday) days.push('7');

            return {
              startTime: item.startTime,
              endTime: item.endTime,
              days: days,
            };
          });

          if (location.pathname === CONFIGURATION_UPDATE) {
            dispatch(
              fetchConfigurationUpdate({
                configurationId: formData.id.toString(),
                name: formData.name,
                prices: handledPrices,
                workingPeriods: handledWorkingPeriods,
              }),
            ).then(() => {
              navigate(-1);
            });
          } else {
            dispatch(
              fetchConfigurationCreate({
                name: formData.name,
                prices: handledPrices,
                workingPeriods: handledWorkingPeriods,
              }),
            ).then(() => {
              navigate(-1);
            });
          }
        }
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    navigate(-1);
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <SettingsIcon fontSize='large' sx={{ marginRight: 2 }} />
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container margin={3}>
        {configurationsListLoading || configurationsTypesListLoading ? (
          <Skeleton animation='wave' width='100%' height='300px' />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table
                // sx={{ minWidth: 500 }}
                size='small'
                aria-label='a dense table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box
                        sx={{
                          width: '100%',
                          padding: 2,
                          borderBottom: 0.1,
                          borderColor: palette.divider,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Box flex={2} sx={{ maxWidth: '400px' }}>
                          <Input
                            data-testid='id'
                            type='text'
                            margin='normal'
                            label='Nome da configuração'
                            value={formData.name}
                            onChange={(value) => {
                              setErrors({ ...errors, name: '' });
                              setFormData({ ...formData, name: value });
                            }}
                            error={
                              errors &&
                              errors.name !== undefined &&
                              errors.name !== ''
                            }
                            helperText={errors && errors.name}
                          />
                        </Box>
                        <Box
                          flex={1}
                          sx={{
                            maxWidth: '300px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            variant='contained'
                            label='Salvar'
                            startIcon={<SaveIcon />}
                            style={{ width: '100%' }}
                            // loading={areaCancelLoading}
                            onClick={handleSubmit}
                          />
                          <Button
                            color='secondary'
                            variant='contained'
                            startIcon={<ArrowBackIcon />}
                            label='Voltar'
                            style={{ width: '100%' }}
                            onClick={handleReturn}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} sx={{ paddingTop: 5 }}>
                      <Button
                        variant='text'
                        startIcon={<AddCircleIcon />}
                        label='Novo período de cobrança'
                        style={{ margin: 0 }}
                        // loading={areaCancelLoading}
                        onClick={addNewEmptyWorkingPeriodRow}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Dias da semana
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: '120px' }}>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Horário Inicial
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: '120px' }}>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Horário Final
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.workingPeriods.length > 0 ? (
                    map(formData.workingPeriods, (item, index) => {
                      return (
                        <TableRow
                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: palette.action.hover,
                            },
                            '&:last-child td, &:last-child th': {
                              borderBottom: 0.1,
                              borderColor: palette.divider,
                            },
                            height: '55px',
                          }}
                        >
                          <TableCell
                            component='th'
                            scope='row'
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                // height: '31.5px',
                                borderRadius: '4px',
                                paddingRight:
                                  responsiveMode === 'phone' ||
                                  responsiveMode === 'tablet'
                                    ? 0
                                    : '10px',
                                display:
                                  responsiveMode === 'phone' ||
                                  responsiveMode === 'tablet'
                                    ? 'block'
                                    : 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                border:
                                  item.errors &&
                                  item.errors.days !== undefined &&
                                  item.errors.days !== ''
                                    ? '1px solid red'
                                    : 0,
                              }}
                            >
                              <Checkbox
                                label='Seg'
                                checked={
                                  formData.workingPeriods[index].days.monday
                                }
                                onChange={() => {
                                  const handledWorkingPeriods =
                                    formData.workingPeriods;
                                  handledWorkingPeriods[index] = {
                                    ...item,
                                    days: {
                                      ...item.days,
                                      monday: !item.days.monday,
                                    },
                                  };
                                  handledWorkingPeriods[index].errors = {
                                    ...formData.workingPeriods[index].errors,
                                    days: '',
                                  };
                                  setFormData({
                                    ...formData,
                                    workingPeriods: handledWorkingPeriods,
                                  });
                                }}
                              />
                              <Checkbox
                                label='Ter'
                                checked={
                                  formData.workingPeriods[index].days.tuesday
                                }
                                onChange={() => {
                                  const handledWorkingPeriods =
                                    formData.workingPeriods;
                                  handledWorkingPeriods[index] = {
                                    ...item,
                                    days: {
                                      ...item.days,
                                      tuesday: !item.days.tuesday,
                                    },
                                  };
                                  handledWorkingPeriods[index].errors = {
                                    ...formData.workingPeriods[index].errors,
                                    days: '',
                                  };
                                  setFormData({
                                    ...formData,
                                    workingPeriods: handledWorkingPeriods,
                                  });
                                }}
                              />
                              <Checkbox
                                label='Qua'
                                checked={
                                  formData.workingPeriods[index].days.wednesday
                                }
                                onChange={() => {
                                  const handledWorkingPeriods =
                                    formData.workingPeriods;
                                  handledWorkingPeriods[index] = {
                                    ...item,
                                    days: {
                                      ...item.days,
                                      wednesday: !item.days.wednesday,
                                    },
                                  };
                                  handledWorkingPeriods[index].errors = {
                                    ...formData.workingPeriods[index].errors,
                                    days: '',
                                  };
                                  setFormData({
                                    ...formData,
                                    workingPeriods: handledWorkingPeriods,
                                  });
                                }}
                              />
                              <Checkbox
                                label='Qui'
                                checked={
                                  formData.workingPeriods[index].days.thursday
                                }
                                onChange={() => {
                                  const handledWorkingPeriods =
                                    formData.workingPeriods;
                                  handledWorkingPeriods[index] = {
                                    ...item,
                                    days: {
                                      ...item.days,
                                      thursday: !item.days.thursday,
                                    },
                                  };
                                  handledWorkingPeriods[index].errors = {
                                    ...formData.workingPeriods[index].errors,
                                    days: '',
                                  };
                                  setFormData({
                                    ...formData,
                                    workingPeriods: handledWorkingPeriods,
                                  });
                                }}
                              />
                              <Checkbox
                                label='Sex'
                                checked={
                                  formData.workingPeriods[index].days.friday
                                }
                                onChange={() => {
                                  const handledWorkingPeriods =
                                    formData.workingPeriods;
                                  handledWorkingPeriods[index] = {
                                    ...item,
                                    days: {
                                      ...item.days,
                                      friday: !item.days.friday,
                                    },
                                  };
                                  handledWorkingPeriods[index].errors = {
                                    ...formData.workingPeriods[index].errors,
                                    days: '',
                                  };
                                  setFormData({
                                    ...formData,
                                    workingPeriods: handledWorkingPeriods,
                                  });
                                }}
                              />
                              <Checkbox
                                label='Sáb'
                                checked={
                                  formData.workingPeriods[index].days.saturday
                                }
                                onChange={() => {
                                  const handledWorkingPeriods =
                                    formData.workingPeriods;
                                  handledWorkingPeriods[index] = {
                                    ...item,
                                    days: {
                                      ...item.days,
                                      saturday: !item.days.saturday,
                                    },
                                  };
                                  handledWorkingPeriods[index].errors = {
                                    ...formData.workingPeriods[index].errors,
                                    days: '',
                                  };
                                  setFormData({
                                    ...formData,
                                    workingPeriods: handledWorkingPeriods,
                                  });
                                }}
                              />
                              <Checkbox
                                label='Dom'
                                checked={
                                  formData.workingPeriods[index].days.sunday
                                }
                                onChange={() => {
                                  const handledWorkingPeriods =
                                    formData.workingPeriods;
                                  handledWorkingPeriods[index] = {
                                    ...item,
                                    days: {
                                      ...item.days,
                                      sunday: !item.days.sunday,
                                    },
                                  };
                                  handledWorkingPeriods[index].errors = {
                                    ...formData.workingPeriods[index].errors,
                                    days: '',
                                  };
                                  setFormData({
                                    ...formData,
                                    workingPeriods: handledWorkingPeriods,
                                  });
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell component='th' scope='row'>
                            <TimePicker
                              value={formData.workingPeriods[index].startTime}
                              onChange={(value) => {
                                const handledWorkingPeriods =
                                  formData.workingPeriods;
                                handledWorkingPeriods[index].startTime = value;
                                handledWorkingPeriods[index].errors = {
                                  ...formData.workingPeriods[index].errors,
                                  startTime: '',
                                };
                                setFormData({
                                  ...formData,
                                  workingPeriods: handledWorkingPeriods,
                                });
                              }}
                              variant='outlined'
                              margin='none'
                              error={
                                item.errors &&
                                item.errors.startTime !== undefined &&
                                item.errors.startTime !== ''
                              }
                            />
                          </TableCell>
                          <TableCell
                            component='th'
                            scope='row'
                            sx={{ padding: 0 }}
                          >
                            <TimePicker
                              value={formData.workingPeriods[index].endTime}
                              onChange={(value) => {
                                const handledWorkingPeriods =
                                  formData.workingPeriods;
                                handledWorkingPeriods[index].endTime = value;
                                handledWorkingPeriods[index].errors = {
                                  ...formData.workingPeriods[index].errors,
                                  endTime: '',
                                };
                                setFormData({
                                  ...formData,
                                  workingPeriods: handledWorkingPeriods,
                                });
                              }}
                              variant='outlined'
                              margin='none'
                              error={
                                item.errors &&
                                item.errors.endTime !== undefined &&
                                item.errors &&
                                item.errors.endTime !== ''
                              }
                            />
                          </TableCell>
                          <TableCell
                            component='th'
                            scope='row'
                            align='right'
                            sx={{ width: '35px', padding: 0 }}
                          >
                            <IconButton
                              color='secondary'
                              aria-label='Remover linha'
                              onClick={() => {
                                const handledWorkingPeriods =
                                  formData.workingPeriods;
                                handledWorkingPeriods.splice(index, 1);
                                setFormData({
                                  ...formData,
                                  workingPeriods: handledWorkingPeriods,
                                });
                              }}
                            >
                              <CancelIcon fontSize='large' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: palette.action.hover,
                        },
                        '&:last-child td, &:last-child th': {
                          borderBottom:
                            errors &&
                            errors.workingPeriods !== undefined &&
                            errors.workingPeriods !== ''
                              ? 2
                              : 1,
                          borderColor:
                            errors &&
                            errors.workingPeriods !== undefined &&
                            errors.workingPeriods !== ''
                              ? 'red'
                              : palette.divider,
                        },
                      }}
                    >
                      <TableCell
                        align='center'
                        colSpan={4}
                        sx={{ height: '55px' }}
                      >
                        <Typography
                          sx={{
                            color:
                              errors &&
                              errors.workingPeriods !== undefined &&
                              errors.workingPeriods !== ''
                                ? 'red'
                                : 'grey',
                          }}
                        >
                          Nenhum período de cobrança inserido
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Table
                // sx={{ minWidth: 500 }}
                size='small'
                aria-label='a dense table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4} sx={{ paddingTop: 5 }}>
                      <Button
                        variant='text'
                        startIcon={<AddCircleIcon />}
                        label='Novo horário de funcionamento'
                        style={{ margin: 0 }}
                        // loading={areaCancelLoading}
                        onClick={addNewEmptyPriceRow}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Período
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Preço
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Categoria
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.prices.length > 0 ? (
                    map(formData.prices, (item, index) => {
                      return (
                        <TableRow
                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: palette.action.hover,
                            },
                            '&:last-child td, &:last-child th': {
                              borderBottom: 0.1,
                              borderColor: palette.divider,
                            },
                            height: '55px',
                          }}
                        >
                          <TableCell
                            component='th'
                            scope='row'
                            sx={{ padding: '0 5px 0 20px' }}
                          >
                            <Select
                              value={formData.prices[index].period}
                              onChange={(value) => {
                                const handledPrices = formData.prices;
                                handledPrices[index] = {
                                  ...item,
                                  period: value.target.value,
                                };
                                handledPrices[index].errors = {
                                  ...formData.prices[index].errors,
                                  period: '',
                                };
                                setFormData({
                                  ...formData,
                                  prices: handledPrices,
                                });
                              }}
                              displayEmpty
                              size='small'
                              margin='none'
                              variant='outlined'
                              fullWidth
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: '300px',
                                  },
                                },
                              }}
                              error={
                                item.errors &&
                                item.errors.period !== undefined &&
                                item.errors.period !== ''
                              }
                            >
                              <MenuItem disabled value=''>
                                <Typography style={{ color: 'grey' }}>
                                  Selecione...
                                </Typography>
                              </MenuItem>
                              {map(periods, (item: string, pos: number) => {
                                return (
                                  <MenuItem
                                    key={item + pos}
                                    value={item}
                                    disabled={item === '1000'}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </TableCell>
                          <TableCell
                            component='th'
                            scope='row'
                            sx={{ padding: '0 5px 0 15px' }}
                          >
                            <TextField
                              placeholder='0,00'
                              size='small'
                              variant='outlined'
                              margin='none'
                              value={formData.prices[index].price}
                              onChange={(e) => {
                                const handledPrices = formData.prices;
                                handledPrices[index].price = VMasker.toMoney(
                                  e.target.value,
                                );
                                handledPrices[index].errors = {
                                  ...formData.prices[index].errors,
                                  price: '',
                                };
                                setFormData({
                                  ...formData,
                                  prices: handledPrices,
                                });
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    R$
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                              error={
                                item.errors &&
                                item.errors.price !== undefined &&
                                item.errors.price !== ''
                              }
                            />
                          </TableCell>
                          <TableCell
                            component='th'
                            scope='row'
                            sx={{ padding: '0 0 0 15px' }}
                          >
                            <Select
                              value={formData.prices[index].type}
                              onChange={(value) => {
                                const handledPrices = formData.prices;
                                handledPrices[index] = {
                                  ...item,
                                  type: value.target.value,
                                };
                                handledPrices[index].errors = {
                                  ...formData.prices[index].errors,
                                  type: '',
                                };
                                setFormData({
                                  ...formData,
                                  prices: handledPrices,
                                });
                              }}
                              displayEmpty
                              size='small'
                              margin='none'
                              variant='outlined'
                              fullWidth
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: '300px',
                                  },
                                },
                              }}
                              error={
                                item.errors &&
                                item.errors.type !== undefined &&
                                item.errors.type !== ''
                              }
                            >
                              <MenuItem disabled value=''>
                                <Typography style={{ color: 'grey' }}>
                                  Selecione...
                                </Typography>
                              </MenuItem>
                              {map(
                                configurationsTypesList?.typePrices,
                                (item: string, pos: number) => {
                                  return (
                                    <MenuItem
                                      key={item + pos}
                                      value={item}
                                      disabled={item === '1000'}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                },
                              )}
                            </Select>
                          </TableCell>
                          <TableCell
                            component='th'
                            scope='row'
                            align='right'
                            sx={{ width: '35px', padding: 0 }}
                          >
                            <IconButton
                              color='secondary'
                              aria-label='Remover linha'
                              onClick={() => {
                                const handledPrices = formData.prices;
                                handledPrices.splice(index, 1);
                                setFormData({
                                  ...formData,
                                  prices: handledPrices,
                                });
                              }}
                            >
                              <CancelIcon fontSize='large' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: palette.action.hover,
                        },
                        '&:last-child td, &:last-child th': {
                          borderBottom:
                            errors &&
                            errors.prices !== undefined &&
                            errors.prices !== ''
                              ? 2
                              : 1,
                          borderColor:
                            errors &&
                            errors.prices !== undefined &&
                            errors.prices !== ''
                              ? 'red'
                              : palette.divider,
                        },
                      }}
                    >
                      <TableCell
                        align='center'
                        colSpan={4}
                        sx={{ height: '55px' }}
                      >
                        <Typography
                          sx={{
                            color:
                              errors &&
                              errors.prices !== undefined &&
                              errors.prices !== ''
                                ? 'red'
                                : 'grey',
                          }}
                        >
                          Nenhum horário de funcionamento inserido
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Grid>
    </PageWrapper>
  );
};
