/* eslint-disable lodash/collection-ordering */
import {
  ThunkDispatch,
  EmptyObject,
  AnyAction,
  Dispatch,
} from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import JSZip from 'jszip';
import {
  fill,
  find,
  findIndex,
  groupBy,
  keys,
  map,
  orderBy,
  replace,
  split,
} from 'lodash';
import forEach from 'lodash/forEach';
import moment from 'moment';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import {
  NotificationDetailsExportResponseType,
  NotificationsExportResponseType,
  NotificationsFinesExportResponseType,
} from '../services/Notifications/types';
import {
  SalesExportCommissionsHtmlResponseType,
  SalesExportOperationalHtmlResponseType,
  SalesExportSalesHtmlResponseType,
} from '../services/Sales/types';
import { SessionsExportResponseType } from '../services/Sessions/types';
import {
  notificationsListSlice,
  SalesFiltersType,
  salesListSlice,
} from '../stores';
import { logoUrl } from '../theme';
import { delay } from './Helpers';

export const exportSessionsToHTML = async (
  apiData: SessionsExportResponseType,
) => {
  const sessions = groupBy(apiData, 'userName');
  const htmlSessions: string[] = fill(Array(keys(sessions).length), '');
  const htmlNames: string[] = [];

  let index = 0;
  forEach(sessions, function (value, key) {
    htmlNames[index] = key;

    map(value, (item) => {
      htmlSessions[index] = htmlSessions[index].concat(`
      <div>
        <dl>
          <dt>
            Nome:
          </dt>
          <dd>
            ${item.userName}
          </dd>
          <dt>
            Hora inicial:
          </dt>
          <dd>
          ${item.startDateTime}
          </dd>
          <dt>
            Hora final:
          </dt>
          <dd>
          ${item.finalDateTime}
          </dd>
          <dt>
            Aparelho:
          </dt>
          <dd>
          ${item.deviceId}
          </dd>
          <dt>
            Trecho(s):
          </dt>
          <dd>
          ${item.routes}
          </dd>
        </dl>
        <!--
        <div class="row">
          <article class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div>
              <table border="0" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th class="table-bordered">Trecho(s)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  ${item.routes}
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
        </div>
        -->
        <hr width="100%"/>
      </div>
    `);
    });
    index += 1;
  });

  const zip = new JSZip();

  index = 0;
  map(htmlSessions, function (item) {
    zip.file(
      `${htmlNames[index]}.html`,
      `
      <!doctype html>
      <html>
      <head>
        <meta name="viewport" content="width=device-width" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Detalhar Sessão</title>
        <style>

          /* -------------------------------------
            GLOBAL RESETS
          ------------------------------------- */
          img {
            border: none;
            -ms-interpolation-mode: bicubic;
            max-width: 100%;
          }

          body {
            background-color: #f6f6f6;
            font-family: 'Arial', sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }

          table {
            border-collapse: separate;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            width: 100%;
          }

            table td {
              font-family: 'Arial', sans-serif;
              font-size: 14px;
              vertical-align: top;
            }

          /* -------------------------------------
            BODY & CONTAINER
          ------------------------------------- */

          .body {
            background-color: #f6f6f6;
            width: 100%;
          }

          /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
          .container {
            display: block;
            Margin: 0 auto !important;
            /* makes it centered */
            max-width: 580px;
            padding: 10px;
            width: 580px;
          }

          /* This should also be a block element, so that it will fill 100% of the .container */
          .content {
            box-sizing: border-box;
            display: block;
            Margin: 0 auto;
            max-width: 580px;
            padding: 10px;
          }

          /* -------------------------------------
            HEADER, FOOTER, MAIN
          ------------------------------------- */
          .main {
            background: #fff;
            border-radius: 3px;
            width: 100%;
          }

            .main tbody tr .wrapper {
              border-bottom: solid 1px #ddd;
              display: block;
            }

          .wrapper {
            box-sizing: border-box;
            padding: 20px;
          }

          .footer {
            clear: both;
            padding-top: 10px;
            text-align: center;
            width: 100%;
          }

            .footer td,
            .footer p,
            .footer span,
            .footer a {
              color: #999999;
              font-size: 12px;
              text-align: center;
            }

          /* -------------------------------------
            TYPOGRAPHY
          ------------------------------------- */
          h1,
          h2,
          h3,
          h4 {
            color: #000000;
            font-family: 'Arial', sans-serif;
            font-weight: 600;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 15px;
          }

          h1 {
            font-size: 35px;
            font-weight: 300;
            text-align: center;
            text-transform: capitalize;
          }

          p,
          ul,
          ol {
            font-family: 'Arial', sans-serif;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            Margin-bottom: 15px;
          }

            p li,
            ul li,
            ol li {
              list-style-position: inside;
              margin-left: 5px;
            }

          a {
            color: #3498db;
            text-decoration: underline;
          }

          /* -------------------------------------
            BUTTONS
          ------------------------------------- */
          .btn {
            box-sizing: border-box;
            width: 100%;
          }

            .btn > tbody > tr > td {
              padding-bottom: 15px;
            }

            .btn table {
              width: auto;
            }

              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }

            .btn a {
              background-color: #ffffff;
              border: solid 1px #3498db;
              border-radius: 5px;
              box-sizing: border-box;
              color: #3498db;
              cursor: pointer;
              display: inline-block;
              font-size: 14px;
              font-weight: bold;
              margin: 0;
              padding: 12px 25px;
              text-decoration: none;
              text-transform: capitalize;
            }

          .btn-primary table td {
            background-color: #3498db;
          }

          .btn-primary a {
            background-color: #3498db;
            border-color: #3498db;
            color: #ffffff;
          }

          /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
          ------------------------------------- */
          .list li {
            margin: 10px 0;
          }

          .last {
            margin-bottom: 0;
          }

          .first {
            margin-top: 0;
          }

          .align-center {
            text-align: center;
          }

          .align-right {
            text-align: right;
          }

          .align-left {
            text-align: left;
          }

          .clear {
            clear: both;
          }

          .mt0 {
            margin-top: 0;
          }

          .mb0 {
            margin-bottom: 0;
          }

          .preheader {
            color: transparent;
            display: none;
            height: 0;
            max-height: 0;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            mso-hide: all;
            visibility: hidden;
            width: 0;
          }

          .powered-by a {
            text-decoration: none;
          }

          hr {
            border: 0;
            border-bottom: 1px solid #f6f6f6;
            Margin: 20px 0;
          }

          /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
          ------------------------------------- */
          @media only screen and (max-width: 620px) {
            table[class=body] h1 {
              font-size: 28px !important;
              margin-bottom: 10px !important;
            }

            table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
              font-size: 16px !important;
            }

            table[class=body] .wrapper,
            table[class=body] .article {
              padding: 10px !important;
            }

            table[class=body] .content {
              padding: 0 !important;
            }

            table[class=body] .container {
              padding: 0 !important;
              width: 100% !important;
            }

            table[class=body] .main {
              border-left-width: 0 !important;
              border-radius: 0 !important;
              border-right-width: 0 !important;
            }

            table[class=body] .btn table {
              width: 100% !important;
            }

            table[class=body] .btn a {
              width: 100% !important;
            }

            table[class=body] .img-responsive {
              height: auto !important;
              max-width: 100% !important;
              width: auto !important;
            }
          }

          /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
          ------------------------------------- */
          @media all {
            .ExternalClass {
              width: 100%;
            }

              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%;
              }

            .apple-link a {
              color: inherit !important;
              font-family: inherit !important;
              font-size: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
              text-decoration: none !important;
            }

            .btn-primary table td:hover {
              background-color: #34495e !important;
            }

            .btn-primary a:hover {
              background-color: #34495e !important;
              border-color: #34495e !important;
            }
          }
        </style>
      </head>
      <body class="">
        <table border="0" cellpadding="0" cellspacing="0" class="body">
          <tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
                <!-- Logo da niterói rotativo -->
                <div class="img-container" style="text-align:center">
                  <img src=${logoUrl} alt="Logo" height="66" width="100" />
                </div>
                <!-- START CENTERED WHITE CONTAINER -->
                <table class="main">
                  <!-- START MAIN CONTENT AREA -->
                  ${item}
                  <!-- END MAIN CONTENT AREA -->
                </table>
                <!-- END CENTERED WHITE CONTAINER -->
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </body>
      </html>
      `,
    );
    index += 1;
  });

  const content = await zip.generateAsync({ type: 'blob' });

  FileSaver.saveAs(
    content,
    `sessoes-${moment().format('yyyy-MM-DD_hh-mm')}` + '.zip',
  );
};

export const exportNotificationsToHTML = async (
  apiData: NotificationsExportResponseType,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
): Promise<void> => {
  dispatch(notificationsListSlice.actions.setNotificationsExportPercentage(20));
  await delay(100);
  const fileType = 'text/plain;charset=utf-8';
  const fileExtension = '.html';
  let handledApiData = '';

  map(apiData, (item) => {
    handledApiData =
      handledApiData +
      `
      <tr>
        <td>
          <h2>Notificação (${item.id})</h2>
          <ul class="list" style="list-style: none; padding-left: 0">
            <li><strong>Veículo: </strong>
              ${item.plate}
            </li>
            <li>
              <strong>Situação: </strong>
              ${
                item.situation === 'C'
                  ? 'Cancelada'
                  : item.situation === 'I'
                  ? 'Irregular'
                  : item.situation === 'P'
                  ? 'Paga'
                  : 'Regular'
              }
            </li>
            <li>
              <strong>Usuário: </strong>
              ${item.userName} (${item.userLogin})
            </li>
            <li><strong>Data/Horário da notificação:</strong>
              ${moment(item.registerDateTime)
                .format('DD/MM/YYYY HH:mm:ss')
                .toString()}
            </li>
            <li>
              <strong>Trecho: </strong>
              ${item.routeName}
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td class="wrapper">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td>
                <ul class="list" style="list-style: none; padding-left: 0">
                  <li>
                    <strong>Data/Horário: </strong>
                    ${moment(item.registerDateTime)
                      .format('DD/MM/YYYY HH:mm:ss')
                      .toString()}
                  </li>
                  <li>
                    <strong>Usuário: </strong>
                    ${item.userName} (${item.userLogin})
                  </li>
                  <li>
                    <strong>Trecho: </strong>
                    ${item.routeName}
                  </li>
                  <li>
                    <img src="${item.plateImg}" alt='Imagem da placa' />
                  </li>
                  <li>
                    <img src="${item.locationImg}" alt='Imagem do local' />
                  </li>
                  <br/>
                </ul>
              </td>
            </tr>
          </table>
          <hr />
        </td>
      </tr>
      `;
  });
  dispatch(notificationsListSlice.actions.setNotificationsExportPercentage(70));
  await delay(100);

  const content = `
  <!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>Detalhar Notificação</title>
      <style>

        /* -------------------------------------
          GLOBAL RESETS
        ------------------------------------- */
        img {
          border: none;
          -ms-interpolation-mode: bicubic;
          max-width: 100%;
        }

        body {
          background-color: #f6f6f6;
          font-family: 'Arial', sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          line-height: 1.4;
          margin: 0;
          padding: 0;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }

        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }

          table td {
            font-family: 'Arial', sans-serif;
            font-size: 14px;
            vertical-align: top;
          }

        /* -------------------------------------
          BODY & CONTAINER
        ------------------------------------- */

        .body {
          background-color: #f6f6f6;
          width: 100%;
        }

        /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
        .container {
          display: block;
          Margin: 0 auto !important;
          /* makes it centered */
          max-width: 580px;
          padding: 10px;
          width: 580px;
        }

        /* This should also be a block element, so that it will fill 100% of the .container */
        .content {
          box-sizing: border-box;
          display: block;
          Margin: 0 auto;
          max-width: 580px;
          padding: 10px;
        }

        /* -------------------------------------
          HEADER, FOOTER, MAIN
        ------------------------------------- */
        .main {
          background: #fff;
          border-radius: 3px;
          width: 100%;
        }

          .main tbody tr .wrapper {
            border-bottom: solid 1px #ddd;
            display: block;
          }

        .wrapper {
          box-sizing: border-box;
          padding: 10px;
        }

        .footer {
          clear: both;
          padding-top: 10px;
          text-align: center;
          width: 100%;
        }

          .footer td,
          .footer p,
          .footer span,
          .footer a {
            color: #999999;
            font-size: 12px;
            text-align: center;
          }

        /* -------------------------------------
          TYPOGRAPHY
        ------------------------------------- */
        h1,
        h2,
        h3,
        h4 {
          color: #000000;
          font-family: 'Arial', sans-serif;
          font-weight: 600;
          line-height: 1.4;
          margin: 0;
          Margin-bottom: 15px;
        }

        h1 {
          font-size: 35px;
          font-weight: 300;
          text-align: center;
          text-transform: capitalize;
        }

        p,
        ul,
        ol {
          font-family: 'Arial', sans-serif;
          font-size: 14px;
          font-weight: normal;
          margin: 0;
          Margin-bottom: 15px;
        }

          p li,
          ul li,
          ol li {
            list-style-position: inside;
            margin-left: 5px;
          }

        a {
          color: #3498db;
          text-decoration: underline;
        }

        /* -------------------------------------
          BUTTONS
        ------------------------------------- */
        .btn {
          box-sizing: border-box;
          width: 100%;
        }

          .btn > tbody > tr > td {
            padding-bottom: 15px;
          }

          .btn table {
            width: auto;
          }

            .btn table td {
              background-color: #ffffff;
              border-radius: 5px;
              text-align: center;
            }

          .btn a {
            background-color: #ffffff;
            border: solid 1px #3498db;
            border-radius: 5px;
            box-sizing: border-box;
            color: #3498db;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            padding: 12px 25px;
            text-decoration: none;
            text-transform: capitalize;
          }

        .btn-primary table td {
          background-color: #3498db;
        }

        .btn-primary a {
          background-color: #3498db;
          border-color: #3498db;
          color: #ffffff;
        }

        /* -------------------------------------
          OTHER STYLES THAT MIGHT BE USEFUL
        ------------------------------------- */
        .list li {
          margin: 5px 0;
        }

        .last {
          margin-bottom: 0;
        }

        .first {
          margin-top: 0;
        }

        .align-center {
          text-align: center;
        }

        .align-right {
          text-align: right;
        }

        .align-left {
          text-align: left;
        }

        .clear {
          clear: both;
        }

        .mt0 {
          margin-top: 0;
        }

        .mb0 {
          margin-bottom: 0;
        }

        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0;
        }

        .powered-by a {
          text-decoration: none;
        }

        hr {
          border: 0;
          border-bottom: 1px solid #f6f6f6;
          Margin: 20px 0;
        }

        /* -------------------------------------
          RESPONSIVE AND MOBILE FRIENDLY STYLES
        ------------------------------------- */
        @media only screen and (max-width: 620px) {
          table[class=body] h1 {
            font-size: 28px !important;
            margin-bottom: 10px !important;
          }

          table[class=body] p,
          table[class=body] ul,
          table[class=body] ol,
          table[class=body] td,
          table[class=body] span,
          table[class=body] a {
            font-size: 16px !important;
          }

          table[class=body] .wrapper,
          table[class=body] .article {
            padding: 10px !important;
          }

          table[class=body] .content {
            padding: 0 !important;
          }

          table[class=body] .container {
            padding: 0 !important;
            width: 100% !important;
          }

          table[class=body] .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }

          table[class=body] .btn table {
            width: 100% !important;
          }

          table[class=body] .btn a {
            width: 100% !important;
          }

          table[class=body] .img-responsive {
            height: auto !important;
            max-width: 100% !important;
            width: auto !important;
          }
        }

        /* -------------------------------------
          PRESERVE THESE STYLES IN THE HEAD
        ------------------------------------- */
        @media all {
          .ExternalClass {
            width: 100%;
          }

            .ExternalClass,
            .ExternalClass p,
            .ExternalClass span,
            .ExternalClass font,
            .ExternalClass td,
            .ExternalClass div {
              line-height: 100%;
            }

          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }

          .btn-primary table td:hover {
            background-color: #34495e !important;
          }

          .btn-primary a:hover {
            background-color: #34495e !important;
            border-color: #34495e !important;
          }
        }
      </style>
    </head>
    <body class="">
      <table border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <div class="content">
              <!-- Logo da niterói rotativo -->
              <div class="img-container" style="text-align:center">
                <img src=${logoUrl} alt="Logo" height="66" width="100" />
              </div>
              <!-- START CENTERED WHITE CONTAINER -->
              <table class="main">
                <!-- START MAIN CONTENT AREA -->
                ${handledApiData}
                <!-- END MAIN CONTENT AREA -->
              </table>
              <!-- END CENTERED WHITE CONTAINER -->
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </body>
    </html>
  `;
  dispatch(
    notificationsListSlice.actions.setNotificationsExportPercentage(100),
  );
  await delay(100);

  const blob = new Blob([content], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `notificações-${moment().format('yyyy-MM-DD_hh-mm')}` + fileExtension,
  );
};

export const exportNotificationDetailsToHTML = async (
  apiData: NotificationDetailsExportResponseType,
) => {
  const zip = new JSZip();

  zip.file(
    `notificacao-${apiData.id}.html`,
    `
      <!doctype html>
      <html>
      <head>
        <meta name="viewport" content="width=device-width" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Fotos de irregularidade</title>
        <style>

          /* -------------------------------------
            GLOBAL RESETS
          ------------------------------------- */
          img {
            border: none;
            -ms-interpolation-mode: bicubic;
            max-width: 100%;
          }

          body {
            background-color: white;
            font-family: sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }

          table {
            border-collapse: separate;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            width: 100%;
          }

            table td {
              font-family: sans-serif;
              font-size: 14px;
              vertical-align: top;
            }

          /* -------------------------------------
            BODY & CONTAINER
          ------------------------------------- */

          .body {
            background-color: white;
            width: 100%;
          }

          /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
          .container {
            display: block;
            Margin: 0 auto !important;
            /* makes it centered */
            max-width: 100%;
            padding: 10px;
            /*width: 100%;*/
          }

          /* This should also be a block element, so that it will fill 100% of the .container */
          .content {
            box-sizing: border-box;
            display: block;
            Margin: 0 auto;
            max-width: 100%;
            padding: 10px;
          }

          /* -------------------------------------
            HEADER, FOOTER, MAIN
          ------------------------------------- */
          .main {
            background: #fff;
            border-radius: 3px;
            width: 100%;
          }

          .wrapper {
            box-sizing: border-box;
            padding: 20px;
          }

          .footer {
            clear: both;
            padding-top: 10px;
            text-align: center;
            width: 100%;
          }

            .footer td,
            .footer p,
            .footer span,
            .footer a {
              color: #999999;
              font-size: 12px;
              text-align: center;
            }

          /* -------------------------------------
            TYPOGRAPHY
          ------------------------------------- */
          h2,
          h3,
          h4 {
            color: #000000;
            font-family: sans-serif;
            font-weight: 400;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 15px;
          }

          h1 {
            font-size: 35px;
            font-weight: 600;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 8px;
          }

          h3 {
            font-size: 17px;
            font-weight: 600;
          }

          p,
          ul,
          ol {
            font-family: sans-serif;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            Margin-bottom: 15px;
          }

            p li,
            ul li,
            ol li {
              list-style-position: inside;
              margin-left: 5px;
            }

          a {
            color: #3498db;
            text-decoration: underline;
          }

          /* -------------------------------------
            BUTTONS
          ------------------------------------- */
          .btn {
            box-sizing: border-box;
            width: 100%;
          }

            .btn > tbody > tr > td {
              padding-bottom: 15px;
            }

            .btn table {
              width: auto;
            }

              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }

            .btn a {
              background-color: #ffffff;
              border: solid 1px #3498db;
              border-radius: 5px;
              box-sizing: border-box;
              color: #3498db;
              cursor: pointer;
              display: inline-block;
              font-size: 14px;
              font-weight: bold;
              margin: 0;
              padding: 12px 25px;
              text-decoration: none;
              text-transform: capitalize;
            }

          .btn-primary table td {
            background-color: #3498db;
          }

          .btn-primary a {
            background-color: #3498db;
            border-color: #3498db;
            color: #ffffff;
          }

          /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
          ------------------------------------- */
          .last {
            margin-bottom: 0;
          }

          .first {
            margin-top: 0;
          }

          .align-center {
            text-align: center;
          }

          .align-right {
            text-align: right;
          }

          .align-left {
            text-align: left;
          }

          .clear {
            clear: both;
          }

          .mt0 {
            margin-top: 0;
          }

          .mb0 {
            margin-bottom: 0;
          }

          .preheader {
            color: transparent;
            display: none;
            height: 0;
            max-height: 0;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            mso-hide: all;
            visibility: hidden;
            width: 0;
          }

          .powered-by a {
            text-decoration: none;
          }

          hr {
            border: 0;
            border-bottom: 1px solid black;
            Margin: 30px 0 10px;
          }

          /* -------------------------------------
              CUSTOM CSS BY ME
            ------------------------------------- */

          .table-adjustment tbody td, .table-adjustment thead th {
            padding: 5px;
          }

          /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
          ------------------------------------- */
          @media only screen and (max-width: 620px) {
            table[class=body] h1 {
              font-size: 28px !important;
              margin-bottom: 10px !important;
            }

            table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
              font-size: 16px !important;
            }

            table[class=body] .wrapper,
            table[class=body] .article {
              padding: 10px !important;
            }

            table[class=body] .content {
              padding: 0 !important;
            }

            table[class=body] .container {
              padding: 0 !important;
              width: 100% !important;
            }

            table[class=body] .main {
              border-left-width: 0 !important;
              border-radius: 0 !important;
              border-right-width: 0 !important;
            }

            table[class=body] .btn table {
              width: 100% !important;
            }

            table[class=body] .btn a {
              width: 100% !important;
            }

            table[class=body] .img-responsive {
              height: auto !important;
              max-width: 100% !important;
              width: auto !important;
            }
          }

          /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
          ------------------------------------- */
          @media all {
            .ExternalClass {
              width: 100%;
            }

              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%;
              }

            .apple-link a {
              color: inherit !important;
              font-family: inherit !important;
              font-size: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
              text-decoration: none !important;
            }

            .btn-primary table td:hover {
              background-color: #34495e !important;
            }

            .btn-primary a:hover {
              background-color: #34495e !important;
              border-color: #34495e !important;
            }
          }
        </style>
      </head>
      <body class="">
        <table border="0" cellpadding="0" cellspacing="0" class="body">
          <tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
                <!-- Logo da niterói rotativo -->
                <div class="img-container" style="text-align:center">
                  <img src=${logoUrl} alt="Logo" height="66" width="100" />
                </div>
                <!-- START CENTERED WHITE CONTAINER -->
                <table class="main">
                  <!-- START MAIN CONTENT AREA -->
                  <tr>
                    <td>
                      <h2>Notificação (${apiData.id})</h2>
                      <ul class="list" style="list-style: none; padding-left: 0">
                        <li><strong>Veículo: </strong>
                          ${apiData.plate}
                        </li>
                        <li>
                          <strong>Situação: </strong>
                          ${
                            apiData.situation === 'C'
                              ? 'Cancelada'
                              : apiData.situation === 'I'
                              ? 'Irregular'
                              : apiData.situation === 'P'
                              ? 'Paga'
                              : 'Regular'
                          }
                        </li><li>
                          <strong>Usuário: </strong>
                          ${apiData.userName} (${apiData.userLogin})
                        </li>
                        <li><strong>Data/Horário da notificação:</strong>
                          ${moment(apiData.registerDateTime)
                            .format('DD/MM/YYYY HH:mm:ss')
                            .toString()}
                        </li>
                        <li>
                          <strong>Trecho: </strong>
                          ${apiData.routeName}
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td class="wrapper">
                      <table border="0" cellpadding="0" cellspacing="0">
                        ${map(apiData.images, (item) => {
                          return `
                            <tr>
                            <td>
                              <ul class="list" style="list-style: none; padding-left: 0">
                                <li>
                                  <strong>Data/Horário: </strong>
                                  ${moment(item.registerDateTime)
                                    .format('DD/MM/YYYY HH:mm:ss')
                                    .toString()}
                                </li>
                                ${
                                  !item.userName
                                    ? ''
                                    : `<li>
                                  <strong>Usuário: </strong>
                                  ${item.userName} (${item.userLogin})
                                </li>`
                                }
                                <li>
                                  <strong>Trecho: </strong>
                                  ${item.routeName}
                                </li>
                                <li>
                                  <img src=${
                                    item.plateImg
                                  } alt='Imagem da placa' />
                                </li>
                                <li>
                                  <img src=${
                                    item.locationImg
                                  } alt='Imagem do local' />
                                </li>
                                <br/>
                              </ul>
                            </td>
                          </tr>
                          `;
                        })}
                      </table>
                      <hr />
                    </td>
                  </tr>
                  <!-- END MAIN CONTENT AREA -->
                </table>
                <!-- END CENTERED WHITE CONTAINER -->
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </body>
      </html>
      `,
  );

  const content = await zip.generateAsync({ type: 'blob' });

  FileSaver.saveAs(content, `notificação-${apiData.id}` + '.zip');
};

export const exportNotificationsFinesToHTML = async (
  apiData: NotificationsFinesExportResponseType,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
): Promise<void> => {
  const fileType = 'text/plain;charset=utf-8';
  const fileExtension = '.html';
  let handledApiData = '';
  dispatch(notificationsListSlice.actions.setNotificationsExportPercentage(20));
  await delay(100);

  map(apiData, (item) => {
    handledApiData =
      handledApiData +
      `
      <tr>
        <td>
          <h2>Notificação (${item.id})</h2>
          <ul class="list" style="list-style: none; padding-left: 0">
            <li><strong>Veículo: </strong>
              ${item.plate}
            </li>
            <li>
              <strong>Situação: </strong>
              ${
                item.situation === 'C'
                  ? 'Cancelada'
                  : item.situation === 'I'
                  ? 'Irregular'
                  : item.situation === 'P'
                  ? 'Paga'
                  : 'Regular'
              }
            </li>
            <li>
              <strong>Usuário: </strong>
              ${item.userName} (${item.userLogin})
            </li>
            <li><strong>Data/Horário da notificação:</strong>
              ${moment(item.registerDateTime)
                .format('DD/MM/YYYY HH:mm:ss')
                .toString()}
            </li>
            <li>
              <strong>Trecho: </strong>
              ${item.routeName}
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td class="wrapper">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td>
                <ul class="list" style="list-style: none; padding-left: 0">
                  <li>
                    <strong>Data/Horário: </strong>
                    ${moment(item.registerDateTime)
                      .format('DD/MM/YYYY HH:mm:ss')
                      .toString()}
                  </li>
                  <li>
                    <strong>Usuário: </strong>
                    ${item.userName} (${item.userLogin})
                  </li>
                  <li>
                    <strong>Trecho: </strong>
                    ${item.routeName}
                  </li>
                  <li>
                    <img src="${item.plateImg}" alt='Imagem da placa' />
                  </li>
                  <li>
                    <img src="${item.locationImg}" alt='Imagem do local' />
                  </li>
                  <br/>
                </ul>
              </td>
            </tr>
          </table>
          <hr />
        </td>
      </tr>
      `;
  });
  dispatch(notificationsListSlice.actions.setNotificationsExportPercentage(60));
  await delay(100);

  const content = `
  <!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>Detalhar Notificação</title>
      <style>

        /* -------------------------------------
          GLOBAL RESETS
        ------------------------------------- */
        img {
          border: none;
          -ms-interpolation-mode: bicubic;
          max-width: 100%;
        }

        body {
          background-color: #f6f6f6;
          font-family: 'Arial', sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          line-height: 1.4;
          margin: 0;
          padding: 0;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }

        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }

          table td {
            font-family: 'Arial', sans-serif;
            font-size: 14px;
            vertical-align: top;
          }

        /* -------------------------------------
          BODY & CONTAINER
        ------------------------------------- */

        .body {
          background-color: #f6f6f6;
          width: 100%;
        }

        /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
        .container {
          display: block;
          Margin: 0 auto !important;
          /* makes it centered */
          max-width: 580px;
          padding: 10px;
          width: 580px;
        }

        /* This should also be a block element, so that it will fill 100% of the .container */
        .content {
          box-sizing: border-box;
          display: block;
          Margin: 0 auto;
          max-width: 580px;
          padding: 10px;
        }

        /* -------------------------------------
          HEADER, FOOTER, MAIN
        ------------------------------------- */
        .main {
          background: #fff;
          border-radius: 3px;
          width: 100%;
        }

          .main tbody tr .wrapper {
            border-bottom: solid 1px #ddd;
            display: block;
          }

        .wrapper {
          box-sizing: border-box;
          padding: 10px;
        }

        .footer {
          clear: both;
          padding-top: 10px;
          text-align: center;
          width: 100%;
        }

          .footer td,
          .footer p,
          .footer span,
          .footer a {
            color: #999999;
            font-size: 12px;
            text-align: center;
          }

        /* -------------------------------------
          TYPOGRAPHY
        ------------------------------------- */
        h1,
        h2,
        h3,
        h4 {
          color: #000000;
          font-family: 'Arial', sans-serif;
          font-weight: 600;
          line-height: 1.4;
          margin: 0;
          Margin-bottom: 15px;
        }

        h1 {
          font-size: 35px;
          font-weight: 300;
          text-align: center;
          text-transform: capitalize;
        }

        p,
        ul,
        ol {
          font-family: 'Arial', sans-serif;
          font-size: 14px;
          font-weight: normal;
          margin: 0;
          Margin-bottom: 15px;
        }

          p li,
          ul li,
          ol li {
            list-style-position: inside;
            margin-left: 5px;
          }

        a {
          color: #3498db;
          text-decoration: underline;
        }

        /* -------------------------------------
          BUTTONS
        ------------------------------------- */
        .btn {
          box-sizing: border-box;
          width: 100%;
        }

          .btn > tbody > tr > td {
            padding-bottom: 15px;
          }

          .btn table {
            width: auto;
          }

            .btn table td {
              background-color: #ffffff;
              border-radius: 5px;
              text-align: center;
            }

          .btn a {
            background-color: #ffffff;
            border: solid 1px #3498db;
            border-radius: 5px;
            box-sizing: border-box;
            color: #3498db;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            padding: 12px 25px;
            text-decoration: none;
            text-transform: capitalize;
          }

        .btn-primary table td {
          background-color: #3498db;
        }

        .btn-primary a {
          background-color: #3498db;
          border-color: #3498db;
          color: #ffffff;
        }

        /* -------------------------------------
          OTHER STYLES THAT MIGHT BE USEFUL
        ------------------------------------- */
        .list li {
          margin: 5px 0;
        }

        .last {
          margin-bottom: 0;
        }

        .first {
          margin-top: 0;
        }

        .align-center {
          text-align: center;
        }

        .align-right {
          text-align: right;
        }

        .align-left {
          text-align: left;
        }

        .clear {
          clear: both;
        }

        .mt0 {
          margin-top: 0;
        }

        .mb0 {
          margin-bottom: 0;
        }

        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0;
        }

        .powered-by a {
          text-decoration: none;
        }

        hr {
          border: 0;
          border-bottom: 1px solid #f6f6f6;
          Margin: 20px 0;
        }

        /* -------------------------------------
          RESPONSIVE AND MOBILE FRIENDLY STYLES
        ------------------------------------- */
        @media only screen and (max-width: 620px) {
          table[class=body] h1 {
            font-size: 28px !important;
            margin-bottom: 10px !important;
          }

          table[class=body] p,
          table[class=body] ul,
          table[class=body] ol,
          table[class=body] td,
          table[class=body] span,
          table[class=body] a {
            font-size: 16px !important;
          }

          table[class=body] .wrapper,
          table[class=body] .article {
            padding: 10px !important;
          }

          table[class=body] .content {
            padding: 0 !important;
          }

          table[class=body] .container {
            padding: 0 !important;
            width: 100% !important;
          }

          table[class=body] .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }

          table[class=body] .btn table {
            width: 100% !important;
          }

          table[class=body] .btn a {
            width: 100% !important;
          }

          table[class=body] .img-responsive {
            height: auto !important;
            max-width: 100% !important;
            width: auto !important;
          }
        }

        /* -------------------------------------
          PRESERVE THESE STYLES IN THE HEAD
        ------------------------------------- */
        @media all {
          .ExternalClass {
            width: 100%;
          }

            .ExternalClass,
            .ExternalClass p,
            .ExternalClass span,
            .ExternalClass font,
            .ExternalClass td,
            .ExternalClass div {
              line-height: 100%;
            }

          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }

          .btn-primary table td:hover {
            background-color: #34495e !important;
          }

          .btn-primary a:hover {
            background-color: #34495e !important;
            border-color: #34495e !important;
          }
        }
      </style>
    </head>
    <body class="">
      <table border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <div class="content">
              <!-- Logo da niterói rotativo -->
              <div class="img-container" style="text-align:center">
                <img src=${logoUrl} alt="Logo" height="66" width="100" />
              </div>
              <!-- START CENTERED WHITE CONTAINER -->
              <table class="main">
                <!-- START MAIN CONTENT AREA -->
                ${handledApiData}
                <!-- END MAIN CONTENT AREA -->
              </table>
              <!-- END CENTERED WHITE CONTAINER -->
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </body>
    </html>
  `;
  dispatch(
    notificationsListSlice.actions.setNotificationsExportPercentage(100),
  );
  await delay(100);

  const blob = new Blob([content], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `notificações-multas-${moment().format('yyyy-MM-DD_hh-mm')}` +
      fileExtension,
  );
};

export const exportSalesCommissionsToHTML = async (
  apiData: SalesExportCommissionsHtmlResponseType,
  startDate: string,
  finalDate: string,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
): Promise<void> => {
  // Initializing stuff
  const handledCommissions: {
    type: string;
    value: number;
    profileId: number;
  }[] = [];
  const handledData: {
    userId: number;
    name: string;
    login: string;
    startDate: string;
    endDate: string;
    total: number;
    operators: {
      profileId: number;
      registerDate: string;
      commissions: {
        type: string;
        count: number;
        sumPrice: number;
        sumMinutes: string;
        commission: number;
      }[];
    }[];
  }[] = [];
  const htmlStuff: {
    fileName: string;
    title: string;
    subtitle: string;
    body: string;
  }[] = [];
  dispatch(salesListSlice.actions.setSalesExportPercentage(20));
  await delay(100);

  let allOperatorsBody = '';

  // Preparing commissions from apiData first
  map(apiData.commissions, (item) => {
    const timeInMinutes = split(item.time, ':');
    if (item.type !== 'Moto') {
      handledCommissions.push({
        type: item.type,
        value:
          item.price /
          (parseInt(timeInMinutes[0]) * 60 +
            parseInt(timeInMinutes[1]) +
            parseInt(timeInMinutes[2]) / 60),
        profileId: item.profileId,
      });
    } else {
      handledCommissions.push({
        type: item.type,
        value: item.price,
        profileId: item.profileId,
      });
    }
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(40));
  await delay(100);

  // Populating handledData
  map(apiData.sales, (item) => {
    const userIdx = findIndex(handledData, ['userId', item.userId]);
    const commissionObj =
      item.profileId !== 8
        ? find(handledCommissions, {
            type: item.type,
            profileId: 0,
          })
        : find(handledCommissions, {
            type: item.type,
            profileId: 8,
          });

    if (commissionObj) {
      const commission =
        item.type !== 'Moto'
          ? parseInt(item.sumMinutes) * commissionObj?.value
          : item.count * commissionObj.value;

      if (userIdx !== -1) {
        const operatorsIdx = findIndex(handledData[userIdx].operators, [
          'registerDate',
          item.registerDate,
        ]);
        if (operatorsIdx !== -1) {
          handledData[userIdx].operators[operatorsIdx].commissions.push({
            type: item.type,
            count: item.count,
            sumPrice: item.sumPrice,
            sumMinutes: item.sumMinutes,
            commission: commission,
          });
          handledData[userIdx].total += commission;
        } else {
          handledData[userIdx].operators.push({
            profileId: item.profileId,
            registerDate: item.registerDate,
            commissions: [
              {
                type: item.type,
                count: item.count,
                sumPrice: item.sumPrice,
                sumMinutes: item.sumMinutes,
                commission: commission,
              },
            ],
          });
          handledData[userIdx].total += commission;
        }
      } else {
        handledData.push({
          userId: item.userId,
          name: item.name,
          login: item.login,
          startDate: startDate,
          endDate: finalDate,
          total: commission,
          operators: [
            {
              profileId: item.profileId,
              registerDate: item.registerDate,
              commissions: [
                {
                  type: item.type,
                  count: item.count,
                  sumPrice: item.sumPrice,
                  sumMinutes: item.sumMinutes,
                  commission: commission,
                },
              ],
            },
          ],
        });
      }
    }
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(70));
  await delay(100);

  // Creating <tr>s
  map(handledData, (data) => {
    const fileName = `${data.name} (${data.login})`;
    const title = `Recibo de comissão (${data.name})`;
    const subtitle = `Período apurado: ${moment(data.startDate)
      .format('DD/MM/YYYY')
      .toString()} - ${moment(data.endDate).format('DD/MM/YYYY').toString()}`;
    let body = '';

    map(data.operators, (operator) => {
      map(operator.commissions, (commission, index) => {
        body += `
        <tr>
          <td>${index === 0 ? data.login : ''}</td>
          <td>${index === 0 ? data.name : ''}</td>
          <td>${
            index === 0
              ? moment(operator.registerDate).format('DD/MM/YYYY').toString()
              : ''
          }</td>
          <td>${commission.type}</td>
          <td>${commission.count}</td>
          <td>${replace(
            commission.sumPrice.toFixed(2).toString(),
            '.',
            ',',
          )}</td>
          <td>${replace(
            commission.commission.toFixed(2).toString(),
            '.',
            ',',
          )}</td>
        </tr>
        `;
      });
    });

    body += `
    <tr>
      <td align="right" colspan="6">Total:</td>
      <td>${replace(data.total.toFixed(2).toString(), '.', ',')}</td>
    </tr>
    `;

    htmlStuff.push({
      fileName: fileName,
      title: title,
      subtitle: subtitle,
      body: body,
    });
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(80));
  await delay(100);

  // Creating files in .zip
  const zip = new JSZip();
  map(htmlStuff, function (item) {
    zip.file(
      `${item.fileName}.html`,
      `
      <!doctype html>
      <html>
      <head>
        <meta name="viewport" content="width=device-width" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Recibo de comissão</title>
        <style>


          /* -------------------------------------
            GLOBAL RESETS
          ------------------------------------- */
          img {
            border: none;
            -ms-interpolation-mode: bicubic;
            max-width: 100%;
          }

          body {
            background-color: white;
            font-family: sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 10px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }

          table {
            border-collapse: separate;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            width: 100%;
          }

            table td {
              font-family: sans-serif;
              font-size: 10px;
              vertical-align: top;
            }

          /* -------------------------------------
            BODY & CONTAINER
          ------------------------------------- */

          .body {
            background-color: white;
            width: 100%;
          }

          /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
          .container {
            display: block;
            Margin: 0 auto !important;
            /* makes it centered */
            max-width: 100%;
            padding: 10px;
            /*width: 100%;*/
          }

          /* This should also be a block element, so that it will fill 100% of the .container */
          .content {
            box-sizing: border-box;
            display: block;
            Margin: 0 auto;
            max-width: 100%;
            padding: 10px;
          }

          /* -------------------------------------
            HEADER, FOOTER, MAIN
          ------------------------------------- */
          .main {
            background: #fff;
            border-radius: 3px;
            width: 100%;
          }

          .wrapper {
            box-sizing: border-box;
            padding: 20px;
          }

          .footer {
            clear: both;
            padding-top: 10px;
            text-align: center;
            width: 100%;
          }

            .footer td,
            .footer p,
            .footer span,
            .footer a {
              color: #999999;
              font-size: 8px;
              text-align: center;
            }

          /* -------------------------------------
            TYPOGRAPHY
          ------------------------------------- */
          h2,
          h3,
          h4 {
            color: #000000;
            font-family: sans-serif;
            font-weight: 400;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 15px;
          }

          h1 {
            font-size: 31px;
            font-weight: 600;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 8px;
          }

          h3 {
            font-size: 13px;
            font-weight: 600;
          }

          p,
          ul,
          ol {
            font-family: sans-serif;
            font-size: 11px;
            font-weight: normal;
            margin: 0;
            Margin-bottom: 15px;
          }

            p li,
            ul li,
            ol li {
              list-style-position: inside;
              margin-left: 5px;
            }

          a {
            color: #3498db;
            text-decoration: underline;
          }

          /* -------------------------------------
            BUTTONS
          ------------------------------------- */
          .btn {
            box-sizing: border-box;
            width: 100%;
          }

            .btn > tbody > tr > td {
              padding-bottom: 15px;
            }

            .btn table {
              width: auto;
            }

              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }

            .btn a {
              background-color: #ffffff;
              border: solid 1px #3498db;
              border-radius: 5px;
              box-sizing: border-box;
              color: #3498db;
              cursor: pointer;
              display: inline-block;
              font-size: 10px;
              font-weight: bold;
              margin: 0;
              padding: 12px 25px;
              text-decoration: none;
              text-transform: capitalize;
            }

          .btn-primary table td {
            background-color: #3498db;
          }

          .btn-primary a {
            background-color: #3498db;
            border-color: #3498db;
            color: #ffffff;
          }

          /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
          ------------------------------------- */
          .last {
            margin-bottom: 0;
          }

          .first {
            margin-top: 0;
          }

          .align-center {
            text-align: center;
          }

          .align-right {
            text-align: right;
          }

          .align-left {
            text-align: left;
          }

          .clear {
            clear: both;
          }

          .mt0 {
            margin-top: 0;
          }

          .mb0 {
            margin-bottom: 0;
          }

          .preheader {
            color: transparent;
            display: none;
            height: 0;
            max-height: 0;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            mso-hide: all;
            visibility: hidden;
            width: 0;
          }

          .powered-by a {
            text-decoration: none;
          }

          hr {
            border: 0;
            border-bottom: 1px solid black;
            Margin: 30px 0 10px;
          }

          /* -------------------------------------
              CUSTOM CSS BY ME
            ------------------------------------- */

          .table-adjustment tbody td, .table-adjustment thead th {
            padding: 5px;
          }

          /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
          ------------------------------------- */
          @media only screen and (max-width: 620px) {
            table[class=body] h1 {
              font-size: 26px !important;
              margin-bottom: 10px !important;
            }

            table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
              font-size: 14px !important;
            }

            table[class=body] .wrapper,
            table[class=body] .article {
              padding: 10px !important;
            }

            table[class=body] .content {
              padding: 0 !important;
            }

            table[class=body] .container {
              padding: 0 !important;
              width: 100% !important;
            }

            table[class=body] .main {
              border-left-width: 0 !important;
              border-radius: 0 !important;
              border-right-width: 0 !important;
            }

            table[class=body] .btn table {
              width: 100% !important;
            }

            table[class=body] .btn a {
              width: 100% !important;
            }

            table[class=body] .img-responsive {
              height: auto !important;
              max-width: 100% !important;
              width: auto !important;
            }
          }

          /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
          ------------------------------------- */
          @media all {
            .ExternalClass {
              width: 100%;
            }

              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%;
              }

            .apple-link a {
              color: inherit !important;
              font-family: inherit !important;
              font-size: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
              text-decoration: none !important;
            }

            .btn-primary table td:hover {
              background-color: #34495e !important;
            }

            .btn-primary a:hover {
              background-color: #34495e !important;
              border-color: #34495e !important;
            }
          }
        </style>
      </head>
      <body class="">
        <table border="0" cellpadding="0" cellspacing="0" class="body">
          <tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
                <!-- Logo da niterói rotativo -->
                <div class="img-container" style="text-align:center">
                  <img src=${logoUrl} alt="Logo" height="66" width="100" />
                </div>
                <!-- START CENTERED WHITE CONTAINER -->
                <table class="main">
                  <!-- START MAIN CONTENT AREA -->
                  <tr>
                    <td class="wrapper">
                      <table border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td>
                            <h1>${item.title}</h1>
                            <h3>${item.subtitle}</h3>
                            <table border="0" cellpadding="0" cellspacing="0">
                              <tbody>
                                <tr>
                                  <td align="left">
                                    <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                                      <thead>
                                        <tr>
                                          <th align="left">Matrícula</th>
                                          <th align="left">Nome</th>
                                          <th align="left">Data</th>
                                          <th align="left">Categoria</th>
                                          <th align="left">Quantidade</th>
                                          <th align="left">Valor</th>
                                          <th align="left">Comissão</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        ${item.body}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0" style="margin-top:96px">
                              <tbody>
                                <tr>
                                  <td width="50%" style="padding: 0 5px">
                                    <hr></hr>
                                    <p align="center">Local e Data</p>
                                  </td>
                                  <td width="50%" style="padding: 0 5px">
                                    <hr></hr>
                                    <p align="center">Declaro que recebi o valor da comissão do período apurado</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <!-- END MAIN CONTENT AREA -->
                </table>
                <!-- END CENTERED WHITE CONTAINER -->
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </body>
      </html>
      `,
    );
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(90));
  await delay(100);

  // Creating all operators file in .zip
  map(htmlStuff, function (item) {
    allOperatorsBody += `<tr>
    <td>
      <h1>${item.title}</h1>
      <h3>${item.subtitle}</h3>
      <table border="0" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td align="left">
              <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                <thead>
                  <tr>
                    <th align="left">Matrícula</th>
                    <th align="left">Nome</th>
                    <th align="left">Data</th>
                    <th align="left">Categoria</th>
                    <th align="left">Quantidade</th>
                    <th align="left">Valor</th>
                    <th align="left">Comissão</th>
                  </tr>
                </thead>
                <tbody>
                  ${item.body}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <table border="0" cellpadding="0" cellspacing="0" style="margin-top:96px">
        <tbody>
          <tr>
            <td width="50%" style="padding: 0 5px">
              <hr></hr>
              <p align="center">Local e Data</p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </td>
            <td width="50%" style="padding: 0 5px">
              <hr></hr>
              <p align="center">Declaro que recebi o valor da comissão do período apurado</p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  </tr>`;
  });
  zip.file(
    `TODOS OS FUNCIONARIOS.html`,
    `
    <!doctype html>
    <html>
    <head>
      <meta name="viewport" content="width=device-width" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>Recibo de comissão</title>
      <style>


        /* -------------------------------------
          GLOBAL RESETS
        ------------------------------------- */
        img {
          border: none;
          -ms-interpolation-mode: bicubic;
          max-width: 100%;
        }

        body {
          background-color: white;
          font-family: sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 10px;
          line-height: 1.4;
          margin: 0;
          padding: 0;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
        }

        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }

          table td {
            font-family: sans-serif;
            font-size: 10px;
            vertical-align: top;
          }

        /* -------------------------------------
          BODY & CONTAINER
        ------------------------------------- */

        .body {
          background-color: white;
          width: 100%;
        }

        /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
        .container {
          display: block;
          Margin: 0 auto !important;
          /* makes it centered */
          max-width: 100%;
          padding: 10px;
          /*width: 100%;*/
        }

        /* This should also be a block element, so that it will fill 100% of the .container */
        .content {
          box-sizing: border-box;
          display: block;
          Margin: 0 auto;
          max-width: 100%;
          padding: 10px;
        }

        /* -------------------------------------
          HEADER, FOOTER, MAIN
        ------------------------------------- */
        .main {
          background: #fff;
          border-radius: 3px;
          width: 100%;
        }

        .wrapper {
          box-sizing: border-box;
          padding: 20px;
        }

        .footer {
          clear: both;
          padding-top: 10px;
          text-align: center;
          width: 100%;
        }

          .footer td,
          .footer p,
          .footer span,
          .footer a {
            color: #999999;
            font-size: 8px;
            text-align: center;
          }

        /* -------------------------------------
          TYPOGRAPHY
        ------------------------------------- */
        h2,
        h3,
        h4 {
          color: #000000;
          font-family: sans-serif;
          font-weight: 400;
          text-align: center;
          line-height: 1.4;
          margin: 0;
          Margin-bottom: 15px;
        }

        h1 {
          font-size: 31px;
          font-weight: 600;
          text-align: center;
          line-height: 1.4;
          margin: 0;
          Margin-bottom: 8px;
        }

        h3 {
          font-size: 13px;
          font-weight: 600;
        }

        p,
        ul,
        ol {
          font-family: sans-serif;
          font-size: 11px;
          font-weight: normal;
          margin: 0;
          Margin-bottom: 15px;
        }

          p li,
          ul li,
          ol li {
            list-style-position: inside;
            margin-left: 5px;
          }

        a {
          color: #3498db;
          text-decoration: underline;
        }

        /* -------------------------------------
          BUTTONS
        ------------------------------------- */
        .btn {
          box-sizing: border-box;
          width: 100%;
        }

          .btn > tbody > tr > td {
            padding-bottom: 15px;
          }

          .btn table {
            width: auto;
          }

            .btn table td {
              background-color: #ffffff;
              border-radius: 5px;
              text-align: center;
            }

          .btn a {
            background-color: #ffffff;
            border: solid 1px #3498db;
            border-radius: 5px;
            box-sizing: border-box;
            color: #3498db;
            cursor: pointer;
            display: inline-block;
            font-size: 10px;
            font-weight: bold;
            margin: 0;
            padding: 12px 25px;
            text-decoration: none;
            text-transform: capitalize;
          }

        .btn-primary table td {
          background-color: #3498db;
        }

        .btn-primary a {
          background-color: #3498db;
          border-color: #3498db;
          color: #ffffff;
        }

        /* -------------------------------------
          OTHER STYLES THAT MIGHT BE USEFUL
        ------------------------------------- */
        .last {
          margin-bottom: 0;
        }

        .first {
          margin-top: 0;
        }

        .align-center {
          text-align: center;
        }

        .align-right {
          text-align: right;
        }

        .align-left {
          text-align: left;
        }

        .clear {
          clear: both;
        }

        .mt0 {
          margin-top: 0;
        }

        .mb0 {
          margin-bottom: 0;
        }

        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0;
        }

        .powered-by a {
          text-decoration: none;
        }

        hr {
          border: 0;
          border-bottom: 1px solid black;
          Margin: 30px 0 10px;
        }

        /* -------------------------------------
            CUSTOM CSS BY ME
          ------------------------------------- */

        .table-adjustment tbody td, .table-adjustment thead th {
          padding: 5px;
        }

        /* -------------------------------------
          RESPONSIVE AND MOBILE FRIENDLY STYLES
        ------------------------------------- */
        @media only screen and (max-width: 620px) {
          table[class=body] h1 {
            font-size: 26px !important;
            margin-bottom: 10px !important;
          }

          table[class=body] p,
          table[class=body] ul,
          table[class=body] ol,
          table[class=body] td,
          table[class=body] span,
          table[class=body] a {
            font-size: 14px !important;
          }

          table[class=body] .wrapper,
          table[class=body] .article {
            padding: 10px !important;
          }

          table[class=body] .content {
            padding: 0 !important;
          }

          table[class=body] .container {
            padding: 0 !important;
            width: 100% !important;
          }

          table[class=body] .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important;
          }

          table[class=body] .btn table {
            width: 100% !important;
          }

          table[class=body] .btn a {
            width: 100% !important;
          }

          table[class=body] .img-responsive {
            height: auto !important;
            max-width: 100% !important;
            width: auto !important;
          }
        }

        /* -------------------------------------
          PRESERVE THESE STYLES IN THE HEAD
        ------------------------------------- */
        @media all {
          .ExternalClass {
            width: 100%;
          }

            .ExternalClass,
            .ExternalClass p,
            .ExternalClass span,
            .ExternalClass font,
            .ExternalClass td,
            .ExternalClass div {
              line-height: 100%;
            }

          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important;
          }

          .btn-primary table td:hover {
            background-color: #34495e !important;
          }

          .btn-primary a:hover {
            background-color: #34495e !important;
            border-color: #34495e !important;
          }
        }
      </style>
    </head>
    <body class="">
      <table border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <div class="content">
              <!-- Logo da niterói rotativo -->
              <div class="img-container" style="text-align:center">
                <img src=${logoUrl} alt="Logo" height="66" width="100" />
              </div>
              <!-- START CENTERED WHITE CONTAINER -->
              <table class="main">
                <!-- START MAIN CONTENT AREA -->
                <tr>
                  <td class="wrapper">
                    <table border="0" cellpadding="0" cellspacing="0">
                      ${allOperatorsBody}
                    </table>
                  </td>
                </tr>
                <!-- END MAIN CONTENT AREA -->
              </table>
              <!-- END CENTERED WHITE CONTAINER -->
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </body>
    </html>
    `,
  );
  dispatch(salesListSlice.actions.setSalesExportPercentage(100));
  await delay(100);

  const content = await zip.generateAsync({ type: 'blob' });

  FileSaver.saveAs(
    content,
    `relatorio-comissoes-${moment().format('yyyy-MM-DD_hh-mm')}` + '.zip',
  );
};

export const exportSalesToHTML = async (
  apiData: SalesExportSalesHtmlResponseType,
  users: {
    id: number;
    value: string;
  }[],
  areas: {
    id: number;
    value: string;
  }[],
  routes: {
    id: number;
    value: string;
    areaId: number;
  }[],
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
): Promise<void> => {
  const handledData: {
    areaId: number;
    registerDate: string;
    areaName: string;
    operational: {
      totalCredit: number;
      totalDebit: number;
      totalPix: number;
      totalCash: number;
      total: number;
      users: {
        userId: number;
        userName: string;
        partialCredit: number;
        partialDebit: number;
        partialPix: number;
        partialCash: number;
        partialTotal: number;
        routes: {
          routeId: number;
          routeName: string;
          routeCredit: number;
          routeDebit: number;
          routePix: number;
          routeCash: number;
          routeTotal: number;
        }[];
      }[];
    };
    driver: {
      totalCredit: number;
      totalDebit: number;
      totalPix: number;
      totalCash: number;
      total: number;
      routes: {
        routeId: number;
        routeName: string;
        valueCredit: number;
        valueDebit: number;
        valuePix: number;
        valueCash: number;
        valueTotal: number;
      }[];
    };
  }[] = [];
  dispatch(salesListSlice.actions.setSalesExportPercentage(20));
  await delay(100);

  // Populating handledData from salesUser (operational/ first part of html)
  map(apiData.salesUser, (item) => {
    // Finds user object first
    const userObj = find(users, ['id', item.userId]);
    // Finding route object from filters
    const routeObj = find(routes, ['id', item.routeId]);
    if (userObj && routeObj) {
      // Finding area object from filters
      const areaObj = find(areas, ['id', routeObj.areaId]);
      if (areaObj) {
        // First: check if entry already exists on handledUsersData for that area && date
        const dataIdx = findIndex(handledData, {
          registerDate: item.registerDate,
          areaId: areaObj.id,
        });
        if (dataIdx !== -1) {
          // If exists, check if user entry exists in it
          const userIdx = findIndex(handledData[dataIdx].operational.users, {
            userId: userObj.id,
          });
          if (userIdx !== -1) {
            // If exists, check if route entry exists in it
            const routeIdx = findIndex(
              handledData[dataIdx].operational.users[userIdx].routes,
              { routeId: item.routeId },
            );
            if (routeIdx !== -1) {
              // If exists, adds new paymentMethod and value to it and updates total
              switch (item.paymentMethod) {
                case 'CREDITO':
                  handledData[dataIdx].operational.users[userIdx].routes[
                    routeIdx
                  ].routeCredit = item.sumPrice;
                  handledData[dataIdx].operational.users[
                    userIdx
                  ].partialCredit += item.sumPrice;
                  handledData[dataIdx].operational.totalCredit += item.sumPrice;
                  break;
                case 'DEBITO':
                  handledData[dataIdx].operational.users[userIdx].routes[
                    routeIdx
                  ].routeDebit = item.sumPrice;
                  handledData[dataIdx].operational.users[
                    userIdx
                  ].partialDebit += item.sumPrice;
                  handledData[dataIdx].operational.totalDebit += item.sumPrice;
                  break;
                case 'PIX':
                  handledData[dataIdx].operational.users[userIdx].routes[
                    routeIdx
                  ].routePix = item.sumPrice;
                  handledData[dataIdx].operational.users[userIdx].partialPix +=
                    item.sumPrice;
                  handledData[dataIdx].operational.totalPix += item.sumPrice;
                  break;
                case 'DINHEIRO':
                  handledData[dataIdx].operational.users[userIdx].routes[
                    routeIdx
                  ].routeCash = item.sumPrice;
                  handledData[dataIdx].operational.users[userIdx].partialCash +=
                    item.sumPrice;
                  handledData[dataIdx].operational.totalCash += item.sumPrice;
                  break;
              }
              handledData[dataIdx].operational.users[userIdx].routes[
                routeIdx
              ].routeTotal += item.sumPrice;
              handledData[dataIdx].operational.users[userIdx].partialTotal +=
                item.sumPrice;
              handledData[dataIdx].operational.total += item.sumPrice;
            } else {
              // If not, adds route entry with paymentMethod and value to it
              handledData[dataIdx].operational.users[userIdx].routes.push({
                routeId: routeObj.id,
                routeName: routeObj.value,
                routeCredit:
                  item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
                routeDebit: item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
                routePix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
                routeCash:
                  item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
                routeTotal: item.sumPrice,
              });
              switch (item.paymentMethod) {
                case 'CREDITO':
                  handledData[dataIdx].operational.users[
                    userIdx
                  ].partialCredit += item.sumPrice;
                  handledData[dataIdx].operational.totalCredit += item.sumPrice;
                  break;
                case 'DEBITO':
                  handledData[dataIdx].operational.users[
                    userIdx
                  ].partialDebit += item.sumPrice;
                  handledData[dataIdx].operational.totalDebit += item.sumPrice;
                  break;
                case 'PIX':
                  handledData[dataIdx].operational.users[userIdx].partialPix +=
                    item.sumPrice;
                  handledData[dataIdx].operational.totalPix += item.sumPrice;
                  break;
                case 'DINHEIRO':
                  handledData[dataIdx].operational.users[userIdx].partialCash +=
                    item.sumPrice;
                  handledData[dataIdx].operational.totalCash += item.sumPrice;
                  break;
              }
              handledData[dataIdx].operational.users[userIdx].partialTotal +=
                item.sumPrice;
              handledData[dataIdx].operational.total += item.sumPrice;
            }
          } else {
            // If not, adds user entry
            handledData[dataIdx].operational.users.push({
              userId: userObj.id,
              userName: userObj.value,
              partialCredit:
                item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
              partialDebit: item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
              partialPix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
              partialCash:
                item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
              partialTotal: item.sumPrice,
              routes: [
                {
                  routeId: routeObj.id,
                  routeName: routeObj.value,
                  routeCredit:
                    item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
                  routeDebit:
                    item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
                  routePix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
                  routeCash:
                    item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
                  routeTotal: item.sumPrice,
                },
              ],
            });
            handledData[dataIdx].operational.total += item.sumPrice;
          }
        } else {
          // If not, adds area entry
          handledData.push({
            registerDate: item.registerDate,
            areaId: areaObj.id,
            areaName: areaObj.value,
            operational: {
              totalCredit: item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
              totalDebit: item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
              totalPix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
              totalCash: item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
              total: item.sumPrice,
              users: [
                {
                  userId: userObj.id,
                  userName: userObj.value,
                  partialCredit:
                    item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
                  partialDebit:
                    item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
                  partialPix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
                  partialCash:
                    item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
                  partialTotal: item.sumPrice,
                  routes: [
                    {
                      routeId: routeObj.id,
                      routeName: routeObj.value,
                      routeCredit:
                        item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
                      routeDebit:
                        item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
                      routePix:
                        item.paymentMethod === 'PIX' ? item.sumPrice : 0,
                      routeCash:
                        item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
                      routeTotal: item.sumPrice,
                    },
                  ],
                },
              ],
            },
            driver: {
              totalCredit: 0,
              totalDebit: 0,
              totalPix: 0,
              totalCash: 0,
              total: 0,
              routes: [],
            },
          });
        }
      }
    }
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(40));
  await delay(100);

  // Adding salesDrivers to handledData (driver/ second part of html)
  map(apiData.salesDriver, (item) => {
    // Finding route object from filters
    const routeObj = find(routes, ['id', item.routeId]);
    if (routeObj) {
      const areaObj = find(areas, ['id', routeObj.areaId]);
      if (areaObj) {
        // Check if already exists drivers entry
        const dataIdx = findIndex(handledData, {
          areaId: routeObj.areaId,
          registerDate: item.registerDate,
        });
        if (dataIdx !== -1) {
          // If exists, check if route entry exists
          const routeIdx = findIndex(handledData[dataIdx].driver.routes, {
            routeId: item.routeId,
          });
          if (routeIdx !== -1) {
            // If exists, updates with value and adds to total
            switch (item.paymentMethod) {
              case 'CREDITO':
                handledData[dataIdx].driver.routes[routeIdx].valueCredit =
                  item.sumPrice;
                handledData[dataIdx].driver.routes[routeIdx].valueTotal +=
                  item.sumPrice;
                handledData[dataIdx].driver.totalCredit += item.sumPrice;
                break;
              case 'DEBITO':
                handledData[dataIdx].driver.routes[routeIdx].valueDebit =
                  item.sumPrice;
                handledData[dataIdx].driver.routes[routeIdx].valueTotal +=
                  item.sumPrice;
                handledData[dataIdx].driver.totalDebit += item.sumPrice;
                break;
              case 'PIX':
                handledData[dataIdx].driver.routes[routeIdx].valuePix =
                  item.sumPrice;
                handledData[dataIdx].driver.routes[routeIdx].valueTotal +=
                  item.sumPrice;
                handledData[dataIdx].driver.totalPix += item.sumPrice;
                break;
              case 'DINHEIRO':
                handledData[dataIdx].driver.routes[routeIdx].valueCash =
                  item.sumPrice;
                handledData[dataIdx].driver.routes[routeIdx].valueTotal +=
                  item.sumPrice;
                handledData[dataIdx].driver.totalCash += item.sumPrice;
                break;
            }
            handledData[dataIdx].driver.total += item.sumPrice;
          } else {
            // Creates route entry
            handledData[dataIdx].driver.routes.push({
              routeId: item.routeId,
              routeName: routeObj.value,
              valueCredit: item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
              valueDebit: item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
              valuePix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
              valueCash: item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
              valueTotal: item.sumPrice,
            });
            switch (item.paymentMethod) {
              case 'CREDITO':
                handledData[dataIdx].driver.totalCredit += item.sumPrice;
                break;
              case 'DEBITO':
                handledData[dataIdx].driver.totalDebit += item.sumPrice;
                break;
              case 'PIX':
                handledData[dataIdx].driver.totalPix += item.sumPrice;
                break;
              case 'DINHEIRO':
                handledData[dataIdx].driver.totalCash += item.sumPrice;
                break;
            }
            handledData[dataIdx].driver.total += item.sumPrice;
          }
        } else {
          // Creates drivers entry
          handledData.push({
            areaId: areaObj.id,
            registerDate: item.registerDate,
            areaName: areaObj?.value,
            operational: {
              totalCredit: 0,
              totalDebit: 0,
              totalPix: 0,
              totalCash: 0,
              total: 0,
              users: [],
            },
            driver: {
              totalCredit: item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
              totalDebit: item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
              totalPix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
              totalCash: item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
              total: item.sumPrice,
              routes: [
                {
                  routeId: item.routeId,
                  routeName: routeObj.value,
                  valueCredit:
                    item.paymentMethod === 'CREDITO' ? item.sumPrice : 0,
                  valueDebit:
                    item.paymentMethod === 'DEBITO' ? item.sumPrice : 0,
                  valuePix: item.paymentMethod === 'PIX' ? item.sumPrice : 0,
                  valueCash:
                    item.paymentMethod === 'DINHEIRO' ? item.sumPrice : 0,
                  valueTotal: item.sumPrice,
                },
              ],
            },
          });
        }
      }
    }
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(60));
  await delay(100);

  // Ordering alphabetically each array
  map(handledData, (data) => {
    map(data.operational.users, (user) => {
      const orderedUserRoutes = orderBy(user.routes, ['routeName']);
      user.routes = orderedUserRoutes;
    });

    const orderedUsers = orderBy(data.operational.users, ['userName']);
    data.operational.users = orderedUsers;

    const orderedDriverRoutes = orderBy(data.driver.routes, ['routeName']);
    data.driver.routes = orderedDriverRoutes;
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(70));
  await delay(100);

  // Initializing stuff
  const htmlStuff: {
    title: string;
    body: string;
  }[] = [];

  // Creating <tr>s
  forEach(handledData, (data, index) => {
    const title = `${data.areaName} ${moment(data.registerDate)
      .format('DD-MM-YYYY')
      .toString()}`;
    let operationalTr = '';
    let driverTr = '';

    // Creating operational <tr>s
    map(data.operational.users, (item) => {
      const currUserRows: string[] = ['', '', '', '', '', ''];
      map(item.routes, (route) => {
        currUserRows[0] += '<br />' + route.routeName;
        currUserRows[1] +=
          '<br />' + replace(route.routeCredit.toFixed(2).toString(), '.', ',');
        currUserRows[2] +=
          '<br />' + replace(route.routeDebit.toFixed(2).toString(), '.', ',');
        currUserRows[3] +=
          '<br />' + replace(route.routePix.toFixed(2).toString(), '.', ',');
        currUserRows[4] +=
          '<br />' + replace(route.routeCash.toFixed(2).toString(), '.', ',');
        currUserRows[5] +=
          '<br />' + replace(route.routeTotal.toFixed(2).toString(), '.', ',');
      });

      operationalTr += `
        <tr>
          <td>
            <strong> ${item.userName}</strong>
            ${currUserRows[0]}
          </td>
          <td>
            <strong> ${replace(
              item.partialCredit.toFixed(2).toString(),
              '.',
              ',',
            )}</strong>
            ${currUserRows[1]}
          </td>
          <td>
            <strong> ${replace(
              item.partialDebit.toFixed(2).toString(),
              '.',
              ',',
            )}</strong>
            ${currUserRows[2]}
          </td>
          <td>
            <strong> ${replace(
              item.partialPix.toFixed(2).toString(),
              '.',
              ',',
            )}</strong>
            ${currUserRows[3]}
          </td>
          <td>
            <strong> ${replace(
              item.partialCash.toFixed(2).toString(),
              '.',
              ',',
            )}</strong>
            ${currUserRows[4]}
          </td>
          <td>
            <strong> ${replace(
              item.partialTotal.toFixed(2).toString(),
              '.',
              ',',
            )}</strong>
            ${currUserRows[5]}
          </td>
        </tr>
      `;
    });

    // Creating driver <tr>s
    map(data.driver.routes, (item) => {
      if (item) {
        driverTr += `
        <tr>
          <td><strong>${item.routeName}</strong></td>
          <td><strong> ${replace(
            item.valueCredit.toFixed(2).toString(),
            '.',
            ',',
          )}</strong></td>
          <td><strong> ${replace(
            item.valueDebit.toFixed(2).toString(),
            '.',
            ',',
          )}</strong></td>
          <td><strong> ${replace(
            item.valuePix.toFixed(2).toString(),
            '.',
            ',',
          )}</strong></td>
          <td><strong> ${replace(
            item.valueCash.toFixed(2).toString(),
            '.',
            ',',
          )}</strong></td>
          <td><strong> ${replace(
            item.valueTotal.toFixed(2).toString(),
            '.',
            ',',
          )}</strong></td>
        </tr>`;
      }
    });

    // Mounting html for this area/ date
    const fullBody =
      operationalTr.length === 0
        ? `
          <tr>
            <td class="wrapper">
              <table border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td>
                    <h1>Relatório financeiro resumido</h1>
                    <h3>
                      ${handledData[index].areaName} (${moment(
            handledData[index].registerDate,
          )
            .format('DD/MM/YYYY')
            .toString()})
                    </h3>
                    <table border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td align="left">
                            <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                              <thead>
                                <tr>
                                  <th align="left">Motorista</th>
                                  <th align="left">Crédito</th>
                                  <th align="left">Débito</th>
                                  <th align="left">Pix</th>
                                  <th align="left">Dinheiro</th>
                                  <th align="left">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                ${driverTr}
                                <tr>
                                  <td><strong>Total motorista:</strong></td>
                                  <td><strong> ${replace(
                                    data.driver.totalCredit
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    data.driver.totalDebit
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    data.driver.totalPix.toFixed(2).toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    data.driver.totalCash.toFixed(2).toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    data.driver.total.toFixed(2).toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                </tr>
                                <tr>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                </tr>
                                <tr>
                                  <td><strong>Total geral:</strong></td>
                                  <td><strong> ${replace(
                                    (
                                      data.operational.totalCredit +
                                      data.driver.totalCredit
                                    )
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    (
                                      data.operational.totalDebit +
                                      data.driver.totalDebit
                                    )
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    (
                                      data.operational.totalPix +
                                      data.driver.totalPix
                                    )
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    (
                                      data.operational.totalCash +
                                      data.driver.totalCash
                                    )
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    (data.operational.total + data.driver.total)
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        `
        : driverTr.length === 0
        ? `
          <tr>
            <td class="wrapper">
              <table border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td>
                    <h1>Relatório financeiro resumido</h1>
                    <h3>
                      ${handledData[index].areaName} (${moment(
            handledData[index].registerDate,
          )
            .format('DD/MM/YYYY')
            .toString()})
                    </h3>
                    <table border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td align="left">
                            <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                              <thead>
                                <tr>
                                  <th align="left">Operacional</th>
                                  <th align="left">Crédito</th>
                                  <th align="left">Débito</th>
                                  <th align="left">Pix</th>
                                  <th align="left">Dinheiro</th>
                                  <th align="left">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                ${operationalTr}
                                <tr>
                                  <td><strong>Total operacional:</strong></td>
                                  <td><strong> ${replace(
                                    data.operational.totalCredit
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    data.operational.totalDebit
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    data.operational.totalPix
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    data.operational.totalCash
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    data.operational.total
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                </tr>
                                <tr>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                  <td><strong></strong></td>
                                </tr>
                                <tr>
                                  <td><strong>Total geral:</strong></td>
                                  <td><strong> ${replace(
                                    (
                                      data.operational.totalCredit +
                                      data.driver.totalCredit
                                    )
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    (
                                      data.operational.totalDebit +
                                      data.driver.totalDebit
                                    )
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    (
                                      data.operational.totalPix +
                                      data.driver.totalPix
                                    )
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    (
                                      data.operational.totalCash +
                                      data.driver.totalCash
                                    )
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                  <td><strong> ${replace(
                                    (data.operational.total + data.driver.total)
                                      .toFixed(2)
                                      .toString(),
                                    '.',
                                    ',',
                                  )}</strong></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        `
        : `
        <tr>
          <td class="wrapper">
            <table border="0" cellpadding="0" cellspacing="0">
              <tr>
                <td>
                  <h1>Relatório financeiro resumido</h1>
                  <h3>
                    ${handledData[index].areaName} (${moment(
            handledData[index].registerDate,
          )
            .format('DD/MM/YYYY')
            .toString()})
                  </h3>
                  <table border="0" cellpadding="0" cellspacing="0">
                    <tbody>
                      <tr>
                        <td align="left">
                          <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                            <thead>
                              <tr>
                                <th align="left">Operacional</th>
                                <th align="left">Crédito</th>
                                <th align="left">Débito</th>
                                <th align="left">Pix</th>
                                <th align="left">Dinheiro</th>
                                <th align="left">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              ${operationalTr}
                              <tr>
                                <td><strong>Total operacional:</strong></td>
                                <td><strong> ${replace(
                                  data.operational.totalCredit
                                    .toFixed(2)
                                    .toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  data.operational.totalDebit
                                    .toFixed(2)
                                    .toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  data.operational.totalPix
                                    .toFixed(2)
                                    .toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  data.operational.totalCash
                                    .toFixed(2)
                                    .toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  data.operational.total.toFixed(2).toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                              </tr>
                              <tr>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                              </tr>
                              <tr>
                                <td><strong>Motorista</strong></td>
                                <td><strong>Crédito</strong></td>
                                <td><strong>Débito</strong></td>
                                <td><strong>Pix</strong></td>
                                <td><strong>Dinheiro</strong></td>
                                <td><strong>Total</strong></td>
                              </tr>
                              ${driverTr}
                              <tr>
                                <td><strong>Total motorista:</strong></td>
                                <td><strong> ${replace(
                                  data.driver.totalCredit.toFixed(2).toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  data.driver.totalDebit.toFixed(2).toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  data.driver.totalPix.toFixed(2).toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  data.driver.totalCash.toFixed(2).toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  data.driver.total.toFixed(2).toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                              </tr>
                              <tr>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                                <td><strong></strong></td>
                              </tr>
                              <tr>
                                <td><strong>Total geral:</strong></td>
                                <td><strong> ${replace(
                                  (
                                    data.operational.totalCredit +
                                    data.driver.totalCredit
                                  )
                                    .toFixed(2)
                                    .toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  (
                                    data.operational.totalDebit +
                                    data.driver.totalDebit
                                  )
                                    .toFixed(2)
                                    .toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  (
                                    data.operational.totalPix +
                                    data.driver.totalPix
                                  )
                                    .toFixed(2)
                                    .toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  (
                                    data.operational.totalCash +
                                    data.driver.totalCash
                                  )
                                    .toFixed(2)
                                    .toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                                <td><strong> ${replace(
                                  (data.operational.total + data.driver.total)
                                    .toFixed(2)
                                    .toString(),
                                  '.',
                                  ',',
                                )}</strong></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      `;
    htmlStuff.push({
      title: title,
      body: fullBody,
    });
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(90));
  await delay(100);

  const zip = new JSZip();

  map(htmlStuff, function (item) {
    zip.file(
      `${item.title}.html`,
      `
      <!doctype html>
      <html>
      <head>
        <meta name="viewport" content="width=device-width" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Relatório financeiro resumido</title>
        <style>

          /* -------------------------------------
            GLOBAL RESETS
          ------------------------------------- */
          img {
            border: none;
            -ms-interpolation-mode: bicubic;
            max-width: 100%;
          }

          body {
            background-color: white;
            font-family: sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }

          table {
            border-collapse: separate;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            width: 100%;
          }

            table td {
              font-family: sans-serif;
              font-size: 14px;
              vertical-align: top;
            }

          /* -------------------------------------
            BODY & CONTAINER
          ------------------------------------- */

          .body {
            background-color: white;
            width: 100%;
          }

          /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
          .container {
            display: block;
            Margin: 0 auto !important;
            /* makes it centered */
            max-width: 100%;
            padding: 10px;
            /*width: 100%;*/
          }

          /* This should also be a block element, so that it will fill 100% of the .container */
          .content {
            box-sizing: border-box;
            display: block;
            Margin: 0 auto;
            max-width: 100%;
            padding: 10px;
          }

          /* -------------------------------------
            HEADER, FOOTER, MAIN
          ------------------------------------- */
          .main {
            background: #fff;
            border-radius: 3px;
            width: 100%;
          }

          .wrapper {
            box-sizing: border-box;
            padding: 20px;
          }

          .footer {
            clear: both;
            padding-top: 10px;
            text-align: center;
            width: 100%;
          }

            .footer td,
            .footer p,
            .footer span,
            .footer a {
              color: #999999;
              font-size: 12px;
              text-align: center;
            }

          /* -------------------------------------
            TYPOGRAPHY
          ------------------------------------- */
          h2,
          h3,
          h4 {
            color: #000000;
            font-family: sans-serif;
            font-weight: 400;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 15px;
          }

          h1 {
            font-size: 35px;
            font-weight: 600;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 8px;
          }

          h3 {
            font-size: 17px;
            font-weight: 600;
          }

          p,
          ul,
          ol {
            font-family: sans-serif;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            Margin-bottom: 15px;
          }

            p li,
            ul li,
            ol li {
              list-style-position: inside;
              margin-left: 5px;
            }

          a {
            color: #3498db;
            text-decoration: underline;
          }

          /* -------------------------------------
            BUTTONS
          ------------------------------------- */
          .btn {
            box-sizing: border-box;
            width: 100%;
          }

            .btn > tbody > tr > td {
              padding-bottom: 15px;
            }

            .btn table {
              width: auto;
            }

              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }

            .btn a {
              background-color: #ffffff;
              border: solid 1px #3498db;
              border-radius: 5px;
              box-sizing: border-box;
              color: #3498db;
              cursor: pointer;
              display: inline-block;
              font-size: 14px;
              font-weight: bold;
              margin: 0;
              padding: 12px 25px;
              text-decoration: none;
              text-transform: capitalize;
            }

          .btn-primary table td {
            background-color: #3498db;
          }

          .btn-primary a {
            background-color: #3498db;
            border-color: #3498db;
            color: #ffffff;
          }

          /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
          ------------------------------------- */
          .last {
            margin-bottom: 0;
          }

          .first {
            margin-top: 0;
          }

          .align-center {
            text-align: center;
          }

          .align-right {
            text-align: right;
          }

          .align-left {
            text-align: left;
          }

          .clear {
            clear: both;
          }

          .mt0 {
            margin-top: 0;
          }

          .mb0 {
            margin-bottom: 0;
          }

          .preheader {
            color: transparent;
            display: none;
            height: 0;
            max-height: 0;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            mso-hide: all;
            visibility: hidden;
            width: 0;
          }

          .powered-by a {
            text-decoration: none;
          }

          hr {
            border: 0;
            border-bottom: 1px solid black;
            Margin: 30px 0 10px;
          }

          /* -------------------------------------
              CUSTOM CSS BY ME
            ------------------------------------- */

          .table-adjustment tbody td, .table-adjustment thead th {
            padding: 5px;
          }

          /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
          ------------------------------------- */
          @media only screen and (max-width: 620px) {
            table[class=body] h1 {
              font-size: 28px !important;
              margin-bottom: 10px !important;
            }

            table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
              font-size: 16px !important;
            }

            table[class=body] .wrapper,
            table[class=body] .article {
              padding: 10px !important;
            }

            table[class=body] .content {
              padding: 0 !important;
            }

            table[class=body] .container {
              padding: 0 !important;
              width: 100% !important;
            }

            table[class=body] .main {
              border-left-width: 0 !important;
              border-radius: 0 !important;
              border-right-width: 0 !important;
            }

            table[class=body] .btn table {
              width: 100% !important;
            }

            table[class=body] .btn a {
              width: 100% !important;
            }

            table[class=body] .img-responsive {
              height: auto !important;
              max-width: 100% !important;
              width: auto !important;
            }
          }

          /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
          ------------------------------------- */
          @media all {
            .ExternalClass {
              width: 100%;
            }

              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%;
              }

            .apple-link a {
              color: inherit !important;
              font-family: inherit !important;
              font-size: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
              text-decoration: none !important;
            }

            .btn-primary table td:hover {
              background-color: #34495e !important;
            }

            .btn-primary a:hover {
              background-color: #34495e !important;
              border-color: #34495e !important;
            }
          }
        </style>
      </head>
      <body class="">
        <table border="0" cellpadding="0" cellspacing="0" class="body">
          <tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
                <!-- Logo da niterói rotativo -->
                <div class="img-container" style="text-align:center">
                  <img src=${logoUrl} alt="Logo" height="66" width="100" />
                </div>
                <!-- START CENTERED WHITE CONTAINER -->
                <table class="main">
                  <!-- START MAIN CONTENT AREA -->
                  ${item.body}
                  <!-- END MAIN CONTENT AREA -->
                </table>
                <!-- END CENTERED WHITE CONTAINER -->
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </body>
      </html>
      `,
    );
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(100));
  await delay(100);

  const content = await zip.generateAsync({ type: 'blob' });

  FileSaver.saveAs(
    content,
    `relatorio-vendas-${moment().format('yyyy-MM-DD_hh-mm')}` + '.zip',
  );
};

export const exportOperationalToHTML = async (
  apiData: SalesExportOperationalHtmlResponseType,
  filters: SalesFiltersType | null,
  granularity: number,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
): Promise<void> => {
  const handledData: {
    areaId: number;
    areaName: string;
    registerDate: string;
    total: number;
    operational: {
      userId: number;
      userName: string;
      userTotal: number;
      firstRegisterDateTime: string;
      lastRegisterDateTime: string;
      rows: {
        startTime: string;
        endTime: string;
        firstRegisterDateTime: string;
        lastRegisterDateTime: string;
        rowTotal: number;
      }[];
    }[];
  }[] = [];
  dispatch(salesListSlice.actions.setSalesExportPercentage(20));
  await delay(100);

  const auxRoutes: any[] = [];
  const auxUsers: any[] = [];

  const preparedApiData = map(apiData, (item) => {
    if (auxRoutes[item.routeId] === undefined) {
      auxRoutes[item.routeId] = find(filters?.routes, ['id', item.routeId]);
    }
    if (item.userId && auxUsers[item.userId] === undefined) {
      auxUsers[item.userId] = find(filters?.users, ['id', item.userId]);
    }

    return {
      ...item,
      route: auxRoutes[item.routeId],
      user: auxUsers[item.userId],
    };
  });

  // Grouping apiData by areaId, registerDatetime
  const orderedApiData = groupBy(
    preparedApiData,
    (item) =>
      `"${item.route.areaId}+${moment(item.registerDateTime)
        .format('yyyy-MM-DD')
        .toString()}"`,
  );
  dispatch(salesListSlice.actions.setSalesExportPercentage(30));
  await delay(100);

  // Populating handledData
  map(orderedApiData, (file) => {
    const areaObj = find(filters?.areas, ['id', file[0].route.areaId]);

    if (areaObj) {
      handledData.push({
        areaId: file[0].route.areaId,
        areaName: areaObj.value,
        registerDate: moment(file[0].registerDateTime)
          .format('yyyy-MM-DD')
          .toString(),
        total: 0,
        operational: [],
      });

      const currDataIdx = handledData.length - 1;
      const orderedUsers = groupBy(file, 'userId');

      map(orderedUsers, (users) => {
        // Readying auxiliar rows array
        const rows: {
          startTime: string;
          endTime: string;
          firstRegisterDateTime: string;
          lastRegisterDateTime: string;
          rowTotal: number;
        }[] = [];
        let i = 0;
        let j = 0;
        while (i < 24) {
          i += granularity;
          if (i > 24) i = 24;
          rows.push({
            startTime: j.toString(),
            endTime: i.toString(),
            firstRegisterDateTime: 'Nenhuma',
            lastRegisterDateTime: 'Nenhuma',
            rowTotal: 0,
          });
          j = i;
        }

        // Run users and add all to row in handledData
        map(users, (user, index) => {
          // Pushes new user if first iteration
          if (index === 0) {
            handledData[currDataIdx].operational.push({
              userId: user.user.id,
              userName: user.user.value,
              userTotal: 0,
              firstRegisterDateTime: 'Nenhuma',
              lastRegisterDateTime: 'Nenhuma',
              rows: rows,
            });
          }

          const currOperationalIdx =
            handledData[currDataIdx].operational.length - 1;

          // Converting user.registerDateTime to seconds for calculation
          const itemHourInSec =
            parseInt(moment(user.registerDateTime).format('HH')) * 3600 +
            parseInt(moment(user.registerDateTime).format('mm')) +
            60 +
            parseInt(moment(user.registerDateTime).format('ss'));

          // Checking if it should go to first register date time of user
          if (
            handledData[currDataIdx].operational[currOperationalIdx]
              .firstRegisterDateTime === 'Nenhuma' ||
            parseInt(
              handledData[currDataIdx].operational[currOperationalIdx]
                .firstRegisterDateTime,
            ) *
              3600 <
              itemHourInSec
          ) {
            handledData[currDataIdx].operational[
              currOperationalIdx
            ].firstRegisterDateTime = user.registerDateTime;
          }

          // Checking if it should go to last register date time of user
          if (
            handledData[currDataIdx].operational[currOperationalIdx]
              .lastRegisterDateTime === 'Nenhuma' ||
            parseInt(
              handledData[currDataIdx].operational[currOperationalIdx]
                .lastRegisterDateTime,
            ) *
              3600 >
              itemHourInSec
          ) {
            handledData[currDataIdx].operational[
              currOperationalIdx
            ].lastRegisterDateTime = user.registerDateTime;
          }

          // Finding where to place item in from rows
          const rowIdx = findIndex(
            handledData[currDataIdx].operational[currOperationalIdx].rows,
            (row) => {
              return (
                parseInt(row.startTime) * 3600 <= itemHourInSec &&
                parseInt(row.endTime) * 3600 >= itemHourInSec
              );
            },
          );

          // Filling data on rows
          const currFirstRegister =
            handledData[currDataIdx].operational[currOperationalIdx].rows[
              rowIdx
            ].firstRegisterDateTime;
          const currLastRegister =
            handledData[currDataIdx].operational[currOperationalIdx].rows[
              rowIdx
            ].lastRegisterDateTime;
          const currCheckingRegister = user.registerDateTime;

          if (
            currFirstRegister === 'Nenhuma' ||
            moment(currCheckingRegister).isBefore(moment(currFirstRegister))
          ) {
            handledData[currDataIdx].operational[currOperationalIdx].rows[
              rowIdx
            ].firstRegisterDateTime = currCheckingRegister;
            if (currLastRegister === 'Nenhuma') {
              handledData[currDataIdx].operational[currOperationalIdx].rows[
                rowIdx
              ].lastRegisterDateTime = currFirstRegister;
            }
          }

          if (
            currLastRegister === 'Nenhuma' ||
            moment(currCheckingRegister).isAfter(moment(currLastRegister))
          ) {
            handledData[currDataIdx].operational[currOperationalIdx].rows[
              rowIdx
            ].lastRegisterDateTime = currCheckingRegister;
          }

          handledData[currDataIdx].operational[currOperationalIdx].rows[
            rowIdx
          ].rowTotal += user.price;

          handledData[currDataIdx].operational[currOperationalIdx].userTotal +=
            user.price;

          handledData[currDataIdx].total += user.price;
        });
      });
    }
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(50));
  await delay(100);

  // Initializing stuff
  const htmlStuff: {
    title: string;
    body: string;
  }[] = [];

  // Ordering users alphabetically on each file
  map(handledData, (data) => {
    const orderedOperational = orderBy(data.operational, ['userName']);
    data.operational = orderedOperational;
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(60));
  await delay(100);

  // Creating <tr>s
  forEach(handledData, (data, index) => {
    const title = `${data.areaName} ${moment(data.registerDate)
      .format('DD-MM-YYYY')
      .toString()}`;
    let tr = '';

    // Creating <tr>s
    map(data.operational, (item) => {
      const currUserRows: string[] = ['', '', '', ''];

      map(item.rows, (row) => {
        currUserRows[0] += `<br />${('0' + row.startTime).slice(-2)}:00 - ${
          row.endTime === '24' ? '23' : ('0' + row.endTime).slice(-2)
        }${row.endTime === '24' ? ':59' : ':00'}`;
        currUserRows[1] += `<br />${
          row.firstRegisterDateTime === 'Nenhuma'
            ? row.firstRegisterDateTime
            : moment(row.firstRegisterDateTime)
                .format('DD/MM/yyyy HH:mm:ss')
                .toString()
        }`;
        currUserRows[2] += `<br />${
          row.lastRegisterDateTime === 'Nenhuma'
            ? row.lastRegisterDateTime
            : moment(row.lastRegisterDateTime)
                .format('DD/MM/yyyy HH:mm:ss')
                .toString()
        }`;
        currUserRows[3] +=
          '<br />' + replace(row.rowTotal.toFixed(2).toString(), '.', ',');
      });

      tr += `
        <tr>
          <td>
            <strong> ${item.userName}</strong>
            ${currUserRows[0]}
          </td>
          <td>
            <strong> ${
              item.firstRegisterDateTime === 'Nenhuma'
                ? item.firstRegisterDateTime
                : moment(item.firstRegisterDateTime)
                    .format('DD/MM/yyyy HH:mm:ss')
                    .toString()
            }</strong>
            ${currUserRows[1]}
          </td>
          <td>
            <strong> ${
              item.lastRegisterDateTime === 'Nenhuma'
                ? item.lastRegisterDateTime
                : moment(item.lastRegisterDateTime)
                    .format('DD/MM/yyyy HH:mm:ss')
                    .toString()
            }</strong>
            ${currUserRows[2]}
          </td>
          <td>
            <strong> ${replace(
              item.userTotal.toFixed(2).toString(),
              '.',
              ',',
            )}</strong>
            ${currUserRows[3]}
          </td>
        </tr>
      `;
    });

    // Mounting html for this area/ date
    const fullBody = `
      <!doctype html>
      <html>
      <head>
        <meta name="viewport" content="width=device-width" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title>Relatório financeiro resumido</title>
        <style>
      
      
          /* -------------------------------------
            GLOBAL RESETS
          ------------------------------------- */
          img {
            border: none;
            -ms-interpolation-mode: bicubic;
            max-width: 100%;
          }
      
          body {
            background-color: white;
            font-family: sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }
      
          table {
            border-collapse: separate;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            width: 100%;
          }
      
            table td {
              font-family: sans-serif;
              font-size: 14px;
              vertical-align: top;
            }
      
          /* -------------------------------------
            BODY & CONTAINER
          ------------------------------------- */
      
          .body {
            background-color: white;
            width: 100%;
          }
      
          /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
          .container {
            display: block;
            Margin: 0 auto !important;
            /* makes it centered */
            max-width: 100%;
            padding: 10px;
            /*width: 100%;*/
          }
      
          /* This should also be a block element, so that it will fill 100% of the .container */
          .content {
            box-sizing: border-box;
            display: block;
            Margin: 0 auto;
            max-width: 100%;
            padding: 10px;
          }
      
          /* -------------------------------------
            HEADER, FOOTER, MAIN
          ------------------------------------- */
          .main {
            background: #fff;
            border-radius: 3px;
            width: 100%;
          }
      
          .wrapper {
            box-sizing: border-box;
            padding: 20px;
          }
      
          .footer {
            clear: both;
            padding-top: 10px;
            text-align: center;
            width: 100%;
          }
      
            .footer td,
            .footer p,
            .footer span,
            .footer a {
              color: #999999;
              font-size: 12px;
              text-align: center;
            }
      
          /* -------------------------------------
            TYPOGRAPHY
          ------------------------------------- */
          h2,
          h3,
          h4 {
            color: #000000;
            font-family: sans-serif;
            font-weight: 400;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 15px;
          }
      
          h1 {
            font-size: 35px;
            font-weight: 600;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 8px;
          }
      
          h3 {
            font-size: 17px;
            font-weight: 600;
          }
      
          p,
          ul,
          ol {
            font-family: sans-serif;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            Margin-bottom: 15px;
          }
      
            p li,
            ul li,
            ol li {
              list-style-position: inside;
              margin-left: 5px;
            }
      
          a {
            color: #3498db;
            text-decoration: underline;
          }
      
          /* -------------------------------------
            BUTTONS
          ------------------------------------- */
          .btn {
            box-sizing: border-box;
            width: 100%;
          }
      
            .btn > tbody > tr > td {
              padding-bottom: 15px;
            }
      
            .btn table {
              width: auto;
            }
      
              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }
      
            .btn a {
              background-color: #ffffff;
              border: solid 1px #3498db;
              border-radius: 5px;
              box-sizing: border-box;
              color: #3498db;
              cursor: pointer;
              display: inline-block;
              font-size: 14px;
              font-weight: bold;
              margin: 0;
              padding: 12px 25px;
              text-decoration: none;
              text-transform: capitalize;
            }
      
          .btn-primary table td {
            background-color: #3498db;
          }
      
          .btn-primary a {
            background-color: #3498db;
            border-color: #3498db;
            color: #ffffff;
          }
      
          /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
          ------------------------------------- */
          .last {
            margin-bottom: 0;
          }
      
          .first {
            margin-top: 0;
          }
      
          .align-center {
            text-align: center;
          }
      
          .align-right {
            text-align: right;
          }
      
          .align-left {
            text-align: left;
          }
      
          .clear {
            clear: both;
          }
      
          .mt0 {
            margin-top: 0;
          }
      
          .mb0 {
            margin-bottom: 0;
          }
      
          .preheader {
            color: transparent;
            display: none;
            height: 0;
            max-height: 0;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            mso-hide: all;
            visibility: hidden;
            width: 0;
          }
      
          .powered-by a {
            text-decoration: none;
          }
      
          hr {
            border: 0;
            border-bottom: 1px solid black;
            Margin: 30px 0 10px;
          }
      
          /* -------------------------------------
              CUSTOM CSS BY ME
            ------------------------------------- */
      
          .table-adjustment tbody td, .table-adjustment thead th {
            padding: 5px;
          }
      
          /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
          ------------------------------------- */
          @media only screen and (max-width: 620px) {
            table[class=body] h1 {
              font-size: 28px !important;
              margin-bottom: 10px !important;
            }
      
            table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
              font-size: 16px !important;
            }
      
            table[class=body] .wrapper,
            table[class=body] .article {
              padding: 10px !important;
            }
      
            table[class=body] .content {
              padding: 0 !important;
            }
      
            table[class=body] .container {
              padding: 0 !important;
              width: 100% !important;
            }
      
            table[class=body] .main {
              border-left-width: 0 !important;
              border-radius: 0 !important;
              border-right-width: 0 !important;
            }
      
            table[class=body] .btn table {
              width: 100% !important;
            }
      
            table[class=body] .btn a {
              width: 100% !important;
            }
      
            table[class=body] .img-responsive {
              height: auto !important;
              max-width: 100% !important;
              width: auto !important;
            }
          }
      
          /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
          ------------------------------------- */
          @media all {
            .ExternalClass {
              width: 100%;
            }
      
              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%;
              }
      
            .apple-link a {
              color: inherit !important;
              font-family: inherit !important;
              font-size: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
              text-decoration: none !important;
            }
      
            .btn-primary table td:hover {
              background-color: #34495e !important;
            }
      
            .btn-primary a:hover {
              background-color: #34495e !important;
              border-color: #34495e !important;
            }
          }
        </style>
      </head>
      <body class="">
        <table border="0" cellpadding="0" cellspacing="0" class="body">
          <tr>
            <td>&nbsp;</td>
            <td class="container">
              <div class="content">
                <!-- Logo da niterói rotativo -->
                <div class="img-container" style="text-align:center">
                  <img src=${logoUrl} alt="Logo" height="66" width="100" />
                </div>
                <!-- START CENTERED WHITE CONTAINER -->
                <table class="main">
                  <!-- START MAIN CONTENT AREA -->
                  <tr>
                    <td class="wrapper">
                      <table border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td>
                            <h1>Relatório de Apoio Operacional</h1>
                            <h3>${handledData[index].areaName} (${moment(
      handledData[index].registerDate,
    )
      .format('DD/MM/YYYY')
      .toString()})</h3>
                            <table border="0" cellpadding="0" cellspacing="0">
                              <tbody>
                                <tr>
                                  <td align="left">
                                    <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                                      <thead>
                                        <tr>
                                          <th align="left">Operacional</th>
                                          <th align="left">Primeira</th>
                                          <th align="left">Ultima</th>
                                          <th align="left">Total</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        ${tr}
                                        <tr>
                                          <td><strong>Total operacional:</strong></td>
                                          <td><strong> </strong></td>
                                          <td><strong> </strong></td>
                                          <td><strong> ${replace(
                                            data.total.toFixed(2).toString(),
                                            '.',
                                            ',',
                                          )}</strong></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <!-- END MAIN CONTENT AREA -->
                </table>
                <!-- END CENTERED WHITE CONTAINER -->
              </div>
            </td>
            <td>&nbsp;</td>
          </tr>
        </table>
      </body>
      </html>
      `;
    htmlStuff.push({
      title: title,
      body: fullBody,
    });
  });
  const zip = new JSZip();
  dispatch(salesListSlice.actions.setSalesExportPercentage(90));
  await delay(100);

  map(htmlStuff, function (item) {
    zip.file(`${item.title}.html`, item.body);
  });
  dispatch(salesListSlice.actions.setSalesExportPercentage(100));
  await delay(100);

  const content = await zip.generateAsync({ type: 'blob' });

  FileSaver.saveAs(
    content,
    `apoio-operacional-${moment().format('yyyy-MM-DD_hh-mm')}` + '.zip',
  );
};
