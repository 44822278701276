/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { SessionsExportResponseType } from '../../../../services/Sessions/types';
import { sessionsListSlice } from '../../../../stores';

export const exportSessionsToCSV = async (
  apiData: SessionsExportResponseType,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  worker: (apiData: SessionsExportResponseType) => Promise<string>,
): Promise<void> => {
  dispatch(sessionsListSlice.actions.setSessionsExportPercentage(20));

  const handledApiData = await worker(apiData);
  dispatch(sessionsListSlice.actions.setSessionsExportPercentage(60));

  const fileType = 'data:text/csv;charset=utf-8;';
  const fileExtension = '.csv';
  dispatch(sessionsListSlice.actions.setSessionsExportPercentage(100));

  const blob = new Blob([handledApiData], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `sessoes-${moment().format('yyyy-MM-DD_hh-mm')}` + fileExtension,
  );

  return;
};

export const handleApiDataSessionsToCSV = (
  apiData: SessionsExportResponseType,
) => {
  let handledApiData = `"Nome";"Login";"Máquina POS";"Início";"Fim";"Latitude";"Longitude";"Trechos";\r\n`;

  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[8] +
      dateTime[9] +
      '/' +
      dateTime[5] +
      dateTime[6] +
      '/' +
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3] +
      ' ' +
      dateTime.slice(11)
    );
  };

  apiData.map((item) => {
    const startDateTime = formatDateTime(item.startDateTime);
    const finalDateTime = formatDateTime(item.finalDateTime);
    handledApiData =
      handledApiData +
      `"${item.userName}";"${item.userLogin}";"${
        item.deviceId
      }";"${startDateTime}";"${
        finalDateTime === '01/01/0001 12:00:00' ? '--' : finalDateTime
      }";"${item.latitude}";"${item.longitude}";"${item.routes}";\r\n`;
  });

  return handledApiData;
};
