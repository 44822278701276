/* eslint-disable pii/no-phone-number */
import CancelIcon from '@mui/icons-material/Cancel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  PageWrapper,
  Form,
  FiltersGrid,
  Accordion,
  DropdownInput,
} from '../../components';
import {
  useDispatch,
  fetchUsersFilters,
  fetchUsersList,
  useSelector,
  usersFiltersValuesSlice,
  usersListSlice,
} from '../../stores';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { CancelUserModal } from './modals';
import { StyledFiltersBtnsGrid } from './styles';
import { UsersTable } from './table';

export const UsersManagement: React.FC = () => {
  const dispatch = useDispatch();
  const { filters, filtersLoading } = useSelector(
    (state) => state.usersFiltersReducer,
  );
  const filtersValues = useSelector((state) => state.usersFiltersValuesReducer);
  const { usersPage, usersListLoading } = useSelector(
    (state) => state.usersListReducer,
  );
  const responsiveMode = useResponsiveMode();
  const [expandedAccordeon, setExpandedAccordeon] = useState(false);
  const [formData, setFormData] = useState({
    userName: filtersValues.userName,
    login: filtersValues.login,
    areaId: filtersValues.areaId,
    profileId: filtersValues.profileId,
  });

  useEffect(() => {
    dispatch(fetchUsersFilters());
    dispatch(fetchUsersList(filtersValues));
  }, []);

  useEffect(() => {
    const values = {
      userName: formData.userName,
      login: formData.login,
      areaId: formData.areaId,
      profileId: formData.profileId,
    };

    dispatch(usersFiltersValuesSlice.actions.setUsersFiltersValues(values));
  }, [formData]);

  const handleSubmit = () => {
    const values = {
      ...filtersValues,
      page: 1,
    };
    dispatch(usersFiltersValuesSlice.actions.setUsersFiltersValues(values));
    dispatch(usersListSlice.actions.setUsersPage(1));
    dispatch(fetchUsersList(values));
    setExpandedAccordeon(false);
  };

  const handleReset = () => {
    const values = {
      userName: '',
      login: '',
      areaId: '0',
      profileId: '0',
      page: 1,
    };
    setFormData({
      userName: '',
      login: '',
      areaId: '0',
      profileId: '0',
    });
    dispatch(usersFiltersValuesSlice.actions.setUsersFiltersValues(values));
    dispatch(usersListSlice.actions.setUsersPage(1));
    dispatch(fetchUsersList(values));
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <PeopleAltIcon fontSize='large' sx={{ marginRight: 2 }} />
            Gerenciamento de usuários
          </Typography>
        </Grid>
      </Grid>
      {responsiveMode !== 'phone' ? (
        <Grid container>
          <Form onSubmit={handleSubmit}>
            <FiltersGrid loading={!filters && filtersLoading} loadingRows={2}>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <Input
                  data-testid='name'
                  type='text'
                  margin='normal'
                  label='Nome'
                  value={formData.userName}
                  onChange={(value) =>
                    setFormData({ ...formData, userName: value })
                  }
                />
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <Input
                  data-testid='login'
                  type='text'
                  margin='normal'
                  label='Login'
                  value={formData.login}
                  onChange={(value) =>
                    setFormData({ ...formData, login: value })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Área'
                  value={formData.areaId}
                  options={filters && filters.areas}
                  onChange={(value) => {
                    setFormData({ ...formData, areaId: value });
                  }}
                />
              </Grid>
              <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Perfil'
                  value={formData.profileId}
                  options={filters && filters.profiles}
                  onChange={(value) =>
                    setFormData({ ...formData, profileId: value })
                  }
                />
              </Grid>
              <StyledFiltersBtnsGrid
                item
                lg={2}
                md={3}
                sm={4}
                xs={12}
                margin={1}
              >
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAltIcon />}
                  onClick={handleSubmit}
                  disabled={usersListLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleReset}
                  disabled={usersListLoading}
                >
                  <CancelIcon fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </FiltersGrid>
          </Form>
        </Grid>
      ) : (
        <Accordion
          expanded={expandedAccordeon}
          setExpanded={setExpandedAccordeon}
          title='Filtros'
        >
          <Form onSubmit={handleSubmit}>
            <FiltersGrid loading={filtersLoading} loadingRows={1}>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <Input
                  data-testid='name'
                  type='text'
                  margin='normal'
                  label='Nome'
                  value={formData.userName}
                  onChange={(value) =>
                    setFormData({ ...formData, userName: value })
                  }
                />
              </Grid>
              <Grid item lg={2} md={3} sm={4} xs={12} margin={1}>
                <Input
                  data-testid='login'
                  type='text'
                  margin='normal'
                  label='Login'
                  value={formData.login}
                  onChange={(value) =>
                    setFormData({ ...formData, login: value })
                  }
                />
              </Grid>
              <Grid item lg={1.5} md={2} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Área'
                  value={formData.areaId}
                  options={filters && filters.areas}
                  onChange={(value) => {
                    setFormData({ ...formData, areaId: value });
                  }}
                />
              </Grid>
              <Grid item lg={2.5} md={2.5} sm={4} xs={12} margin={1}>
                <DropdownInput
                  label='Trecho'
                  value={formData.profileId}
                  options={filters && filters.profiles}
                  onChange={(value) =>
                    setFormData({ ...formData, profileId: value })
                  }
                />
              </Grid>
              <StyledFiltersBtnsGrid
                item
                lg={1.5}
                md={4}
                sm={4}
                xs={12}
                margin={1}
              >
                <Button
                  variant='contained'
                  label='Filtrar'
                  startIcon={<FilterAltIcon />}
                  onClick={handleSubmit}
                  sx={{ width: responsiveMode === 'phone' ? '100%' : 'auto' }}
                  disabled={usersListLoading}
                />
                <IconButton
                  color='secondary'
                  aria-label='Remover filtros'
                  onClick={handleReset}
                  disabled={usersListLoading}
                >
                  <CancelIcon fontSize='large' />
                </IconButton>
              </StyledFiltersBtnsGrid>
            </FiltersGrid>
          </Form>
        </Accordion>
      )}
      <Grid container margin={3}>
        <UsersTable />
      </Grid>
      <CancelUserModal />
    </PageWrapper>
  );
};
