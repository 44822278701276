import InfoIcon from '@mui/icons-material/Info';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Button as MuiButton } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as SecureStorage from 'react-secure-storage';
import { Table, TableText } from '../../../components';
import { NOTIFICATIONS_MANAGEMENT } from '../../../routes';
import {
  fetchSalesList,
  useDispatch,
  useSelector,
  salesListSlice,
  saleSlice,
  fetchSaleInfo,
} from '../../../stores';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { VehiclesEntryTableToolbar } from './VehiclesEntryTableToolbar';

export const VehiclesEntryTable: React.FC = () => {
  const dispatch = useDispatch();
  const { salesList, salesListLoading } = useSelector(
    (state) => state.salesListReducer,
  );
  const { filters } = useSelector((state) => state.salesFiltersReducer);
  const salesFiltersValues = useSelector(
    (state) => state.salesFiltersValuesReducer,
  );
  const { salesPage } = useSelector((state) => state.salesListReducer);
  const responsiveMode = useResponsiveMode();

  const [rows, setRows] = useState<any[]>([]);

  const {
    startDateTime,
    finalDateTime,
    plate,
    serviceOrderUserId,
    driverId,
    serviceOrderOrigin,
    paymentMethod,
    routeAreaId,
    routeId,
    reprint,
    hasNotPlate,
    hasIrregularity,
    hasDiscount,
    profileId,
    posMachine,
  } = salesFiltersValues;

  useEffect(() => {
    if (salesList && salesList !== undefined) {
      setRows(salesList.page);
    } else setRows([]);
  }, [salesList]);

  const handleInfo = (saleId: GridRowId, userId: string, driverId: string) => {
    dispatch(fetchSaleInfo({ saleId: saleId.toString() }));
    dispatch(
      saleSlice.actions.setSaleInfoOrigin({
        origin: userId ? 'Operacional' : driverId ? 'Motorista' : '',
      }),
    );
    dispatch(saleSlice.actions.toggleSaleInfoIsOpen());
  };

  const handleIrregularityClick = (irregularities: string | undefined) => {
    if (irregularities !== undefined) {
      SecureStorage.default.setItem('irregularity', irregularities);
    }
  };

  const handleOriginText = (userId: string, driverId: string): string => {
    if (filters) {
      let info = undefined;
      const additionalText = userId ? '(Operacional)' : '(Motorista)';
      if (userId) {
        info = find(filters.users, ['id', userId]);
      } else {
        info = find(filters.drivers, ['id', driverId]);
      }
      if (info !== undefined) {
        return `${info.value} ${additionalText}`;
      } else return `Indisponível ${additionalText}`;
    }
    return `Indisponível`;
  };

  const columns: GridColDef[] = [
    {
      field: 'vehicle',
      headerName: 'Veículo',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.vehicle} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'paymentMethod',
      headerName: 'Pagamento',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.paymentMethod} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'registerDateTime',
      headerName: 'Hora de registro',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.registerDateTime} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'entryDateTime',
      headerName: 'Hora de início',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.entryDateTime} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'period',
      headerName: 'Período',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.period} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'origin',
      headerName: 'Origem',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => (
        <TableText
          label={handleOriginText(params.row.userId, params.row.driverId)}
          size={27}
        />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'routeName',
      headerName: 'Trecho',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => (
        <TableText
          label={
            (filters &&
              find(filters?.routes, { id: params.row.routeId })?.value) ||
            'Indisponível'
          }
          size={45}
        />
      ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'irregularities',
      headerName: 'Irregularidade',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) =>
        params.row.irregularities !== '--' ? (
          <MuiButton
            variant='text'
            size='small'
            onClick={() => handleIrregularityClick(params.row.irregularities)}
            sx={{
              color: 'blue',
              '&:hover': {
                textDecoration: 'underline blue',
              },
              minWidth: 0,
            }}
          >
            <Link
              to={NOTIFICATIONS_MANAGEMENT}
              target='_blank'
              rel='noopener, noreferrer'
            >
              <TableText label={params.row.irregularities} />
            </Link>
          </MuiButton>
        ) : (
          <TableText label={params.row.irregularities} />
        ),
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'paidPrice',
      headerName: 'Valor Pago',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.paidPrice} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'price',
      headerName: 'Valor',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => <TableText label={params.row.price} />,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'Ações',
      // description: 'This column has a value getter and is not sortable.',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return responsiveMode === 'large-screen' ? (
          <MuiButton
            variant='contained'
            startIcon={<InfoIcon />}
            size='small'
            onClick={() =>
              handleInfo(params.id, params.row.userId, params.row.driverId)
            }
            sx={{ paddingLeft: 1, paddingRight: 1 }}
          >
            Detalhar
          </MuiButton>
        ) : (
          <MuiButton
            variant='contained'
            size='small'
            onClick={() =>
              handleInfo(params.id, params.row.userId, params.row.driverId)
            }
            sx={{
              minWidth: 0,
            }}
          >
            <InfoTwoToneIcon />
          </MuiButton>
        );
      },
      headerClassName: 'super-app-theme--header',
    },
  ];

  const columnsResponsive: GridColDef[] = [
    {
      field: 'vehicle',
      headerName: 'Veículo',
      editable: false,
      sortable: false,
      flex: 2,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'registerDateTime',
      headerName: 'Hora de registro',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 2,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'period',
      headerName: 'Período',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'price',
      headerName: 'Valor',
      // type: 'date',
      editable: false,
      sortable: false,
      flex: 1,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: '',
      // description: 'This column has a value getter and is not sortable.',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <MuiButton
          variant='contained'
          size='small'
          onClick={() =>
            handleInfo(params.id, params.row.userId, params.row.driverId)
          }
          sx={{
            minWidth: 0,
          }}
        >
          <InfoTwoToneIcon />
        </MuiButton>
      ),
      headerClassName: 'super-app-theme--header',
    },
  ];

  return (
    <Table
      page={salesPage}
      changePage={(value: number) => salesListSlice.actions.setSalesPage(value)}
      rows={rows}
      rowCount={salesList ? salesList.size : 0}
      fetchRows={() =>
        dispatch(
          fetchSalesList({
            startDateTime: startDateTime,
            finalDateTime: finalDateTime,
            plate: plate,
            serviceOrderUserId: serviceOrderUserId,
            driverId: driverId,
            serviceOrderOrigin: serviceOrderOrigin,
            paymentMethod: paymentMethod,
            routeAreaId: routeAreaId,
            routeId: routeId,
            reprint: reprint,
            hasNotPlate: hasNotPlate,
            hasIrregularity: hasIrregularity,
            hasDiscount: hasDiscount,
            profileId: profileId,
            posMachine: posMachine,
          }),
        )
      }
      columns={
        responsiveMode === 'phone' || responsiveMode === 'tablet'
          ? columnsResponsive
          : columns
      }
      toolbar={
        responsiveMode === 'phone' ||
        responsiveMode === 'tablet' ? undefined : (
          <VehiclesEntryTableToolbar />
        )
      }
      loading={salesListLoading}
    />
  );
};
