/* eslint-disable pii/no-phone-number */
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import DirectionsIcon from '@mui/icons-material/Directions';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';
import {
  IconButton,
  Grid,
  Typography,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  Paper,
  Skeleton,
  Table,
  TableRow,
  Box,
} from '@mui/material';
import { find, findIndex, map, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  DropdownInput,
  Form,
  Input,
  PageWrapper,
  CoordsInput,
} from '../../../components';
import { ROUTE_CREATE, ROUTE_UPDATE } from '../../../routes';
import {
  fetchRouteCreate,
  fetchRouteUpdate,
  fetchRoutesFilters,
  useDispatch,
  routeSlice,
  useSelector,
  fetchRoutesMapInfo,
  routesMapSlice,
} from '../../../stores';
import { palette } from '../../../theme';
import { generateRandomId, handleErrors } from '../../../utils/Helpers';
import useResponsiveMode from '../../../utils/useResponsiveMode';
import { StyledFiltersBtnsGrid, StyledMapGrid } from '../styles';
import { Map } from './Map';
import { validationSchema } from './validations';

export const RouteUpsert: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { routesListLoading } = useSelector((state) => state.routesListReducer);
  const { filters, filtersLoading } = useSelector(
    (state) => state.routesFiltersReducer,
  );
  const { routeInfo, routeCreateLoading, routeUpdateLoading } = useSelector(
    (state) => state.routeReducer,
  );
  const { routesCoords, routesRecenterMap } = useSelector(
    (state) => state.routesMapReducer,
  );
  const responsiveMode = useResponsiveMode();
  const location = useLocation();
  const [formData, setFormData] = useState<{
    name: string;
    areaId: string;
    configurationId: string;
    coordinates: {
      id: number;
      address: string;
      latitude: number | undefined;
      longitude: number | undefined;
      errors: { [key: string]: string | undefined };
    }[];
    vacancies: {
      quantity: number;
      type: string;
      errors: { [key: string]: string | undefined };
    }[];
  }>({
    name: routeInfo && location.pathname === ROUTE_UPDATE ? routeInfo.name : '',
    areaId:
      routeInfo && location.pathname === ROUTE_UPDATE
        ? routeInfo.areaId.toString()
        : '',
    configurationId:
      routeInfo && location.pathname === ROUTE_UPDATE
        ? routeInfo.configurationId.toString()
        : '',
    coordinates:
      routeInfo && location.pathname === ROUTE_UPDATE
        ? map(routeInfo.coordinates, (item) => ({
            ...item,
            id: generateRandomId(),
            errors: {},
          }))
        : [],
    vacancies:
      routeInfo && location.pathname === ROUTE_UPDATE
        ? map(routeInfo.vacancies, (item) => ({ ...item, errors: {} }))
        : [],
  });
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {},
  );
  const [title, setTitle] = useState('Adicionar trecho');
  const [pageMayRender, setPageMayRender] = useState(false);

  useEffect(() => {
    if (location.pathname === ROUTE_UPDATE) {
      setTitle('Editar trecho');
    }
  }, []);

  useEffect(() => {
    if (!filtersLoading && filters) {
      dispatch(fetchRoutesFilters()).then(() => {
        dispatch(fetchRoutesMapInfo()).then(() => {
          setPageMayRender(true);
        });
      });
    }
  }, []);

  const addNewEmptyVacancyRow = () => {
    const newVacancies = formData.vacancies;
    newVacancies.push({
      quantity: 0,
      type: '0',
      errors: {},
    });
    setFormData({
      ...formData,
      vacancies: newVacancies,
    });
    setErrors({ ...errors, vacancies: '' });
  };

  const addNewEmptyCoordinatesRow = () => {
    const newCoordinates = formData.coordinates;
    newCoordinates.push({
      id: generateRandomId(),
      address: '',
      latitude: undefined,
      longitude: undefined,
      errors: {},
    });
    setFormData({
      ...formData,
      coordinates: newCoordinates,
    });
    setErrors({ ...errors, coordinates: '' });
  };

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        const handledCoordinates: {
          address: string;
          latitude: number;
          longitude: number;
        }[] = [];
        map(formData.coordinates, (item) => {
          if (item.latitude !== undefined && item.longitude !== undefined) {
            handledCoordinates.push({
              address: item.address,
              latitude: item.latitude,
              longitude: item.longitude,
            });
          }
        });

        const handledVacancies: {
          quantity: number;
          type: string;
        }[] = [];
        map(formData.vacancies, (item) => {
          const vacancy = find(filters?.vacancyTypes, ['id', item.type]);
          if (vacancy) {
            handledVacancies.push({
              quantity: item.quantity,
              type: vacancy.value,
            });
          }
        });

        if (location.pathname === ROUTE_UPDATE) {
          if (routeInfo) {
            dispatch(
              fetchRouteUpdate({
                name: formData.name,
                areaId: parseInt(formData.areaId),
                configurationId: parseInt(formData.configurationId),
                coordinates: handledCoordinates,
                vacancies: handledVacancies,
                routeId: routeInfo.id.toString(),
              }),
            ).then((res) => {
              res.meta.requestStatus === 'fulfilled' && navigate(-1);
            });
          }
        } else {
          dispatch(
            fetchRouteCreate({
              name: formData.name,
              areaId: parseInt(formData.areaId),
              configurationId: parseInt(formData.configurationId),
              coordinates: handledCoordinates,
              vacancies: handledVacancies,
            }),
          ).then((res) => {
            res.meta.requestStatus === 'fulfilled' && navigate(-1);
          });
        }
      })
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    dispatch(routeSlice.actions.resetRouteInfo());
    navigate(-1);
  };

  const updateMarkers = (params: {
    id: number;
    address: string;
    lat: number;
    lng: number;
  }) => {
    const idx = findIndex(formData.coordinates, function (o) {
      return o.id == params.id;
    });
    if (idx !== -1) {
      const handledCoordinates = formData.coordinates;
      handledCoordinates[idx].address =
        params.address !== ''
          ? params.address
          : handledCoordinates[idx].address;
      handledCoordinates[idx].latitude =
        params.lat !== 0 ? params.lat : handledCoordinates[idx].latitude;
      handledCoordinates[idx].longitude =
        params.lng !== 0 ? params.lng : handledCoordinates[idx].longitude;
      setFormData({ ...formData, coordinates: handledCoordinates });
    }
  };

  const getAreasOptions = () => {
    const handledAreas: {
      id: string;
      value: string;
    }[] = [];
    filters &&
      map(filters.areas, (item) => {
        if (item.id !== '0') {
          handledAreas[parseInt(item.id)] = {
            id: item.id.toString(),
            value: item.value,
          };
        } else {
          handledAreas[0] = {
            id: '0',
            value: '',
          };
        }
      });
    return handledAreas;
  };

  const getConfigurationsOptions = () => {
    const handledConfigurations: {
      id: string;
      value: string;
    }[] = [];
    filters &&
      map(filters.configurations, (item) => {
        if (item.id !== '0') {
          handledConfigurations[parseInt(item.id)] = {
            id: item.id.toString(),
            value: item.value,
          };
        } else {
          handledConfigurations[0] = {
            id: '0',
            value: '',
          };
        }
      });
    return handledConfigurations;
  };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <DirectionsIcon fontSize='large' sx={{ marginRight: 2 }} />
            {title}
          </Typography>
        </Grid>
      </Grid>
      {routesListLoading || filtersLoading ? (
        <Skeleton animation='wave' width='100%' height='300px' />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Grid container component={Paper}>
            <TableContainer>
              <Table
                // sx={{ minWidth: 500 }}
                size='small'
                aria-label='a dense table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box
                        sx={{
                          width: '100%',
                          paddingTop: 2,
                          paddingLeft: 2,
                          paddingRight: 2,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          paddingLeft={1}
                          paddingRight={1}
                        >
                          <Input
                            data-testid='name'
                            type='text'
                            margin='normal'
                            label='Nome'
                            value={formData.name}
                            onChange={(value) => {
                              setErrors({ ...errors, name: '' });
                              setFormData({ ...formData, name: value });
                            }}
                            error={
                              errors &&
                              errors.name !== undefined &&
                              errors.name !== ''
                            }
                            helperText={errors && errors.name}
                          />
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          width: '100%',
                          paddingBottom: 2,
                          paddingLeft: 2,
                          paddingRight: 2,
                          borderBottom: 0.1,
                          borderColor: palette.divider,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          paddingLeft={1}
                          paddingRight={1}
                        >
                          <DropdownInput
                            label='Área'
                            value={formData.areaId}
                            options={getAreasOptions()}
                            onChange={(value) => {
                              setErrors({ ...errors, areaId: '' });
                              setFormData({ ...formData, areaId: value });
                            }}
                            errorMessage={errors && errors.areaId}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          paddingLeft={1}
                          paddingRight={1}
                        >
                          <DropdownInput
                            label='Configuração'
                            value={formData.configurationId}
                            options={getConfigurationsOptions()}
                            onChange={(value) => {
                              setErrors({ ...errors, configurationId: '' });
                              setFormData({
                                ...formData,
                                configurationId: value,
                              });
                            }}
                            errorMessage={errors && errors.configurationId}
                          />
                        </Grid>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} sx={{ paddingTop: 5 }}>
                      <Button
                        variant='text'
                        startIcon={<AddCircleIcon />}
                        label='Novo tipo de vaga'
                        style={{ margin: 0 }}
                        // loading={areaCancelLoading}
                        onClick={addNewEmptyVacancyRow}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Tipos de vaga
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: '120px' }}>
                      <Typography
                        color='primary.main'
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        Quantidades
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.vacancies.length > 0 ? (
                    map(formData.vacancies, (item, index) => {
                      return (
                        <TableRow
                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: palette.action.hover,
                            },
                            '&:last-child td, &:last-child th': {
                              borderBottom: 0.1,
                              borderColor: palette.divider,
                            },
                            height: '55px',
                          }}
                        >
                          <TableCell component='th' scope='row'>
                            <DropdownInput
                              label='Tipo de vaga'
                              options={filters?.vacancyTypes}
                              value={formData.vacancies[index].type}
                              onChange={(value) => {
                                const handledVacancies = formData.vacancies;
                                handledVacancies[index].type = value;
                                handledVacancies[index].errors = {
                                  ...formData.vacancies[index].errors,
                                  type: '',
                                };
                                setFormData({
                                  ...formData,
                                  vacancies: handledVacancies,
                                });
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component='th'
                            scope='row'
                            sx={{ padding: 0 }}
                          >
                            <Input
                              data-testid='id'
                              type='text'
                              margin='normal'
                              label='Quantidade'
                              value={formData.vacancies[
                                index
                              ].quantity.toString()}
                              onChange={(value) => {
                                const handledVacancies = formData.vacancies;
                                handledVacancies[index].quantity = !value
                                  ? 0
                                  : parseInt(value);
                                handledVacancies[index].errors = {
                                  ...formData.vacancies[index].errors,
                                  quantity: '',
                                };
                                setFormData({
                                  ...formData,
                                  vacancies: handledVacancies,
                                });
                              }}
                              error={
                                item.errors &&
                                item.errors.quantity !== undefined &&
                                item.errors.quantity !== ''
                              }
                            />
                          </TableCell>
                          <TableCell
                            component='th'
                            scope='row'
                            align='right'
                            sx={{ width: '35px', padding: 0 }}
                          >
                            <IconButton
                              color='secondary'
                              aria-label='Remover linha'
                              onClick={() => {
                                const handledVacancies = formData.vacancies;
                                handledVacancies.splice(index, 1);
                                setFormData({
                                  ...formData,
                                  vacancies: handledVacancies,
                                });
                              }}
                            >
                              <CancelIcon fontSize='large' />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      sx={{
                        '&:nth-of-type(odd)': {
                          backgroundColor: palette.action.hover,
                        },
                        '&:last-child td, &:last-child th': {
                          borderBottom:
                            errors &&
                            errors.vacancies !== undefined &&
                            errors.vacancies !== ''
                              ? 2
                              : 1,
                          borderColor:
                            errors &&
                            errors.vacancies !== undefined &&
                            errors.vacancies !== ''
                              ? 'red'
                              : palette.divider,
                        },
                      }}
                    >
                      <TableCell
                        align='center'
                        colSpan={4}
                        sx={{ height: '55px' }}
                      >
                        <Typography
                          sx={{
                            color:
                              errors &&
                              errors.vacancies !== undefined &&
                              errors.vacancies !== ''
                                ? 'red'
                                : 'grey',
                          }}
                        >
                          Nenhum tipo de vaga inserido
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              sx={{
                width: '100%',
                paddingTop: 5,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <div style={{ flex: 1 }}>
                <TableContainer>
                  <Table
                    // sx={{ minWidth: 500 }}
                    size='small'
                    aria-label='a dense table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Button
                            variant='text'
                            startIcon={<AddCircleIcon />}
                            label='Novo endereço'
                            style={{ margin: 0 }}
                            // loading={areaCancelLoading}
                            onClick={addNewEmptyCoordinatesRow}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            color='primary.main'
                            sx={{ fontSize: '14px', fontWeight: 'bold' }}
                          >
                            Endereços
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formData.coordinates.length > 0 ? (
                        map(formData.coordinates, (item, index) => {
                          return (
                            <TableRow
                              sx={{
                                '&:nth-of-type(odd)': {
                                  backgroundColor: palette.action.hover,
                                },
                                '&:last-child td, &:last-child th': {
                                  borderBottom: 0.1,
                                  borderColor: palette.divider,
                                },
                                height: '55px',
                              }}
                            >
                              <TableCell
                                component='th'
                                scope='row'
                                sx={{
                                  paddingTop: 0,
                                  paddingRight: 0,
                                }}
                              >
                                <CoordsInput
                                  data-testid='id'
                                  type='text'
                                  margin='normal'
                                  label='Endereço'
                                  value={formData.coordinates[index].address}
                                  onChange={(value) => {
                                    const handledCoordinates =
                                      formData.coordinates;
                                    handledCoordinates[index].address = value;
                                    handledCoordinates[index].errors = {
                                      ...formData.coordinates[index].errors,
                                      address: '',
                                    };
                                    setFormData({
                                      ...formData,
                                      coordinates: handledCoordinates,
                                    });
                                  }}
                                  onChangeCoords={(value) => {
                                    const handledCoordinates =
                                      formData.coordinates;
                                    handledCoordinates[index].address =
                                      value.address;
                                    handledCoordinates[index].latitude =
                                      value.lat;
                                    handledCoordinates[index].longitude =
                                      value.lng;
                                    handledCoordinates[index].errors = {
                                      ...formData.coordinates[index].errors,
                                      latitude: '',
                                      longitude: '',
                                    };
                                    setFormData({
                                      ...formData,
                                      coordinates: handledCoordinates,
                                    });
                                  }}
                                  error={
                                    item.errors &&
                                    item.errors.address !== undefined &&
                                    item.errors.address !== '' &&
                                    item.errors.latitude !== undefined &&
                                    item.errors.latitude !== '' &&
                                    item.errors.longitude !== undefined &&
                                    item.errors.longitude !== ''
                                  }
                                />
                              </TableCell>
                              <TableCell
                                component='th'
                                scope='row'
                                align='right'
                                sx={{
                                  width: '20px',
                                  padding: 0,
                                  paddingLeft: '5px',
                                }}
                              >
                                <IconButton
                                  color='primary'
                                  aria-label='Copiar linha'
                                  sx={{ padding: 0 }}
                                  onClick={() => {
                                    const handledCoordinates =
                                      formData.coordinates;
                                    handledCoordinates.push({
                                      ...item,
                                      id: generateRandomId(),
                                    });
                                    setFormData({
                                      ...formData,
                                      coordinates: handledCoordinates,
                                    });
                                  }}
                                >
                                  <FileCopyIcon fontSize='medium' />
                                </IconButton>
                              </TableCell>
                              <TableCell
                                component='th'
                                scope='row'
                                align='right'
                                sx={{
                                  width: '30px',
                                  padding: 0,
                                  paddingRight: '5px',
                                }}
                              >
                                <IconButton
                                  color='secondary'
                                  aria-label='Remover linha'
                                  sx={{ padding: 0 }}
                                  onClick={() => {
                                    const handledCoordinates =
                                      formData.coordinates;
                                    handledCoordinates.splice(index, 1);
                                    setFormData({
                                      ...formData,
                                      coordinates: handledCoordinates,
                                    });
                                  }}
                                >
                                  <CancelIcon fontSize='medium' />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow
                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: palette.action.hover,
                            },
                            '&:last-child td, &:last-child th': {
                              borderBottom:
                                errors &&
                                errors.coordinates !== undefined &&
                                errors.coordinates !== ''
                                  ? 2
                                  : 1,
                              borderColor:
                                errors &&
                                errors.coordinates !== undefined &&
                                errors.coordinates !== ''
                                  ? 'red'
                                  : palette.divider,
                            },
                          }}
                        >
                          <TableCell
                            align='center'
                            colSpan={4}
                            sx={{ height: '55px' }}
                          >
                            <Typography
                              sx={{
                                color:
                                  errors &&
                                  errors.coordinates !== undefined &&
                                  errors.coordinates !== ''
                                    ? 'red'
                                    : 'grey',
                              }}
                            >
                              Nenhum endereço inserido
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div style={{ flex: 2, paddingTop: '85px' }}>
                {!pageMayRender ? (
                  <Skeleton
                    animation='wave'
                    width='100%'
                    sx={{ padding: 0, margin: 0 }}
                    variant='rectangular'
                  >
                    <Map
                      routes={routesCoords}
                      data={formData}
                      recenterMap={routesRecenterMap}
                      setRecenterMapAsFalse={() =>
                        dispatch(
                          routesMapSlice.actions.setRecenterRoutesMap(false),
                        )
                      }
                      updateMarkers={updateMarkers}
                    />
                  </Skeleton>
                ) : (
                  <StyledMapGrid item lg={12} md={12} sm={12} xs={12}>
                    <Map
                      routes={routesCoords}
                      data={formData}
                      recenterMap={routesRecenterMap}
                      setRecenterMapAsFalse={() =>
                        dispatch(
                          routesMapSlice.actions.setRecenterRoutesMap(false),
                        )
                      }
                      updateMarkers={updateMarkers}
                    />
                  </StyledMapGrid>
                )}
              </div>
            </Grid>
          </Grid>
          <StyledFiltersBtnsGrid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            margin={1}
          >
            <Button
              variant='contained'
              label='Salvar'
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
              sx={{ width: '100%' }}
              disabled={routeCreateLoading || routeUpdateLoading}
              loading={routeCreateLoading || routeUpdateLoading}
            />
            <Button
              color='secondary'
              variant='contained'
              label='Voltar'
              startIcon={<ArrowBackIcon />}
              onClick={handleReturn}
              sx={{ width: '100%' }}
              disabled={routeCreateLoading || routeUpdateLoading}
            />
          </StyledFiltersBtnsGrid>
        </Form>
      )}
    </PageWrapper>
  );
};
