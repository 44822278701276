import GoogleMapReact from 'google-map-react';
import { findIndex, forEach, map as lodashMap } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ROUTE_UPDATE } from '../../../../routes';
import { useSelector } from '../../../../stores';
import { palette } from '../../../../theme';
import { generateRandomId } from '../../../../utils/Helpers';
import { Marker } from './Marker';
import { StyledGoogleMapDiv } from './styles';

export const Map = (props: {
  routes: {
    id: number;
    name: string;
    areaName: string;
    color: string;
    coordinates: {
      latitude: number;
      longitude: number;
    }[];
  }[];
  data: {
    name: string;
    areaId: string;
    configurationId: string;
    coordinates: {
      id: number;
      address: string;
      latitude: number | undefined;
      longitude: number | undefined;
      errors: { [key: string]: string | undefined };
    }[];
    vacancies: {
      quantity: number;
      type: string;
      errors: { [key: string]: string | undefined };
    }[];
  };
  recenterMap: boolean;
  setRecenterMapAsFalse: () => { payload: boolean; type: string };
  updateMarkers: (params: {
    id: number;
    address: string;
    lat: number;
    lng: number;
  }) => void;
}) => {
  const { routeInfo } = useSelector((state) => state.routeReducer);
  const [gMaps, setGMaps] = useState();
  const [gMap, setGMap] = useState<google.maps.Map>();
  const [markers, setMarkers] = useState<JSX.Element[]>([]);
  const [lines, setLines] = useState<
    { id: number; line: google.maps.Polyline }[]
  >([]);
  const [newRouteLines, setNewRouteLines] =
    useState<google.maps.Polyline | null>(null);
  const [isMapStarting, setIsMapStarting] = useState<boolean>(true);
  const [draggable, setDraggable] = useState(true);

  useEffect(() => {
    const newMarkers: JSX.Element[] = [];
    lodashMap(props.data.coordinates, (item) => {
      if (item.latitude !== undefined && item.longitude !== undefined) {
        newMarkers.push(
          <Marker key={item.id} lat={item.latitude} lng={item.longitude} />,
        );
      }
    });
    setMarkers(newMarkers);
    handleMapBounds();
  }, [props.data]);

  useEffect(() => {
    handleNewRoute();
  }, [markers]);

  useEffect(() => {
    if (
      gMaps &&
      gMap &&
      isMapStarting &&
      lines.length === 0 &&
      props.routes.length !== 0
    ) {
      handleStartRoutes();
      handleDrawRoutes();
      handleMapBounds();
      handleNewRoute();
      setIsMapStarting(false);
    }
  }, [gMaps, props.routes]);

  useEffect(() => {
    if (gMaps && gMap) {
      handleDrawRoutes();
      if (props.recenterMap) {
        handleMapBounds();
        props.setRecenterMapAsFalse();
      }
    }
  }, [props.routes]);

  const createMapOptions = () => {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatr ixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      zoomControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
  };

  const handleStartRoutes = () => {
    if (gMap) {
      const newLines = lines;
      // Starting up lines array if first time
      forEach(props.routes, function (item) {
        const coordinates: { lat: number; lng: number }[] = [];

        if (location.pathname !== ROUTE_UPDATE || item.id !== routeInfo?.id) {
          // eslint-disable-next-line lodash/prefer-map
          forEach(item.coordinates, (coords) => {
            coordinates.push({
              lat: coords.latitude,
              lng: coords.longitude,
            });
          });

          // Line
          const polyline = new google.maps.Polyline({
            path: coordinates,
            geodesic: true,
            strokeColor: item.color,
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });

          newLines.push({ id: item.id, line: polyline });
        }
      });

      setLines(newLines);
    }
  };

  const handleNewRoute = () => {
    if (gMap) {
      newRouteLines?.setMap(null);
      setNewRouteLines(null);

      const coordinates: { lat: number; lng: number }[] = [];
      lodashMap(markers, function (item) {
        if (item.props.lat !== undefined && item.props.lng !== undefined) {
          coordinates.push({
            lat: item.props.lat,
            lng: item.props.lng,
          });
        }
      });

      if (coordinates.length > 0) {
        const polyline = new google.maps.Polyline({
          path: coordinates,
          geodesic: true,
          strokeColor: palette.secondary.main,
          strokeOpacity: 1.0,
          strokeWeight: 2,
        });
        polyline.setMap(gMap);
        setNewRouteLines(polyline);
      }
    }
  };

  const handleDrawRoutes = () => {
    if (gMap) {
      // Drawing lines
      lodashMap(lines, (item, index) => {
        const lineIdx = findIndex(props.routes, ['id', item.id]);
        if (lineIdx === -1) {
          lines[index].line.setMap(null);
        } else {
          lines[index].line.setMap(gMap);
        }
      });
    }
  };

  const handleMapBounds = () => {
    if (gMap) {
      // Setting map bounds
      const bounds = new google.maps.LatLngBounds();
      let isDataCoordinatesFilled = false;

      if (props.data.coordinates.length !== 0) {
        forEach(props.data.coordinates, function (item) {
          const coordinates: { lat: number; lng: number }[] = [];
          if (item.latitude !== undefined && item.longitude !== undefined) {
            isDataCoordinatesFilled = true;
            coordinates.push({
              lat: item.latitude,
              lng: item.longitude,
            });

            const location = new google.maps.LatLng(
              (coordinates[0].lat + coordinates[coordinates.length - 1].lat) /
                2,
              (coordinates[0].lng + coordinates[coordinates.length - 1].lng) /
                2,
            );

            bounds.extend(location);
          }
        });
      }
      if (!isDataCoordinatesFilled) {
        forEach(props.routes, function (item) {
          const coordinates: { lat: number; lng: number }[] = [];

          // eslint-disable-next-line lodash/prefer-map
          forEach(item.coordinates, (coords) => {
            coordinates.push({
              lat: coords.latitude,
              lng: coords.longitude,
            });
          });

          const location = new google.maps.LatLng(
            (coordinates[0].lat + coordinates[coordinates.length - 1].lat) / 2,
            (coordinates[0].lng + coordinates[coordinates.length - 1].lng) / 2,
          );

          bounds.extend(location);
        });
      }
      gMap.fitBounds(bounds);
    }
  };

  const onChildMouseDown = () => {
    // set map no draggable
    setDraggable(false);
  };

  const onChildMouseUp = async (
    hoverKey: number,
    childProps: any,
    mouse: { lat: number; lng: number },
  ) => {
    // set map draggable again
    setDraggable(true);
    const geocode = new google.maps.Geocoder();
    const location = await geocode.geocode({
      location: new google.maps.LatLng(mouse.lat, mouse.lng),
    });
    props.updateMarkers({
      id: hoverKey,
      address: location.results[0].formatted_address,
      lat: 0,
      lng: 0,
    });
  };

  const onChildMouseMove = (
    hoverKey: number,
    childProps: any,
    mouse: { lat: number; lng: number },
  ) => {
    const idx = findIndex(markers, ['key', hoverKey]);
    if (idx !== -1) {
      const newMarkers = [...markers];
      newMarkers[idx] = (
        <Marker key={hoverKey} lat={mouse.lat} lng={mouse.lng} />
      );
      setMarkers(newMarkers);
    }
    props.updateMarkers({
      id: hoverKey,
      address: '',
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };

  return (
    <StyledGoogleMapDiv>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: `${process.env.REACT_APP_GOOGLE_KEY}`,
        }}
        defaultCenter={{ lat: 0.0, lng: 0.0 }}
        zoom={10}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setIsMapStarting(true);
          setGMap(map);
          setGMaps(maps);
        }}
        draggable={draggable}
        onChildMouseDown={onChildMouseDown}
        onChildMouseUp={onChildMouseUp}
        onChildMouseMove={(key, childProps, mouse) =>
          onChildMouseMove(key, childProps, mouse)
        }
      >
        {markers}
      </GoogleMapReact>
    </StyledGoogleMapDiv>
  );
};
