/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { NotificationsReceiptExportResponseType } from '../../../../services/Notifications/types';
import { notificationsListSlice } from '../../../../stores';
import { logoUrl } from '../../../../theme';

export const exportNotificationsReceiptToHTML = async (
  apiData: NotificationsReceiptExportResponseType,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  worker: (apiData: NotificationsReceiptExportResponseType) => Promise<string>,
): Promise<void> => {
  dispatch(notificationsListSlice.actions.setNotificationsExportPercentage(20));

  const handledApiData = await worker(apiData);

  dispatch(notificationsListSlice.actions.setNotificationsExportPercentage(70));

  const content = `
  <!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>Detalhar Notificação</title>
      <style>


          /* -------------------------------------
            GLOBAL RESETS
          ------------------------------------- */
          img {
            border: none;
            -ms-interpolation-mode: bicubic;
            max-width: 100%;
          }

          body {
            background-color: white;
            font-family: sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 10px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }

          table {
            border-collapse: separate;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            width: 100%;
          }

            table td {
              font-family: sans-serif;
              font-size: 10px;
              vertical-align: top;
            }

          /* -------------------------------------
            BODY & CONTAINER
          ------------------------------------- */

          .body {
            background-color: white;
            width: 100%;
          }

          /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
          .container {
            display: block;
            Margin: 0 auto !important;
            /* makes it centered */
            max-width: 100%;
            padding: 10px;
            /*width: 100%;*/
          }

          /* This should also be a block element, so that it will fill 100% of the .container */
          .content {
            box-sizing: border-box;
            display: block;
            Margin: 0 auto;
            max-width: 100%;
            padding: 10px;
          }

          /* -------------------------------------
            HEADER, FOOTER, MAIN
          ------------------------------------- */
          .main {
            background: #fff;
            border-radius: 3px;
            width: 100%;
          }

          .wrapper {
            box-sizing: border-box;
            padding: 20px;
          }

          .footer {
            clear: both;
            padding-top: 10px;
            text-align: center;
            width: 100%;
          }

            .footer td,
            .footer p,
            .footer span,
            .footer a {
              color: #999999;
              font-size: 8px;
              text-align: center;
            }

          /* -------------------------------------
            TYPOGRAPHY
          ------------------------------------- */
          h2,
          h3,
          h4 {
            color: #000000;
            font-family: sans-serif;
            font-weight: 400;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 15px;
          }

          h1 {
            font-size: 31px;
            font-weight: 600;
            text-align: center;
            line-height: 1.4;
            margin: 0;
            Margin-bottom: 8px;
          }

          h3 {
            font-size: 13px;
            font-weight: 600;
          }

          p,
          ul,
          ol {
            font-family: sans-serif;
            font-size: 11px;
            font-weight: normal;
            margin: 0;
            Margin-bottom: 15px;
          }

            p li,
            ul li,
            ol li {
              list-style-position: inside;
              margin-left: 5px;
            }

          a {
            color: #3498db;
            text-decoration: underline;
          }

          /* -------------------------------------
            BUTTONS
          ------------------------------------- */
          .btn {
            box-sizing: border-box;
            width: 100%;
          }

            .btn > tbody > tr > td {
              padding-bottom: 15px;
            }

            .btn table {
              width: auto;
            }

              .btn table td {
                background-color: #ffffff;
                border-radius: 5px;
                text-align: center;
              }

            .btn a {
              background-color: #ffffff;
              border: solid 1px #3498db;
              border-radius: 5px;
              box-sizing: border-box;
              color: #3498db;
              cursor: pointer;
              display: inline-block;
              font-size: 10px;
              font-weight: bold;
              margin: 0;
              padding: 12px 25px;
              text-decoration: none;
              text-transform: capitalize;
            }

          .btn-primary table td {
            background-color: #3498db;
          }

          .btn-primary a {
            background-color: #3498db;
            border-color: #3498db;
            color: #ffffff;
          }

          /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
          ------------------------------------- */
          .last {
            margin-bottom: 0;
          }

          .first {
            margin-top: 0;
          }

          .align-center {
            text-align: center;
          }

          .align-right {
            text-align: right;
          }

          .align-left {
            text-align: left;
          }

          .clear {
            clear: both;
          }

          .mt0 {
            margin-top: 0;
          }

          .mb0 {
            margin-bottom: 0;
          }

          .preheader {
            color: transparent;
            display: none;
            height: 0;
            max-height: 0;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            mso-hide: all;
            visibility: hidden;
            width: 0;
          }

          .powered-by a {
            text-decoration: none;
          }

          hr {
            border: 0;
            border-bottom: 1px solid black;
            Margin: 30px 0 10px;
          }

          /* -------------------------------------
              CUSTOM CSS BY ME
            ------------------------------------- */

          .table-adjustment tbody td, .table-adjustment thead th {
            padding: 5px;
          }

          /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
          ------------------------------------- */
          @media only screen and (max-width: 620px) {
            table[class=body] h1 {
              font-size: 26px !important;
              margin-bottom: 10px !important;
            }

            table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
              font-size: 14px !important;
            }

            table[class=body] .wrapper,
            table[class=body] .article {
              padding: 10px !important;
            }

            table[class=body] .content {
              padding: 0 !important;
            }

            table[class=body] .container {
              padding: 0 !important;
              width: 100% !important;
            }

            table[class=body] .main {
              border-left-width: 0 !important;
              border-radius: 0 !important;
              border-right-width: 0 !important;
            }

            table[class=body] .btn table {
              width: 100% !important;
            }

            table[class=body] .btn a {
              width: 100% !important;
            }

            table[class=body] .img-responsive {
              height: auto !important;
              max-width: 100% !important;
              width: auto !important;
            }
          }

          /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
          ------------------------------------- */
          @media all {
            .ExternalClass {
              width: 100%;
            }

              .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                line-height: 100%;
              }

            .apple-link a {
              color: inherit !important;
              font-family: inherit !important;
              font-size: inherit !important;
              font-weight: inherit !important;
              line-height: inherit !important;
              text-decoration: none !important;
            }

            .btn-primary table td:hover {
              background-color: #34495e !important;
            }

            .btn-primary a:hover {
              background-color: #34495e !important;
              border-color: #34495e !important;
            }
          }
        </style>
    </head>
    <body class="">
      <table border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <div class="content">
              <div class="img-container" style="text-align:center">
                <img src=${logoUrl} alt="Logo" height="66" width="100" />
              </div>
              <!-- START CENTERED WHITE CONTAINER -->
              <table class="main">
                <!-- START MAIN CONTENT AREA -->
                ${handledApiData}
                <!-- END MAIN CONTENT AREA -->
              </table>
              <!-- END CENTERED WHITE CONTAINER -->
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </body>
    </html>
  `;
  dispatch(
    notificationsListSlice.actions.setNotificationsExportPercentage(100),
  );

  const fileType = 'text/plain;charset=utf-8';
  const fileExtension = '.html';

  const blob = new Blob([content], {
    type: fileType,
  });

  FileSaver.saveAs(
    blob,
    `comprovante-irregularidades-${moment().format('yyyy-MM-DD_hh-mm')}` +
      fileExtension,
  );
};

export const handleApiDataNotificationsReceiptToHTML = (
  apiData: NotificationsReceiptExportResponseType,
) => {
  let handledApiData = '';

  const formatDateTime = (dateTime: string) => {
    return (
      dateTime[8] +
      dateTime[9] +
      '/' +
      dateTime[5] +
      dateTime[6] +
      '/' +
      dateTime[0] +
      dateTime[1] +
      dateTime[2] +
      dateTime[3] +
      ' ' +
      dateTime.slice(11)
    );
  };

  const title = `Veículo: ${apiData.plate || 'Sem placa'}`;
  const subtitle = `Período apurado: ${formatDateTime(
    apiData.startDateTime,
  )} - ${formatDateTime(apiData.finalDateTime)}`;
  const secondSubtitle = `Data/horário da geração do documento:
  ${formatDateTime(apiData.registerDateTime)}
  `;
  let body = '';

  if (apiData.irregularities.length === 0) {
    handledApiData = `
    <tr>
      <td class="wrapper">
        <table border="0" cellpadding="0" cellspacing="0">
          <tr>
            <td>
              <h1>${title}</h1>
              <h3>${subtitle}</h3>
              <h3>${secondSubtitle}</h3>
              <h3>Não há irregularidades nesse período</h3>
            </td>
          </tr>
        </table>
      </td>
    </tr>`;
  } else {
    apiData.irregularities.map((item) => {
      // eslint-disable-next-line lodash/prefer-lodash-method
      body += `
      <tr>
        <td>${item.id}</td>
        <td>${item.plate}</td>
        <td>${formatDateTime(item.registerDateTime)}</td>
      </tr>
      `;
    });

    handledApiData = `
    <tr>
      <td class="wrapper">
        <table border="0" cellpadding="0" cellspacing="0">
          <tr>
            <td>
              <h1>${title}</h1>
              <h3>${subtitle}</h3>
              <h3>${secondSubtitle}</h3>
              <table border="0" cellpadding="0" cellspacing="0">
                <tbody>
                  <tr>
                    <td align="left">
                      <table border="1px" cellpadding="0" cellspacing="0" class="table-adjustment">
                        <thead>
                          <tr>
                            <th align="left">Identificador</th>
                            <th align="left">Veículo</th>
                            <th align="left">Data de criação</th>
                          </tr>
                        </thead>
                        <tbody>
                          ${body}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>`;
  }

  return handledApiData;
};
