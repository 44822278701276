import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, ColorPicker, Input, Modal } from '../../../components';
import {
  useDispatch,
  useSelector,
  areasSlice,
  fetchAreasCreate,
  fetchAreasList,
} from '../../../stores';
import { handleErrors } from '../../../utils/Helpers';

export const CreateAreaModal: React.FC = () => {
  const dispatch = useDispatch();
  const { areasCreateIsOpen, areasCreateLoading } = useSelector(
    (state) => state.areasReducer,
  );
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    color: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Insira o nome da área')
      .required('O nome é obrigatório'),
    color: Yup.string()
      .required('Selecione uma cor')
      .required('A cor é obrigatória'),
  });

  const handleClose = () => {
    setErrors({});
    setFormData({
      name: '',
      description: '',
      color: '',
    });
    dispatch(areasSlice.actions.toggleAreasCreateIsOpen());
  };

  const handleSubmit = () => {
    setErrors({});
    validationSchema
      .validate(formData, { abortEarly: false })
      .then(() =>
        dispatch(
          fetchAreasCreate({
            ...formData,
            description:
              formData.description === '' ? null : formData.description,
          }),
        ).then(() => {
          handleClose();
          dispatch(areasSlice.actions.toggleAreasCreateIsOpen());
          dispatch(fetchAreasList());
        }),
      )
      .catch((err) => {
        setErrors(handleErrors(err));
      });
  };

  const handleReturn = () => {
    setErrors({});
    setFormData({
      name: '',
      description: '',
      color: '',
    });
    dispatch(areasSlice.actions.toggleAreasCreateIsOpen());
  };

  return (
    <Modal
      open={areasCreateIsOpen}
      onClose={handleClose}
      title='Criar nova área'
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: '10px',
        }}
      >
        <Input
          data-testid='areaName'
          type='text'
          margin='normal'
          label='Nome'
          value={formData.name}
          onChange={(value) => setFormData({ ...formData, name: value })}
          errorMessage={errors && errors.name}
        />
        <Input
          data-testid='areaDescription'
          type='text'
          margin='normal'
          label='Descrição (opcional)'
          value={formData.description}
          onChange={(value) => setFormData({ ...formData, description: value })}
        />
        <ColorPicker
          data-testid='areaColor'
          type='text'
          margin='normal'
          label='Cor'
          value={formData.color}
          onChange={(value) => setFormData({ ...formData, color: value })}
          errorMessage={errors && errors.color}
        />
        <Button
          variant='contained'
          startIcon={<SaveIcon />}
          label='Salvar'
          type='submit'
          style={{ width: '100%', margin: 0, marginTop: 30 }}
          loading={areasCreateLoading}
          onClick={handleSubmit}
        />
        <Button
          color='secondary'
          variant='contained'
          startIcon={<ArrowBackIcon />}
          label='Voltar'
          type='reset'
          style={{ width: '100%', margin: 0, marginTop: 10 }}
          onClick={handleReturn}
        />
      </Box>
    </Modal>
  );
};
