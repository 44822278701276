import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchActionsHistoryFiltersService } from '../../services/Actions';

type FilterType = {
  id: number
  value: string
}

export type ActionsFiltersStateType = {
  filters: {
    profiles: FilterType[]
    permissions: FilterType[]
  },
  isLoading: boolean
}

const initialState: ActionsFiltersStateType = {
  filters: {
    profiles: [],
    permissions: []
  },
  isLoading: false
}

export const fetchActionsHistoryFilters = createAsyncThunk(
  'actionsHistoryFilters',
  async () => {
    return await fetchActionsHistoryFiltersService();
  }
);

export const actionsFiltersSlice = createSlice({
  name: 'actionsFilters',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchActionsHistoryFilters.pending, state => {
      return {
        ...state,
        isLoading: true
      }
    });
    builder.addCase(fetchActionsHistoryFilters.rejected, state => {
      return {
        ...state,
        isLoading: false
      }
    });
    builder.addCase(fetchActionsHistoryFilters.fulfilled, (state, res) => {
      const { profiles, permissions } = res.payload;

      return {
        ...state,
        filters: {
          profiles,
          permissions
        },
        isLoading: false
      }
    });
  }
});

export const { } = actionsFiltersSlice.actions;