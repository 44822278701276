import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
  fetchCredentialsCreateService,
  fetchCredentialsUpdateService,
  fetchCredentialsUploadDocumentService,
  fetchCredentialsDownloadDocumentService,
  fetchCredentialsViewService,
  fetchCredentialsRenewService,
  fetchCredentialsExportService,
  fetchCredentialsDeleteService,
  fetchCredentialsCPFService,
} from '../../services/Credentials';
import { CredentialsCPFResponseType } from '../../services/Credentials/types';
import { CredentialFiltersValueStateType } from './CredentialsFiltersValues';

export type CredentialsStateType = {
  isCredentialsLoading?: boolean;
  userName: string;
  userDocument: string;
  userPhone: string;
  addressCode: string;
  address: string;
  addressNumber: string;
  addressComplement: string;
  district: string;
  city: string;
  state: string;
  number: number;
  validity: string;
  documents: string;
  vehiclePlate: string;
  vehicleBrand: string;
  vehicleColor: string;
  delimited: number;
  observation: string;
  routes: number[];
  id: number;
  isCredentialsCPFLoading: boolean;
  credentialDeactivateIsOpen: boolean;
  credentialDeactivateIsLoading: boolean;
};

const initialState: CredentialsStateType = {
  isCredentialsLoading: false,
  userName: '',
  userDocument: '',
  userPhone: '',
  addressCode: '',
  address: '',
  addressNumber: '',
  addressComplement: '',
  district: '',
  city: '',
  state: '',
  number: 0,
  validity: '',
  documents: '',
  vehiclePlate: '',
  vehicleBrand: '',
  vehicleColor: '',
  delimited: 0,
  observation: '',
  routes: [],
  id: 0,
  isCredentialsCPFLoading: false,
  credentialDeactivateIsOpen: false,
  credentialDeactivateIsLoading: false,
};

export const fetchCredentialsCreate = createAsyncThunk(
  'credentialsCreate',
  async (params: {
    validity: string;
    delimited: number;
    vehiclePlate: string;
    vehicleBrand: string;
    vehicleColor: string;
    userName: string;
    userDocument: string;
    userPhone: string;
    documents: string;
    addressCode: string;
    address: string;
    addressNumber: string;
    addressComplement: string;
    district: string;
    city: string;
    state: string;
    observation: string;
    routes: number[];
  }) => {
    return await fetchCredentialsCreateService(params);
  },
);

export const fetchCredentialsUpdate = createAsyncThunk(
  'credentialsUpdate',
  async (params: {
    id: number;
    validity: string;
    delimited: number;
    vehiclePlate: string;
    vehicleBrand: string;
    vehicleColor: string;
    userName: string;
    userDocument: string;
    userPhone: string;
    documents: string;
    addressCode: string;
    address: string;
    addressNumber: string;
    addressComplement: string;
    district: string;
    city: string;
    state: string;
    observation: string;
    routes: number[];
  }) => {
    return await fetchCredentialsUpdateService(params);
  },
);

export const fetchCredentialsUploadDocument = createAsyncThunk(
  'credentialsUploadDocument',
  async (params: FormData | undefined) => {
    return await fetchCredentialsUploadDocumentService(params);
  },
);

export const fetchCredentialsDownloadDocument = createAsyncThunk(
  'credentialsDownloadDocument',
  async (params: { fileName: string }) => {
    return await fetchCredentialsDownloadDocumentService(params);
  },
);

export const fetchCredentialsView = createAsyncThunk(
  'credentialsView',
  async (id: number) => {
    return await fetchCredentialsViewService(id);
  },
);

export const fetchCredentialsRenew = createAsyncThunk(
  'credentialsRenew',
  async (params: { id: number; validity: string }) => {
    return await fetchCredentialsRenewService(params);
  },
);

export const fetchCredentialsExport = createAsyncThunk(
  'credentialsExport',
  async (params: {
    number: number;
    plate: string;
    userName: string;
    userDocument: string;
    address: string;
    routeId: string;
    validity: string;
  }) => {
    return await fetchCredentialsExportService(params);
  },
);

export const fetchCredentialsDelete = createAsyncThunk(
  'credentialsDelete',
  async (params: { id: number }) => {
    return await fetchCredentialsDeleteService(params);
  },
);

export const fetchCredentialsCPF = createAsyncThunk(
  'credentialsCPF',
  async (params: { cpf: string }): Promise<CredentialsCPFResponseType> => {
    return await fetchCredentialsCPFService(params);
  },
);

export const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    clearCredentials: () => {
      return initialState;
    },
    setCredentialId: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        id: action.payload,
      };
    },
    setCredentials: (state, action: PayloadAction<CredentialsStateType>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    toggleCredentialDeactivateIsOpen(state) {
      state.credentialDeactivateIsOpen
        ? (state.credentialDeactivateIsOpen = false)
        : (state.credentialDeactivateIsOpen = true);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCredentialsCreate.fulfilled, () => {
      toast.success('Credencial criada com sucesso!');
    });
    builder.addCase(fetchCredentialsUpdate.fulfilled, (state, res) => {
      toast.success('Credencial atualizada com sucesso!');

      return {
        ...state,
        ...res.payload,
      };
    });
    builder.addCase(fetchCredentialsUploadDocument.fulfilled, (state, res) => {
      const { fileName } = res.payload;

      toast.success('Arquivo enviado com sucesso!');

      return {
        ...state,
        documents: fileName,
      };
    });
    builder.addCase(fetchCredentialsView.pending, (state) => {
      return {
        ...state,
        isCredentialsLoading: true,
      };
    });
    builder.addCase(fetchCredentialsView.rejected, (state) => {
      return {
        ...state,
        isCredentialsLoading: false,
      };
    });
    builder.addCase(fetchCredentialsView.fulfilled, (state, res) => {
      return {
        ...state,
        ...res.payload,
        isCredentialsLoading: false,
      };
    });
    builder.addCase(fetchCredentialsRenew.fulfilled, (state, res) => {
      toast.success('Validade da credencial atualizada com sucesso!');

      const { validity } = res.payload;

      return {
        ...state,
        validity,
      };
    });
    builder.addCase(fetchCredentialsDelete.fulfilled, () => {
      toast.success('Credencial desativada com sucesso!');
    });
    builder.addCase(fetchCredentialsCPF.fulfilled, (state, res) => {
      toast.warning(
        `CPF está vinculado a credencial ${
          res.payload.status === 0 ? 'desativada' : 'ativa'
        } ${res.payload.number} `,
      );
      return {
        ...state,
        userName: res.payload.userName,
        userPhone: res.payload.userPhone,
        isCredentialsCPFLoading: false,
      };
    });
    builder.addCase(fetchCredentialsCPF.pending, (state) => {
      return {
        ...state,
        isCredentialsCPFLoading: true,
      };
    });
    builder.addCase(fetchCredentialsCPF.rejected, (state) => {
      return {
        ...state,
        isCredentialsCPFLoading: false,
      };
    });
  },
});

export const { clearCredentials, setCredentialId, setCredentials } =
  credentialsSlice.actions;
