/* eslint-disable pii/no-phone-number */
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, Typography } from '@mui/material';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  PageWrapper,
  FiltersGrid,
  DropdownInput,
  Button,
} from '../../components';
import {
  useDispatch,
  useSelector,
  fetchPermissionsFilters,
  fetchPermissionsList,
  permissionsSlice,
  fetchPermissionsUpdate,
} from '../../stores';
import { palette } from '../../theme';
import useResponsiveMode from '../../utils/useResponsiveMode';
import { PermissionsTable } from './PermissionsTable';

export const PermissionsManagement: React.FC = () => {
  const dispatch = useDispatch();
  const {
    permissionsList,
    permissionsFiltersLoading,
    permissionsUpdateLoading,
    profiles,
    selectedProfileId,
  } = useSelector((state) => state.permissionsReducer);
  const responsiveMode = useResponsiveMode();
  const [profileId, setProfileId] = useState<string>(
    selectedProfileId.toString(),
  );

  useEffect(() => {
    dispatch(fetchPermissionsFilters());
  }, []);

  useEffect(() => {
    setProfileId(selectedProfileId.toString());
    dispatch(fetchPermissionsList({ profileId: selectedProfileId.toString() }));
  }, [selectedProfileId]);

  const handleSubmit = () => {
    const permissionsIds: number[] = [];
    map(permissionsList, (item) => {
      if (item.allowed) {
        permissionsIds.push(item.id);
      }
    });
    dispatch(
      fetchPermissionsUpdate({
        profileId: selectedProfileId,
        permissions: permissionsIds,
      }),
    );
  };

  // const handleReset = () => {
  //   dispatch(permissionsSlice.actions.setSelectedProfile(profiles[0].id));
  // };

  return (
    <PageWrapper>
      <Grid container justifyContent='space-between'>
        <Grid item lg={12} md={12} sm={12} xs={12} margin={1}>
          <Typography
            variant={responsiveMode !== 'phone' ? 'h4' : 'h5'}
            margin={1}
            display='flex'
            justifyContent='flex-start'
            alignItems='center'
            color={palette.text.main}
          >
            <PeopleAltIcon fontSize='large' sx={{ marginRight: 2 }} />
            Gerenciamento de permissões
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <FiltersGrid loading={permissionsFiltersLoading} loadingRows={2}>
          <Grid item lg={7} md={7} sm={7} xs={12} margin={1}>
            <DropdownInput
              label='Área'
              value={profileId}
              options={profiles}
              onChange={(value) => {
                if (value === '0') {
                  dispatch(
                    permissionsSlice.actions.setSelectedProfile(profiles[0].id),
                  );
                } else {
                  dispatch(permissionsSlice.actions.setSelectedProfile(value));
                }
              }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} margin={1}>
            <Button
              variant='contained'
              label='Salvar'
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
              sx={{ width: '100%' }}
              disabled={permissionsUpdateLoading}
              loading={permissionsUpdateLoading}
            />
          </Grid>
        </FiltersGrid>
      </Grid>
      <Grid container margin={3}>
        <PermissionsTable />
      </Grid>
    </PageWrapper>
  );
};
