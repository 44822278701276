// All user related database operations can be defined here.
import { toast } from 'react-toastify';
import { api } from '..';
import {
  fetchSessionsCloseUrl,
  fetchSessionsExportExcelUrl,
  fetchSessionsExportHTMLUrl,
  fetchSessionsFiltersUrl,
  fetchSessionsInfoUrl,
  fetchSessionsListUrl,
} from './constants';
import {
  SessionsCloseResponseType,
  SessionsExportResponseType,
  SessionsFiltersResponseType,
  SessionsInfoResponseType,
  SessionsListResponseType,
} from './types';

/**
 * Function to fetch the details of the user based on userId.
 */
export const fetchSessionsFiltersService = async () => {
  return await api
    .get<SessionsFiltersResponseType>(fetchSessionsFiltersUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSessionsListService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  situation: string;
  page: string;
}) => {
  return await api
    .get<SessionsListResponseType>(fetchSessionsListUrl, { params: params })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSessionsInfoService = async (params: {
  sessionId: string;
}) => {
  return await api
    .get<SessionsInfoResponseType>(
      `${fetchSessionsInfoUrl}/${params.sessionId}`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSessionsCloseService = async (params: {
  sessionId: string;
}) => {
  return await api
    .put<SessionsCloseResponseType>(
      `${fetchSessionsCloseUrl}/${params.sessionId}/close`,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSessionsExportExcelService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  situation: string;
}) => {
  return await api
    .get<SessionsExportResponseType>(fetchSessionsExportExcelUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};

export const fetchSessionsExportHTMLService = async (params: {
  userName: string;
  startDateTime: string;
  finalDateTime: string;
  situation: string;
}) => {
  return await api
    .get<SessionsExportResponseType>(fetchSessionsExportHTMLUrl, {
      params: params,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      // toast.error(err.response.data.msg);
      return Promise.reject(err.response.data);
    });
};
