import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { find, map } from 'lodash';
import * as SecureStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import {
  fetchAuthAreasService,
  fetchAuthUpdatePasswordService,
  fetchAuthUpdateProfileService,
  fetchLoginService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { AuthStateType } from './types';

const initialState: AuthStateType = {
  email: '',
  id: 0,
  login: '',
  name: '',
  profileId: 0,
  profileDescription: '',
  operatingAreas: [],
  operatingAreasIds: [],
  areas: [],
  token: '',
  passwordChangeIsOpen: false,
  authLoading: false,
  authUpdateProfileLoading: false,
  authUpdatePasswordLoading: false,
};

export const fetchLogin = createAsyncThunk(
  'login',
  async (params: { username: string; password: string }) => {
    return await fetchLoginService(params);
  },
);

export const fetchAuthAreas = createAsyncThunk('authAreas', async () => {
  return await fetchAuthAreasService();
});

export const fetchAuthUpdateProfile = createAsyncThunk(
  'authUpdateProfile',
  async (params: { userId: string; name: string; email: string }) => {
    return await fetchAuthUpdateProfileService(params);
  },
);

export const fetchAuthUpdatePassword = createAsyncThunk(
  'authUpdatePassword',
  async (params: {
    userId: string;
    oldPassword: string;
    newPassword: string;
  }) => {
    return await fetchAuthUpdatePasswordService(params);
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (res) => {
      // This action is treated on rootReducer
      return res;
    },
    togglePasswordChangeIsOpen(state) {
      state.passwordChangeIsOpen
        ? (state.passwordChangeIsOpen = false)
        : (state.passwordChangeIsOpen = true);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.pending, (state) => {
      return {
        ...state,
        authLoading: true,
      };
    }),
      builder.addCase(fetchLogin.fulfilled, (state, res) => {
        SecureStorage.default.setItem('token', res.payload.data.token);
        SecureStorage.default.setItem('id', res.payload.data.id.toString());
        window.dispatchEvent(new Event('storage'));
        toast.success('Bem vindo!');

        return {
          ...state,
          email: res.payload.data.email,
          id: res.payload.data.id,
          login: res.payload.data.login,
          name: res.payload.data.name,
          profileId: res.payload.data.profileId,
          profileDescription: res.payload.data.profileDescription,
          operatingAreasIds: res.payload.data.operatingAreas,
          token: res.payload.data.token,
          authLoading: false,
        };
      }),
      builder.addCase(fetchLogin.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          authLoading: false,
        };
      });

    builder.addCase(fetchAuthAreas.pending, (state) => {
      return {
        ...state,
        authLoading: true,
      };
    }),
      builder.addCase(fetchAuthAreas.fulfilled, (state, res) => {
        const handledAreas: { id: string; value: string }[] = [];
        const handledOperatingAreas: { id: string; value: string }[] = [];

        map(res.payload.data.areas, (item) => {
          handledAreas.push({
            id: item.id.toString(),
            value: item.value,
          });
        });
        map(state.operatingAreasIds, (item) => {
          const area = find(res.payload.data.areas, ['id', item]);
          area !== undefined &&
            handledOperatingAreas.push({
              id: area.id.toString(),
              value: area.value,
            });
        });

        return {
          ...state,
          areas: handledAreas,
          operatingAreas: handledOperatingAreas,
          authLoading: false,
        };
      }),
      builder.addCase(fetchAuthAreas.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          authLoading: false,
        };
      });

    builder.addCase(fetchAuthUpdateProfile.pending, (state) => {
      return {
        ...state,
        authUpdateProfileLoading: true,
      };
    }),
      builder.addCase(fetchAuthUpdateProfile.fulfilled, (state, res) => {
        toast.success('Perfil editado com sucesso!');

        return {
          ...state,
          email: res.payload.data.email,
          name: res.payload.data.name,
          authUpdateProfileLoading: false,
        };
      }),
      builder.addCase(fetchAuthUpdateProfile.rejected, (state, res) => {
        return {
          ...state,
          authUpdateProfileLoading: false,
        };
      });

    builder.addCase(fetchAuthUpdatePassword.pending, (state) => {
      return {
        ...state,
        authUpdatePasswordLoading: true,
      };
    }),
      builder.addCase(fetchAuthUpdatePassword.fulfilled, (state, res) => {
        toast.success('Senha editada com sucesso!');

        return {
          ...state,
          authUpdatePasswordLoading: false,
        };
      }),
      builder.addCase(fetchAuthUpdatePassword.rejected, (state, res) => {
        return {
          ...state,
          authUpdatePasswordLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { logout } = authSlice.actions;
