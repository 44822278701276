// All user related database operations can be defined here.
import { api } from '..';
import {
  fetchAdministrativeDashboardMapInfoUrl,
  fetchAdministrativeDashboardAreasUrl,
  fetchAdministrativeDashboardNotificationsUrl,
  fetchAdministrativeDashboardSalesUrl,
  fetchAdministrativeDashboardSessionsLastWeekUrl,
  fetchAdministrativeDashboardSessionsTodayUrl,
} from './constants';
import {
  AdministrativeDashboardMapInfoResponse,
  AdministrativeDashboardAreasResponse,
  AdministrativeDashboardNotificationsResponse,
  AdministrativeDashboardSalesResponse,
  AdministrativeDashboardSessionsResponse,
} from './types';

export const fetchAdministrativeDashboardMapInfoService = async (params: {
  areas: string[];
}) => {
  return await api
    .get<AdministrativeDashboardMapInfoResponse>(
      fetchAdministrativeDashboardMapInfoUrl,
      {
        params: params,
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchAdministrativeDashboardAreasService = async () => {
  return await api
    .get<AdministrativeDashboardAreasResponse>(
      fetchAdministrativeDashboardAreasUrl,
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchAdministrativeDashboardNotificationsService = async (params: {
  areas: string[];
}) => {
  return await api
    .get<AdministrativeDashboardNotificationsResponse>(
      fetchAdministrativeDashboardNotificationsUrl,
      {
        params: params,
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchAdministrativeDashboardSalesService = async (params: {
  areas: string[];
}) => {
  return await api
    .get<AdministrativeDashboardSalesResponse>(
      fetchAdministrativeDashboardSalesUrl,
      {
        params: params,
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

export const fetchAdministrativeDashboardSessionsLastWeekService =
  async (params: { areas: string[] }) => {
    return await api
      .get<AdministrativeDashboardSessionsResponse>(
        fetchAdministrativeDashboardSessionsLastWeekUrl,
        {
          params: params,
        },
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err.response.data);
      });
  };

export const fetchAdministrativeDashboardSessionsTodayService = async (params: {
  areas: string[];
}) => {
  return await api
    .get<AdministrativeDashboardSessionsResponse>(
      fetchAdministrativeDashboardSessionsTodayUrl,
      {
        params: params,
      },
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};
