import { Global } from '@emotion/react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import {
  AppStyles,
  StyledAppOuterWrapper,
  StyledAppMidWrapper,
  StyledAppInnerWrapper,
} from './App.styles';
import { RouterConfig } from './routes';
import { theme } from './theme';

function App() {
  moment.locale();

  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns}>
    <StyledAppOuterWrapper>
      <Global styles={AppStyles} />
      <ThemeProvider theme={theme}>
        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          theme='colored'
        />
        <StyledAppMidWrapper>
          <StyledEngineProvider injectFirst>
            <StyledAppInnerWrapper>
              <RouterConfig />
            </StyledAppInnerWrapper>
          </StyledEngineProvider>
        </StyledAppMidWrapper>
      </ThemeProvider>
    </StyledAppOuterWrapper>
    // </LocalizationProvider>
  );
}

export default App;
