import { Typography } from '@mui/material';
import React from 'react';
import { LinkRoute } from '../../components';
import { LOGIN } from '../../routes';

export const NotFound: React.FC = () => {
  return (
    <>
      {/* Page Not Found! */}
      <Typography variant='h2'>Página não encontrada ;(</Typography>
      <LinkRoute to={LOGIN}>
        <Typography variant='h6'>Voltar para o Login</Typography>
      </LinkRoute>
    </>
  );
};
