import { Grid, styled } from '@mui/material';

export const StyledFiltersGrid = styled(Grid)(() => ({
  backgroundColor: 'white',
  borderRadius: 9,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  // border: '1px solid ' + palette.divider,
}));

export const StyledSkeletonGrid = styled(Grid)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledSkeletonGridRow = styled(Grid)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));
