import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { find, map } from 'lodash';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchRoutesInfoService,
  fetchRoutesCancelService,
  fetchRoutesBlockService,
  fetchRoutesCreateService,
  fetchRoutesUpdateService,
} from '../../services';
import { RootState } from '../Store';
import { RouteIdType, RouteStateType } from './types';

const initialState: RouteStateType = {
  routeInfo: null,
  routeInfoLoading: false,
  routeCreateLoading: false,
  routeCancelLoading: false,
  routeUpdateLoading: false,
  routeCancelIsOpen: false,
  routeIdToCancel: '',
};

export const fetchRouteInfo = createAsyncThunk(
  'routesInfo',
  async (params: { routeId: string }, { getState }) => {
    const state = getState() as RootState;
    const res = await fetchRoutesInfoService(params);
    return { ...res, filters: state.routesFiltersReducer.filters };
  },
);

export const fetchRouteCreate = createAsyncThunk(
  'routeCreate',
  async (params: {
    name: string;
    areaId: number;
    configurationId: number;
    coordinates: {
      address: string;
      latitude: number;
      longitude: number;
    }[];
    vacancies: {
      quantity: number;
      type: string;
    }[];
  }) => {
    return await fetchRoutesCreateService(params);
  },
);

export const fetchRouteCancel = createAsyncThunk(
  'routesCancel',
  async (params: { routeId: string }) => {
    return await fetchRoutesCancelService(params);
  },
);

export const fetchRouteBlock = createAsyncThunk(
  'routesBlock',
  async (params: { routeId: string; block: string }) => {
    return await fetchRoutesBlockService(params);
  },
);

export const fetchRouteUpdate = createAsyncThunk(
  'routeUpdate',
  async (params: {
    name: string;
    areaId: number;
    configurationId: number;
    coordinates: {
      address: string;
      latitude: number;
      longitude: number;
    }[];
    vacancies: {
      quantity: number;
      type: string;
    }[];
    routeId: string;
  }) => {
    return await fetchRoutesUpdateService(params);
  },
);

export const routeSlice = createSlice({
  name: 'routesInfo',
  initialState,
  reducers: {
    toggleRouteCancelIsOpen(state) {
      state.routeCancelIsOpen
        ? (state.routeCancelIsOpen = false)
        : (state.routeCancelIsOpen = true);
    },
    setRouteCancelId(state, action: PayloadAction<RouteIdType>) {
      state.routeIdToCancel = action.payload.routeIdToCancel;
    },
    resetRouteInfo(state) {
      state.routeInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRouteInfo.pending, (state) => {
      return {
        ...state,
        routeInfoLoading: true,
      };
    }),
      builder.addCase(fetchRouteInfo.fulfilled, (state, res) => {
        const handledVacancies: {
          quantity: number;
          type: string;
        }[] = [];
        map(res.payload.data.vacancies, (item) => {
          if (res.payload.filters && res.payload.filters.vacancyTypes) {
            const vacancyType = find(res.payload.filters.vacancyTypes, {
              value: item.type,
            });
            if (vacancyType) {
              handledVacancies.push({
                quantity: item.quantity,
                type: vacancyType.id,
              });
            }
          }
        });

        return {
          ...state,
          routeInfo: { ...res.payload.data, vacancies: handledVacancies },
          routeInfoLoading: false,
        };
      }),
      builder.addCase(fetchRouteInfo.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          routeInfoLoading: false,
        };
      });

    builder.addCase(fetchRouteCreate.pending, (state) => {
      return {
        ...state,
        routeCreateLoading: true,
      };
    }),
      builder.addCase(fetchRouteCreate.fulfilled, (state, res) => {
        toast.success('Trecho criado com sucesso');
        return {
          ...state,
          routeCreateLoading: false,
        };
      }),
      builder.addCase(fetchRouteCreate.rejected, (state) => {
        return {
          ...state,
          routeCreateLoading: false,
        };
      });

    builder.addCase(fetchRouteCancel.pending, (state) => {
      return {
        ...state,
        routeCancelLoading: true,
      };
    }),
      builder.addCase(fetchRouteCancel.fulfilled, (state, res) => {
        toast.success('Rota removida com sucesso.');
        return {
          ...state,
          routeInfo: null,
          routeCancelLoading: false,
        };
      }),
      builder.addCase(fetchRouteCancel.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          routesCancelLoading: false,
        };
      });

    builder.addCase(fetchRouteBlock.pending, (state) => {
      return {
        ...state,
      };
    }),
      builder.addCase(fetchRouteBlock.fulfilled, (state) => {
        return {
          ...state,
        };
      }),
      builder.addCase(fetchRouteBlock.rejected, (state) => {
        return {
          ...state,
        };
      });

    builder.addCase(fetchRouteUpdate.pending, (state) => {
      return {
        ...state,
        routeUpdateLoading: true,
      };
    }),
      builder.addCase(fetchRouteUpdate.fulfilled, (state, res) => {
        toast.success('Trecho editado com sucesso.');
        return {
          ...state,
          routeUpdateLoading: false,
        };
      }),
      builder.addCase(fetchRouteUpdate.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          routeUpdateLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { toggleRouteCancelIsOpen, setRouteCancelId, resetRouteInfo } =
  routeSlice.actions;
