/* eslint-disable lodash/prefer-lodash-method */
import { ThunkDispatch, EmptyObject, AnyAction } from '@reduxjs/toolkit';
import * as FileSaver from 'file-saver';
import JSZip from 'jszip';
import { map } from 'lodash';
import moment from 'moment';
import { Dispatch } from 'react';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { RoutesExportActiveVacanciesResponseType } from '../../../../services/Routes/types';
import { routesListSlice } from '../../../../stores';

export const exportActiveVacanciesToCSV = async (
  apiData: RoutesExportActiveVacanciesResponseType,
  vacancyTypes:
    | {
        id: string;
        value: string;
      }[]
    | undefined,
  dispatch: ThunkDispatch<EmptyObject & PersistPartial, undefined, AnyAction> &
    Dispatch<AnyAction>,
  worker: (
    apiData: RoutesExportActiveVacanciesResponseType,
    vacancyTypes: {
      id: string;
      value: string;
    }[],
  ) => Promise<any>,
): Promise<void> => {
  dispatch(routesListSlice.actions.setRoutesExportPercentage(20));

  if (vacancyTypes) {
    const handledApiData = await worker(apiData, vacancyTypes);

    dispatch(routesListSlice.actions.setRoutesExportPercentage(60));

    const zip = new JSZip();

    const fileType = 'data:text/csv;charset=utf-8;';
    const fileExtension = '.csv';
    dispatch(routesListSlice.actions.setRoutesExportPercentage(80));

    map(handledApiData, function (item) {
      zip.file(`${item.title}${fileExtension}`, item.body);
    });
    dispatch(routesListSlice.actions.setRoutesExportPercentage(100));

    const content = await zip.generateAsync({ type: 'blob' });

    FileSaver.saveAs(
      content,
      `trechos-vagas-ativas-${moment().format('yyyy-MM-DD_hh-mm')}` + '.zip',
    );
  }

  return;
};

export const handleApiDataActiveVacanciesToCSV = (
  apiData: RoutesExportActiveVacanciesResponseType,
  vacancyTypes: {
    id: string;
    value: string;
  }[],
) => {
  const handledActiveVacancies: {
    areaId: number;
    areaName: string;
    total: number;
    routes: {
      routeId: number;
      routeName: string;
      types: {
        type: string;
        quantity: number;
      }[];
    }[];
    columns: {
      type: string;
      hasValues: boolean;
    }[];
  }[] = [];
  let idxArea = -1;
  let idxRoute = -1;

  apiData.map((item) => {
    handledActiveVacancies.map((area, index) => {
      if (item.areaId === area.areaId) {
        idxArea = index;
      }
    });

    if (idxArea !== -1) {
      handledActiveVacancies[idxArea].routes.map((route, index) => {
        if (item.routeId === route.routeId) {
          idxRoute = index;
        }
      });

      if (idxRoute !== -1) {
        handledActiveVacancies[idxArea].routes[idxRoute].types.map((type) => {
          if (type.type === item.type) {
            type.quantity = item.quantity;
          }
        });
        handledActiveVacancies[idxArea].total += item.quantity;
        handledActiveVacancies[idxArea].columns.map((column, index) => {
          if (column.type === item.type) {
            handledActiveVacancies[idxArea].columns[index].hasValues = true;
          }
        });
        idxRoute = -1;
      } else {
        const types: {
          type: string;
          quantity: number;
        }[] = [];
        vacancyTypes.map((vacancyType) => {
          types.push({
            type: vacancyType.value,
            quantity: vacancyType.value === item.type ? item.quantity : 0,
          });
        });
        handledActiveVacancies[idxArea].routes.push({
          routeId: item.routeId,
          routeName: item.routeName,
          types: types,
        });
        handledActiveVacancies[idxArea].total += item.quantity;
        handledActiveVacancies[idxArea].columns.map((column, index) => {
          if (column.type === item.type) {
            handledActiveVacancies[idxArea].columns[index].hasValues = true;
          }
        });
      }
      idxArea = -1;
    } else {
      const types: {
        type: string;
        quantity: number;
      }[] = [];
      vacancyTypes.map((vacancyType) => {
        types.push({
          type: vacancyType.value,
          quantity: vacancyType.value === item.type ? item.quantity : 0,
        });
      });
      handledActiveVacancies.push({
        areaId: item.areaId,
        areaName: item.areaName,
        total: item.quantity,
        routes: [
          {
            routeId: item.routeId,
            routeName: item.routeName,
            types: types,
          },
        ],
        columns: types.map((type) => {
          return {
            type: type.type,
            hasValues: type.type === item.type,
          };
        }),
      });
    }
  });

  const handledApiData: {
    title: string;
    body: string;
  }[] = [];

  handledActiveVacancies.map((area, index) => {
    let header = '"Trecho/Vaga";';
    area.columns.map((column) => {
      if (column.hasValues) {
        header += `"${column.type}";`;
      }
    });
    header += '\r\n';

    handledApiData.push({
      title: area.areaName,
      body: header,
    });

    area.routes.map((route) => {
      handledApiData[index].body += `"${route.routeName}";`;
      route.types.map((type) => {
        area.columns.map((column) => {
          if (column.type === type.type && column.hasValues) {
            handledApiData[index].body += `"${type.quantity}";`;
          }
        });
      });
      handledApiData[index].body += '\r\n';
    });

    handledApiData[index].body += `"Total";"${area.total}";\r\n`;
  });

  return handledApiData;
};
