import { styled, Typography } from '@mui/material';
import { palette } from '../../../../theme';

export const StyledGoogleMapDiv = styled('div')(() => ({
  height: '70vh',
  width: '100%',
  borderBottomRightRadius: '5px',
  overflow: 'hidden',
}));

export const StyledMarkerDiv = styled(Typography)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export const StyledMapMarker = styled(Typography)(() => ({
  height: '20px',
  width: '20px',
  backgroundColor: palette.primary.main,
  whiteSpace: 'nowrap',
  padding: 5,
  borderRadius: '20px',
  boxShadow: '2px 2px 5px grey',
  position: 'absolute',
  left: '-7.5px',
  color: 'white',
  cursor: 'pointer',
  border: `3px solid ${palette.secondary.main}`,
}));
