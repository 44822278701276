import { Box, Pagination, Skeleton, Stack } from '@mui/material';
import {
  DataGridProps as MuiDataGridProps,
  DataGrid,
  GridToolbarContainer,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
} from '@mui/x-data-grid';
import React, { ReactNode, useState } from 'react';
import { useDispatch } from '../../stores';
import { StyledTableGrid } from './styles';
import { RowsState } from './types';

interface DataGridProps extends MuiDataGridProps {
  page: number;
  toolbar?: ReactNode;
  row?: any[];
  fetchRows?: Function;
  changePage: Function;
  loading?: boolean;
  paginationMode?: 'client' | 'server';
  pageSize?: number;
  detachedToolbar?: ReactNode;
  noPagination?: boolean;
}

export const Table = (props: DataGridProps) => {
  const dispatch = useDispatch();
  const [rowsState, setRowsState] = useState<RowsState>({
    page: props.page - 1,
    pageSize: props.pageSize ? props.pageSize : 15,
  });

  const CustomToolbar = () => {
    return <GridToolbarContainer>{props.toolbar}</GridToolbarContainer>;
  };

  const CustomLoadingOverlay = () => {
    return (
      <>
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
        <Skeleton animation='wave' height={60} />
      </>
    );
  };

  const CustomNoRowsOverlay = () => {
    return (
      <Stack
        height='100%'
        alignItems='center'
        justifyContent='center'
        sx={{ padding: 5 }}
      >
        Não há resultados a serem mostrados
      </Stack>
    );
  };

  const CustomNoResultsOverlay = () => {
    return (
      <Stack
        height='100%'
        alignItems='center'
        justifyContent='center'
        sx={{ padding: 5 }}
      >
        Não há resultados a serem mostrados
      </Stack>
    );
  };

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return props.noPagination ? (
      <></>
    ) : (
      <Pagination
        color='primary'
        count={pageCount}
        page={props.page}
        onChange={async (event, value) => {
          apiRef.current.setPage(value);
          if (props.changePage) {
            if (props.paginationMode !== 'client' && props.fetchRows) {
              setRowsState((prev: any) => ({ ...prev, page: value - 1 }));
              dispatch(props.changePage(value));
              props.fetchRows();
            } else {
              setRowsState((prev: any) => ({ ...prev, page: value - 1 }));
              dispatch(props.changePage(value));
            }
          }
        }}
      />
    );
  };

  return (
    <StyledTableGrid boxShadow={1}>
      {props.detachedToolbar && (
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'white',
            borderTopLeftRadius: 9,
            borderTopRightRadius: 9,
            padding: 1,
          }}
        >
          {props.detachedToolbar}
        </Box>
      )}
      <DataGrid
        {...props}
        {...rowsState}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: CustomNoRowsOverlay,
          NoResultsOverlay: CustomNoResultsOverlay,
          Pagination: CustomPagination,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        pagination={props.paginationMode !== 'server' ? true : undefined}
        paginationMode={props.paginationMode || 'server'}
        getRowClassName={() => `super-app-theme--rows`}
        disableColumnFilter
        disableColumnMenu
        autoHeight
        hideFooterSelectedRowCount
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            // textOverflow: 'clip',
            // whiteSpace: 'break-spaces',
            // lineHeight: 1,
          },
          '& .MuiDataGrid-cell': {
            overflowWrap: 'break-word !important',
            wordWrap: 'break-word !important',
            hyphens: 'auto',
            whiteSpace: 'pre-wrap !important',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
            // textOverflow: 'ellipsis',
            // maxWidth: '200px',
          },
        }}
      />
    </StyledTableGrid>
  );
};
