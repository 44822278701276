import * as Yup from 'yup';

const VEHICLE_PLATE_MIN_LENGTH = 7;

export const validation = Yup.object({
  userName: Yup.string().required('O campo nome é obrigatório'),
  vehiclePlate: Yup.string()
    .min(VEHICLE_PLATE_MIN_LENGTH, 'A placa precisa estar no formato correto')
    .required('O campo placa é obrigatório'),
});
