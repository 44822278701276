import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Grid, Paper, Typography } from '@mui/material';
import { isString, replace } from 'lodash';
import React from 'react';
import { ProgressCircle } from '../../components';
import { palette } from '../../theme';
import { StyledModalLoadingWrapper } from './styles';

export const KPIBox = (props: {
  title: string;
  value: React.ReactNode | string;
  diffValue: string;
  loading?: boolean;
}) => {
  const { title, value, diffValue = '0', loading = false } = props;

  return (
    <Grid
      item
      lg={4}
      md={4}
      sm={6}
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 2,
        width: '100%',
      }}
    >
      <Paper
        sx={{
          p: 2,
          marginRight: 2,
          marginLeft: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 120,
          width: '100%',
          backgroundColor: 'white',
          boxShadow: '0 1px 6px 1px lightgrey',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            maxWidth: '100%',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>
        {loading ? (
          <StyledModalLoadingWrapper>
            <ProgressCircle color='primary' size={30} />
          </StyledModalLoadingWrapper>
        ) : (
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flex-end',
              paddingLeft: diffValue !== '0' ? '25px' : '0px',
            }}
          >
            {isString(value) ? (
              <Typography variant='h4' color='primary'>
                {value}
              </Typography>
            ) : (
              value
            )}
            {diffValue !== '0' ? (
              parseInt(diffValue) <= 0 ? (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <ArrowUpwardIcon
                    color='success'
                    sx={{
                      fontSize: 15,
                      // textAlign: 'center',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: palette.success.main,
                      paddingBottom: '12px',
                    }}
                  >
                    {replace(diffValue, '-', '+')}
                  </Typography>
                </div>
              ) : (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: palette.error.main,
                      paddingTop: '15px',
                    }}
                  >
                    {'-' + diffValue}
                  </Typography>
                  <ArrowDownwardIcon color='error' sx={{ fontSize: 15 }} />
                </div>
              )
            ) : null}
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};
