import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { fetchRoutesFiltersService } from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { RoutesFiltersStateType } from './types';

const initialState: RoutesFiltersStateType = {
  filters: null,
  filtersLoading: false,
};

export const fetchRoutesFilters = createAsyncThunk(
  'routesFilters',
  async () => {
    return await fetchRoutesFiltersService();
  },
);

export const routesFiltersSlice = createSlice({
  name: 'routesFilters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRoutesFilters.pending, (state) => {
      return {
        ...state,
        filtersLoading: true,
      };
    }),
      builder.addCase(fetchRoutesFilters.fulfilled, (state, res) => {
        const handledAreas: { id: string; value: string }[] = [];
        const handledConfigurations: { id: string; value: string }[] = [];
        const handledVacancyTypes: { id: string; value: string }[] = [];
        const handledOperations: { id: string; value: string }[] = [];

        map(res.payload.data.areas, (item) => {
          handledAreas.push({
            id: item.id.toString(),
            value: item.value,
          });
        });
        map(res.payload.data.configurations, (item) => {
          handledConfigurations.push({
            id: item.id.toString(),
            value: item.value,
          });
        });
        map(res.payload.data.vacancyTypes, (item) => {
          if (item.id !== 0) {
            handledVacancyTypes[item.id] = {
              id: item.id.toString(),
              value: item.value,
            };
          }
        });
        map(res.payload.data.operations, (item) => {
          handledOperations.push({
            id: item.id.toString(),
            value: item.value,
          });
        });

        return {
          ...state,
          filters: {
            areas: handledAreas,
            configurations: handledConfigurations,
            vacancyTypes: handledVacancyTypes,
            operations: handledOperations,
          },
          filtersLoading: false,
        };
      }),
      builder.addCase(fetchRoutesFilters.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          filtersLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = routesFiltersSlice.actions;
