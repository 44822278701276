import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  fetchConfigurationsCancelService,
  fetchConfigurationsCreateService,
  fetchConfigurationsUpdateService,
  fetchConfigurationsInfoService,
} from '../../services';
import { ConfigurationStateType } from './types';

const initialState: ConfigurationStateType = {
  configurationInfo: null,
  configurationInfoIsOpen: false,
  configurationInfoLoading: false,
  configurationCreateLoading: false,
  configurationIdToCancel: '',
  configurationCancelIsOpen: false,
  configurationCancelLoading: false,
  configurationIdToUpdate: '',
  configurationUpdateLoading: false,
};

export const fetchConfigurationInfo = createAsyncThunk(
  'configurationInfo',
  async (params: { configurationId: string }) => {
    return await fetchConfigurationsInfoService(params);
  },
);

export const fetchConfigurationCreate = createAsyncThunk(
  'configurationCreate',
  async (params: {
    name: string;
    prices: {
      type: string;
      period: string;
      price: string;
    }[];
    workingPeriods: {
      startTime: string;
      endTime: string;
      days: string[];
    }[];
  }) => {
    return await fetchConfigurationsCreateService(params);
  },
);

export const fetchConfigurationCancel = createAsyncThunk(
  'configurationCancel',
  async (params: { configurationId: string }) => {
    return await fetchConfigurationsCancelService(params);
  },
);

export const fetchConfigurationUpdate = createAsyncThunk(
  'configurationUpdate',
  async (params: {
    configurationId: string;
    name: string;
    prices: {
      type: string;
      period: string;
      price: string;
    }[];
    workingPeriods: {
      startTime: string;
      endTime: string;
      days: string[];
    }[];
  }) => {
    return await fetchConfigurationsUpdateService(params);
  },
);

export const configurationSlice = createSlice({
  name: 'configurationInfo',
  initialState,
  reducers: {
    toggleConfigurationInfoIsOpen(state) {
      state.configurationInfoIsOpen
        ? (state.configurationInfoIsOpen = false)
        : (state.configurationInfoIsOpen = true);
    },
    setConfigurationCancelId(
      state,
      action: PayloadAction<{ configurationId: string }>,
    ) {
      state.configurationIdToCancel = action.payload.configurationId;
    },
    toggleConfigurationCancelIsOpen(state) {
      state.configurationCancelIsOpen
        ? (state.configurationCancelIsOpen = false)
        : (state.configurationCancelIsOpen = true);
    },
    setConfigurationUpdateId(
      state,
      action: PayloadAction<{ configurationId: string }>,
    ) {
      state.configurationIdToUpdate = action.payload.configurationId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfigurationInfo.pending, (state) => {
      return {
        ...state,
        configurationInfoLoading: true,
      };
    }),
      builder.addCase(fetchConfigurationInfo.fulfilled, (state, res) => {
        return {
          ...state,
          configurationInfo: {
            id: res.payload.data.id,
            name: res.payload.data.name,
            prices: res.payload.data.prices,
            workingPeriods: res.payload.data.workingPeriods,
          },
          configurationInfoLoading: false,
        };
      }),
      builder.addCase(fetchConfigurationInfo.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          configurationInfoLoading: false,
        };
      });

    builder.addCase(fetchConfigurationCreate.pending, (state) => {
      return {
        ...state,
        configurationCreateLoading: true,
      };
    }),
      builder.addCase(fetchConfigurationCreate.fulfilled, (state, res) => {
        toast.success('Configuração criada com sucesso');
        return {
          ...state,
          configurationCreateLoading: false,
        };
      }),
      builder.addCase(fetchConfigurationCreate.rejected, (state) => {
        return {
          ...state,
          configurationCreateLoading: false,
        };
      });

    builder.addCase(fetchConfigurationCancel.pending, (state) => {
      return {
        ...state,
        configurationCancelLoading: true,
      };
    }),
      builder.addCase(fetchConfigurationCancel.fulfilled, (state, res) => {
        toast.success('Área cancelada com sucesso.');
        return {
          ...state,
          configurationCancelLoading: false,
        };
      }),
      builder.addCase(fetchConfigurationCancel.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          configurationCancelLoading: false,
        };
      });

    builder.addCase(fetchConfigurationUpdate.pending, (state) => {
      return {
        ...state,
        configurationUpdateLoading: true,
      };
    }),
      builder.addCase(fetchConfigurationUpdate.fulfilled, (state, res) => {
        toast.success('Área editada com sucesso.');
        return {
          ...state,
          configurationUpdateLoading: false,
        };
      }),
      builder.addCase(fetchConfigurationUpdate.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          configurationUpdateLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleConfigurationCancelIsOpen,
  setConfigurationCancelId,
  setConfigurationUpdateId,
} = configurationSlice.actions;

export default configurationSlice.reducer;
