import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  CircularProgress,
  Typography,
} from '@mui/material';
import { transparentize } from 'polished';
import React from 'react';
import { SwitchThumb, StyledSwitchWrapper } from './styles';

interface SwitchProps extends MuiSwitchProps {
  loading?: boolean;
  checked?: boolean | undefined;
  label?: string;
}

export const Switch = (props: SwitchProps) => {
  const Icon = () => (
    <SwitchThumb checked={props.checked}>
      {props.loading && (
        <CircularProgress
          size={14}
          sx={{ color: transparentize(0.3, 'white') }}
        />
      )}
    </SwitchThumb>
  );

  return (
    <StyledSwitchWrapper>
      <MuiSwitch
        color='secondary'
        disabled={props.loading}
        checkedIcon={<Icon />}
        icon={<Icon />}
        {...props}
      />
      {props.label && <Typography color='text'>{props.label}</Typography>}
    </StyledSwitchWrapper>
  );
};
