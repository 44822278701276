import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { findIndex, map, split } from 'lodash';
import {
  fetchRoutesExportActiveVacanciesService,
  fetchRoutesExportOperatingVacanciesService,
  fetchRoutesListService,
} from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from '../Store';
import { RoutesListStateType } from './types';

const initialState: RoutesListStateType = {
  routesList: null,
  routesListLoading: false,
  routesExportOperatingVacanciesLoading: false,
  routesExportActiveVacanciesLoading: false,
  routesOperatingVacanciesExportIsOpen: false,
  routesExportPercentage: 0,
  routesExportModalIsOpen: false,
  routesPage: 1,
};

export const fetchRoutesList = createAsyncThunk(
  'routesList',
  async (
    params: {
      routeName: string | undefined;
      areaId: string | undefined;
      configurationId: string | undefined;
      operation: string | undefined;
    },
    { getState },
  ) => {
    const state = getState() as RootState;
    return await fetchRoutesListService({
      ...params,
      page: state.routesListReducer.routesPage.toString(),
    });
  },
);

export const fetchRoutesExportOperatingVacancies = createAsyncThunk(
  'routesExportOperatingVacancies',
  async (params: { startDate: string; finalDate: string }) => {
    return await fetchRoutesExportOperatingVacanciesService(params);
  },
);

export const fetchRoutesExportActiveVacancies = createAsyncThunk(
  'routesExportActiveVacancies',
  async () => {
    return await fetchRoutesExportActiveVacanciesService();
  },
);

export const routesListSlice = createSlice({
  name: 'routesList',
  initialState,
  reducers: {
    setRoutesPage(state, action: PayloadAction<number>) {
      state.routesPage = action.payload;
    },
    setRouteBlock(state, action: PayloadAction<{ routeId: string }>) {
      if (state.routesList) {
        const idx = findIndex(state.routesList.page, {
          id: parseInt(action.payload.routeId),
        });
        const page = state.routesList.page;
        page[idx] = {
          id: state.routesList.page[idx].id,
          name: state.routesList.page[idx].name,
          configurationName: state.routesList.page[idx].configurationName,
          areaName: state.routesList.page[idx].areaName,
          operation: state.routesList.page[idx].operation === 0 ? 1 : 0,
        };
        const routeList = {
          ...state.routesList,
          page: page,
        };

        state.routesList = routeList;
      }
    },
    toggleRoutesOperatingVacanciesExportIsOpen(state) {
      state.routesOperatingVacanciesExportIsOpen
        ? (state.routesOperatingVacanciesExportIsOpen = false)
        : (state.routesOperatingVacanciesExportIsOpen = true);
    },
    setRoutesExportPercentage(state, action: PayloadAction<number>) {
      state.routesExportPercentage = action.payload;
    },
    toggleExportModalIsOpen(state) {
      state.routesExportModalIsOpen = !state.routesExportModalIsOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoutesList.pending, (state) => {
      return {
        ...state,
        routesList: null,
        routesListLoading: true,
      };
    }),
      builder.addCase(fetchRoutesList.fulfilled, (state, res) => {
        return {
          ...state,
          routesList: {
            size: res.payload.data.size,
            page: res.payload.data.page,
          },
          routesListLoading: false,
        };
      }),
      builder.addCase(fetchRoutesList.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          routesListLoading: false,
        };
      });

    builder.addCase(fetchRoutesExportOperatingVacancies.pending, (state) => {
      return {
        ...state,
        routesExportOperatingVacanciesLoading: true,
        routesExportModalIsOpen: true,
        routesExportPercentage: 0,
      };
    }),
      builder.addCase(
        fetchRoutesExportOperatingVacancies.fulfilled,
        (state, res) => {
          return {
            ...state,
            routesExportOperatingVacanciesLoading: false,
            routesExportPercentage: 10,
          };
        },
      ),
      builder.addCase(fetchRoutesExportOperatingVacancies.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          routesExportOperatingVacanciesLoading: false,
          routesExportModalIsOpen: false,
          routesExportPercentage: 0,
        };
      });

    builder.addCase(fetchRoutesExportActiveVacancies.pending, (state) => {
      return {
        ...state,
        routesExportActiveVacanciesLoading: true,
        routesExportModalIsOpen: true,
        routesExportPercentage: 0,
      };
    }),
      builder.addCase(
        fetchRoutesExportActiveVacancies.fulfilled,
        (state, res) => {
          return {
            ...state,
            routesExportActiveVacanciesLoading: false,
            routesExportPercentage: 10,
          };
        },
      ),
      builder.addCase(fetchRoutesExportActiveVacancies.rejected, (state) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          routesExportActiveVacanciesLoading: false,
          routesExportModalIsOpen: false,
          routesExportPercentage: 0,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setRoutesPage,
  setRouteBlock,
  toggleRoutesOperatingVacanciesExportIsOpen,
  setRoutesExportPercentage,
  toggleExportModalIsOpen,
} = routesListSlice.actions;
