import { Grid, styled, lighten } from '@mui/material';
import { palette } from '../../theme';

export const StyledTableGrid = styled(Grid)(() => ({
  // maxHeight: 955,
  width: '100%',
  backgroundColor: 'white',
  borderRadius: 9,
  '& .super-app-theme--header': {
    backgroundColor: 'white',
  },
  '& .super-app-theme--rows': {
    backgroundColor: lighten(palette.divider, 0.6),
    '&:hover': {
      backgroundColor: palette.divider,
    },
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px dotted ${palette.text.main}`,
  },
  overflow: 'hidden',
}));
