import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { map, split } from 'lodash';
import { fetchRoutesMapInfoService } from '../../services';
import 'react-toastify/dist/ReactToastify.css';
import { RoutesMapStateType } from './types';

const initialState: RoutesMapStateType = {
  routesCoords: [],
  routesRecenterMap: false,
  routesMapInfoLoading: false,
};

export const fetchRoutesMapInfo = createAsyncThunk(
  'routesMapInfo',
  async () => {
    return await fetchRoutesMapInfoService();
  },
);

export const routesMapSlice = createSlice({
  name: 'routesMap',
  initialState,
  reducers: {
    setRecenterRoutesMap(state, action: PayloadAction<boolean>) {
      state.routesRecenterMap = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoutesMapInfo.pending, (state) => {
      return {
        ...state,
        routesCoords: [],
        routesMapInfoLoading: true,
      };
    }),
      builder.addCase(fetchRoutesMapInfo.fulfilled, (state, res) => {
        const handledRoutes: {
          id: number;
          name: string;
          areaName: string;
          color: string;
          coordinates: { latitude: number; longitude: number }[];
        }[] = [];
        map(res.payload.data, (item) => {
          const splitCoords = split(item.coordinates, '|');
          const handledCoords = map(splitCoords, (splitCoord) => {
            const coords = split(splitCoord, ';');
            return {
              latitude: parseFloat(coords[0]),
              longitude: parseFloat(coords[1]),
            };
          });

          if (item.id !== 0) {
            handledRoutes.push({
              id: item.id,
              name: item.name,
              areaName: item.areaName,
              color: item.color,
              coordinates: handledCoords,
            });
          }
        });

        return {
          ...state,
          routesCoords: handledRoutes,
          routesMapInfoLoading: false,
        };
      }),
      builder.addCase(fetchRoutesMapInfo.rejected, (state, res) => {
        // toast.error('Ocorreu um erro. Tente novamente mais tarde.');
        return {
          ...state,
          routesMapInfoLoading: false,
        };
      });
  },
});

// Action creators are generated for each case reducer function
export const { setRecenterRoutesMap } = routesMapSlice.actions;
