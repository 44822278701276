import React from 'react';
import { StyledTimePicker } from './styles';

export const TimePicker = (props: {
  label?: string;
  value: string;
  onChange: (value: any) => void;
  variant?: 'outlined' | 'filled' | 'standard';
  margin?: 'none' | 'normal' | 'dense';
  [x: string]: any;
}) => {
  return <StyledTimePicker {...props} type='time' shrink />;
};
