import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

export type ActionsFiltersValuesStateType = {
  userName: string
  profile: number
  permissions: number
  startDate: string
  finalDate: string
}

const initialState: ActionsFiltersValuesStateType = {
  userName: '',
  profile: 0,
  permissions: 0,
  startDate: moment().format('YYYY-MM-DD'),
  finalDate: moment().format('YYYY-MM-DD')
}

export const actionsFiltersValuesSlice = createSlice({
  name: 'actionsFiltersValues',
  initialState,
  reducers: {
    setActionsFiltersValues: (state, action: PayloadAction<ActionsFiltersValuesStateType>) => {
      return {
        ...state,
        ...action.payload
      }
    },
    resetActionsFiltersValues: () => {
      return {
        userName: '',
        profile: 0,
        permissions: 0,
        startDate: '',
        finalDate: ''
      }
    }
  }
});

export const {
  setActionsFiltersValues,
  resetActionsFiltersValues
} = actionsFiltersValuesSlice.actions;