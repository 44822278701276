import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles: Function = makeStyles((theme: Theme) => createStyles({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px',
    backgroundColor: '#f0f0f0',
    borderBottom: '1px solid #8b8b8b'
  }
}));