import { styled, TextField as MuiTextField } from '@mui/material';

// export const StyledCheckbox = styled(MuiTextField)(() => ({
//   // height: 60,
// }));

export const StyledCheckboxWrapper = styled('div')(() => ({
  display: 'flex',
  flexdirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));
