import { Autocomplete } from '@mui/material';
import React from 'react';
import { Input } from '../Input';

export const DropdownMultiInput = (props: {
  label: string;
  options: { id: string; value: string }[];
  defaultValue: { id: string; value: string }[];
  value: { id: string; value: string }[];
  onChange: (e: any) => void;
  errorMessage?: string;
  readOnly?: boolean;
  disabled?: boolean;
}) => {
  const { options, defaultValue, errorMessage } = props;

  return (
    <Autocomplete
      multiple
      fullWidth
      disableCloseOnSelect
      size='small'
      isOptionEqualToValue={(
        selected: {
          id: string;
          value: string;
        },
        comparison: {
          id: string;
          value: string;
        },
      ) => {
        return selected.id === comparison.id;
      }}
      options={options}
      getOptionLabel={(option) => option.value}
      defaultValue={defaultValue}
      onChange={(event, newInputValue) => props.onChange(newInputValue)}
      renderInput={(params) => (
        <Input
          {...params}
          label={props.label}
          value={''}
          error={errorMessage !== undefined}
          helperText={errorMessage}
        />
      )}
      readOnly={props.readOnly}
      disabled={props.disabled}
    />
  );
};
