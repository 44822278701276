import { useEffect, useRef } from 'react';

export function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef<Function>();
  let ticker: NodeJS.Timer | null = null;

  // User has switched back to the tab
  const onFocus = () => {
    ticker = setInterval(() => {
      if (savedCallback !== undefined && savedCallback.current !== undefined) {
        savedCallback.current();
      }
    }, delay);
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    if (ticker) {
      clearInterval(ticker);
    }
  };

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    if (delay !== null) {
      window.addEventListener('focus', onFocus);
      window.addEventListener('blur', onBlur);

      return () => {
        window.removeEventListener('focus', onFocus);
        window.removeEventListener('blur', onBlur);
        if (ticker) {
          clearInterval(ticker);
        }
      };
    }
  }, [delay]);
}
